export const porCliente = { contratante: 1008, asegurado: 1007 };
export const considerarEstratoDe = { personas: "P", empresas: "E", todos: "T" };
export const ramosObject = {
  Todos: 0,
  Incendio: 1,
  Terremoto: 3,
  Robo: 8,
  "Vehiculos Motorizados": 9,
  "Vehiculos Motorizados G2": 10,
  "Casco Marítimo": 11,
  "Casco Aéreo": 12,
  "Transporte Terrestre": 13,
  "Transporte Marítimo": 14,
  "Transporte Aéreo": 15,
  "Equipo Móvil": 16,
  "Todo Riesgo Construcción y Montaje": 17,
  "Avería de Maquinaria": 18,
  "Equipos Electrónicos": 19,
  "Responsabilidad Civil Vehíc.Motorizados": 20,
  "Responsabilidad Civil": 21,
  Multiriesgos: 22,
  "Accidentes Personales": 23,
  "S.O.A.P.": 24,
  Garantía: 25,
  Fidelidad: 26,
  Varios: 30,
  "Responsabilidad de Garage": 31,
  Agrícola: 32,
  "Locomoción Colectiva": 33,
  "Remesa de Valores": 34,
  "Vidrios, Cristales y Espejos": 35,
  "Integral del Hogar": 36,
  "Animales Finos": 37,
  Piscicultura: 38,
  "Todo Riesgo Objetos Valiosos": 39,
  "Protección de Oficina": 40,
  "Protección de Comercio": 41,
  "Protección de Hogar": 42,
  Miscelaneos: 43,
  Hogar: 44,
  "Banco Santiago Hogar": 45,
  "Multiriesgo Hogar": 46,
  Asistencias: 47,
  MultiTransporte: 48
};
