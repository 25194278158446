import React from 'react'
import TableComponet from '../../../BaseComponents/TableComponent/TableComponet'
export default class PaymentsComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {};

  }

  render() {
    return (
      <main className="main-container">
        <div>
          <h3>Payments Page (WIP)</h3>
          <TableComponet />
        </div>
      </main>  
    );
  }
}