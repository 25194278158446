export const filterosHistorial = {
    "nroCarga":"N° Carga", 
    "origenArchivo" : "Origen Archivo" ,
    "fechaHora":"Fecha/Hora" ,
    "usuario":"Usuario", 
    "tipoArchivo":"Tipo Archivo" ,
    "nombreArchivo":"Nombre Archivo" ,
    "moneda":"Moneda" ,
    "nroRegistro":"N° Registros" ,
    "montoArchivo":"Monto Archivo" ,
    "estado" :"Estado" ,
    "flag" : "Acción"
}


export const detalleDeTickets = {
    "tipoRecibo" : "Tipo Recibo", 
    "númeroReciboTicket" : "Nro Recibo-Ticket" ,
    "estado" :"Estado" ,
    "monedaOrigen" :"Moneda Origen" ,
    "montoPagadoMo" :"Monto Pagado MO" ,
    "monedaPago":"Moneda Pago" ,
    "MontoMoconvertidoaMp" :"Monto MO convertido a MP" ,
    "interésporMora":"Interes por Mora" ,
    "gastosCobranza":"Gastos Cobranza" ,
    "montoaPagarMP":"Monto a pagar MP" ,
    "montoPagadoMP":"Monto pagado MP" ,
    "diferenciaCambioMP":"Diferencia Cambio MP" 
}