import React from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Cobranza from "./PolicyDetailComponent/Cobranza";
import Produccion from "./PolicyDetailComponent/Produccion";
import Sinistros from "./PolicyDetailComponent/Sinistros";
import Reaseguro from "./PolicyDetailComponent/Reaseguro";
import MateriaAseg from "./PolicyDetailComponent/MateriaAseg";
import Coaseguro from "./PolicyDetailComponent/Coaseguro";
import { DetaileDePolizaFun } from "../../api/consulta";
import { ToastContainer, toast } from "react-toastify";
import _ from "lodash";
import "./DetaileDePoliza.scss";
import Loader from "../../../BaseComponents/Loader/Loader";

var styles = {
  default_tab: {
    backgroundColor: "#fdsfsd",
    fontWeight: 400
  },
  active_tab: {
    backgroundColor: "#3f51b5"
  }
};

function TabContainer(props) {
  return <Typography component="div">{props.children}</Typography>;
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

class DetaileDePoliza extends React.Component {
  constructor(props) {
    super(props);
    if (!props.location.state) {
      {
        let urlArr = _.split(window.location.pathname, "/");
        let navUrlStrig = "";
        if (urlArr && urlArr.length > 2) {
          navUrlStrig = "/" + urlArr[1] + "/" + urlArr[2];
        }
        this.props.history.push(navUrlStrig);
      }
    }
    this.state = {
      value: 0,
      ramo: "",
      showPolicy: false,
      showLoader: false,
      reqObj: undefined,
      responseObj: undefined,
      policyNo: undefined,
      itemNo: undefined
    };
    this.showLoaderAction = this.showLoaderAction.bind(this);
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  showLoaderAction() {
    let showLoader = this.state.showLoader;
    this.setState({
      showLoader: !showLoader
    });
  }

  /* function to display policy details information */
  showPolicyDetails(reqObj) {
    this.showLoaderAction();
    DetaileDePolizaFun(reqObj).then(response => {
      if (!_.isEmpty(response)) {
        this.setState({
          showPolicy: true,
          responseObj: response
        });
        this.showLoaderAction();
      } else {
        toast.error(response.msg || "No Policy details found", {
          position: toast.POSITION.TOP_RIGHT
        });
        this.setState({
          showPolicy: false,
          policyData: undefined
        });
        this.showLoaderAction();
      }
    });
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props;
    const policyNo = params.poliza;
    const itemNo = params.item;

    this.setState({ policyNo: policyNo, itemNo: itemNo });
    console.log("this.propsthis.props", this.props);
    console.log("this.props.location", this.props.location);
    console.log("this.props.location.state", this.props.location.state);
    let reqObj = {};
    if (policyNo) {
      reqObj.policyNo = parseInt(policyNo);
    } else {
      reqObj.policyNo = 0;
    }
    if (itemNo) {
      reqObj.itemNo = parseInt(itemNo);
    } else {
      reqObj.itemNo = 0;
    }
    if (this.props.location.state.flag === "porReferenciaCorredor") {
      reqObj.porReferenciaCorredor = true;
    }
    let lineOfBusRamoCode =
      this.props.location.state && this.props.location.state.lobDtailsData
        ? parseInt(this.props.location.state.lobDtailsData[0].codRamo)
        : 0;
    reqObj.ramos = this.props.location.state.p_R_VLO_COD_RAMOS
      ? this.props.location.state.p_R_VLO_COD_RAMOS
      : lineOfBusRamoCode;
    reqObj.rut = this.props.location.state.p_R_VLO_CODIGO_UNO;
    reqObj.porPolizaItem = this.props.location.state.p_R_VLO_COD_SUS;
    reqObj.porPoliza = this.props.location.state.porPoliza;
    reqObj.porSinistro = this.props.location.state.porSinistro;
    reqObj.nonRut = this.props.location.state.nonRut;
    this.showPolicyDetails(reqObj);
  }

  render() {
    styles.tab = [];
    styles.tab[0] = styles.default_tab;
    styles.tab[1] = styles.default_tab;
    styles.tab[2] = styles.default_tab;
    styles.tab[this.state.slideIndex] = Object.assign(
      {},
      styles.tab[this.state.slideIndex],
      styles.active_tab
    );
    let showLoader = this.state.showLoader;
    let lineOfBusRamoCode =
      this.props.location.state && this.props.location.state.lobDtailsData
        ? parseInt(this.props.location.state.lobDtailsData[0].codRamo)
        : 0;
    let ramoKey = this.props.location.state.p_R_VLO_COD_RAMOS
      ? this.props.location.state.p_R_VLO_COD_RAMOS
      : lineOfBusRamoCode;
    return (
      <main className="main-container tab-container">
        {showLoader ? <Loader /> : null}
        {this.state.showPolicy ? (
          <>
            <h3>{"Detalle De Poliza"}</h3>
            <div>
              <Paper square>
                <Tabs
                  value={this.state.value}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  className="tab"
                  selected={true}
                  onChange={this.handleChange}
                >
                  <Tab label="Producción" className="tab-button" />
                  <Tab label="Cobranza" className="tab-button" />
                  <Tab label="Siniestros" className="tab-button" />
                  <Tab label="Reaseguro" className="tab-button" />
                  <Tab label="Materia Aseg." className="tab-button" />
                  <Tab label="Coaseguro" className="tab-button" />
                </Tabs>
                {this.state.value === 0 && (
                  <TabContainer>
                    <div>
                      <Produccion data={this.state.responseObj.production} />
                    </div>
                  </TabContainer>
                )}
                {this.state.value === 1 && (
                  <TabContainer>
                    <div>
                      <Cobranza data={this.state.responseObj.cobranza} />
                    </div>
                  </TabContainer>
                )}
                {this.state.value === 2 && (
                  <TabContainer>
                    <div>
                      <Sinistros data={this.state.responseObj.siniestro} />
                    </div>
                  </TabContainer>
                )}
                {this.state.value === 3 && (
                  <TabContainer>
                    <div>
                      <Reaseguro data={this.state.responseObj.reaseguro} />
                    </div>
                  </TabContainer>
                )}
                {this.state.value === 4 && (
                  <TabContainer>
                    <div>
                      <MateriaAseg
                        data={this.state.responseObj.materiaAseg}
                        ramo={ramoKey}
                      />
                    </div>
                  </TabContainer>
                )}
                {this.state.value === 5 && (
                  <TabContainer>
                    <div>
                      <Coaseguro data={this.state.responseObj.coaseguro} />
                    </div>
                  </TabContainer>
                )}
              </Paper>
            </div>
          </>
        ) : null}
      </main>
    );
  }
}

export default DetaileDePoliza;
