import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import GlobalSearchPorClient from "../Phoenix/components/GlobalSearch/GlobalSearchPorClient";
import LineOfBusiness from "../Phoenix/components/GlobalSearch/LineOfBusiness";
import LineOfBusinessDetail from "../Phoenix/components/GlobalSearch/LineOfBusinessDetail";
import PorNegocio from "../Phoenix/components/GlobalSearch/PorNegocio";
import PorPoliza from "../Phoenix/components/GlobalSearch/PorPoliza";
import PorRamos from "../Phoenix/components/GlobalSearch/PorRamos";
import PorSiniestro from "../Phoenix/components/GlobalSearch/PorSiniestro";
import PorReferenciaCorredor from "../Phoenix/components/GlobalSearch/PorReferenciaCorredor";
import DetaileDePoliza from "../Phoenix/components/GlobalSearch/DetaileDePoliza";

export const ConsultaGlobal = ({ match }) => (
  <Switch>
    {/* Por Cliente Routing */}
    <Route
      exact
      path={`${match.url}/por-cliente`}
      component={GlobalSearchPorClient}
    />
    <Route
      exact
      path={`${match.url}/por-cliente/line-of-business/`}
      component={LineOfBusiness}
    />
    ponent={LineOfBusiness}
    />
    <Route
      exact
      path={`${match.url}/por-cliente/line-of-business/:ramo`}
      component={LineOfBusinessDetail}
    />
    <Route
      exact
      path={`${match.url}/por-cliente/line-of-business/poliza/:poliza`}
      component={DetaileDePoliza}
    />
    <Route
      exact
      path={`${match.url}/por-cliente/line-of-business/poliza/:poliza/:item`}
      component={DetaileDePoliza}
    />
    {/* Por Siniestro Routing */}
    <Route exact path={`${match.url}/por-siniestro`} component={PorSiniestro} />
    <Route
      exact
      path={`${match.url}/por-siniestro/line-of-business/:ramo`}
      component={LineOfBusinessDetail}
    />
    <Route
      exact
      path={`${match.url}/por-siniestro/line-of-business/poliza/:poliza`}
      component={DetaileDePoliza}
    />
    <Route
      exact
      path={`${match.url}/por-siniestro/line-of-business/poliza/:poliza/:item`}
      component={DetaileDePoliza}
    />
    {/* Por Referencia Corredor Routing */}
    <Route
      exact
      path={`${match.url}/por-referencia-corredor`}
      component={PorReferenciaCorredor}
    />
    <Route
      exact
      path={`${match.url}/por-referencia-corredor/line-of-business/:ramo`}
      component={LineOfBusinessDetail}
    />
    <Route
      exact
      path={`${
        match.url
      }/por-referencia-corredor/line-of-business/poliza/:poliza/:item`}
      component={DetaileDePoliza}
    />
    <Route
      exact
      path={`${
        match.url
      }/por-referencia-corredor/line-of-business/poliza/:poliza/:item`}
      component={DetaileDePoliza}
    />
    {/* Por Poliza Routing */}
    <Route exact path={`${match.url}/por-poliza`} component={PorPoliza} />
    <Route
      exact
      path={`${match.url}/por-poliza/line-of-business/:ramo`}
      component={LineOfBusinessDetail}
    />
    <Route
      exact
      path={`${match.url}/por-poliza/line-of-business/poliza/:poliza`}
      component={DetaileDePoliza}
    />
    <Route
      exact
      path={`${match.url}/por-poliza/line-of-business/poliza/:poliza/:item`}
      component={DetaileDePoliza}
    />
    {/* Por Ramos Routing */}
    <Route exact path={`${match.url}/por-ramos`} component={PorRamos} />
    <Route
      exact
      path={`${match.url}/por-ramos/line-of-business`}
      component={LineOfBusiness}
    />
    <Route
      exact
      path={`${match.url}/por-ramos/line-of-business/:ramo`}
      component={LineOfBusinessDetail}
    />
    <Route
      exact
      path={`${match.url}/por-ramos/line-of-business/poliza/:poliza`}
      component={DetaileDePoliza}
    />
    <Route
      exact
      path={`${match.url}/por-ramos/line-of-business/poliza/:poliza/:item`}
      component={DetaileDePoliza}
    />
    {/* Por N* Negocio Routing */}
    <Route exact path={`${match.url}/por-n-negocio`} component={PorNegocio} />
    <Route
      exact
      path={`${match.url}/por-n-negocio/line-of-business`}
      component={LineOfBusiness}
    />
    <Route
      exact
      path={`${match.url}/por-n-negocio/line-of-business/:ramo`}
      component={LineOfBusinessDetail}
    />
    <Route
      exact
      path={`${match.url}/por-n-negocio/line-of-business/poliza/:poliza`}
      component={DetaileDePoliza}
    />
    <Route
      exact
      path={`${match.url}/por-n-negocio/line-of-business/poliza/:poliza/:item`}
      component={DetaileDePoliza}
    />
  </Switch>
);
