import React from "react";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import "./GlobalSearchPorClient.scss";
import { getNombre, getrazorsocial } from "./../../api/policy";
import { ToastContainer, toast } from "react-toastify";
import { translate, Trans } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Loader from "../../../BaseComponents/Loader/Loader";
import { Link } from "react-router-dom";
import DyanmicTableWithSorting from "../../../BaseComponents/DyanmicTableWithSorting/DyanmicTableWithSorting";
import { listOfRamos } from "../../../Constants/listOfRamos";
import {
  porCliente,
  considerarEstratoDe
} from "../../../Constants/consultaConstant";
import moment from "moment";
import SearchIcon from "@material-ui/icons/Search";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
import esLocale from "date-fns/locale/es";
import enLocale from "date-fns/locale/en-US";
import Select from "react-select";
import { connect } from "react-redux";
import { stat } from "fs";
import _ from "lodash";

const styles = theme => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: "100vh",
    overflow: "auto"
  },
  progress: {
    margin: theme.spacing.unit * 2
  },
  grid: {
    width: "70%"
  }
});

const localeMap = {
  es: esLocale,
  en: enLocale
};

const PolizaDetailColumn = {
  srno: "",
  rutCliente: "Rut Cliente",
  razonSocial: "Razón Social"
};

class GlobalSearchPorClient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nombreData: undefined,
      nombre: "",
      searchTextRut: "",
      showNombre: false,
      showLoader: false,
      enableSearch: true,
      enableBuskar: true,
      openDailog: false,
      porClienteRadio: "contratante",
      criterioRadio: "fechaDeVigencia",
      considerarRadio: "todos",
      considerarAnRadio: "poliza",
      porClenteValue: porCliente["contratante"],
      ramosValue: 0,
      ramoText: listOfRamos[0].label,
      ramoNumber: listOfRamos[0].value,
      considerarRadioValue: considerarEstratoDe["todos"],
      startDate: new Date(new Date().getFullYear(), 0, 1),
      endDate: new Date(new Date().getFullYear(), 0, 1),
      fetchHastaDate: new Date()
    };
  }

  componentDidMount() {
    if (this.props.porClienteObj && !_.isEmpty(this.props.porClienteObj)) {
      let {
        searchTextRut,
        nombre,
        startDate,
        endDate,
        considerarAnRadio,
        considerarRadio,
        considerarRadioValue,
        criterioRadio,
        porClenteValue,
        porClienteRadio,
        ramosValue,
        ramoNumber,
        ramoText
      } = this.props.porClienteObj;
      if (searchTextRut && nombre && startDate && endDate) {
        this.setState({
          searchTextRut: searchTextRut,
          nombre: nombre,
          startDate: startDate,
          endDate: endDate,
          enableSearch: false,
          enableBuskar: false,
          considerarAnRadio: considerarAnRadio,
          considerarRadioValue: considerarRadioValue,
          criterioRadio: criterioRadio,
          porClenteValue: porClenteValue,
          porClienteRadio: porClienteRadio,
          ramosValue: ramosValue,
          considerarRadio: considerarRadio,
          ramoNumber: ramoNumber,
          ramoText: ramoText
        });
      }
    }
  }

  handleChangeRut = event => {
    const value = event.target.value;
    let enableSearch = value.length > 4 ? false : true;
    this.setState({
      searchTextRut: value,
      enableSearch: enableSearch,
      enableBuskar: true
    });
  };

  handleChangeNombre = event => {
    const value = event.target.value;
    let enableSearch = value.length > 4 ? false : true;
    this.setState({
      nombre: value,
      enableSearch: enableSearch
    });
  };

  handleChangeStartDate = (chageDate, event) => {
    this.setState({
      startDate: chageDate
    });
  };

  handleChangeEndDate = (chageDate, event) => {
    this.setState({
      endDate: chageDate
    });
  };

  handleChangeFetchaHastaDate = (chageDate, event) => {
    this.setState({
      fetchHastaDate: chageDate
    });
  };

  handlePorClienteRadio = event => {
    const value = event.target.value;
    const setConsiderar = value === "contratante" ? "poliza" : "item";
    this.setState({
      porClienteRadio: value,
      porClenteValue: porCliente[value],
      considerarAnRadio: setConsiderar
    });
  };

  handleChangeCriterioRadio = event => {
    this.setState({
      criterioRadio: event.target.value
    });
  };

  handleConsiderarEstraRadio = event => {
    let value = event.target.value;
    this.setState({
      considerarRadio: value,
      considerarRadioValue: considerarEstratoDe[value]
    });
  };

  handleKeyPressRut = event => {
    let inputRutValue = event.target.value;
    if (event.key === "Enter" && inputRutValue.length > 4) {
      this.getNombreByRutNumber(inputRutValue);
    } else {
      this.setState({
        enableBuskar: true
      });
    }
  };

  handleKeyPressRazorSocial = event => {
    let inputValue = event.target.value;
    if (event.key === "Enter" && inputValue.length > 4) {
      this.getRazorSocial(inputValue);
    }
  };

  handleClose = () => {
    this.setState({ openDailog: false });
  };

  showLoaderAction = () => {
    let showLoader = this.state.showLoader;
    this.setState({
      showLoader: !showLoader
    });
  };

  /* function to get nombre */

  getNombreByRutNumber = rutNumber => {
    let rut = this.state.searchText || rutNumber;
    this.showLoaderAction();
    getNombre(rut).then(response => {
      if (response && response.data && response.data.nombre) {
        this.setState({
          showNombre: true,
          nombreData: response.data,
          nombre: response.data.nombre,
          enableBuskar: false
        });
      } else {
        toast.error(response.msg || "Please enter R.u.t. number", {
          position: toast.POSITION.TOP_RIGHT
        });
        this.setState({
          showNombre: false,
          nombreData: undefined,
          nombre: "",
          enableBuskar: true
        });
      }
      this.showLoaderAction();
    });
  };

  /* function to get getRazorSocial */

  getRazorSocial = rutNumber => {
    let nombre = this.state.nombre || rutNumber;
    this.showLoaderAction();
    getrazorsocial(nombre).then(response => {
      if (response && response.data) {
        this.setState({
          showNombre: true,
          nombreData: response.data.razorSocial,
          openDailog: true
        });
      } else {
        toast.error(response.msg || "Please enter R.u.t. number", {
          position: toast.POSITION.TOP_RIGHT
        });
        this.setState({
          showNombre: false,
          nombreData: undefined
        });
      }
      this.showLoaderAction();
    });
  };

  searchRutNumber = () => {
    if (this.state.searchTextRut) {
      this.getNombreByRutNumber(this.state.searchTextRut);
    } else {
      this.getRazorSocial(this.state.nombre);
    }
  };

  selectHandleChange = actionMeta => {
    if (actionMeta) {
      this.setState({
        ramosValue: actionMeta.value,
        ramoText: actionMeta.label,
        ramoNumber: actionMeta.value
      });
    }
    console.groupEnd();
  };

  setRutNombre = (rutText, NombreText) => {
    this.setState({
      searchTextRut: rutText,
      nombre: NombreText,
      enableBuskar: false
    });
    this.handleClose();
  };

  resetSearch = () => {
    this.setState({
      porClienteRadio: "contratante",
      criterioRadio: "fechaDeVigencia",
      considerarRadio: "todos",
      considerarAnRadio: "poliza",
      nombre: "",
      searchTextRut: "",
      nombreData: undefined,
      enableSearch: true,
      enableBuskar: true,
      porClienteRadio: "contratante",
      criterioRadio: "fechaDeVigencia",
      considerarRadio: "todos",
      considerarAnRadio: "poliza",
      porClenteValue: porCliente["contratante"],
      ramosValue: 0,
      ramoText: listOfRamos[0].label,
      ramoNumber: listOfRamos[0].value,
      considerarRadioValue: considerarEstratoDe["todos"],
      startDate: new Date(new Date().getFullYear(), 0, 1),
      endDate: new Date(new Date().getFullYear(), 0, 1),
      fetchHastaDate: new Date()
    });
    this.props.deletePorRamosFormData();
  };

  porClienteSearch = () => {
    this.props.addPorFormData(this.state);
  };

  getDate = date => {
    return moment(date).format("YYYYMMDD");
  };

  render() {
    const { classes } = this.props;
    const { i18n } = this.props;
    let translateObj =
      (i18n.store.data[i18n.language] &&
        i18n.store.data[i18n.language].translations) ||
      i18n.store.data["es"].translations;

    const stateObj = {
      porClente: this.state.porClenteValue,
      rutNumber: parseInt(this.state.searchTextRut),
      ramos: this.state.ramosValue,
      ConsiderarEstratoDe: this.state.considerarRadioValue,
      flag: "porCliente"
    };

    if (this.state.criterioRadio === "carteraVigente") {
      stateObj.startDate = 0;
      stateObj.endDate = moment(this.state.fetchHastaDate).format("YYYYMMDD");
      stateObj.polEndDate = moment(this.state.fetchHastaDate).format(
        "YYYYMMDD"
      );
      stateObj.p_R_VST_CODIGO_DOS = "C";
    } else {
      stateObj.polEndDate = 0;
      stateObj.startDate = moment(this.state.startDate).format("YYYYMMDD");
      stateObj.endDate = moment(this.state.endDate).format("YYYYMMDD");
    }

    if (this.state.considerarAnRadio === "item") {
      stateObj.unidadCase = true;
    }
    let showLoader = this.state.showLoader;
    return (
      <main className={classes.content + " home-page"}>
        <div className="mainComponent">
          <Card className="Card-area box-show-none">
            <fieldset className="custom-border">
              <legend className="legend-box">Por Cliente</legend>
              <div className="container">
                <div className="row porClientRowClass">
                  <div className="col-sm-4">
                    <div>
                      <FormControl
                        component="fieldset"
                        className={classes.formControl}
                      >
                        <FormLabel component="legend" />
                        <RadioGroup
                          className={classes.group}
                          value={this.state.porClienteRadio}
                          onChange={this.handlePorClienteRadio}
                          row={true}
                        >
                          <FormControlLabel
                            value="contratante"
                            control={<Radio color="primary" />}
                            label="Contratante"
                          />
                          <FormControlLabel
                            value="asegurado"
                            control={<Radio color="primary" />}
                            label="Asegurado"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                  <div className="col-sm-8">
                    <TextField
                      id="custom-css-standard-input"
                      label="R.u.t"
                      type="search"
                      className="porClenteInp"
                      margin="normal"
                      value={this.state.searchTextRut}
                      onChange={this.handleChangeRut}
                      onKeyPress={this.handleKeyPressRut}
                    />
                    <TextField
                      id="custom-css-standard-input"
                      label="Nombre"
                      type="search"
                      className="porClenteInp"
                      margin="normal"
                      value={this.state.nombre}
                      onChange={this.handleChangeNombre}
                      onKeyPress={this.handleKeyPressRazorSocial}
                    />
                    <Button
                      variant="contained"
                      size="medium"
                      color="primary"
                      disabled={this.state.enableSearch}
                      className="search-icon search-policy"
                      onClick={this.searchRutNumber.bind()}
                    >
                      <SearchIcon />
                      {/* {"Buscar"} */}
                    </Button>
                  </div>
                </div>
                <Divider />
                <div className="row porClientRowClass">
                  {this.state.criterioRadio === "fechaDeVigencia" ? (
                    <>
                      <div className="col date-picker-mod">
                        <MuiPickersUtilsProvider
                          utils={DateFnsUtils}
                          locale={localeMap["es"]}
                        >
                          <Grid
                            container
                            className={classes.grid}
                            justify="space-around"
                          >
                            <DatePicker
                              margin="normal"
                              keyboard
                              label="Fecha Inicio"
                              format="dd/MM/yyyy"
                              placeholder="10/10/2018"
                              value={this.state.startDate}
                              onChange={this.handleChangeStartDate}
                              disableOpenOnEnter
                              animateYearScrolling={false}
                            />
                          </Grid>
                        </MuiPickersUtilsProvider>
                      </div>
                      <div className="col">
                        <MuiPickersUtilsProvider
                          utils={DateFnsUtils}
                          locale={localeMap["es"]}
                        >
                          <Grid
                            container
                            className={classes.grid}
                            justify="space-around"
                          >
                            <DatePicker
                              margin="normal"
                              keyboard
                              label="Fecha Término"
                              format="dd/MM/yyyy"
                              placeholder="10/10/2018"
                              value={this.state.endDate}
                              onChange={this.handleChangeEndDate}
                              disableOpenOnEnter
                              animateYearScrolling={false}
                            />
                          </Grid>
                        </MuiPickersUtilsProvider>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Grid
                            container
                            className={classes.grid}
                            justify="space-around"
                          >
                            <DatePicker
                              margin="normal"
                              keyboard
                              label="Fecha Hasta"
                              format="dd/MM/yyyy"
                              placeholder="10/10/2018"
                              value={this.state.fetchHastaDate}
                              onChange={this.handleChangeFetchaHastaDate}
                              disableOpenOnEnter
                              animateYearScrolling={false}
                              disabled
                            />
                          </Grid>
                        </MuiPickersUtilsProvider>
                      </div>
                      <div className="col" />
                    </>
                  )}

                  <div className="col">
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <FormLabel component="legend">
                        Considerar a nivel de
                      </FormLabel>
                      <RadioGroup
                        className={classes.group}
                        value={this.state.considerarAnRadio}
                        row={true}
                      >
                        <FormControlLabel
                          value="poliza"
                          control={<Radio color="primary" />}
                          label="Poliza"
                        />
                        <FormControlLabel
                          value="item"
                          control={<Radio color="primary" />}
                          label="Item"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div className="col">
                    <span className="label-class">Ramos</span>
                    <div className="ramosClass">
                      <Select
                        maxMenuHeight={200}
                        isSearchable={true}
                        value={{
                          label: this.state.ramoText,
                          value: this.state.ramoNumber
                        }}
                        onChange={this.selectHandleChange}
                        options={listOfRamos.map(option => ({
                          value: option.value,
                          label: option.label
                        }))}
                      />
                    </div>
                  </div>
                </div>
                <Divider />
                <div className="row porClientRowClass">
                  <div className="col">
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <FormLabel component="legend">Criterio</FormLabel>
                      <RadioGroup
                        className={classes.group}
                        value={this.state.criterioRadio}
                        onChange={this.handleChangeCriterioRadio}
                        row={true}
                      >
                        <FormControlLabel
                          value="fechaDeVigencia"
                          control={<Radio color="primary" />}
                          label="Fecha De Vigencia"
                        />
                        <FormControlLabel
                          value="cierreProduccionHistorica"
                          disabled
                          control={<Radio color="primary" />}
                          label="Cierre Produccion Historica"
                        />
                        <FormControlLabel
                          value="cierreProduccionalDia"
                          disabled
                          control={<Radio color="primary" />}
                          label="Cierre Produccion al Dia"
                        />
                        <FormControlLabel
                          value="carteraVigente"
                          control={<Radio color="primary" />}
                          label="Cartera Vigente"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
                <Divider />
                <div className="row porClientRowClass">
                  <div className="col">
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <FormLabel component="legend">
                        Considerar Estrato de
                      </FormLabel>
                      <RadioGroup
                        className={classes.group}
                        value={this.state.considerarRadio}
                        onChange={this.handleConsiderarEstraRadio}
                        row={true}
                      >
                        <FormControlLabel
                          value="personas"
                          control={<Radio color="primary" />}
                          label="Personas"
                        />
                        <FormControlLabel
                          value="empresas"
                          control={<Radio color="primary" />}
                          label="Empresas"
                        />
                        <FormControlLabel
                          selected
                          value="todos"
                          control={<Radio color="primary" />}
                          label="Todos"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div className="col">
                    <Button
                      variant="contained"
                      size="medium"
                      color="primary"
                      className="search-policy resetButtonClass"
                      onClick={this.resetSearch}
                    >
                      {"Limpiar"}
                    </Button>
                    {!this.state.enableBuskar ? (
                      <Link
                        to={{
                          pathname: `/consulta-global/por-cliente/line-of-business/`,
                          state: stateObj
                        }}
                        className="BuskarLink"
                      >
                        <Button
                          variant="contained"
                          size="medium"
                          color="primary"
                          className="search-policy"
                          onClick={this.porClienteSearch}
                          disabled={this.state.enableBuskar}
                        >
                          {"Consulta"}
                        </Button>
                      </Link>
                    ) : null}
                    {this.state.enableBuskar ? (
                      <div className="BuskarLink">
                        <Button
                          variant="contained"
                          size="medium"
                          color="primary"
                          className="search-policy"
                          disabled={this.state.enableBuskar}
                        >
                          {"Consulta"}
                        </Button>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </fieldset>
          </Card>
          {showLoader ? <Loader /> : null}
          {this.state.openDailog ? (
            <>
              <Dialog
                open={this.state.openDailog}
                onClose={this.handleClose}
                aria-labelledby="responsive-dialog-title"
              >
                <div style={{ width: 600 }}>
                  <DialogTitle
                    id="responsive-dialog-title"
                    className="dailog-title-style"
                  >
                    Nombre : {this.state.nombre}
                  </DialogTitle>
                  <DialogContent>
                    <DyanmicTableWithSorting
                      data={this.state.nombreData}
                      keyArray={PolizaDetailColumn}
                      orderBy={PolizaDetailColumn[0]}
                      pathData={{}}
                      isPaginationReq={true}
                      isEnhancedTableHeadReq={false}
                      defaultRowPerPage={this.state.defaultRowPerPage}
                      setRutNombre={this.setRutNombre}
                    />
                  </DialogContent>
                </div>
              </Dialog>
            </>
          ) : null}
        </div>
        <ToastContainer />
      </main>
    );
  }
}

function mapStateToProps(state) {
  console.log("state------>", state);
  return {
    porClienteObj: state.porClienteObj
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addPorFormData: porReduxReqObj =>
      dispatch({ type: "ADD_POR_CLIENTE_FORM_DATA", porReduxReqObj }),
    deletePorRamosFormData: () =>
      dispatch({ type: "DELETE_POR_CLIENTE_FORM_DATA" })
  };
}

// let data= withStyles(styles)(GlobalSearchPorClient);
// export default translate("translations")(data);

let GlobalSearchPorClientObj = connect(
  mapStateToProps,
  mapDispatchToProps
)(GlobalSearchPorClient);
let data = withStyles(styles)(GlobalSearchPorClientObj);
export default translate("translations")(data);
