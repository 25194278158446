import React from "react";
import DyanmicTableWithSorting from "../../../../BaseComponents/DyanmicTableWithSorting/DyanmicTableWithSorting";
import { translate, Trans } from "react-i18next";
import _ from "lodash";
import CobranzaKeys from "./CobranzaKeys.json";
import Button from "@material-ui/core/Button";
import CloudDownload from "@material-ui/icons/CloudDownload";
import { geCobranzaExcel } from "./../../../api/ExcelDownload";
import EmptyState from "../../../../BaseComponents/EmptyState/EmptyState";
import VolverButton from "../../../../BaseComponents/VolverButtons/VolverButton";
import "./tabs.scss";

class Cobranza extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cobranzaData: undefined,
      isPaginationReq: false,
      defaultRowPerPage: 10,
      isEnhancedTableHeadReq: false,
      showCobranza: true
    };
  }

  componentDidMount() {
    let showCobData =
      this.props.data && this.props.data.length > 0 ? true : false;
    this.setState({
      cobranzaData: this.props.data,
      showCobranza: showCobData
    });
  }

  downloadEcel = () => {
    geCobranzaExcel(CobranzaKeys, this.state.cobranzaData);
  };

  render() {
    return (
      <div className="tab-class">
        {this.state.showCobranza ? (
          <>
            <DyanmicTableWithSorting
              data={this.state.cobranzaData}
              keyArray={CobranzaKeys}
              orderBy={CobranzaKeys[0]}
              pathData={{}}
              isPaginationReq={this.state.isPaginationReq}
              isEnhancedTableHeadReq={this.state.isEnhancedTableHeadReq}
              defaultRowPerPage={this.state.defaultRowPerPage}
              virticalScroll={true}
              tableMaxHeight={"400"}
            />
            <Button
              variant="contained"
              size="medium"
              color="primary"
              disabled={false}
              className="search-icon search-policy"
              style={{ float: "right", marginBottom: "5px" }}
              onClick={this.downloadEcel}
            >
              <CloudDownload className="download-icon" />
              {"Excel"}
            </Button>
            <VolverButton />
          </>
        ) : (
          <div className={"content"}>
            <EmptyState
              emoticon={"🔍"}
              message={"No se encontraron datos de Cobranza"}
            />
          </div>
        )}
      </div>
    );
  }
}

export default translate("translations")(Cobranza);
