import React from "react";
import DyanmicTableWithSorting from "../../../BaseComponents/DyanmicTableWithSorting/DyanmicTableWithSorting";
import EmptyState from "../../../BaseComponents/EmptyState/EmptyState";
import { translate, Trans } from "react-i18next";
import { getCheckDetails } from "../../api/CajaPagadora";
import Loader from "../../../BaseComponents/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import Card from "@material-ui/core/Card";
import _ from "lodash";
import CheckDetailKeys from "./CheckDetailKeys.json";
import { Button } from "@material-ui/core";

const styles = theme => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: "100vh",
    overflow: "auto"
  },
  progress: {
    margin: theme.spacing.unit * 2
  },
  grid: {
    width: "60%"
  }
});

class CheckDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      numero: "",
      checkDetails: undefined,
      emptyState: false,
      CheckDetailsTable: undefined,
      apiReqObj: props.location.rowData
    };
  }

  showLoader() {
    let showLoader = this.state.showLoader;
    this.setState({ showLoader: !showLoader });
  }

  getCheckDetailsFun(reqObj) {
    this.showLoader();
    getCheckDetails(reqObj)
      .then(res => {
        if (res.data && res.data.checkheader) {
          let dataToDisplay = res.data.checkheader[0];
          let emptyState = res.data.checkheader.length > 0 ? false : true;
          let modifiedData = dataToDisplay;
          this.setState({
            checkDetails: modifiedData,
            CheckDetailsTable: res.data.checkDetail,
            emptyState: emptyState
          });
          // toast.success(res.msg, { position: toast.POSITION.TOP_RIGHT });
        } else {
          toast.error(res.msg || "Detalle de Cheque N°", {
            position: toast.POSITION.TOP_RIGHT
          });
        }
        this.showLoader();
      })
      .catch(ex => {
        console.log(ex);
      });
  }

  componentDidMount() {
    const { match: { params } } = this.props;
    const {numero, folio,secuen,nroSolicitudPago} = params;
    let reqObj = {numero};
    this.setState({
      numero: numero,
      folio : folio,
      secuen:  secuen,
      nroSolicitudPago    : nroSolicitudPago 
    });
    this.getCheckDetailsFun(reqObj);
  }
  goToSiniestrosVehiculos = ()=>{
    let {folio ,secuen , nroSolicitudPago} =  this.state;
    this.props.history.push(`/vehiculos/siniestros-vehiculos/${folio}/${secuen}/${nroSolicitudPago}`)
  }

  render() {
    let displayObj = this.state.checkDetails ?  this.state.checkDetails : {};
    let showLoader = this.state.showLoader;
    const { classes } = this.props;
    return (
      <main className={classes.content + " home-page"}>
        <div className="mainComponent">
          {showLoader ? <Loader /> : null}

          {displayObj ? 
            <div>
              {
              this.state.folio  && this.state.secuen  && this.state.nroSolicitudPago ? 
              <div className="text-align-right">
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  className="search-policy"
                  onClick={this.goToSiniestrosVehiculos}
                  >
                  {"Volver"}
                </Button>
              </div>
              : null
            }
            <Card className="Card-area box-show-none">
              <fieldset className="custom-border">
                <legend className="legend-box">
                  Detalle de Cheque N° {this.state.numero}
                </legend>
                <div className="container">
                  <div className="row porClientRowClass">
                    <div className="col-sm-3">
                      <TextField
                        InputLabelProps={{
                          shrink: true
                        }}
                        id="standard-bare"
                        label="Concepto"
                        className="textField"
                        value={displayObj.ori_var_nombre}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          readOnly: true
                        }}
                      />
                    </div>
                    <div className="col-sm-3">
                      <TextField
                        InputLabelProps={{
                          shrink: true
                        }}
                        id="standard-bare"
                        label="Sucursal"
                        className="textField"
                        value={displayObj.nombreOficina}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          readOnly: true
                        }}
                      />
                    </div>
                    <div className="col-sm-3">
                      <TextField
                        InputLabelProps={{
                          shrink: true
                        }}
                        id="standard-bare"
                        label="RUT"
                        className="textField"
                        value={displayObj.rutBenificia}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          readOnly: true
                        }}
                      />
                    </div>
                    <div className="col-sm-3">
                      <TextField
                        InputLabelProps={{
                          shrink: true
                        }}
                        id="standard-bare"
                        label="Nombre"
                        className="textField"
                        value={displayObj.nombre}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          readOnly: true
                        }}
                      />
                    </div>
                  </div>
                  <Divider />
                  <div className="row porClientRowClass">
                    <div className="col-sm-3">
                      <TextField
                        InputLabelProps={{
                          shrink: true
                        }}
                        id="standard-bare"
                        label="Banco"
                        className="textField"
                        value={displayObj.nombreBanco}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          readOnly: true
                        }}
                      />
                    </div>
                    <div className="col-sm-3">
                      <TextField
                        InputLabelProps={{
                          shrink: true
                        }}
                        id="standard-bare"
                        label="N° Cuenta Corriente"
                        className="textField"
                        value={displayObj.ctacte}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          readOnly: true
                        }}
                      />
                    </div>
                    <div className="col-sm-3">
                      <TextField
                        InputLabelProps={{
                          shrink: true
                        }}
                        id="standard-bare"
                        label="N° Cheque"
                        className="textField"
                        value={this.state.numero}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          readOnly: true
                        }}
                      />
                    </div>
                    <div className="col-sm-3">
                      <TextField
                        InputLabelProps={{
                          shrink: true
                        }}
                        id="standard-bare"
                        label="Monto"
                        className="textField"
                        value={displayObj.monto}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          readOnly: true
                        }}
                      />
                    </div>
                  </div>
                  <div className="row porClientRowClass">
                    <div className="col-sm-3">
                      <TextField
                        InputLabelProps={{
                          shrink: true
                        }}
                        id="standard-bare"
                        label="Fecha Emisión"
                        className="textField"
                        value={displayObj.fechaEmision}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          readOnly: true
                        }}
                      />
                    </div>
                  </div>
                  <Divider />
                  <div className="row porClientRowClass">
                    <div className="col-sm-3">
                      <TextField
                        InputLabelProps={{
                          shrink: true
                        }}
                        id="standard-bare"
                        label="Estado"
                        className="textField"
                        value={displayObj.nombreEstado}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          readOnly: true
                        }}
                      />
                    </div>
                    <div className="col-sm-3">
                      <TextField
                        InputLabelProps={{
                          shrink: true
                        }}
                        id="standard-bare"
                        label="N° Nómina despacho"
                        className="textField"
                        value={displayObj.nominaDespacho}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          readOnly: true
                        }}
                      />
                    </div>
                    <div className="col-sm-3">
                      <TextField
                        InputLabelProps={{
                          shrink: true
                        }}
                        id="standard-bare"
                        label="Indicador"
                        className="textField"
                        value={displayObj.indicator}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          readOnly: true
                        }}
                      />
                    </div>
                    <div className="col-sm-3">
                      <TextField
                        InputLabelProps={{
                          shrink: true
                        }}
                        id="standard-bare"
                        label="Descripción ContraCuenta"
                        className="textField"
                        value={displayObj.descripcionContraCuenta}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          readOnly: true
                        }}
                      />
                    </div>
                  </div>
                  <Divider />
                  <div className="row porClientRowClass">
                    <div className="col-sm-3">
                      <TextField
                        InputLabelProps={{
                          shrink: true
                        }}
                        id="standard-bare"
                        label="Rut Retirante"
                        className="textField"
                        value={displayObj.rutRetirante}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          readOnly: true
                        }}
                      />
                    </div>
                    <div>
                      <span className="Space-around">
                        <TextField
                          InputLabelProps={{
                            shrink: true
                          }}
                          id="standard-bare"
                          label="Nombre Retirante"
                          className="textField"
                          value={displayObj.nombreRetirante}
                          margin="normal"
                          variant="outlined"
                          InputProps={{
                            readOnly: true
                          }}
                          style = {{width: 450}}
                        />
                      </span>
                    </div>
                    <div>
                      <span className="Space-around">
                        <TextField
                          InputLabelProps={{
                            shrink: true
                          }}
                          id="standard-bare"
                          label="Fecha Retiro"
                          className="textField"
                          value={displayObj.fechaRetiro}
                          margin="normal"
                          variant="outlined"
                          InputProps={{
                            readOnly: true
                          }}
                        />
                      </span>
                    </div>
                    <div>
                      <span className="Space-around">
                      <TextField
                        InputLabelProps={{
                          shrink: true
                        }}
                        id="standard-bare"
                        label="Comentario Entrega"
                        className="textField"
                        value={" "}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          readOnly: true
                        }}
                        style = {{width: 350}}
                      />
                      </span>                    
                    </div>
                  </div>
                  <div>
                    <DyanmicTableWithSorting
                      data={this.state.CheckDetailsTable}
                      keyArray={CheckDetailKeys}
                      orderBy={CheckDetailKeys[0]}
                      pathData={{}}
                      isPaginationReq={this.state.isPaginationReq}
                      isEnhancedTableHeadReq={this.state.isEnhancedTableHeadReq}
                      defaultRowPerPage={this.state.defaultRowPerPage}
                      virticalScroll={true}
                      tableMaxHeight={"400"}
                    />
                  </div>
                </div>
              </fieldset>
            </Card>
            </div>
          : null}
        </div>
        <ToastContainer />
      </main>
    );
  }
}

// export default translate("translations")(CheckDetails);

export default withStyles(styles)(CheckDetails);