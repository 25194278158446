import React from "react";
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import PropTypes from "prop-types";
import Divider from "@material-ui/core/Divider";
import { withStyles } from "@material-ui/core/styles";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Grid from "@material-ui/core/Grid";
import { translate, Trans } from "react-i18next";
import Loader from "../../../../BaseComponents/Loader/Loader";
import { polizaTableOneKeys, polizaTableTwoKeys, detalleDistribucionKeys } from "../../../../Constants/reaseguradoresKeys";
import DyanmicTableWithSorting from "../../../../BaseComponents/DyanmicTableWithSorting/DyanmicTableWithSorting";
import DyanmicTable from "./../../../../BaseComponents/DyanmicTableParticipation/DyanmicTable"
import { getConsultaTableDetails } from "../../../api/reaseguradores";
import { Link } from "react-router-dom";
import "../Reaseguradores.scss";

import { ToastContainer, toast } from "react-toastify";

const styles = theme => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing.unit * 3,
    },
    group: {
        margin: `${theme.spacing.unit}px 0`,
    },
});


class ReporteDistribucion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoader: false,
            coaseguro: "",
            diasVigencia: "",
            forzado: "",
            hasta: "",
            moneda: "",
            proporcional: "",
            tipoRegla: "",
            resumeDeCondiciones: "",
        }

    }

    showLoaderAction() {
        let showLoader = this.state.showLoader;
        this.setState({
            showLoader: !showLoader
        });
    }

    // goToPreviousPage = () => {
    //     let { policyID } = this.state.id;
    //     this.props.history.push(`/reaseguradores/consultapordistribucion/${policyID}`)
    // };



    componentDidMount() {
        const { match: { params } } = this.props;
        console.log(params.id)
        let id = params.id;//479853
        this.setState({ id: id })
        this.showLoaderAction();
        getConsultaTableDetails(id).then((response) => {
            console.log(response)
            let polizaTable = undefined;
            let datosGeneralesDetails = undefined;
            let detalleDistribucion = undefined;
            let detalleDistribucionDetails = undefined;
            let detalleDistribucionTotal = undefined;
            let tableData = [];
            this.showLoaderAction();
            if (response && response.data && response.data.policydetail) {
                polizaTable = (response.data && response.data.policydetail) ? response.data.policydetail : undefined;
                datosGeneralesDetails = (response.data && response.data.datosGenerales) ? response.data.datosGenerales[0] : undefined;
                detalleDistribucion = (response.data && response.data.detalleDistribucion) ? response.data.detalleDistribucion : undefined;
                detalleDistribucionTotal = (response.data && response.data.detalleDistribucionTotal) ? response.data.detalleDistribucionTotal : undefined;
                console.log("====",detalleDistribucion,detalleDistribucionTotal  )
                for (let key in detalleDistribucion) {
                    if(!detalleDistribucion[key].tipoParticipacion){
                        detalleDistribucion[key]["child"]=true;
                    }else{
                        detalleDistribucion[key]["bold"] = true
                    }
                    tableData.push(detalleDistribucion[key]);
                }
                detalleDistribucionTotal[0]["total"] = true;
                detalleDistribucionTotal[0]["fixed"] = true;
                tableData.push(detalleDistribucionTotal[0])
                this.setState({
                    polizaTable: polizaTable,
                    coaseguro: datosGeneralesDetails.coaseguro,
                    diasVigencia: datosGeneralesDetails.diasVigencia,
                    forzado: datosGeneralesDetails.forzado,
                    hasta: datosGeneralesDetails.hasta,
                    moneda: datosGeneralesDetails.moneda,
                    proporcional: datosGeneralesDetails.proporcional,
                    tipoRegla: datosGeneralesDetails.tipoRegla,
                    desde: datosGeneralesDetails.desde,
                    flag: response.data.flag,
                    tableData: tableData,
                    resumeDeCondiciones: response.data.resumen
                });
            }
            !polizaTable && toast.error((response && response.msg) || "No Distribucion Details found", {
                position: toast.POSITION.TOP_RIGHT
            });

        }).catch((err) => {
            this.showLoaderAction();
            toast.error("Internal Server Error", { position: toast.POSITION.TOP_RIGHT });
        })

    }


    render() {
        return (
            <main className="main-container">
                {this.state.showLoader ? <Loader /> : null}
                {this.state.polizaTable ?
                    <Card className="Card-area box-show-none">
                        <h3>{"Reporte Distribucion: " + this.state.id}</h3>
                        <fieldset className="custom-border">
                            <legend className="legend-box">Detalle Polizas</legend>
                            <DyanmicTableWithSorting
                                data={this.state.polizaTable}
                                keyArray={(this.state.flag) ? polizaTableOneKeys : polizaTableTwoKeys}
                                orderBy={(this.state.flag) ? polizaTableOneKeys[0] : polizaTableTwoKeys[0]}
                                virticalScroll={true}
                                tableMaxHeight={"400"}
                                isEnhancedTableHeadReq={false}
                                virticalScroll={true}
                            />
                        </fieldset>
                        <fieldset className="custom-border margin-top-30px">
                            <legend className="legend-box">Datos Genarales</legend>
                            <div className="box container" >
                                <div className="row row-border">
                                    <div className="headerStyle border-right col-sm-3">
                                        Vigencias
                        </div>
                                    <div className="col-sm-9">
                                        <div className="row border-bottom ">
                                            <div className="headerStyle  col-sm-4">
                                                Desde
                                 </div>
                                            <div className="headerStyle col-sm-4">
                                                Hasta
                                </div>
                                            <div className="headerStyle col-sm-4">
                                                Dias Vigencia
                                </div>
                                        </div>
                                        <div className="row">
                                            <div className="border-right col-sm-4 coloumn-style">
                                                {this.state.desde}
                                            </div>
                                            <div className="border-right  col-sm-4 coloumn-style">
                                                {this.state.hasta}
                                            </div>
                                            <div className="border-right col-sm-4 coloumn-style">
                                                {this.state.diasVigencia}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-border ">
                                    <div className="headerStyle col-sm-3 align-center">
                                        Moneda
                        </div>
                                    <div className="col-sm-3 align-center">
                                        {this.state.moneda}
                                    </div>
                                    <div className=" headerStyle col-sm-3">
                                        Coaseguro
                                 </div>
                                    <div className="col-sm-3 align-center">
                                        {this.state.coaseguro}
                                    </div>
                                </div>
                                <div className="row row-border ">
                                    <div className="headerStyle col-sm-3">
                                        Proporcional
                        </div>
                                    <div className="col-sm-3 align-center">
                                        {this.state.proporcional}
                                    </div>
                                    <div className=" headerStyle col-sm-3">
                                        Tipo Regla
                                 </div>
                                    <div className="col-sm-3 align-center">
                                        {this.state.tipoRegla}
                                    </div>
                                </div>

                                <div className="row row-border">
                                    <div className="headerStyle col-sm-3 align-center">
                                        Forzado
                        </div>
                                    <div className="  col-sm-9 align-center">
                                        {this.state.forzado}
                                    </div>

                                </div>



                            </div>
                        </fieldset>
                        <fieldset className="custom-border margin-top-30px">
                            <legend className="legend-box">Detalle Distribucion</legend>
                            <DyanmicTableWithSorting
                                data={this.state.tableData}
                                keyArray={detalleDistribucionKeys}
                                orderBy={detalleDistribucionKeys[0]}
                                virticalScroll={true}
                                tableMaxHeight={"450"}
                                hideColoumn={["tipoParticipacion", "montoAsegurado", "prima", "dcto"]}
                            />
                        </fieldset>
                        <fieldset className="custom-border margin-top-30px">
                            <legend className="legend-box">Resumen de Condiciones</legend>
                            <TextField
                                fullWidth
                                className="textField"
                                margin="normal"
                                variant="outlined"
                                value={this.state.resumeDeCondiciones}
                            />
                        </fieldset>
                        <div className="row porClientRowClass">
                            <div className="col-sm-11" />
                            <div className="col-sm-1">
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                  //  onClick={this.goToPreviousPage}
                                    className="search-policy pull-right resetButtonClass ">Volver</Button>
                            </div>
                        </div>
                    </Card>
                    : null}
                <ToastContainer />
            </main >
        );
    }
}


ReporteDistribucion.propTypes = {
    classes: PropTypes.object.isRequired
};

export default translate("translations")(withStyles(styles)(ReporteDistribucion));


