import React from "react";
import DyanmicTableWithSorting from "../../../BaseComponents/DyanmicTableWithSorting/DyanmicTableWithSorting";
import EndorsementKey from "./EndorsementKey.json";
import TextField from "@material-ui/core/TextField";
import { translate, Trans } from "react-i18next";
import Button from "@material-ui/core/Button";
import { getEndorsementByPolicy } from "./../../api/policy";
import Loader from "../../../BaseComponents/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import "./EndorsementsComponent.scss";
import _ from "lodash";

class EndorsementsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      data: undefined,
      EndorsementKeyArray: EndorsementKey,
      enableSearch: true,
      searchText: "",
      blnShowEndorsement: false,
      showLoader: false,
      isPaginationReq: false,
      isEnhancedTableHeadReq: false,
      showVolver: false
    };

    this.resetSearch = this.resetSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.showEndorsementList = this.showEndorsementList.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props;
    const policyNo = params.policyNo;
    if (policyNo) {
      this.setState({ searchText: policyNo, showVolver: true });
      this.showEndorsementList(policyNo);
      this.setState({ enableSearch: false });
    }
  }

  showLoaderAction() {
    let showLoader = this.state.showLoader;
    this.setState({ showLoader: !showLoader });
  }

  showEndorsementList(policyNo) {
    this.showLoaderAction();
    if (this.props.match.path == "/endorsement") {
      this.props.history.push("endorsement/" + this.state.searchText);
    }
    getEndorsementByPolicy(this.state.searchText || policyNo)
      .then(response => {
        if (response && response.data && !_.isEmpty(response.data)) {
          var resObj = response.data;
          let totalObj = {};
          totalObj.poliza = "Total:";
          totalObj.fechaProd = "";
          totalObj.endos = "";
          totalObj.referencia = "";
          totalObj.tip = "";
          totalObj.vigDesde = "";
          totalObj.vigHasta = "";
          totalObj.primaNetaAfecta = resObj.tot_affecta;
          totalObj.primaNetaExenta = resObj.tot_Exenta;
          totalObj.primaBruta = resObj.tot_Bruta;
          totalObj.pagosContado = resObj.tot_Contado;
          totalObj.devoluciones = resObj.tot_Devoluciones;
          totalObj.primaDocumentada = resObj.tot_Documentado;
          totalObj.primaPendiente = resObj.tot_Pendiente;
          totalObj.propuesta = "";
          totalObj.origen = "";
          totalObj.total = true;
          resObj.endorsement.push(totalObj);
          this.setState({
            blnShowEndorsement: true,
            data: resObj.endorsement
          });
          this.showLoaderAction();
        } else {
          toast.error(
            response.msg || "No Endorsement found for given policy number",
            {
              position: toast.POSITION.TOP_RIGHT
            }
          );
          this.setState({
            data: undefined,
            blnShowEndorsement: false
          });
          this.showLoaderAction();
        }
      })
      .catch(err => {
        this.showLoaderAction();
        console.error(err);
      });
    this.setState({ blnShowEndorsement: true });
  }

  handleChange(event) {
    const value = event.target.value;
    let bln = value.length > 0 ? false : true;
    this.setState({ searchText: value, enableSearch: bln });
  }

  handleKeyPress(event) {
    if (event.key === "Enter") {
      this.showEndorsementList();
    }
  }

  resetSearch() {
    this.setState({
      blnShowEndorsement: false,
      searchText: "",
      data: undefined
    });
  }

  backToPreviousPage = () => {
    if (window.location.pathname.indexOf("/endorsement") !== -1) {
      this.setState({
        blnShowEndorsement: false,
        searchText: "",
        showVolver: false
      });
    }
    window.history.back();
  };

  render() {
    const { i18n } = this.props;
    const blnShowEndorsement = this.state.blnShowEndorsement;
    let showLoader = this.state.showLoader;
    let translateObj =
      (i18n.store.data[i18n.language] &&
        i18n.store.data[i18n.language].translations) ||
      i18n.store.data["es"].translations;

    const data = {
      route: "/endorsement-details/",
      search: {
        policyNo: this.state.searchText
      },
      key: "endos"
    };

    return (
      <main className="main-container">
        {showLoader ? <Loader /> : null}
        <div className="main-screen">
          <div className="search-wrapper">
            <TextField
              id="standard-search"
              label={translateObj.policy_page.search_placeholder}
              type="search"
              className="text-field"
              margin="normal"
              value={this.state.searchText}
              onChange={this.handleChange}
              onKeyPress={this.handleKeyPress}
            />
            <Button
              variant="contained"
              size="medium"
              color="primary"
              className="search-policy"
              disabled={this.state.enableSearch}
              onClick={this.showEndorsementList.bind()}
            >
              {translateObj.policy_page.search_btn}
            </Button>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              className="search-policy"
              onClick={this.resetSearch.bind()}
            >
              {translateObj.policy_page.reset_btn}
            </Button>
            {this.state.showVolver ? (
              <Button
                variant="contained"
                size="medium"
                color="primary"
                className="search-policy"
                onClick={this.backToPreviousPage}
              >
                {"Volver"}
              </Button>
            ) : null}
          </div>

          {blnShowEndorsement && this.state.data ? (
            <DyanmicTableWithSorting
              data={this.state.data}
              keyArray={this.state.EndorsementKeyArray}
              orderBy={this.state.EndorsementKeyArray[0]}
              pathData={data}
              isPaginationReq={this.state.isPaginationReq}
              isEnhancedTableHeadReq={this.state.isEnhancedTableHeadReq}
              virticalScroll={true}
              tableMaxHeight={"400"}
            />
          ) : null}
        </div>
        <ToastContainer />
      </main>
    );
  }
}

export default translate("translations")(EndorsementsComponent);
