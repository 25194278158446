import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import './DyanmicTable.scss';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import EnhancedTableHead from "./EnhancedTableHead";
import  _ from 'lodash'
import Tooltip from '@material-ui/core/Tooltip';


const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: "100%"
  },
});

function getPath(data){
  let path = "";
  let key = ""
  if(data && data.route){
    path +=data.route; 
  }

  if(data && data.search) {
    const keys = _.keys(data.search)
    const searchtext = parseInt(data.search[keys[0]])
    path = path + searchtext+ "/" 
  }

  if(data && data.key) {
    key  = data.key
  }

  return {path , key};
}


function DyanmicTable(props) {
  const { classes } = props;
  const data = props.data;
  const id = props.id;
  const rows = props.keyArray;
  const route  = props.route || {};
  const{ path , key } =  getPath(route);
  let align=props.align||"left";
  const tableMaxHeight=props.tableMaxHeight;
  return (
    <div>
        { data ?
        <Paper className={classes.root +" max-height-"+ tableMaxHeight}>
            <Table className={classes.table}>
                <EnhancedTableHead
                  keyArray={rows}
                  align={props.align}
                  virticalScroll={props.virticalScroll}
                />
                <TableBody>
                {data.map((d, index) => (
                    <TableRow key={index}>
                        {(Array.isArray(rows)?rows:Object.keys(rows)).map((row, index2) => (
                            <TableCell align={align} key={index2} className="centered-align">
                            {
                              path && key ?      
                              <Tooltip title="Click For More Detail">
                                <Link to={path+d[key]} className="text-decoration-none default-color">
                                  {d[row]}
                                </Link>
                               </Tooltip>
                              : d[row]
                            } 
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </Paper>
        : null 
        }
    </div>
    
  );
}

DyanmicTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DyanmicTable);