import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n"; // added translattion packg for adding localization
import { runWithAdal } from "react-adal";
import { authContext } from "./utils/adalConfig";
import "typeface-roboto";
import { createStore } from "redux";
import { Provider } from "react-redux";

let envirnment =
  window.location.host && window.location.host.indexOf("localhost") == -1
    ? true
    : false;

const phoenix = (state = {}, action) => {
  switch (action.type) {
    case "ADD_POR_CLIENTE_FORM_DATA":
      return Object.assign({}, state, {
        porClienteObj: action.porReduxReqObj
      });
    case "DELETE_POR_CLIENTE_FORM_DATA":
      return Object.assign({}, state, {
        porClienteObj: {}
      });
    case "ADD_POR_SINIESTROS_FORM_DATA":
      return Object.assign({}, state, {
        porSiniestrosObj: action.porReduxReqObj
      });
    case "DELETE_POR_SINIESTROS_FORM_DATA":
      return Object.assign({}, state, {
        porSiniestrosObj: {}
      });
    case "ADD_POR_REFERENCIA_FORM_DATA":
      return Object.assign({}, state, {
        porReferenciaObj: action.porReduxReqObj
      });
    case "DELETE_POR_REFERENCIA_FORM_DATA":
      return Object.assign({}, state, {
        porReferenciaObj: {}
      });
    case "ADD_POR_POLIZA_FORM_DATA":
      return Object.assign({}, state, {
        porPolizaObj: action.porReduxReqObj
      });
    case "DELETE_POR_POLIZA_FORM_DATA":
      return Object.assign({}, state, {
        porPolizaObj: {}
      });
    case "ADD_POR_RAMOS_FORM_DATA":
      return Object.assign({}, state, {
        porRamosObj: action.porReduxReqObj
      });
    case "DELETE_POR_RAMOS_FORM_DATA":
      return Object.assign({}, state, {
        porRamosObj: {}
      });
    case "ADD_POR_NEGOCIO_FORM_DATA":
      return Object.assign({}, state, {
        porNegocioObj: action.porReduxReqObj
      });
    case "DELETE_POR_NEGOCIO_FORM_DATA":
      return Object.assign({}, state, {
        porNegocioObj: {}
      });
    default:
      return state;
  }
};
const store = createStore(phoenix);
const DO_NOT_LOGIN = false;

// {envirnment ?
runWithAdal(
  authContext,
  () => {
    ReactDOM.render(
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
      </Provider>,
      document.getElementById("root")
    );
  },
  DO_NOT_LOGIN
);
// :
// 	ReactDOM.render(
// 		<I18nextProvider i18n={i18n}>
// 			<App />
// 		</I18nextProvider>,
// 		document.getElementById("root")
// 	);
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

serviceWorker.unregister();

if (module.hot) {
  module.hot.accept();
}
