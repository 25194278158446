import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import FilterosHistorial from '../Phoenix/components/CajaPenta/FilterosHistorial'
import DetalleDeTickets from '../Phoenix/components/CajaPenta/DetalleDeTickets'
import ConsultaDeTickets from '../Phoenix/components/CajaPenta/ConsultaDeTickets'
import DetalleDeComprobante from '../Phoenix/components/CajaPenta/DetalleDeComprobante'

export const CajaPenta = ({ match }) => (
    <Switch>
        <Route exact path={`${match.url}/filterosh-historial`} component={FilterosHistorial}/>
        <Route exact path={`${match.url}/filterosh-historial/detalle-de-tickets/:nroCarga/:moneda/:oficina/:periodoContable/:fechaHora`} component={DetalleDeTickets}/>
        <Route exact path={`${match.url}/consulta-de-tickets`} component={ConsultaDeTickets}/>
        <Route exact path={`${match.url}/consulta-de-tickets/detalle-de-comprobante/:oficina/:anoContable/:mesContable/:tipoDocumento/:correlativoint`} component={DetalleDeComprobante}/>
    </Switch>
   
  ) 