import React from 'react';
import PropTypes, { object } from 'prop-types';
// import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Paper from "@material-ui/core/Paper";
import Tab from '@material-ui/core/Tab';
import Divider from "@material-ui/core/Divider";
import Typography from '@material-ui/core/Typography';
import { translate, Trans } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';

import ClassesDeContrato from "./ClassesDeContrato";
import Limities from "./Limities";
import { getNombreContratoDropdown, getClasesdeContratoDetails,getDescuentosDetails, getLimitesDetails, getParticipacion, getLimitesPorZona, getRamosVCoberturas, getCondicionesData  } from "../../../api/reaseguradores";
import Loader from "../../../../BaseComponents/Loader/Loader";

import Participaciones from "./Participaciones";
import Descuentos from "./Descuentos";

import CondicionesEspeciales from "./CondicionesEspeciales";
import RamosYCoberturas from "./RamosYCoberturas";

import { ToastContainer, toast } from "react-toastify";
import LimitesPorZona from './LimitesPorZona';
import DataGenerales from "./DatasGenerales";
import '../Reaseguradores.scss'

function createCustomisedTable(responseData){
    let finalData=[];
    let totalArray=[];
    let totalValue=0;
    for(let i in responseData){
        let index=finalData.findIndex(singleObj=>singleObj.clase===responseData[i].clase &&singleObj.nombreCia===responseData[i].nombreCia )
        let totalindex=finalData.findIndex(singleObj=>singleObj.clase===responseData[i].clase)
        if(totalindex==-1 && finalData.length){
            let totalPartipacion={total:true,"nombreSecurity":"Total Participacion"+responseData[i-1].clase , "partSecurity":totalValue}
            let totalRetencion={total:true,"nombreSecurity":"Retencion Pentasecurity("+responseData[i-1].clase+")" , "partSecurity":(100-totalValue).toString()};
            totalArray.push(totalPartipacion);
            totalArray.push(totalRetencion);
            totalValue=parseFloat(responseData[i].partSecurity);
        }else{
            totalValue=totalValue+parseFloat(responseData[i].partSecurity);
        }
        if(index==-1){
            let parentObj=Object.assign({},responseData[i])
            parentObj.nombreSecurity=parentObj.nombreCia;
            parentObj.partSecurity=parentObj.porParticipacion
            responseData[i].child=true;
            finalData.push(parentObj);
            responseData[i].nombreSecurity ?finalData.push(responseData[i]):"";
        }else{
            responseData[i].child=true;
            finalData.push(responseData[i])
        }
    }
    let totalPartipacion={total:true,"nombreSecurity":"Total Participacion"+responseData[((responseData.length)-1)].clase, "partSecurity":totalValue}
    let totalRetencion={total:true,"nombreSecurity":"Retencion Pentasecurity("+responseData[((responseData.length)-1)].clase+")", "partSecurity":(100-totalValue).toString()};
    totalArray.push(totalPartipacion);
    totalArray.push(totalRetencion);
    finalData=finalData.concat(totalArray);
    return finalData;
}
function createTab5Data(arrayList){
    let i=0, result=[];
    let parteTotal=0;
    let exdenteTotal=0;
    let totalTotal=0;
    while(i<arrayList.length){
        parteTotal=parteTotal+arrayList[i].limite;
        exdenteTotal=exdenteTotal+arrayList[i+1].limite;
        totalTotal=totalTotal+arrayList[i].limite+arrayList[i+1].limite;
        let obj={"zone":arrayList[i].zona,"cparte":arrayList[i].limite, "exdente":arrayList[i+1].limite, "totalCol":arrayList[i].limite+arrayList[i+1].limite }
        result.push(obj);
        i=i+2;
    }
    let finaObj={"zone":"Total", "cparte":parteTotal,"exdente":exdenteTotal, "totalCol":totalTotal, "total":true }
    result.push(finaObj);
    return result;
}

function TabContainer(props) {
    return (
        <Typography component="div">
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

var styles = {
    default_tab: {
        backgroundColor: "#fdsfsd",
        fontWeight: 400
    },
    active_tab: {
        backgroundColor: "#3f51b5"
    }
};

class Contratos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            showLoader: false,
            reqObj: undefined,
            responseObj: undefined,
            showContratos: false,
            showTabs: false,
            firstTabData: {
                avisoPasoSiniestro: "",
                fechaVigDesde: null,
                fechaVigHasta: null,
                indiceRiesgo: "",
                iri: "",
                limitacionCapacidad: "",
                nombreMoneda: "",
                periodoLiquidacion: "",
                retencionExclusiva: "",
                codigoContrato: "",
                nombreContrato:"",
                codigoContrato:""
            }
        };
        this.showLoaderAction = this.showLoaderAction.bind(this);
    }
    componentDidMount() {
        this.showLoaderAction();
        getNombreContratoDropdown().then((response) => {
            this.showLoaderAction()
            if (response && response.data && response.data.dropdownDetails) {
                this.setState({
                    responseObj: response.data.dropdownDetails,
                    showContratos: true,
                })
            } else {
                toast.error(response.msg, { position: toast.POSITION.TOP_RIGHT });
            }
        }).catch((err) => {
            this.showLoaderAction();
            toast.error("Something went wrong", { position: toast.POSITION.TOP_RIGHT });
        })
    }

    showLoaderAction() {
        let showLoader = this.state.showLoader;
        this.setState({
            showLoader: !showLoader
        });
    }

    setTabState = (value) => {
        if(value===1 && !this.state.tabData2){
            this.getSecondTabData(this.state.nombreContrato);
        }else if(value===2 && !this.state.tabData3){
            this.getThirdTabData(this.state.nombreContrato);
        }else if(value===3 && !this.state.tabData4){
            this.getFourthTabData(this.state.nombreContrato);           
        }else if(value===4 && !this.state.tab5Data){
            this.getFifthTabData(this.state.nombreContrato);
        }else if(value===5){
            this.getTabSixData(this.state.nombreContrato)            
        }else if(value===6 && !this.state.tabData7Table1){
            this.getSevenTabData(this.state.nombreContrato);
        }else if(value===7 && !this.state.getTabEightData){
            this.getTabEightData(this.state.nombreContrato);
        }
        this.setState({ value: value });
    }
    setVisibilityOfTab = (value, responseObj, isAllClear) => {
        if (value) {
            this.setState({ showTabs: true,nombreContrato:responseObj.codigoContrato, contratonumber:responseObj.codigoContrato, firstTabData:responseObj });
        } else{
            this.setState({ showTabs: false ,nombreContrato:responseObj.codigoContrato, contratonumber:responseObj.codigoContrato, firstTabData:responseObj });
        }
        if(isAllClear==="clearData"){
            this.setState({
                tabData2:undefined,
                tabData3:undefined,
                tabData4:undefined,
                tab5Data:undefined,
                tabData7Table1:undefined,
                tabData7Table2:undefined,
                tab8Data:undefined,
                firstTabData:{
                    avisoPasoSiniestro: "",
                    fechaVigDesde: null,
                    fechaVigHasta: null,
                    indiceRiesgo: "",
                    iri: "",
                    limitacionCapacidad: "",
                    nombreMoneda: "",
                    periodoLiquidacion: "",
                    retencionExclusiva: "",
                    codigoContrato: "",
                    nombreContrato:"",
                    codigoContrato:""
                }
            })
        }
    }
    handleChange = (event, value) => {
        this.setState({ value });
    };

    getSecondTabData = (nombreContrato) => {
        let tabData2 = undefined;
        this.showLoaderAction();
        getClasesdeContratoDetails(nombreContrato||this.state.nombreContrato).then((response) => {
            this.showLoaderAction();
            tabData2 = (response.data && response.data["contrato "]) ? response.data["contrato "] : undefined;
            this.setState({ tabData2: tabData2, value: 1, nombreContrato: nombreContrato })
            !tabData2 && toast.error(response.msg || "No contrato details found", {
                position: toast.POSITION.TOP_RIGHT
            });
        }).catch((ex) => {
            this.showLoaderAction();
            toast.error("Internal Server Error", {
                position: toast.POSITION.TOP_RIGHT
            });
        })
    }

    getThirdTabData = (nombreContrato) => {
        let tabData3 = undefined;
        this.showLoaderAction();
        getLimitesDetails(nombreContrato||this.state.nombreContrato).then((response) => {
            this.showLoaderAction();
            tabData3 = (response.data && response.data.limitesContratos) ? response.data.limitesContratos : undefined;
            this.setState({ tabData3: tabData3, value: 2, nombreContrato: nombreContrato })
            !tabData3 && toast.error(response.msg || "No contrato details found", {
                position: toast.POSITION.TOP_RIGHT
            });
        }).catch((ex) => {
            this.showLoaderAction();
            toast.error("Internal Server Error", {
                position: toast.POSITION.TOP_RIGHT
            });


        })
    }

    getFourthTabData=(nombreContrato)=>{
        this.showLoaderAction();
        getParticipacion(nombreContrato||this.state.nombreContrato).then((response) => {
            this.showLoaderAction();
            if(response.data && response.data.participaciones && response.data.participaciones.length){
                let finalData=createCustomisedTable(response.data.participaciones);
                this.setState({
                    tabData4:finalData
                })
            }else{
                this.setState({
                    tabData4:undefined
                })
                toast.error(response.msg || "No contrato details found", {
                    position: toast.POSITION.TOP_RIGHT
                });
                console.log("no data found")
            }
        }).catch((error)=>{
            this.showLoaderAction();
            console.error("error", error);
        })
    }

    getFifthTabData=(nombreContrato)=>{
        this.showLoaderAction();
        getLimitesPorZona(nombreContrato||this.state.nombreContrato).then((response) => {
            this.showLoaderAction();
            if(response.data && response.data.limitesPor && response.data.limitesPor.length){
                let tab5Data= createTab5Data(response.data.limitesPor);
                this.setState({
                    tabData5:tab5Data
                })
            }else{
                this.setState({
                    tabData5:undefined
                })
                toast.error(response.msg || "No contrato details found", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }).catch((error)=>{
            this.showLoaderAction();
            console.error("error", error);
        })
    }

    getTabSixData=(nombreContrato)=>{
        this.showLoaderAction();
        getDescuentosDetails(nombreContrato||this.state.nombreContrato).then((response) => {
            this.showLoaderAction();
            if(response && response.data && response.data.descuentos && response.data.descuentos.length){
                this.setState({
                    tabData6:response.data.descuentos
                })
            }else{
                this.setState({
                    tabData6:undefined
                })
                toast.error(response.msg || "No contrato details found", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }).catch((error)=>{
            this.showLoaderAction();
            console.error("error", error);
        })
    }

    getSevenTabData=(nombreContrato)=>{
        this.showLoaderAction();
        getRamosVCoberturas(nombreContrato||this.state.nombreContrato).then((response) => {
            this.showLoaderAction();
            if(response.data && response.data.coberturas && response.data.coberturas.length){
                this.setState({
                    tabData7Table1:response.data.coberturas,
                    tabData7Table2:response.data.coberturas1
                })
            }else{
                this.setState({
                    tabData7Table1:undefined,
                    tabData7Table2:undefined
                })
                toast.error(response.msg || "No contrato details found", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }).catch((error)=>{
            this.showLoaderAction();
        })
    }
    getTabEightData=(nombreContrato)=>{
        this.showLoaderAction();
        getCondicionesData(nombreContrato||this.state.nombreContrato).then((response) => {
            this.showLoaderAction();
            if(response.data && response.data.especiales){
                this.setState({
                    tab8Data:response.data.especiales,
                })
            }else{
                this.setState({
                    tab8Data:undefined
                })
            }
        }).catch((error)=>{
            this.showLoaderAction();
            console.error("error", error);
        })
    }

    render() {
        styles.tab = [];
        styles.tab[0] = styles.default_tab;
        styles.tab[1] = styles.default_tab;
        styles.tab[2] = styles.default_tab;
        styles.tab[this.state.slideIndex] = Object.assign(
            {},
            styles.tab[this.state.slideIndex],
            styles.active_tab
        );
        let showLoader = this.state.showLoader;
        return (
            <main className="main-container">
                {showLoader ? <Loader /> : null}

                {this.state.showContratos ? (
                    <>
                        <div>
                            <Paper square>
                                <Tabs
                                    value={this.state.value}
                                    onChange={this.handleChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    selected={true}
                                    className="tab border-bottom"
                                    variant="scrollable"
                                    scrollButtons="auto"
                                >
                                    <Tab label="Datos Generales"  className="tab-button" />
                                    <Tab label="Clases de Contrato" disabled={!this.state.showTabs} className="tab-button" onClick={this.getSecondTabData.bind(null, this.state.contratonumber)} />
                                    <Tab label="Limites" className="tab-button" disabled={!this.state.showTabs} onClick={this.getThirdTabData.bind(null, this.state.contratonumber)} />
                                    <Tab label="Participaciones" className="tab-button" disabled={!this.state.showTabs} onClick={this.getFourthTabData.bind(null, this.state.contratonumber) } />
                                    <Tab label="Limites por Zona" className="tab-button" disabled={!this.state.showTabs} onClick={this.getFifthTabData.bind(null, this.state.contratonumber) }/>
                                    <Tab label="Descuentos" className="tab-button" disabled={!this.state.showTabs} onClick={this.getTabSixData.bind(null, this.state.contratonumber)}/>
                                    <Tab label="Ramos y Coberturas" className="tab-button" disabled={!this.state.showTabs} onClick={this.getSevenTabData.bind(null, this.state.contratonumber) } />
                                    <Tab label="Condiciones Especiales" className="tab-button" disabled={!this.state.showTabs} onClick={this.getTabEightData.bind(null, this.state.contratonumber) }/>
                                </Tabs>

                                {this.state.value === 0 && (
                                    <TabContainer>
                                        <div>
                                            <DataGenerales data={this.state.responseObj} searchFuntion={this.getSecondTabData} enableAllTabs={this.setVisibilityOfTab} firstTabData={this.state.firstTabData} />
                                        </div>
                                    </TabContainer>
                                )}

                                {this.state.value === 1 && <TabContainer>
                                    < ClassesDeContrato data={this.state.tabData2} goToDataGenerales={this.setTabState} contratoNo={this.state.nombreContrato} goToLimites={this.getThirdTabData} />
                                </TabContainer>}
                                {this.state.value === 2 && <TabContainer>
                                    <Limities data={this.state.tabData3} goToClasesDeContrato={this.setTabState} getFourthTabData={this.getFourthTabData}/>
                                </TabContainer>}
                                {this.state.value === 3 && <TabContainer>
                                    < Participaciones data={this.state.tabData4}  goToClasesDeContrato={this.setTabState} getFifthTabData={this.getFifthTabData} />
                                </TabContainer>}
                                {this.state.value === 4 && <TabContainer>
                                    < LimitesPorZona data={this.state.tabData5} goToClasesDeContrato={this.setTabState} />
                                </TabContainer>}
                                {this.state.value === 5 && <TabContainer >
                                    <Descuentos data={this.state.tabData6} goToClasesDeContrato={this.setTabState} goToClasesDeContrato={this.setTabState} getSevenTabData={this.getSevenTabData}/>
                                </TabContainer>}
                                {this.state.value === 6 && <TabContainer>
                                     <RamosYCoberturas tableOne={this.state.tabData7Table1} tableTwo={this.state.tabData7Table2} goToClasesDeContrato={this.setTabState} getTabEightData={this.getTabEightData}/>  
                                </TabContainer>}
                                {this.state.value === 7 && <TabContainer>
                                     <CondicionesEspeciales data={this.state.tab8Data} goToClasesDeContrato={this.setTabState}/> 
                                </TabContainer>}
                            </Paper>
                        </div>
                        <ToastContainer />
                    </>
                ) : null}
            </main>
        );
    }
}
export default translate("translations")(withStyles(styles)(Contratos));


