import { config, base_url, axiousInstance } from './index';
let axios=axiousInstance;




class Reasuguro {
    constructor(){
        this.url = base_url + 'consulta-de-reaseguros'
    }


    getScreen1(policyNo, itemNo=null , fechaConsulta=null){
        let data = {
            policyNo:  policyNo,
            itemNo: itemNo,
            fechaConsulta: fechaConsulta
        }    
        return axios
        .post(this.url + "/screen1", data, config)
        .then(res => {
        return res.data;
        })
        .catch(error => {
        console.log("error " + error);
        return error;
        });        
    }


    getScreen2(data){
        return axios
            .post(this.url + "/screen2", data, config)
            .then(res => {
            return res.data;
            })
            .catch(error => {
            console.log("error " + error);
            return error;
            });        
    }


    getScreen3(data){
        return axios
            .post(this.url + "/screen3", data, config)
            .then(res => {
            return res.data;
            })
            .catch(error => {
            console.log("error " + error);
            return error;
            });        
    }

      getScreen4(data){
        return axios
            .post(this.url + "/screen4", data, config)
            .then(res => {
            return res.data;
            })
            .catch(error => {
            console.log("error " + error);
            return error;
            });        
    }

    getScreen4a(data){       
        return axios
            .post(this.url + "/screen4a", data, config)
            .then(res => {
            return res.data;
            })
            .catch(error => {
            console.log("error " + error);
            return error;
            });        
    }

    getScreen4b(data){       
        return axios
            .post(this.url + "/screen4b", data, config)
            .then(res => {
            return res.data;
            })
            .catch(error => {
            console.log("error " + error);
            return error;
            });        
    }

    getScreen5(data){        
        return axios
            .post(this.url + "/screen5", data, config)
            .then(res => {
            return res.data;
            })
            .catch(error => {
            console.log("error " + error);
            return error;
            });        
    }

}


export const api = new Reasuguro();