import React, { Component } from "react";
import "./App.scss";
import jwt from "jsonwebtoken";
import RouterComponent from "./router";
import { MENU } from "./Constants/menu";
import { roleList } from "./Constants/roleConfig";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { authContext } from "./utils/adalConfig";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      authorizedUser: false,
      payLoad: jwt.decode(localStorage.getItem("adal.idtoken"), {
        complete: true
      })
    };

    this.handleDrawerClose = this.handleDrawerClose.bind(this);
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    if (
      this.state.payLoad &&
      this.state.payLoad.payload &&
      this.state.payLoad.payload.given_name
    ) {
      localStorage.setItem("emailID", this.state.payLoad.payload.unique_name);
      localStorage.setItem("userName", this.state.payLoad.payload.given_name);
    } else {
      localStorage.setItem("userName", "Alice"); // Default user name
    }

    if (
      this.state.payLoad &&
      this.state.payLoad.payload &&
      this.state.payLoad.payload.roles &&
      this.state.payLoad.payload.roles.length > 0 &&
      this.state.payLoad.payload.roles
    ) {
      localStorage.setItem(
        "userRole",
        JSON.stringify(this.state.payLoad.payload.roles)
      );
      this.state.authorizedUser = true;

      let userRole = this.state.payLoad.payload.roles[0];
      let listOfApp = roleList[userRole];
      let flagLinkMached = false;
      let defaultLink = "";
      MENU.forEach(item => {
        if (
          listOfApp.indexOf(item.key) > -1 &&
          window.location.pathname === "/"
        ) {
          defaultLink = defaultLink == "" ? item.defaultPage : defaultLink;
          item.children.forEach(child => {
            if ("/policy".indexOf(child.link) > -1) {
              flagLinkMached = true;
            }
          });
        }
      });
      if (flagLinkMached === false && defaultLink) {
        window.location.pathname = defaultLink;
      }
    } else {
      this.state.authorizedUser = false; // TODO: change to false before deployment
    }
  }
  componentWillMount() {
    // if (
    //   localStorage.getItem("adal.expired") &&
    //   Date.now() >= localStorage.getItem("adal.expired") * 1000
    // ) {
    //   console.log("ession expired");
    //   authContext.logOut();
    //   localStorage.clear();
    // }
  }
  // handling sidebar open and close functionality
  handleDrawerClose = () => {
    this.setState({ open: false });
  };
  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  render() {
    return (
      <RouterComponent
        data={this.state}
        handleDrawerOpen={this.handleDrawerOpen.bind(this)}
        handleDrawerClose={this.handleDrawerClose.bind(this)}
      />
    );
  }
}

export default App;
