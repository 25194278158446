import React from "react";
import PropTypes from "prop-types";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from "@material-ui/core/Tooltip";

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy, rowCount, keyArray } = this.props;
    const virticalScroll=(this.props.virticalScroll)?" vertical-scroll":"";
    return (
      <TableHead className="tableHeader">
        <TableRow>
          {Object.keys(keyArray).map(
            (row, index) => (
              <TableCell
                key={row.id}
                align={this.props.align ? this.props.align : "left"}
                padding={row.disablePadding ? "none" : "default"}
                sortDirection={orderBy === row.id ? order : false}
                key={index}
                className={virticalScroll+" text-header-style"}
              >
                  <TableSortLabel
                    className="tableHeaderText"
                    active={orderBy === row}
                    direction={order}
                    onClick={this.props.onRequestSort? this.createSortHandler(row): null}
                  >
                    {keyArray[row]}
                  </TableSortLabel>
              </TableCell>
            ),
            this
          )}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
};

export default EnhancedTableHead;
