import React from 'react';
import PropTypes from 'prop-types';
// import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Divider from "@material-ui/core/Divider";
import Typography from '@material-ui/core/Typography';
import { translate, Trans } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';

import TextField from '@material-ui/core/TextField';
import { getLimitesDetails } from "../../../api/reaseguradores";

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import DyanmicTableWithSorting from "../../../../BaseComponents/DyanmicTableWithSorting/DyanmicTableWithSorting";

import {
    MuiPickersUtilsProvider,
    DatePicker
} from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";
import EmptyState from "../../../../BaseComponents/EmptyState/EmptyState";

class Limities extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            showLimities: true,
        };
    }


    showLoaderAction() {
        let showLoader = this.state.showLoader;
        this.setState({
            showLoader: !showLoader
        });
    }

    goToPreviousPage = () => {
        this.props.goToClasesDeContrato(1)
    };


    componentDidMount() {
        let showLimities = this.props.data && this.props.data.length > 0 ? true : false;

        this.setState({
            LimitesData: this.props.data,
            showLimities: showLimities
        });
    }
    submitForm=()=>{
        // this.props.getFourthTabData();
        this.props.goToClasesDeContrato(3)
    }  
    render() {
        const showLimities = this.state.showLimities;
        let keys = {
            "indiceRiesgo": "Indice de Riesgo",
            "capacidadCuotaParteUs": "Capacidad Cuota Parte (US$)",
            "líneasDeExcedentes": "Líneas De Excedente 1",
            "capacidadExcedente": "Capacidad Excedente",
            "capacidadTotal": "Capacidad Total",
        }

        let LimitesData=this.props.data;
        return (
            <div className="tab-class">
                    <Card style={{ marginTop: "25px" }} className="Card-area box-show-none">
                        {LimitesData ? (
                            <div >
                                <div className="container">
                                    <center> <h5>Table Base de Capacidades</h5></center>
                                    <DyanmicTableWithSorting
                                        data={LimitesData}
                                        keyArray={keys}
                                        virticalScroll={true}
                                        tableMaxHeight={"400"}
                                    />
                                </div>
                            </div>) :
                        (
                            <div className={"content"}>
                                <EmptyState
                                    emoticon={"🔍"}
                                    message={"No se encontraron datos"}
                                />
                            </div>
                        )}
                        
                        <div className="reso-btn-group">
                            <div className="btn">
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    onClick={this.goToPreviousPage}
                                    className="search-policy pull-right resetButtonClass">Volver</Button>
                            </div>
                            <div className="btn" >
                                <Button variant="contained" size="medium" color="primary" className="search-policy pull-right resetButtonClass" onClick={this.submitForm}>
                                    Confirmar cambios y continuar
                                </Button>
                            </div>
                        </div>
                    </Card>
            </div>
        )
    }
}

export default translate("translations")(Limities);