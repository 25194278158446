import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import Select from "react-select";
import esLocale from "date-fns/locale/es";
import enLocale from "date-fns/locale/en-US";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider, TimePicker,   DatePicker  } from "material-ui-pickers";


const localeMap = {
    es: esLocale,
    en: enLocale
  };
  
  const styles = theme => ({
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      padding: theme.spacing.unit * 3,
      height: "100vh",
      overflow: "auto"
    },
    progress: {
      margin: theme.spacing.unit * 2
    },
    grid: {
      width: "90%"
    }
});


class  Calendar extends React.Component {
    constructor(props) {
        super(props);
        this.state ={

        }
    }



    render(){
        const { classes } = this.props;

        return (                
            <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            locale={localeMap["es"]}
            >
                <Grid
                container
                className={classes.grid}
                justify="space-around"
                >
                    <DatePicker
                        margin="normal"
                        keyboard
                        label={this.props.label}
                        format={this.props.format}
                        placeholder={this.props.placeholder}
                        value={this.props.selectedDate}
                        onChange={this.props.handleDateChange}
                        disableOpenOnEnter
                        animateYearScrolling={false}
                    />
                </Grid>
            </MuiPickersUtilsProvider>
       )
    }
}

export default withStyles(styles)(Calendar);
