import { config, base_url, axiousInstance } from './index';
let axios=axiousInstance;


class Vehiculos {
    constructor(){

    }

    getSinestros(data){
        return axios
             .post(base_url+"sinestroVechiculo/getsinestros",data,config)
             .then(res =>{
                 return res.data;
    
             })
             .catch(error=>{
                 console.log(error);
                 return error;
    
             });
    
    }

    getPaymentOrder(sinestros){
        const data = {
            "sinestros":sinestros
        }    
        return axios
            .post(base_url+"sinestroVechiculo/getPaymentOrder",data,config)
            .then(res =>{
                return res.data;
            })
            .catch(error =>{
                console.log(error);
                return error;
            });
                
    
    }
    
}


export const api = new Vehiculos();



