import React from "react";
import PropTypes from "prop-types";
import { translate, Trans } from "react-i18next";
import _ from "lodash";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Collapse from "@material-ui/core/Collapse";
import Button from "@material-ui/core/Button";
import "./ExcelDownloadCheckBox.scss";
import Loader from "../../../../BaseComponents/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import {
  getlineofbusinessExcel,
  getUnoHydNewDetailsExcel,
  geRamosExcel
} from "./../../../api/ExcelDownload";
import LineOfBusinessKeys from "../LineOfBusinessKeys.json";

const listOfDownloads = [
  "Resultado Busqueda",
  "Detalle de la Busqueda",
  "Produccion",
  "Cobranza",
  "Siniestro",
  "Detalle Siniestro",
  "Materia Asegurada"
];

class ExcelDownloadCheckBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [0],
      open: true,
      siniDetails: true,
      detalleDeLaBusqueda: true,
      Resultado_Busqueda: true,
      Detalle_De_La_Busqueda: false,
      Produccion: false,
      Cobranza: false,
      Siniestro: false,
      Detalle_Siniestro: false,
      Materia_Asegurad: false,
      showLoader: false
    };
  }

  downloadEcel = () => {
    let listOfCond = {
      Resultado_Busqueda: this.state.Resultado_Busqueda,
      Detalle_De_La_Busqueda: this.state.Detalle_De_La_Busqueda,
      Produccion: this.state.Produccion,
      Cobranza: this.state.Cobranza,
      Siniestro: this.state.Siniestro,
      Detalle_Siniestro: this.state.Detalle_Siniestro,
      Materia_Asegurad: this.state.Materia_Asegurad,
      ramosScreen: this.props.ramosScreen
    };

    if (this.props.ramosScreen) {
      this.showLoaderAction();
      geRamosExcel(
        this.props.excelKeys,
        this.props.reqObj,
        listOfCond,
        this.props.selectRowData
      ).then(response => {
        if (response.statusCode === 200 || response.success) {
          this.showLoaderAction();
          toast.success(
            response.msg ||
              "Data is too large shortly you will receive data in email",
            {
              position: toast.POSITION.TOP_RIGHT
            }
          );
        } else {
          toast.error(response.msg || "Please enter policy number", {
            position: toast.POSITION.TOP_RIGHT
          });
          this.showLoaderAction();
        }
      });
    } else {
      this.showLoaderAction();

      getlineofbusinessExcel(
        this.props.excelKeys,
        this.props.reqObj,
        listOfCond,
        this.props.selectRowData,
        this.props.amountOfDataLargeFlag
      ).then(response => {
        if (response.statusCode === 200 || response.success) {
          this.showLoaderAction();
          toast.success(
            response.msg ||
              "Data is too large shortly you will receive data in email",
            {
              position: toast.POSITION.TOP_RIGHT
            }
          );
        } else {
          toast.error(response.msg || "Please enter policy number", {
            position: toast.POSITION.TOP_RIGHT
          });
          this.showLoaderAction();
        }
      });
    }
  };

  showLoaderAction = () => {
    console.log("Inside showLoaderAction");
    let showLoader = this.state.showLoader;
    this.setState({
      showLoader: !showLoader
    });
  };

  downloadEcelCerrar = () => {
    this.props.dialogueHandler();
  };

  handleToggle = name => event => {
    // console.log(name);
    let key = name;
    let val = event.target.checked;
    let obj = {};
    obj[key] = val;
    if (name === "Detalle_De_La_Busqueda") {
      if (val) {
        obj.detalleDeLaBusqueda = false;
        obj.Produccion = true;
      } else {
        obj.detalleDeLaBusqueda = true;
        obj.Produccion = false;
        obj.Cobranza = false;
        obj.Siniestro = false;
        obj.Detalle_Siniestro = false;
        obj.Materia_Asegurad = false;
        obj.siniDetails = true;
      }
    }
    if (name === "Siniestro") {
      if (val) {
        obj.siniDetails = false;
      } else {
        obj.Detalle_Siniestro = false;
        obj.siniDetails = true;
      }
    }
    this.setState(obj);
  };

  render() {
    const {
      Resultado_Busqueda,
      Detalle_De_La_Busqueda,
      Produccion,
      Cobranza,
      Siniestro,
      Detalle_Siniestro,
      Materia_Asegurad
    } = this.state;
    const { classes } = this.props;
    let showLoader = this.state.showLoader;
    return (
      <>
        {showLoader ? <Loader /> : null}
        <div>
          <List component="nav">
            <ListItem
              key={listOfDownloads[0]}
              role={undefined}
              dense
              button
              className="list-item"
            >
              <Checkbox
                checked={Resultado_Busqueda}
                onChange={this.handleToggle("Resultado_Busqueda")}
                tabIndex={-1}
                disableRipple
                color="primary"
              />
              <ListItemText primary={listOfDownloads[0]} />
            </ListItem>
            <ListItem
              key={listOfDownloads[1]}
              role={undefined}
              dense
              button
              className="list-item"
            >
              <Checkbox
                checked={Detalle_De_La_Busqueda}
                onChange={this.handleToggle("Detalle_De_La_Busqueda")}
                tabIndex={-1}
                disableRipple
                color="primary"
              />
              <ListItemText primary={listOfDownloads[1]} />
            </ListItem>
            <Collapse in={this.state.open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  style={{ paddingLeft: "45px" }}
                  key={listOfDownloads[2]}
                  role={undefined}
                  dense
                  button
                  className="list-item"
                  disabled={this.state.detalleDeLaBusqueda}
                >
                  <Checkbox
                    checked={Produccion}
                    tabIndex={-1}
                    disableRipple
                    color="primary"
                  />
                  <ListItemText primary={listOfDownloads[2]} />
                </ListItem>
                {!this.props.reqObj.historica ? (
                  <>
                    <ListItem
                      button
                      style={{ paddingLeft: "45px" }}
                      key={listOfDownloads[3]}
                      role={undefined}
                      dense
                      button
                      className="list-item"
                      disabled={this.state.detalleDeLaBusqueda}
                    >
                      <Checkbox
                        checked={Cobranza}
                        onChange={this.handleToggle("Cobranza")}
                        tabIndex={-1}
                        disableRipple
                        color="primary"
                      />
                      <ListItemText primary={listOfDownloads[3]} />
                    </ListItem>

                    <ListItem
                      button
                      style={{ paddingLeft: "45px" }}
                      key={listOfDownloads[4]}
                      role={undefined}
                      dense
                      button
                      className="list-item"
                      disabled={this.state.detalleDeLaBusqueda}
                    >
                      <Checkbox
                        checked={Siniestro}
                        onChange={this.handleToggle("Siniestro")}
                        tabIndex={-1}
                        disableRipple
                        color="primary"
                      />
                      <ListItemText primary={listOfDownloads[4]} />
                    </ListItem>

                    {this.props.siniestroFlag ? (
                      <List component="div" disablePadding>
                        <ListItem
                          button
                          style={{ paddingLeft: "90px" }}
                          key={listOfDownloads[5]}
                          role={undefined}
                          dense
                          button
                          className="list-item"
                          disabled={this.state.siniDetails}
                        >
                          <Checkbox
                            checked={Detalle_Siniestro}
                            onChange={this.handleToggle("Detalle_Siniestro")}
                            tabIndex={-1}
                            disableRipple
                            color="primary"
                          />
                          <ListItemText primary={listOfDownloads[5]} />
                        </ListItem>
                      </List>
                    ) : null}
                  </>
                ) : null}

                <ListItem
                  button
                  style={{ paddingLeft: "45px" }}
                  key={listOfDownloads[6]}
                  role={undefined}
                  dense
                  button
                  className="list-item"
                  disabled={this.state.detalleDeLaBusqueda}
                >
                  <Checkbox
                    checked={Materia_Asegurad}
                    onChange={this.handleToggle("Materia_Asegurad")}
                    tabIndex={-1}
                    disableRipple
                    color="primary"
                  />
                  <ListItemText primary={listOfDownloads[6]} />
                </ListItem>
              </List>
            </Collapse>
          </List>
        </div>
        <div className="button-box">
          <Button
            variant="contained"
            size="medium"
            color="primary"
            className="download-button"
            onClick={this.downloadEcel}
          >
            {"Aceptar"}
          </Button>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            className="download-button"
            onClick={this.downloadEcelCerrar}
          >
            {"Cerrar"}
          </Button>
        </div>
        <ToastContainer />
      </>
    );
  }
}

export default ExcelDownloadCheckBox;
