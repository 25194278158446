import React from "react";
import PropTypes from "prop-types";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from "@material-ui/core/Tooltip";

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy, rowCount, keyArray } = this.props;
    const EnhancedTableHead = this.props.isEnhancedTableHeadReq;
    let verticalScroll=(this.props.verticalScroll)?" vertical-scroll":"";
    return (
      <TableHead className="tableHeader">
        {EnhancedTableHead ? (
          <TableRow>
            {Object.keys(keyArray).map(
              (row, index) => {
                return (
                <TableCell
                  key={row.id}
                  align={row.numeric ? "right" : "left"}
                  padding={row.disablePadding ? "none" : "default"}
                  sortDirection={orderBy === row.id ? order : false}
                  key={index}
                  className={verticalScroll+" text-header-style"}
                >
                  <Tooltip
                    title="Sort"
                    placement={row.numeric ? "bottom-end" : "bottom-start"}
                    enterDelay={300}
                  >
                    <TableSortLabel
                      className="tableHeaderText"
                      active={orderBy === row}
                      direction={order}
                      onClick={this.createSortHandler(row)}
                    >
                      {keyArray[row]}
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
              )},
              this
            )}
          </TableRow>
        ) : (
          <TableRow>
            {Object.keys(keyArray).map(
              (row, index) =>{
              verticalScroll=(this.props.coloumnLeftAlign===row)?verticalScroll+" text-align-left":verticalScroll;
              return (
                <TableCell
                  key={row.id}
                  align={row.numeric ? "right" : "left"}
                  padding={row.disablePadding ? "none" : "default"}
                  sortDirection={orderBy === row.id ? order : false}
                  key={index}
                  className={verticalScroll +" text-header-style"}
                >
                  <TableSortLabel className="tableHeaderText">
                    {keyArray[row]}
                  </TableSortLabel>
                </TableCell>
              )},
              this
            )}
          </TableRow>
        )}
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

export default EnhancedTableHead;
