import { config, base_url, axiousInstance } from "./index";
let axios = axiousInstance;

export function getPolicyDetails(id) {
  let data = {
    policyNo: id
  };
  return axios
    .post(base_url + "getPolicyDetails", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
}

export function getClaimDetails(policy_id, itemNo) {
  let data = {
    policyNo: parseInt(policy_id),
    itemNo: parseInt(itemNo)
  };
  if (policy_id && itemNo) {
    return axios
      .post(base_url + "getclaim", data, config)
      .then(res => {
        return res.data;
      })
      .catch(error => {
        console.log("error " + error);
        return error;
      });
  } else {
    return axios
      .post(base_url + "getclaimByPolicy", data, config)
      .then(res => {
        return res.data;
      })
      .catch(error => {
        console.log("error " + error);
        return error;
      });
  }
}

export function getClaimDetailsByPolicyId(policy_id) {
  let data = {
    policyNo: policy_id
  };
  return axios
    .post(base_url + "getclaimByPolicy", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
}

export function getItemList(policy_id, start, end) {
  let data = {
    policyNo: policy_id,
    startRange: start || "",
    endRange: end || ""
  };
  return axios
    .post(base_url + "getpolicyitems", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
}

export function getItemDetails(policy_id, itemId) {
  let data = {
    policyNo: policy_id,
    itemNo: itemId
  };
  return axios
    .post(base_url + "getItemDetails", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
}

export function getByPersonDetail(data) {
  /* ex  https://lrigoocw6l.execute-api.us-east-1.amazonaws.com/dev/getbypersondetail */

  return axios
    .post(base_url + "getbypersondetail", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
}

export function ObtenerelNumerodePolizaPorrutina(rudId, filterSelected) {
  /* ex  https://lrigoocw6l.execute-api.us-east-1.amazonaws.com/dev/obtenerelnumerodepolizaporrutina */
  const data = {
    rut: rudId,
    tipo: filterSelected
  };
  return axios
    .post(base_url + "obtenerelnumerodepolizaporrutina", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
}

export function getEndorsementByPolicy(policy_id) {
  let data = {
    policyNo: policy_id
  };
  return axios
    .post(base_url + "getEndorsementByPolicy", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
}

export function getEndorsementDetails(param) {
  console.log("paramparam", param);
  let data = {
    policyNo: parseInt(param.policy_id),
    endosNo: parseInt(param.endosoNo)
  };
  return axios
    .post(base_url + "getEndorsementDetails", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
}

export function getCoinsuranceDetail(policyId) {
  let data = {
    policyNo: parseInt(policyId)
  };
  return axios
    .post(base_url + "getCoinsuranceDetail", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
}

export function getDocumentosByPolicyId(policyNo) {
  let data = {
    policyNo: parseInt(policyNo)
  };
  return axios
    .post(base_url + "getDocumentos", data, config)
    .then(res => {
      return res && res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
}

export function getNombre(rutNo) {
  let data = {
    rut: parseInt(rutNo),
    nombre: "",
    offset: 0,
    limit: 10
  };
  return axios
    .post(base_url + "getnombrebyrut", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
}

export function getrazorsocial(rutNo) {
  let data = {
    rut: 0,
    nombre: rutNo,
    offset: 10,
    limit: 15
  };
  return axios
    .post(base_url + "getrazorsocial", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
}

export function getlineofbusiness(data) {
  return axios
    .post(base_url + "getlineofbusiness", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
}

export function getlineofbusinessdetail(reqObj) {
  let data = {};
  if (reqObj.p_R_VST_PATENTE) {
    data = reqObj;
  } else {
    reqObj.p_R_VST_PATENTE = "";
    data = reqObj;
  }

  return axios
    .post(base_url + "getlineofbusinessdetail", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
}

function getTotalCount(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(base_url + "dosnewcount", data, config)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(error => {
        console.log("error " + error);
        reject(error);
      });
  });
}

function getPaginatedData(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(base_url + "getLeeNivelDosNew", data, config)
      .then(res => {
        resolve(res.data.data);
      })
      .catch(error => {
        console.log("error " + error);
        reject(error);
      });
  });
}

export function getLeeNivelDosNew(reqObj) {
  let data = {};
  if (reqObj.p_R_VST_PATENTE) {
    data = reqObj;
  } else {
    reqObj.p_R_VST_PATENTE = "";
    data = reqObj;
  }
  console.log("reqObjreqObjreqObjreqObjreqObj----->", reqObj);

  //flag: "porReferenciaCorredor"
  if (reqObj.flag === "porReferenciaCorredor") {
    return Promise.all([getPaginatedData(data), getTotalCount(data)])
      .then(combineResponse => {
        return combineResponse;
      })
      .catch(err => {
        console.log("error", err);
        throw err;
      });
  } else {
    return axios
      .post(base_url + "getLeeNivelDosNew", data, config)
      .then(res => {
        return res.data;
      })
      .catch(error => {
        console.log("error " + error);
        return error;
      });
  }
}

export function getLeeNivelDosNewPaginatedData(reqObj) {
  let data = {};
  if (reqObj.p_R_VST_PATENTE) {
    data = reqObj;
  } else {
    reqObj.p_R_VST_PATENTE = "";
    data = reqObj;
  }

  return axios
    .post(base_url + "getLeeNivelDosNew", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
}

export function getleeInfGenUnidad(reqObj) {
  let data = {};
  if (reqObj.p_R_VST_PATENTE) {
    data = reqObj;
  } else {
    reqObj.p_R_VST_PATENTE = "";
    data = reqObj;
  }

  return axios
    .post(base_url + "getleeInfGenUnidad", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
}

export function getcobranza(t) {
  let data = {
    p_R_VLO_COD_RAMOS: 9,
    p_R_VLO_NRO_POLIZA: 395993,
    p_R_VLO_NRO_UNIDAD: 0,
    p_R_VLO_RUT: 72702600
  };
  return axios
    .post(base_url + "getcobranza", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
}

export function getProduction(t) {
  let data = {
    p_R_VLO_COD_RAMOS: 9,
    p_R_VLO_NRO_POLIZA: 395993,
    p_R_VLO_NRO_UNIDAD: 0,
    p_R_VLO_RUT: 72702600
  };
  return axios
    .post(base_url + "getProduction", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
}

export function getSiniestro(t) {
  let data = {
    p_Poliza: 395993,
    p_Item: 49
  };
  return axios
    .post(base_url + "getSiniestro", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
}

export function getreaseguro(t) {
  let data = {
    p_R_VLO_COD_RAMOS: 9,
    p_R_VLO_NRO_POLIZA: 395993,
    p_R_VLO_NRO_UNIDAD: 49,
    p_R_VLO_RUT: 72702600
  };
  return axios
    .post(base_url + "getreaseguro", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
}

export function getMateriaAsseg(t) {
  let data = {
    p_R_VLO_COD_RAMOS: 9,
    p_R_VLO_NRO_POLIZA: 395993,
    p_R_VLO_NRO_UNIDAD: 0
  };
  return axios
    .post(base_url + "getMateriaAsseg", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
}

export function getCarPolicies(patente, comp) {
  let data = {
    patente: patente, //D4CBC136344
    comp: comp //1
  };
  return axios
    .post(base_url + "getCarPolicies", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
}

export function getMinuta(policyNo, item, endoso) {
  let data = {
    policyNo: policyNo,
    item: item,
    endoso: parseInt(endoso)
  };
  return axios
    .post(base_url + "getMinuta", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
}
