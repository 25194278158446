import React from "react";
import { translate, Trans } from "react-i18next";
import {
  getCollectionByPolicyNo,
  getCollectionResumen
} from "../../../api/collection";
import { ToastContainer, toast } from "react-toastify";
import _ from "lodash";
import Loader from "../../../../BaseComponents/Loader/Loader";
import DyanmicTableWithSorting from "../../../../BaseComponents/DyanmicTableWithSorting/DyanmicTableWithSorting";
import {
  resumenColumns,
  insertDataArray
} from "../../../../Constants/collection";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import VegeneComponet from "../VegeneComponet/VegeneComponet";
import { getCollectionByVigente } from "../../../api/collection";
import Button from "@material-ui/core/Button";

class CollectionsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enableSearch: true,
      collectionData: undefined,
      searchText: "",
      showLoader: false,
      showResumen: false,
      showPolizaDetail: false,
      openDailog: false,
      isPaginationReq: true,
      isEnhancedTableHeadReq: true,
      collections: undefined,
      policyNo: undefined,
      polizaData: undefined,
      showVegente: false,
      vegenteData: undefined,
      collectionVegenteData: undefined,
      openDailog: false,
      showTitle: "Poliza",
      errorMessage: "No details found for given Policy no : ",
      showVolver: false
    };

    this.showCollectionDetails = this.showCollectionDetails.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props;
    const policyNo = params.policyNo ? params.policyNo : params.documentoNo;
    this.setState({
      showTitle: params.policyNo ? "Poliza" : "Documento",
      errorMessage: params.policyNo
        ? this.state.errorMessage
        : "No details found for given Document no : "
    });
    if (policyNo) {
      this.setState({ searchText: policyNo, showVolver: true });
      this.getCollectionResumen(policyNo);
    }
  }
  showLoaderAction() {
    let showLoader = this.state.showLoader;
    this.setState({ showLoader: !showLoader });
  }

  handleChange(event) {
    const value = event.target.value;
    this.setState({ searchText: value });
    if (value > 0) {
      this.setState({ enableSearch: false });
    } else {
      this.setState({ enableSearch: true });
    }
  }

  showCollectionDetails(policyNumber) {
    this.showLoaderAction();
    this.setState({ collectionData: undefined });
    let policyNo = policyNumber;

    if (policyNo) {
      getCollectionByPolicyNo(policyNo)
        .then(res => {
          if (res && res.data && !_.isEmpty(res.data)) {
            let collection = res.data.collection;
            const collection_data = _.cloneDeep(res.data.collection);
            let total = res.data.total;
            const polizaData = [
              {
                poliza: policyNo,
                endoso: collection_data[0].endoso || "0",
                item: collection_data[0].item | "0",
                captial: total.amortizacionTotal
              }
            ];
            let collectionData = this.getInsertDataInCollection(
              total,
              collection,
              policyNo
            );
            this.setState({
              showResumen: true,
              collectionData: collectionData,
              collections: collection_data,
              polizaData: polizaData
            });
          } else {
            toast.error(
              (this.state.showTitle === "Poliza"
                ? res.msg
                : res.documentoMsg) ||
                "No Collection found for given Policy no : " + policyNo,
              { position: toast.POSITION.TOP_RIGHT }
            );
            this.setState({
              collectionData: undefined
            });
          }
          this.showLoaderAction();
        })
        .catch(ex => {
          console.error(ex);
          toast.error("Something went wrong. ", {
            position: toast.POSITION.TOP_RIGHT
          });
        });
    }
  }

  getCollectionResumen(policyNumber) {
    this.showLoaderAction();
    this.setState({ collectionData: undefined });
    let policyNo = policyNumber;

    if (policyNo) {
      getCollectionResumen(policyNo)
        .then(res => {
          if (res && res.data && !_.isEmpty(res.data)) {
            let resumenTotals = res.data.resumenTotals;
            this.setState({
              showResumen: true,
              collectionData: resumenTotals
            });
          } else {
            toast.error(
              (this.state.showTitle === "Poliza"
                ? res.msg
                : res.documentoMsg) ||
                `No se encontraron datos de cobranza para la póliza`,
              { position: toast.POSITION.TOP_RIGHT }
            );
            this.setState({
              collectionData: undefined
            });
          }
          this.showLoaderAction();
        })
        .catch(ex => {
          console.error(ex);
          toast.error("Something went wrong. ", {
            position: toast.POSITION.TOP_RIGHT
          });
        });
    }
  }

  getInsertDataInCollection(total, collection, policyNo) {
    var data = insertDataArray(total);
    _.forEach(collection, c => {
      c.poliza = policyNo;
    });
    collection.push(data[0]);
    collection.push(data[1]);
    return collection;
  }

  handleClickOpen() {
    this.setState({ openDailog: true });
  }

  handleClose = () => {
    this.setState({ openDailog: false });
  };

  clickRow = data => {
    this.showLoaderAction();
    const documentoNo = data.documento;
    this.getCollectionByVigenteNO(documentoNo);
  };

  getCollectionByVigenteNO(documentoNo) {
    if (documentoNo) {
      getCollectionByVigente(documentoNo)
        .then(res => {
          if (res && res.data && !_.isEmpty(res.data)) {
            this.setState({
              collectionVegenteData: res.data,
              showVegente: true,
              openDailog: true
            });
          } else {
            toast.warn(
              res.msg ||
                "No Vegento found for given documentoNo no : " + documentoNo,
              { position: toast.POSITION.TOP_RIGHT }
            );
            this.setState({
              collectionData: undefined,
              collectionVegenteData: undefined
            });
          }
          this.showLoaderAction();
        })
        .catch(ex => {
          console.log("api ex :", ex);
          toast.error(ex.msg || "Something wen wrong.", {
            position: toast.POSITION.TOP_RIGHT
          });
        });
    }
  }

  backToPreviousPage = () => {
    window.history.back();
  };

  render() {
    const { i18n } = this.props;
    let translateObj =
      (i18n.store.data[i18n.language] &&
        i18n.store.data[i18n.language].translations) ||
      i18n.store.data["es"].translations;
    const resumenColumn = resumenColumns;
    return (
      <main className="main-container">
        {this.state.showLoader ? <Loader /> : null}
        <div>
          <h3>
            {" "}
            [ {this.state.showTitle} : {this.state.searchText} ]
          </h3>
          {this.state.showResumen ? (
            <>
              <DyanmicTableWithSorting
                data={this.state.collectionData}
                keyArray={resumenColumn}
                orderBy={resumenColumn[0]}
                pathData={{}}
                isEnhancedTableHeadReq={this.state.isEnhancedTableHeadReq}
                passClick={this.clickRow}
                virticalScroll={true}
                tableMaxHeight={"400"}
              />
              {this.state.showVolver ? (
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  className="search-policy collection-button-space"
                  onClick={this.backToPreviousPage}
                  style={{ float: "right", marginTop: 10 }}
                >
                  {"Volver"}
                </Button>
              ) : null}
            </>
          ) : null}
        </div>
        {this.state.showVegente && this.state.collectionVegenteData ? (
          <Dialog
            open={this.state.openDailog}
            onClose={this.handleClose}
            aria-labelledby="responsive-dialog-title"
            maxWidth="true"
            maxHeight="true"
          >
            <DialogTitle
              id="responsive-dialog-title"
              className="dailog-title-style"
            >
              {" "}
              {"Numero Mandato Asociado a Documento"}
            </DialogTitle>
            <DialogContent>
              <VegeneComponet data={this.state.collectionVegenteData} />
            </DialogContent>
          </Dialog>
        ) : null}
        <ToastContainer />
      </main>
    );
  }
}

export default translate("translations")(CollectionsComponent);
