import React from 'react';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';

export default class AntecedentesAsegurado extends React.Component  {
  constructor(props) {
    super(props);
    this.state =  {
    }
  }
  componentDidMount() {
    this.setState({ data : this.props.data }); 
  }

  componentWillReceiveProps() {
    this.setState({ data : this.props.data }); 
  }

  render() {
    let dataObj=this.state.data;
    return (
      <div className="margin-top-30">
        {dataObj?
             <Card className="Card-area box-show-none">
             <fieldset className="custom-border">
               <legend className="legend-box">Antecedentes Asegurado</legend>
                {/* <h3 className="Heading-name">Antecedentes Asegurado</h3> */}
                <div className="vertical-align-middle">
                    <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="Nombre"
                        className="textField"
                        value={dataObj.nombre}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                        readOnly: true,
                        }}
                        style = {{width: 400}}
                    />
                    </span>
                    <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="Rut"
                        className="textField"
                        value={dataObj.rut}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                        readOnly: true,
                        }}
                        style = {{width: 200}}
                    />
                    <span className="minus-sign">-</span>
                    <TextField InputLabelProps={{
                            shrink: true,
                        }}
                        id="standard-bare"
                        label=""
                        className="textField"
                        margin="normal"
                        value={dataObj.rut1}
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                          }}
                          style = {{width: 100}}
                    />
                    </span>
                    
                    <span className="Space-around">
                    <TextField InputLabelProps={{
                            shrink: true,
                        }}
                        id="standard-bare"
                        label="Teléfono"
                        className="textField"
                        value={dataObj.telefono}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                        readOnly: true,
                        }}
                        style = {{width: 171}}
                    />
                    </span>
                </div>
                <div>
                    <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="Dirección"
                        className="textField"
                        value={dataObj.direccion}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                        readOnly: true,
                        }}
                        style = {{width: 378}}
                    />
                    </span>
                    <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="Comuna"
                        className="textField"
                        value={dataObj.comuna}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                        readOnly: true,
                        }}
                        style = {{width: 260}}

                    />
                    </span>
                    <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="Región"
                        className="textField"
                        value={dataObj.region}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                        readOnly: true,
                        }}
                        style = {{width: 260}}

                    />
                    </span>
                </div>
                </fieldset>
            </Card>
        :null}
      </div>
    )
  }
}