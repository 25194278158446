import React from "react";
import DyanmicTableWithSorting from "../../../BaseComponents/DyanmicTableWithExcelDownload/DyanmicTableWithSorting";
import EmptyState from "../../../BaseComponents/EmptyState/EmptyState";
import { translate, Trans } from "react-i18next";
import { getlineofbusiness } from "./../../api/policy";
import { getUnoHydNewDetails } from "./../../api/consulta";
import {
  getlineofbusinessExcel,
  getUnoHydNewDetailsExcel
} from "./../../api/ExcelDownload";
import Loader from "../../../BaseComponents/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import _ from "lodash";
import LineOfBusinessKeys from "./LineOfBusinessKeys.json";
import ProduccionHistoricaKeys from "./ProduccionHistoricaKeys.json";
import SearchIcon from "@material-ui/icons/CloudDownload";
import "./GlobalSearchPorClient.scss";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import ExcelDownloadCheckBox from "./PolicyDetailComponent/ExcelDownloadCheckBox";
import { compareDesc } from "date-fns/esm";
import { connect } from "react-redux";

let selectRowData = [];
class LineOfBusiness extends React.Component {
  constructor(props) {
    super(props);

    let reqObject = {};
    if (props.location.state && props.location.state.historica) {
      reqObject.p_Criterio = props.location.state.porClente;
      reqObject.p_R_VLO_COD_RAMOS = props.location.state.ramos;
      reqObject.p_R_VLO_CODIGO_UNO = 0;
      reqObject.p_PeriodoAnoMes = props.location.state.p_PeriodoAnoMes;
      reqObject.p_Oficina = 0;
      reqObject.p_Indicador_matriz = "S";
      reqObject.p_CodigoOficina = 0;
      reqObject.p_RutUsuario = 0;
      reqObject.historica = props.location.state.historica;
      reqObject.slug = props.location.state.slug;
    } else if (props.location.state) {
      reqObject.p_Criterio = props.location.state.porClente;
      reqObject.p_R_VLO_CODIGO_UNO = props.location.state.rutNumber;
      reqObject.p_R_VLO_COD_SUS = 0;
      reqObject.p_R_VLO_FEC_INICIO = 0; //change
      reqObject.p_R_VLO_FEC_TERMINO = props.location.state.polEndDate; //change
      reqObject.p_R_VST_CODIGO_UNO = props.location.state.ConsiderarEstratoDe;
      reqObject.p_R_VST_CODIGO_DOS = props.location.state.p_R_VST_CODIGO_DOS
        ? props.location.state.p_R_VST_CODIGO_DOS
        : "";
      reqObject.p_R_VLO_FEC_POL_INI = props.location.state.startDate; //Change
      reqObject.p_R_VLO_FEC_POL_TER = props.location.state.endDate; //Change
      reqObject.p_R_VLO_COD_RAMOS = props.location.state.ramos;
      reqObject.p_CodigoOficina = 0;
      reqObject.p_RutUsuario = 0;
      reqObject.slug = props.location.state.slug;
      reqObject.unidadCase = props.location.state.unidadCase;
      reqObject.nonRut = props.location.state.nonRut;
      reqObject.flag = props.location.state.flag;
    } else {
      let urlArr = _.split(window.location.pathname, "/");
      let navUrlStrig = "";
      if (urlArr && urlArr.length > 2) {
        navUrlStrig = "/" + urlArr[1] + "/" + urlArr[2];
      }
      this.props.history.push(navUrlStrig);
    }

    this.state = {
      blnShowLineOfBusiness: false,
      lineOfBusinessData: undefined,
      isPaginationReq: false,
      defaultRowPerPage: 10,
      isEnhancedTableHeadReq: false,
      showLoader: false,
      reqObj: reqObject,
      openDailog: false,
      checked: [0],
      open: true,
      emptyState: false,
      adExcelButton: true,
      siniestrosCheckBoxReq: true,
      amountOfDataLargeFlag: false,
      excelButtonData: undefined
    };
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props;
    let { rut, nombre, startdate, enddate } = params;
    /*if (rut && nombre && startdate && enddate) {
      this.setState({
        rut: rut,
        nombre: nombre,
        startdate: startdate,
        enddate: enddate,
        showVolver: true
      });
    }**/

    this.setState({
      showVolver: true
    });

    if (this.state.reqObj.historica) {
      this.getUnoHydNewDetails(this.state.reqObj);
    } else {
      this.getLineOfBusiness(this.state.reqObj);
    }
  }

  componentWillUnmount() {
    selectRowData = [];
  }

  getLineOfBusiness(reqObj) {
    this.showLoader();
    getlineofbusiness(reqObj)
      .then(res => {
        if (res.data && res.data.lob) {
          let dataToDisplay = res.data.lob;
          let emptyState = res.data.lob.length > 0 ? false : true;
          let i = 0;
          _.forEach(dataToDisplay, function(indexObj) {
            indexObj.excel = "Excel";
          });
          let modifiedData = dataToDisplay;
          modifiedData.push({});
          res.data.lobTotal[0].total = true;
          modifiedData.push(res.data.lobTotal[0]);
          this.setState({
            blnShowLineOfBusiness: true,
            lineOfBusinessData: modifiedData,
            emptyState: emptyState
          });
        } else {
          this.setState({
            emptyState: true
          });
          toast.error(res.msg || "No Line of Business found", {
            position: toast.POSITION.TOP_RIGHT
          });
        }
        this.showLoader();
      })
      .catch(ex => {
        console.log(ex);
      });
  }

  getUnoHydNewDetails(reqObj) {
    this.showLoader();
    getUnoHydNewDetails(reqObj)
      .then(res => {
        if (res.data && res.data.unoHydNewDetails) {
          let dataToDisplay = res.data.unoHydNewDetails;
          let emptyState = res.data.unoHydNewDetails.length > 0 ? false : true;
          let modifiedData = dataToDisplay;
          modifiedData.push({});
          res.data.unoHydTotal[0].total = true;
          modifiedData.push(res.data.unoHydTotal[0]);
          this.setState({
            blnShowLineOfBusiness: true,
            lineOfBusinessData: modifiedData,
            emptyState: emptyState
          });
          // toast.success(res.msg , { position: toast.POSITION.TOP_RIGHT})
        } else {
          this.setState({
            emptyState: true
          });
          toast.error(res.msg || "No Line of Business found", {
            position: toast.POSITION.TOP_RIGHT
          });
        }
        this.showLoader();
      })
      .catch(ex => {
        console.log(ex);
      });
  }

  showLoader() {
    let showLoader = this.state.showLoader;
    this.setState({ showLoader: !showLoader });
  }

  downloadEcel = () => {
    // if (this.props.location.state.historica) {
    //   getUnoHydNewDetailsExcel(ProduccionHistoricaKeys, this.state.reqObj);
    // } else {
    //   getlineofbusinessExcel(LineOfBusinessKeys, this.state.reqObj);
    // }
    this.setState({
      openDailog: true
    });
  };

  clickRow = (data, event) => {
    if (typeof event.target.checked === "undefined" && event.type === "click") {
      this.setState({
        openDailog: true,
        amountOfDataLargeFlag: true,
        excelButtonData: data
      });
    } else {
      if (event.target.checked) {
        let checkInArr = _.includes(selectRowData, data);
        if (!checkInArr) {
          selectRowData.push(data);
        }
      } else {
        let checkInArr = _.includes(selectRowData, data);
        if (checkInArr) {
          _.remove(selectRowData, data);
        }
      }
      let adExcelButtonFlag = selectRowData.length > 0 ? false : true;
      this.setState({
        adExcelButton: adExcelButtonFlag,
        amountOfDataLargeFlag: false
      });
    }
  };

  handleClose = () => {
    this.setState({ openDailog: false });
  };

  backToPreviousPage = () => {
    // let {rut,nombre,startdate , enddate} = this.state;
    // this.props.history.push(`/consulta-global/por-cliente/`);
    window.history.back();
  };

  render() {
    const { i18n } = this.props;
    let translateObj =
      (i18n.store.data[i18n.language] &&
        i18n.store.data[i18n.language].translations) ||
      i18n.store.data["es"].translations;
    let slugUrl = "";
    if (this.state.reqObj && this.state.reqObj.slug) {
      slugUrl = "/" + this.state.reqObj.slug + "/" + "Line-of-business/";
    } else {
      slugUrl = "/consulta-global/por-cliente/Line-of-business/";
    }
    const data = {
      route: slugUrl,
      key: "ramo",
      reqObj: this.state.reqObj
    };
    let showLoader = this.state.showLoader;
    const { classes } = this.props;
    const Keys = this.state.reqObj.historica
      ? ProduccionHistoricaKeys
      : LineOfBusinessKeys;
    return (
      <main className="main-container">
        {this.state.showLoader ? <Loader /> : null}
        {this.state.blnShowLineOfBusiness &&
        this.state.lineOfBusinessData &&
        !this.state.emptyState ? (
          <>
            <h3>{"Informacion General"}</h3>
            <DyanmicTableWithSorting
              data={this.state.lineOfBusinessData}
              keyArray={Keys}
              orderBy={Keys[0]}
              pathData={data}
              reqObj={this.state.reqObj}
              isPaginationReq={this.state.isPaginationReq}
              isEnhancedTableHeadReq={this.state.isEnhancedTableHeadReq}
              passClick={this.clickRow}
              defaultRowPerPage={this.state.defaultRowPerPage}
              virticalScroll={true}
              tableMaxHeight={"400"}
            />
            <Button
              variant="contained"
              size="medium"
              color="primary"
              disabled={this.state.adExcelButton}
              className="search-icon search-policy"
              style={{ float: "right", marginBottom: "5px" }}
              onClick={this.downloadEcel}
            >
              <SearchIcon className="download-icon" />
              {"Excel"}
            </Button>
            {this.state.showVolver ? (
              <Button
                variant="contained"
                size="medium"
                color="primary"
                className="search-icon search-policy"
                style={{
                  float: "right",
                  marginBottom: "5px",
                  marginRight: "10px"
                }}
                onClick={this.backToPreviousPage}
              >
                {"Volver"}
              </Button>
            ) : null}
          </>
        ) : null}

        {this.state.emptyState ? (
          <div className={"content"}>
            <EmptyState
              emoticon={"🔍"}
              message={"Informacion general no encontrada"}
              showButton={true}
            />
          </div>
        ) : null}
        {/* Replace null with below compareDesc */}

        <ToastContainer />
        {this.state.openDailog ? (
          <>
            {/* {showLoader ? <Loader /> : null} */}
            <Dialog
              open={this.state.openDailog}
              onClose={this.handleClose}
              aria-labelledby="responsive-dialog-title"
            >
              <div style={{ width: 600 }}>
                <DialogTitle
                  id="responsive-dialog-title"
                  className="dailog-title-style"
                >
                  Informacion a enviar a excel
                </DialogTitle>
                <ExcelDownloadCheckBox
                  excelKeys={
                    this.props.location.state.historica
                      ? ProduccionHistoricaKeys
                      : LineOfBusinessKeys
                  }
                  selectRowData={
                    this.state.amountOfDataLargeFlag
                      ? this.state.excelButtonData
                      : selectRowData
                  }
                  reqObj={this.state.reqObj}
                  downloadEcelMethod={this.downloadEcel}
                  dialogueHandler={this.handleClose}
                  siniestroFlag={this.state.siniestrosCheckBoxReq}
                  amountOfDataLargeFlag={this.state.amountOfDataLargeFlag}
                />
              </div>
            </Dialog>
          </>
        ) : null}
      </main>
    );
  }
}

function mapStateToProps(state) {
  console.log("state------>", state);
  return {
    porClienteObj: state.porClienteObj
  };
}

function mapDispatchToProps(dispatch) {
  return {
    increment: () => dispatch({ type: "INCREMENT" }),
    decrement: () => dispatch({ type: "DECREMENT" })
  };
}

let LineOfBusinessObj = connect(
  mapStateToProps,
  mapDispatchToProps
)(LineOfBusiness);
export default translate("translations")(LineOfBusinessObj);
