import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { translate, Trans } from "react-i18next";
import Loader from "../../../BaseComponents/Loader/Loader";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { ToastContainer, toast } from "react-toastify";
import './usecase25.scss';
import {getSiniestrosDetails} from "../../api/analista_recuperos";
import {siniestrotableKeys}  from "../../../Constants/analista_recuperoskeys";
import DyanmicTableWithSorting from "../../../BaseComponents/DyanmicTableWithSorting/DyanmicTableWithSorting";
import ExcelDownload  from '../../../utils/ExcelDownloadComponent'
import SearchIcon from "@material-ui/icons/CloudDownload";

const styles = theme => ({
    root: {
        display: 'flex',
      },
      formControl: {
        margin: theme.spacing.unit * 3,
      },
      group: {
        margin: `${theme.spacing.unit}px 0`,
      },
});

class ConsultaSiniestros extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      siniestroNo:"",
      enableSearch:true,
      resultData:undefined,
      showLoader:false,
      defaultRowPerPage:20,
      excelsheetName :"ReportServlet"
    };
  }
  handleChange=(event)=> {
    const value = event.target.value;
    let bln = value.length > 0 ? false : true;
    this.setState({ siniestroNo: value, enableSearch: bln });
  }
  resetSearch=()=>{
    this.setState({
      siniestroNo:"",
      enableSearch:true,
      tableData:undefined
    })
  }
  showLoaderAction() {
    let showLoader = this.state.showLoader;
    this.setState({
      showLoader: !showLoader
    });
  }
  siniestroDetails=()=>{
    let tableData=undefined;
    this.showLoaderAction();
    getSiniestrosDetails(this.state.siniestroNo).then((response)=>{
      this.showLoaderAction();
      tableData=(response.data && response.data.consultaSiniestros)? response.data.consultaSiniestros :undefined;
      this.setState({tableData:tableData})
      !tableData && toast.error(response.msg || "No consultaSiniestros found", {
        position: toast.POSITION.TOP_RIGHT
      });
    }).catch((ex)=>{
        this.showLoaderAction();
        toast.error("Internal Server Error", {
          position: toast.POSITION.TOP_RIGHT
        });
    })
  }
  
 donwloadExcel=()=>{
   this.setState({
    excelDownlaod : true
   })
 }
  render() {
    const { i18n } = this.props;
    let translateObj =
      (i18n.store.data[i18n.language] &&
        i18n.store.data[i18n.language].translations) ||
      i18n.store.data["es"].translations;
      let newFormatData;
      if(!_.isEmpty(this.state.tableData) && !_.isEmpty(this.state.tableData)){
        newFormatData = this.state.tableData && this.state.tableData.map((singleObj)=>{
          // console.log(singleObj)
          return Object.values(singleObj);
        })
      }

      let finalData=[{
        columns: ["CONSULTA DE SINIESTROS"],
        data: []
        },
        {
            xSteps: 0, // Will start putting cell with 0 empty cell on left most
            ySteps: 1, //will put space of 2 rows,
            columns: [
              "Siniestro",
              "Poliza",
              "Item",
              "Ramo",
              "Oficina de atencion",
              "Nombre asegurado",
              "Patente",
              "Fecha siniestro",
              "Fecha denuncio",
              "Liquidador",
              "Tipo Contacto"
            ],
            data:newFormatData
        }
      ];
      let sheetName="sheet1";

    return (
      <main className="main-container">
        {this.state.showLoader ? <Loader /> : null}
        <div className="search-wrapper">
            <TextField
              id="custom-css-standard-input"
              label={translateObj.siniestroNo}
              type="search"
              className="text-field"
              margin="normal"
              value={this.state.siniestroNo}
              onChange={this.handleChange}
            />
            <Button
              variant="contained"
              size="medium"
              color="primary"
              className="search-policy"
              disabled={this.state.enableSearch}
              onClick={this.siniestroDetails}
            >
              {translateObj.policy_page.search_btn}
            </Button>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              className="search-policy"
              onClick={this.resetSearch}
            >
              {translateObj.policy_page.reset_btn}
            </Button>
        </div>
        {this.state.tableData && this.state.tableData.length?<>
          <div className="">
            <Button
                variant="contained"
                size="medium"
                color="primary"
                className="search-policy download-button-style"
                onClick={this.donwloadExcel}
                >
              <SearchIcon className="download-icon" />
              {"Excel"}
            </Button>
          </div>
          <DyanmicTableWithSorting
            data={this.state.tableData}
            keyArray={siniestrotableKeys}
            orderBy={siniestrotableKeys[0]}
            defaultRowPerPage={this.state.defaultRowPerPage}
            isPaginationReq={true}
            isEnhancedTableHeadReq={true}
            virticalScroll={true}
            tableMaxHeight={"450"}
          />
        </>:null}
        {
                this.state.excelDownlaod ?  
                <ExcelDownload excelData={finalData} sheetName={sheetName} excelName={this.state.excelsheetName}/> : null
          }
        <ToastContainer />
      </main>
    );
  }
}

export default translate("translations")( withStyles(styles)(ConsultaSiniestros));


