import React from "react";
import DyanmicTableWithSorting from "../../../BaseComponents/DyanmicTableWithSorting/DyanmicTableWithSorting";
import CoInsuranceKeys from "./CoInsuranceKeys.json";
import TextField from "@material-ui/core/TextField";
import { translate, Trans } from "react-i18next";
import Button from "@material-ui/core/Button";
import { getCoinsuranceDetail } from "./../../api/policy";
import Loader from "../../../BaseComponents/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import "./CoInsurance.scss";
import _ from "lodash";

class CoInsurance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      data: undefined,
      CoInsuranceKeysArray: CoInsuranceKeys,
      enableSearch: true,
      searchText: "",
      blnShowCoInsurance: false,
      showLoader: false,
      title: "",
      isPaginationReq: false,
      isEnhancedTableHeadReq: false,
      showVolver: false
    };
    this.resetSearch = this.resetSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.showCoInsurance = this.showCoInsurance.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props;
    const policyNo = params.policyNo;
    if (policyNo) {
      this.setState({ searchText: policyNo, showVolver: true });
      this.showCoInsurance(policyNo);
      this.setState({ enableSearch: false });
    }
  }

  showLoaderAction() {
    let showLoader = this.state.showLoader;
    this.setState({ showLoader: !showLoader });
  }

  showCoInsurance(policyNo, start, end) {
    this.showLoaderAction();

    // if (this.state.searchText > 0) {
    //   this.props.history.push("/co-insurance/policy=" + this.state.searchText);
    // }

    getCoinsuranceDetail(this.state.searchText || policyNo, start, end)
      .then(response => {
        if (response && response.data && !_.isEmpty(response.data)) {
          this.setState({
            blnShowCoInsurance: true,
            data: response.data && response.data.company,
            title: response.data && response.data.title
          });
          this.showLoaderAction();
        } else {
          toast.error(
            response.msg || "No Co-Insurance found for given policy number",
            {
              position: toast.POSITION.TOP_RIGHT
            }
          );
          this.setState({
            data: undefined,
            blnShowCoInsurance: false
          });
          this.showLoaderAction();
        }
      })
      .catch(err => {
        this.showLoaderAction();
        console.error(err);
      });
    this.setState({ blnShowCoInsurance: true });
  }

  handleChange(event) {
    const value = event.target.value;
    let bln = value.length > 0 ? false : true;
    this.setState({ searchText: value, enableSearch: bln });
  }

  handleKeyPress(event) {
    if (event.key === "Enter") {
      this.showCoInsurance();
    }
  }

  backToPreviousPage = () => {
    window.history.back();
  };

  resetSearch() {
    this.setState({
      blnShowCoInsurance: false,
      searchText: "",
      data: undefined
    });
  }

  render() {
    const { classes, i18n } = this.props;
    const { value } = this.state;
    const blnShowCoInsurance = this.state.blnShowCoInsurance;
    let showLoader = this.state.showLoader;
    let translateObj =
      (i18n.store.data[i18n.language] &&
        i18n.store.data[i18n.language].translations) ||
      i18n.store.data["es"].translations;

    return (
      <main className="main-container">
        {showLoader ? <Loader /> : null}
        <div className="main-screen">
          <div className="search-wrapper">
            <TextField
              id="standard-search"
              label={translateObj.policy_page.search_placeholder}
              type="search"
              className="text-field"
              margin="normal"
              value={this.state.searchText}
              onChange={this.handleChange}
              onKeyPress={this.handleKeyPress}
            />
            <Button
              variant="contained"
              size="medium"
              color="primary"
              className="search-policy"
              disabled={this.state.enableSearch}
              onClick={this.showCoInsurance.bind()}
            >
              {translateObj.policy_page.search_btn}
            </Button>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              className="search-policy"
              onClick={this.resetSearch.bind()}
            >
              {translateObj.policy_page.reset_btn}
            </Button>
            {this.state.showVolver ? (
              <Button
                variant="contained"
                size="medium"
                color="primary"
                className="search-policy"
                onClick={this.backToPreviousPage}
              >
                {"Volver"}
              </Button>
            ) : null}
          </div>

          {blnShowCoInsurance && this.state.data ? (
            <>
              <h3>{this.state.title}</h3>
              <DyanmicTableWithSorting
                data={this.state.data}
                keyArray={this.state.CoInsuranceKeysArray}
                orderBy={this.state.CoInsuranceKeysArray[0]}
                pathData={{}}
                pathData={{}}
                virticalScroll={true}
                tableMaxHeight={"450"}
                isEnhancedTableHeadReq={this.state.isEnhancedTableHeadReq}
              />
            </>
          ) : null}
        </div>
        <ToastContainer />
      </main>
    );
  }
}

export default translate("translations")(CoInsurance);
