import React, { Component } from 'react'
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import DyanmicTable from '../../../../BaseComponents/DyanmicTable/DyanmicTable'

export default class registroDeinspeccion extends React.Component {
  constructor(props){
    super(props);
    this.state = {
    };

  }

  componentDidMount() {
    this.setState({ data : this.props.data}); 
  }

  componentWillReceiveProps() {
    this.setState({ data : this.props.data}); 
  }

  render() {
    const datosDelVehiculo = this.state.data && this.state.data.datosDelVehiculo ? this.state.data.datosDelVehiculo : undefined;
    const datosInspeccion = this.state.data && this.state.data.datosInspeccion ?   this.state.data.datosInspeccion : undefined
    const datosReinspeccion = this.state.data && this.state.data.datosReinspeccion ? this.state.data.datosReinspeccion : undefined;
    const accesorios = this.state.data && this.state.data.accesorios ?   this.state.data.accesorios : undefined;
    const preexistencias = this.state.data && this.state.data.preexistencias ?   this.state.data.preexistencias : undefined;
    
    return (
      <main>
       <div>
         {datosDelVehiculo ?
          <Card className="card-style">
                <h3 className="Heading-name">Datos Del Vehiculo</h3>
                <div>
                    <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="NInspeccion"
                        className="textField"
                        value={datosDelVehiculo.nInspeccion}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                        readOnly: true,
                        }}
                    />
                    </span>
                    <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="Tipo"
                        className="textField"
                        value={datosDelVehiculo.tipo}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                        readOnly: true,
                        }}
                    />
                    </span>
                    <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="Marca"
                        className="textField"
                        margin="normal"
                        value={datosDelVehiculo.marca}
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                          }}
                    />
                    </span>
                    <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="Modelo"
                        className="textField"
                        margin="normal"
                        value={datosDelVehiculo.modelo}
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                          }}
                    />
                    </span>
                    <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="nMotor"
                        className="textField"
                        margin="normal"
                        value={datosDelVehiculo.nMotor}
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                          }}
                    />
                    </span>                    
                </div>
                <div>
                    <span className="Space-around">
                      <TextField InputLabelProps={{
    shrink: true,
}}
                          id="standard-bare"
                          label="Ano"
                          className="textField"
                          value={datosDelVehiculo.ano}
                          margin="normal"
                          variant="outlined"
                          InputProps={{
                          readOnly: true,
                          }}
                      />
                    </span>
                    <span className="Space-around">
                      <TextField InputLabelProps={{
    shrink: true,
}}
                          id="standard-bare"
                          label="Kilometraje"
                          className="textField"
                          value={datosDelVehiculo.kilometraje}
                          margin="normal"
                          variant="outlined"
                          InputProps={{
                          readOnly: true,
                          }}
                      />
                    </span>
                    <span className="Space-around">
                      <TextField InputLabelProps={{
    shrink: true,
}}
                          id="standard-bare"
                          label="NChasis"
                          className="textField"
                          value={datosDelVehiculo.nChasis}
                          margin="normal"
                          variant="outlined"
                          InputProps={{
                          readOnly: true,
                          }}
                      />
                    </span>
                    <span className="Space-around">
                      <TextField InputLabelProps={{
    shrink: true,
}}
                          id="standard-bare"
                          label="Color"
                          className="textField"
                          value={datosDelVehiculo.color}
                          margin="normal"
                          variant="outlined"
                          InputProps={{
                          readOnly: true,
                          }}
                          style = {{width: 300}}
                      />
                    </span>
                  </div>
            </Card> : null
          }
          { datosInspeccion ? 
          <Card className="card-style">
            <h3 className="Heading-name">Datos Inspeccion</h3>
            <div>
                <span className="Space-around">
                  <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Inspector"
                      className="textField"
                      value={datosInspeccion.inspector}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                      readOnly: true,
                      }}
                  />
                </span>
                <span className="Space-around">
                  <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="InspectorName"
                      className="textField"
                      value={datosInspeccion.inspectorName}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                      readOnly: true,
                      }}
                      style = {{width: 800}}
                  />
                </span>              
            </div>
            <div>
                <span className="Space-around">
                  <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Lugar De Inspeccion"
                      className="textField"
                      value={datosInspeccion.lugarDeInspeccion}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                      readOnly: true,
                      }}
                      style = {{width: 400}}
                  />
                </span>
                <span className="Space-around">
                  <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Nombre Del Contacto"
                      className="textField"
                      value={datosInspeccion.nombreDelContacto}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                      readOnly: true,
                      }}
                      style = {{width: 400}}
                  />
                </span>
                <span className="Space-around">
                  <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="FechaInspeccion"
                      className="textField"
                      value={datosInspeccion.fechaInspeccion}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                      readOnly: true,
                      }}
                      style = {{width: 200}}
                  />
                </span>
                <span className="Space-around">
                  <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="Hora"
                      className="textField"
                      value={datosInspeccion.hora}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                      readOnly: true,
                      }}
                  />
                </span>
                <span className="Space-around">
                  <TextField InputLabelProps={{
    shrink: true,
}}
                      id="standard-bare"
                      label="NRefExterna"
                      className="textField"
                      value={datosInspeccion.nRefExterna}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                      readOnly: true,
                      }}
                  />
                </span>
              </div>
          </Card> : null
          }
          {datosReinspeccion ?
          <Card className="card-style">
            <h3 className="Heading-name">Datos Reinspeccion</h3>
              <div>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="NReinspeccion"
                        className="textField"
                        value={datosReinspeccion.nReinspeccion}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                        readOnly: true,
                        }}
                    />
                  </span>
                  <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="Observacion"
                        className="textField"
                        value={datosReinspeccion.observacion}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                        readOnly: true,
                        }}
                          style = {{width: 800}}
                    />
                  </span>                    
              </div>
          </Card> : null 
          }
          {accesorios && _.isArray(accesorios)  && accesorios.length ?
          <Card className="card-style">
            <h3 className="Heading-name">Accesorios</h3>
            <DyanmicTable data={accesorios}  id="codigo" keyArray={Object.keys(accesorios[0])}/>
          </Card> : null
          }
          {preexistencias ? 
          <Card className="card-style">
            <h3 className="Heading-name">Preexistencias</h3>
            <DyanmicTable data={preexistencias}  id="codigo_d" keyArray={Object.keys(preexistencias[0])}/>
          </Card> : null
          }              
      </div>
      </main>  
    );
  }
}