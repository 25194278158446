
import React from 'react'
import TextField from '@material-ui/core/TextField';
import  './AntecedentesGenerales.scss'
import Card from '@material-ui/core/Card';


export default function AntecedentesGenerales(props) {
  const antecedentesGenerales = props.data && props.data.antecedentesGenerales;
  return (
    <div className="Main-container">
      {props.data ?
        <Card className="Card-area box-show-none">
        <fieldset className="custom-border">
         <legend className="legend-box">Antecedentes Generales</legend>
            {/* <h3 className="Heading-name">Antecedentes Generales</h3> */}
            <div>
                <span className="Space-around">
                <TextField InputLabelProps={{
    shrink: true,
}}
                    id="standard-bare"
                    label="Moneda"
                    className="textField"
                    value={props.data.moneda}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                    }}
                    style = {{width: 300}}
                  />
                </span>
                <span className="Space-around">
            
                  <TextField InputLabelProps={{
    shrink: true,
}}
                    id="standard-bare"
                    label="Oficina"
                    className="textField"
                    value={props.data.oficina}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                    }}
                    style = {{width: 300}}
                  />
                  </span>
                  <span className="Space-around">
            
                  <TextField InputLabelProps={{
    shrink: true,
}}
                    id="standard-bare"
                    label="Ramo"
                    className="textField"
                    value={props.data.ramo}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                    }}
                    style = {{width: 250}}
                  />
                  </span>
            </div>
            <div>
                <span className="Space-around">
                <TextField InputLabelProps={{
    shrink: true,
}}
                    id="standard-bare"
                    label="Fecha Ingreso"
                    className="textField"
                    value={antecedentesGenerales.fechaLan}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                    }}
                    style = {{width: 300}}
                  />
                </span>
                <span className="Space-around">
            
                  <TextField InputLabelProps={{
    shrink: true,
}}
                    id="standard-bare"
                    label="Vigencia Desde"
                    className="textField"
                    value={antecedentesGenerales.VDesde}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                    }}
                    style = {{width: 300}}
                  />
                  </span>
                  <span className="Space-around">
            
                  <TextField InputLabelProps={{
    shrink: true,
}}
                    id="standard-bare"
                    label="Vigencia Hasta"
                    className="textField"
                    value={antecedentesGenerales.VHasta}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                    }}
                    style = {{width: 250}}
                  />
                  </span>
            </div>
            <div>
                <span className="Space-around">
                <TextField InputLabelProps={{
    shrink: true,
}}
                    id="standard-bare"
                    label="Factura"
                    className="textField"
                    value={antecedentesGenerales.Factura}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                    }}
                    style = {{width: 300}}
                  />
                </span>
                <span className="Space-around">
            
                  <TextField InputLabelProps={{
    shrink: true,
}}
                    id="standard-bare"
                    label="Itemes"
                    className="textField"
                    value={antecedentesGenerales.Itemes}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                    }}
                    style = {{width: 300}}
                  />
                  </span>
                  <span className="Space-around">
            
                  <TextField InputLabelProps={{
    shrink: true,
}}
                    id="standard-bare"
                    label="T. Partic"
                    className="textField"
                    value={antecedentesGenerales.TPartic}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                    }}
                    style = {{width: 250}}
                  />
                  </span>
            </div>
            <div>
                <span className="Space-around">
                <TextField InputLabelProps={{
    shrink: true,
}}
                    id="standard-bare"
                    label="Nro. Negocio"
                    className="textField"
                    value={antecedentesGenerales.atributosPoliza.nroNeg[0]}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                    }}
                    style = {{width: 250}}
                  />
                </span>
                <span className="Space-around">
            
                  <TextField InputLabelProps={{
    shrink: true,
}}
                    id="standard-bare"
                    label=""
                    className="textField"
                    value={antecedentesGenerales.atributosPoliza.nroNeg[1]||" "}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                    }}
                    style = {{width: 620}}
                  />
                  </span>
            </div>
            <div>
                <span className="Space-around">
                <TextField InputLabelProps={{
    shrink: true,
}}
                    id="standard-bare"
                    label="Póliza Anterior"
                    className="textField"
                    value={antecedentesGenerales.atributosPoliza.polAnt}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                    }}
                    style = {{width: 240}}
                  />
                </span>
                <span className="Space-around">
            
                  <TextField InputLabelProps={{
    shrink: true,
}}
                    id="standard-bare"
                    label="Origen"
                    className="textField"
                    value={antecedentesGenerales.atributosPoliza.origen}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                    }}
                    style = {{width: 250}}
                  />
                  </span>
                  <span className="Space-around">
                <TextField InputLabelProps={{
    shrink: true,
}}
                    id="standard-bare"
                    label="Ref. Cor."
                    className="textField"
                    value={antecedentesGenerales.atributosPoliza.refCor}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                    }}
                    style = {{width: 200}}
                  />
                </span>
                <span className="Space-around">
            
                  <TextField InputLabelProps={{
    shrink: true,
}}
                    id="standard-bare"
                    label="Facultativo"
                    className="textField"
                    value={antecedentesGenerales.atributosPoliza.facultativo}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                    }}
                    style = {{width: 140}}
                  />
                  </span>
            </div>
            <div>
                <span className="Space-around">
                <TextField InputLabelProps={{
    shrink: true,
}}
                    id="standard-bare"
                    label="N&#176; Predistribución"
                    className="textField"
                    value={antecedentesGenerales.atributosPoliza.nPredistribucion}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                    }}
                    style = {{width: 610}}
                  />
                </span>
                <span className="Space-around">
            
                  <TextField InputLabelProps={{
    shrink: true,
}}
                    id="standard-bare"
                    label="Propuesta"
                    className="textField"
                    value={antecedentesGenerales.atributosPoliza.propuesta}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                    }}
                    style = {{width: 260}}
                  />
                  </span>
            </div>
            <div>
                <span className="Space-around">
                <TextField InputLabelProps={{
    shrink: true,
}}
                    id="standard-bare"
                    label="Condición"
                    className="textField"
                    value={antecedentesGenerales.atributosPoliza.condicion}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                    }}
                    style = {{width: 610}}
                  />
                </span>
                <span className="Space-around">
            
                  <TextField InputLabelProps={{
    shrink: true,
}}
                    id="standard-bare"
                    label="Limite"
                    className="textField"
                    value={antecedentesGenerales.atributosPoliza.limite}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                    }}
                    style = {{width: 260}}
                  />
                  </span>
            </div>
            </fieldset>
        </Card>
      :null
      }
   </div>
  )
}
