import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import Select from "react-select";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import { ToastContainer, toast } from "react-toastify";
import Divider from "@material-ui/core/Divider";
import { consultaPorDistributionKeys } from "../../../../Constants/reaseguradoresKeys";
import { getConsultaDropDownDetails, submitConsultaPorDetails, getConsultaTableDetails } from "../../../api/reaseguradores";
import DyanmicTableWithSorting from "../../../../BaseComponents/DyanmicTableWithSorting/DyanmicTableWithSorting";


import _ from 'lodash'
import Loader from "../../../../BaseComponents/Loader/Loader";
import '../Reaseguradores.scss'
import Card from '@material-ui/core/Card';
import 'date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { translate, Trans } from "react-i18next";
import { scrollToBottom } from '../../../../utils/scrollDown'
import { Link } from "../../../../../node_modules/react-router-dom";



const styles = theme => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing.unit * 3,
    },
    group: {
        margin: `${theme.spacing.unit}px 0`,
    },
});

class ConsultaPorDistribucion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            idDistribution: "",
            proporcional: "1",
            asegurado: "",
            grupo: "",
            fechaGeneracion: "1",
            nPoliza: "",
            ramoList: [],
            anoIngresoList: [],
            ramoValue: "",
            ramoLabel: "",
            anoIngresoIDValue: "",
            anoIngresoIDLabel: "",
            defaultRowPerPage: 20,
            enableBuscar: false
        };
        this.submitDetails = this.submitDetails.bind(this);
    }

    showLoaderAction() {
        let showLoader = this.state.showLoader;
        this.setState({
            showLoader: !showLoader
        });
    }

    componentDidMount() {
        this.showLoaderAction();
        // const { match: { params } } = this.props;
        // const { policyID } = params;
        // console.log(policyID)
        // if (policyID) {
        //     this.setState({ idDistribution: policyID });
        //     this.submitDetails(policyID)
        // }
        getConsultaDropDownDetails().then((response) => {
            this.showLoaderAction()
            if (response && response.data) {
                this.setState({
                    ramoList: response.data.dropdownDetailsRamo,
                    anoIngresoList: response.data.dropdownDetailsAnoIngreso,
                })

            } else {
                toast.error(response.msg, { position: toast.POSITION.TOP_RIGHT });
            }
        }).catch((err) => {
            this.showLoaderAction();
            toast.error("Something went wrong", { position: toast.POSITION.TOP_RIGHT });
        })


    }

    resetSearch = () => {
        this.setState({
            idDistribution: "",
            proporcional: "1",
            asegurado: "",
            grupo: "",
            fechaGeneracion: "1",
            nPoliza: "",
            ramoValue: { value: "" },
            ramoLabel: "",
            anoIngresoIDValue: { value: "" },
            anoIngresoIDLabel: "",
            tableData: "",
            enableBuscar: false
        })
    }

    handleFechaGeneracionRadio = event => {
        const value = event.target.value;
        this.setState({
            fechaGeneracion: value,
            enableBuscar: true
        });

    };

    handleProporcialRadio = event => {
        const value = event.target.value;
        this.setState({
            proporcional: value,
        });

    };

    handleChangeAsegurado = event => {
        const value = event.target.value;
        this.checkDetails(this.state.idDistribution, this.state.nPoliza, this.state.grupo, value);
        this.setState({
            asegurado: value,
        });

    };
    handleChangeGrupo = event => {
        const value = event.target.value;
        this.checkDetails(this.state.idDistribution, this.state.nPoliza, value, this.state.asegurado);
        this.setState({
            grupo: value,

        });
    };
    handleChangeNPoliza = event => {
        const value = event.target.value;
        this.checkDetails(this.state.idDistribution, value, this.state.grupo, this.state.asegurado);
        this.setState({
            nPoliza: value,

        });
    };
    handleChangeIdDistribution = event => {

        const value = event.target.value;
        this.checkDetails(value, this.state.nPoliza, this.state.grupo, this.state.asegurado);
        this.setState({
            idDistribution: value,

        });
    };

    selectHandleChangeRamo = actionMeta => {
        if (actionMeta && actionMeta.value) {
            this.setState({
                ramoValue: actionMeta.value,
                ramoLabel: actionMeta.label,
                enableBuscar: true
            });
        }
    };

    selectHandleChangeAnoIngresoID = actionMeta => {
        if (actionMeta && actionMeta.value) {
            this.setState({
                anoIngresoIDValue: actionMeta.value,
                anoIngresoIDLabel: actionMeta.label,
                enableBuscar: true
            });
        }
        console.groupEnd();
    };

    clickRow = (data) => {
        if (data && data.idPredistribucion) {
            const idPredistribucion = data.idPredistribucion
            this.getConsultaData(idPredistribucion)
        }
    }

    getConsultaData(no) {
        this.showLoaderAction()
    }

    checkDetails(idDistribution, nPoliza, grupo, asegurado) {
        if (idDistribution || nPoliza || grupo || asegurado) {
            this.setState({ enableBuscar: true });
        }
        else {
            this.setState({
                enableBuscar: false
            });
        }
    }

    submitDetails = () => {
        //  let id = parseInt() || parseInt(policyID);

        const reqObj = {
            idDistribution: this.state.idDistribution,
            poliza: this.state.nPoliza,
            grupo: this.state.grupo,
            asegurado: this.state.asegurado,
            ramo: (this.state.ramoValue.value === "") ? "" : this.state.ramoValue,
            ingresoId: (this.state.anoIngresoIDValue.value === "") ? "" : this.state.anoIngresoIDValue,
            proporcional: this.state.proporcional
        }

        let tableData = undefined;
        if (reqObj) {
            this.showLoaderAction();
            submitConsultaPorDetails(reqObj).then((response) => {
                scrollToBottom();
                tableData = (response.data && response.data.getDistribucionDetails) ? response.data.getDistribucionDetails : undefined;
                this.showLoaderAction();
                this.setState({ tableData: tableData })
                !tableData && toast.error(response.msg || "No Distribucion Details found", {
                    position: toast.POSITION.TOP_RIGHT
                });


            }).catch((err) => {
                this.showLoaderAction();
                toast.error("Internal Server Error", { position: toast.POSITION.TOP_RIGHT });
            })
        }
    }


    render() {
        const { classes } = this.props;
        const data = {
            route: "/reaseguradores/consulta-por-distribucion",
            keys: ["option"]
        };
        return (
            <main className="main-container">
                {this.state.showLoader ? <Loader /> : null}
                <Card className="Card-area box-show-none">

                    <fieldset className="custom-border">
                        <legend className="legend-box">Consulta Por Distribucion</legend>
                        <div className="container">
                            <div className="row porClientRowClass">
                                <div className="col-sm-4">
                                    <TextField
                                        id="custom-css-standard-input"
                                        label={"ID Distribución"}
                                        type="search"
                                        className="text-field"
                                        margin="normal"
                                        value={this.state.idDistribution}
                                        onChange={this.handleChangeIdDistribution}
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <TextField
                                        id="custom-css-standard-input"
                                        label={"N° Póliza"}
                                        type="search"
                                        className="text-field"
                                        margin="normal"
                                        value={this.state.nPoliza}
                                        onChange={this.handleChangeNPoliza}
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <TextField
                                        id="custom-css-standard-input"
                                        label={"Grupo"}
                                        type="search"
                                        className="text-field"
                                        margin="normal"
                                        value={this.state.grupo}
                                        onChange={this.handleChangeGrupo}
                                    />
                                </div>
                            </div>
                            <Divider />
                            <div className="row porClientRowClass">
                                <div className="col-sm-4">
                                    <TextField
                                        id="custom-css-standard-input"
                                        label={"Asegurado"}
                                        type="search"
                                        className="text-field"
                                        margin="normal"
                                        value={this.state.asegurado}
                                        onChange={this.handleChangeAsegurado}

                                    />
                                </div>
                                <div className="col-sm-4">
                                    <span className="label-class">Ramo</span>
                                    <Select
                                        maxMenuHeight={180}
                                        placeholder="Seleccione"
                                        onChange={this.selectHandleChangeRamo}
                                        value={this.state.ramoValue.value}
                                        options={this.state.ramoList}
                                    />

                                </div>
                                <div className="col-sm-4">
                                    <span className="label-class">Año ingreso ID</span>
                                    <Select value={this.state.anoIngresoIDValue.value}
                                        maxMenuHeight={180}
                                        placeholder="Seleccione"
                                        onChange={this.selectHandleChangeAnoIngresoID}
                                        options={this.state.anoIngresoList}
                                    />

                                </div>
                            </div>
                            <Divider />
                            <div className="row porClientRowClass">

                                <div className="col-sm-4">

                                    <FormLabel component="legend">Proporcial</FormLabel>

                                    <RadioGroup
                                        className={classes.group}
                                        value={this.state.proporcional}
                                        onChange={this.handleProporcialRadio}
                                        row={true}
                                    >
                                        <FormControlLabel
                                            value="1"
                                            control={<Radio color="primary" />}
                                            label="Si"
                                        />
                                        <FormControlLabel
                                            value="2"
                                            control={<Radio color="primary" />}
                                            label="No"
                                        />

                                    </RadioGroup>

                                </div>
                                <div className="col-sm-8">
                                    <div className="search-wrapper">
                                        <Button 
                                        variant="contained" 
                                        size="medium" 
                                        color="primary" 
                                        className=" resetButtonClass search-policy" 
                                        onClick={this.resetSearch} >
                                            {"Limpiar"}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            size="medium"
                                            color="primary"
                                            className="search-policy resetButtonClass"
                                            disabled={!this.state.enableBuscar}
                                            onClick={this.submitDetails.bind()}
                                        >
                                            {"Buscar"}
                                        </Button>
                                    </div>
                                </div>
                            </div>



                        </div>

                    </fieldset>
                    <DyanmicTableWithSorting
                        data={this.state.tableData}
                        keyArray={consultaPorDistributionKeys}
                        orderBy={consultaPorDistributionKeys[0]}
                        virticalScroll={true}
                        tableMaxHeight={"450"}
                        isPaginationReq={true}
                        isEnhancedTableHeadReq={true}
                        defaultRowPerPage={20}
                        virticalScroll={true}
                        pathData={data}
                        linkCreateArray={["idPredistribucion"]}
                    />
                    <ToastContainer />
                </Card>
            </main >
        );
    }
}


ConsultaPorDistribucion.propTypes = {
    classes: PropTypes.object.isRequired
};

export default translate("translations")(withStyles(styles)(ConsultaPorDistribucion));


