import React from "react";
import Card from "@material-ui/core/Card";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import "./GlobalSearchPorClient.scss";
import { getPeriodo } from "../../api/consulta";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../BaseComponents/Loader/Loader";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import { listOfRamos } from "../../../Constants/listOfRamos";
import {
  porCliente,
  considerarEstratoDe
} from "../../../Constants/consultaConstant";
import moment from "moment";
import _ from "lodash";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
import Select from "react-select";
import esLocale from "date-fns/locale/es";
import enLocale from "date-fns/locale/en-US";
import { connect } from "react-redux";

const localeMap = {
  es: esLocale,
  en: enLocale
};

const styles = theme => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: "100vh",
    overflow: "auto"
  },
  progress: {
    margin: theme.spacing.unit * 2
  },
  grid: {
    width: "70%"
  }
});

class PorRamos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      historica: false,
      showLoader: false,
      enableSearch: true,
      enableBuskar: false,
      openDailog: false,
      porClienteRadio: "contratante",
      criterioRadio: "fechaDeVigencia",
      considerarRadio: "todos",
      considerarAnRadio: "poliza",
      porClenteValue: porCliente["contratante"],
      ramosValue: 0,
      considerarRadioValue: considerarEstratoDe["todos"],
      startDate: new Date(new Date().getFullYear(), 0, 1),
      endDate: new Date(new Date().getFullYear(), 0, 1),
      fetchaHastaDate: new Date(),
      selectedDate: new Date("2014-08-18T21:11:54"),
      listOfPeriodo: undefined,
      showPeriodoDropDown: false,
      ramoText: listOfRamos[0].label,
      ramoNumber: listOfRamos[0].value,
      periodoDate: "-Seleccion-"
    };
    this.showPolizaDetail = this.showPolizaDetail.bind(this);
    this.showLoaderAction = this.showLoaderAction.bind(this);
  }

  handleChangeStartDate = (chageDate, event) => {
    this.setState({
      startDate: chageDate
    });
  };

  handleChangeEndDate = (chageDate, event) => {
    let bln = true;
    let startDate = this.state.startDate
      ? moment(this.state.startDate).format("YYYYMMDD")
      : "";
    let endDate = chageDate
      ? moment(this.state.chageDate).format("YYYYMMDD")
      : "";

    if (!(_.isEmpty(startDate) && _.isEmpty(endDate)) && startDate > endDate) {
      bln = true;
    } else {
      bln = false;
    }

    this.setState({
      endDate: chageDate,
      enableBuskar: bln
    });
  };

  handleChangefetchaHastaDate = (chageDate, event) => {
    this.setState({
      fetchaHastaDate: chageDate
    });
  };

  handlePorClienteRadio = event => {
    const value = event.target.value;
    let valueToSet =
      this.state.criterioRadio === "fechaDeVigencia" ? value : "item";
    this.setState({
      considerarAnRadio: valueToSet
    });
  };

  handleChangeCriterioRadio = event => {
    let considerarAnRadio =
      event.target.value === "fechaDeVigencia" ? "poliza" : "item";
    let historica =
      event.target.value === "cierreProduccionHistorica" ||
      event.target.value === "cierreProduccionalDia"
        ? true
        : false;

    let enableBuskar = this.state.periodoDate === "-Seleccion-" ? true : false;

    this.setState({
      criterioRadio: event.target.value,
      considerarAnRadio: considerarAnRadio,
      historica: historica,
      enableBuskar: enableBuskar
    });
  };

  handleConsiderarEstraRadio = event => {
    let value = event.target.value;
    this.setState({
      considerarRadio: value,
      considerarRadioValue: considerarEstratoDe[value]
    });
  };

  showPolizaDetail() {
    this.setState({
      openDailog: true
    });
  }

  handleClose = () => {
    this.setState({ openDailog: false });
  };

  showLoaderAction() {
    let showLoader = this.state.showLoader;
    this.setState({
      showLoader: !showLoader
    });
  }

  selectHandleChange = actionMeta => {
    if (actionMeta) {
      this.setState({
        ramosValue: actionMeta.value,
        ramoText: actionMeta.label,
        ramoNumber: actionMeta.value
      });
    }
    console.groupEnd();
  };

  selectHandleInputChange = (inputValue, actionMeta) => {
    console.groupEnd();
  };

  handleDateChange = date => {
    this.setState({ selectedDate: date });
  };

  selectHandleChangePeriodo = actionMeta => {
    let enableBuskar = actionMeta.value === "-Seleccion-" ? true : false;
    if (actionMeta && actionMeta.value) {
      this.setState({
        periodoDate: actionMeta.value,
        enableBuskar: enableBuskar
      });
    }
    console.groupEnd();
  };

  /* function to get getPeriodoData */

  getPeriodoData = () => {
    this.showLoaderAction();
    getPeriodo().then(response => {
      if (response && response.data && response.data.periodos) {
        let modifiedPeriodos = response.data.periodos;
        modifiedPeriodos.unshift({ periodo: "-Seleccion-" });
        this.setState({
          listOfPeriodo: modifiedPeriodos,
          periodoDate: modifiedPeriodos[0].periodo,
          showPeriodoDropDown: true
        });
        this.showLoaderAction();
      } else {
        toast.error(response.msg || "No Periodo data found", {
          position: toast.POSITION.TOP_RIGHT
        });
        this.setState({
          listOfPeriodo: undefined,
          showPeriodoDropDown: false
        });
        this.showLoaderAction();
      }
    });
  };

  resetSearch = () => {
    this.setState({
      historica: false,
      showLoader: false,
      enableSearch: true,
      enableBuskar: true,
      openDailog: false,
      porClienteRadio: "contratante",
      criterioRadio: "fechaDeVigencia",
      considerarRadio: "todos",
      considerarAnRadio: "poliza",
      porClenteValue: porCliente["contratante"],
      ramosValue: 0,
      considerarRadioValue: considerarEstratoDe["todos"],
      startDate: new Date(new Date().getFullYear(), 0, 1),
      endDate: new Date(new Date().getFullYear(), 0, 1),
      fetchaHastaDate: new Date(),
      selectedDate: new Date("2014-08-18T21:11:54"),
      listOfPeriodo: undefined,
      showPeriodoDropDown: false,
      periodoDate: null
    });
    this.props.deletePorRamosFormData();
    this.getPeriodoData();
  };

  porRamosSearch = () => {
    this.props.addPorRamosFormData(this.state);
  };

  componentDidMount() {
    const { porRamosObj } = this.props;

    if (porRamosObj && !_.isEmpty(porRamosObj)) {
      let {
        considerarAnRadio,
        considerarRadio,
        considerarRadioValue,
        criterioRadio,
        enableBuskar,
        endDate,
        fetchaHastaDate,
        historica,
        listOfPeriodo,
        openDailog,
        periodoDate,
        porClenteValue,
        porClienteRadio,
        ramosValue,
        selectedDate,
        showLoader,
        showPeriodoDropDown,
        startDate,
        ramoNumber,
        ramoText
      } = this.props.porRamosObj;
      if (true) {
        this.setState({
          considerarAnRadio: considerarAnRadio,
          considerarRadio: considerarRadio,
          considerarRadioValue: considerarRadioValue,
          criterioRadio: criterioRadio,
          enableBuskar: enableBuskar,
          endDate: endDate,
          fetchaHastaDate: fetchaHastaDate,
          historica: historica,
          listOfPeriodo: listOfPeriodo,
          openDailog: openDailog,
          periodoDate: periodoDate,
          porClenteValue: porClenteValue,
          porClienteRadio: porClienteRadio,
          ramosValue: ramosValue,
          selectedDate: selectedDate,
          showLoader: showLoader,
          showPeriodoDropDown: showPeriodoDropDown,
          startDate: startDate,
          ramoNumber: ramoNumber,
          ramoText: ramoText
        });
      }
    } else {
      this.getPeriodoData();
    }
  }

  render() {
    const { classes } = this.props;
    const { selectedDate } = this.state;
    // const { i18n } = this.props;
    //let translateObj = (i18n.store.data[i18n.language] && i18n.store.data[i18n.language].translations) || i18n.store.data["es"].translations;

    const stateObj = {
      rutNumber: 0,
      ramos: this.state.ramosValue,
      ConsiderarEstratoDe: this.state.considerarRadioValue,
      flag: "porRamos",
      slug: "consulta-global/por-ramos",
      historica: this.state.historica
    };

    if (this.state.criterioRadio === "fechaDeVigencia") {
      stateObj.porClente = 1014;
      stateObj.polEndDate = 0;
      stateObj.startDate = moment(this.state.startDate).format("YYYYMMDD");
      stateObj.endDate = moment(this.state.endDate).format("YYYYMMDD");
    } else if (this.state.criterioRadio === "cierreProduccionalDia") {
      stateObj.porClente = 1029;
      stateObj.p_PeriodoAnoMes = moment(this.state.periodoDate).format(
        "YYYYMM"
      );
      stateObj.prodHistorica = "cierreProduccionalDia";
    } else if (this.state.criterioRadio === "carteraVigente") {
      stateObj.porClente = 1014;
      stateObj.startDate = 0;
      stateObj.endDate = moment(this.state.startDate).format("YYYYMMDD");
      stateObj.polEndDate = moment(this.state.startDate).format("YYYYMMDD");
      stateObj.p_R_VST_CODIGO_DOS = "C";
    } else {
      let periodoString = this.state.periodoDate;
      let periodoArr = periodoString ? _.split(periodoString, "/", 2) : null;
      let periodoDate = "";
      if (periodoArr && periodoArr.length > 0) {
        _.forEachRight(periodoArr, function(value) {
          periodoDate = periodoDate + value;
        });
      }
      stateObj.porClente = 1028;
      stateObj.p_PeriodoAnoMes = moment(periodoDate).format("YYYYMM");
    }

    if (this.state.considerarAnRadio === "item") {
      stateObj.unidadCase = true;
    }

    let showLoader = this.state.showLoader;
    return (
      <main className={classes.content + " home-page"}>
        <div className="mainComponent">
          <Card className="Card-area box-show-none">
            <fieldset className="custom-border">
              <legend className="legend-box">Por Ramos</legend>
              <div className="container">
                <div className="row porClientRowClass">
                  {this.state.criterioRadio === "fechaDeVigencia" ? (
                    <>
                      <div className="col">
                        <MuiPickersUtilsProvider
                          utils={DateFnsUtils}
                          locale={localeMap["es"]}
                        >
                          <Grid
                            container
                            className={classes.grid}
                            justify="space-around"
                          >
                            <DatePicker
                              margin="normal"
                              keyboard
                              label="Fecha Inicio"
                              format="dd/MM/yyyy"
                              placeholder="10/10/2018"
                              value={this.state.startDate}
                              invalidDateMessage={"Rango fecha erroneo"}
                              minDateMessage={"Rango fecha erroneo"}
                              onChange={this.handleChangeStartDate}
                              disableOpenOnEnter
                              animateYearScrolling={false}
                            />
                          </Grid>
                        </MuiPickersUtilsProvider>
                      </div>
                      <div className="col">
                        <MuiPickersUtilsProvider
                          utils={DateFnsUtils}
                          locale={localeMap["es"]}
                        >
                          <Grid
                            container
                            className={classes.grid}
                            justify="space-around"
                          >
                            <DatePicker
                              margin="normal"
                              keyboard
                              label="Fecha Término"
                              format="dd/MM/yyyy"
                              placeholder="10/10/2018"
                              value={this.state.endDate}
                              invalidDateMessage={"Rango fecha erroneo"}
                              minDate={this.state.startDate}
                              minDateMessage={
                                "Fecha de finalización mayor que la fecha de inicio"
                              }
                              onChange={this.handleChangeEndDate}
                              disableOpenOnEnter
                              animateYearScrolling={false}
                            />
                          </Grid>
                        </MuiPickersUtilsProvider>
                      </div>
                    </>
                  ) : null}

                  {this.state.criterioRadio === "carteraVigente" ? (
                    <>
                      <div className="col">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Grid
                            container
                            className={classes.grid}
                            justify="space-around"
                          >
                            <DatePicker
                              margin="normal"
                              keyboard
                              label="Fecha Hasta"
                              format="dd/MM/yyyy"
                              placeholder="10/10/2018"
                              value={this.state.fetchaHastaDate}
                              onChange={this.handleChangefetchaHastaDate}
                              disableOpenOnEnter
                              animateYearScrolling={false}
                              disabled
                            />
                          </Grid>
                        </MuiPickersUtilsProvider>
                      </div>
                      <div className="col" />
                    </>
                  ) : null}

                  {this.state.criterioRadio === "cierreProduccionHistorica" ? (
                    <>
                      <div className="col">
                        <span className="label-class">Periodo</span>
                        <div className="ramosClass">
                          <Select
                            maxMenuHeight={180}
                            isSearchable={true}
                            value={{
                              label: this.state.periodoDate,
                              value: this.state.periodoDate
                            }}
                            onChange={this.selectHandleChangePeriodo}
                            options={this.state.listOfPeriodo.map(option => ({
                              value: option.periodo,
                              label: option.periodo
                            }))}
                          />
                        </div>
                      </div>
                      <div className="col" />
                    </>
                  ) : null}

                  {this.state.criterioRadio === "cierreProduccionalDia" ? (
                    <>
                      <div className="col">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Grid
                            container
                            className={classes.grid}
                            justify="space-around"
                          >
                            <DatePicker
                              margin="normal"
                              keyboard
                              label="Fecha"
                              format="dd/MM/yyyy"
                              placeholder="10/10/2018"
                              value={this.state.fetchaHastaDate}
                              onChange={this.handleChangefetchaHastaDate}
                              disableOpenOnEnter
                              animateYearScrolling={false}
                              disabled
                            />
                          </Grid>
                        </MuiPickersUtilsProvider>
                      </div>
                      <div className="col" />
                    </>
                  ) : null}

                  <div className="col">
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <FormLabel component="legend">
                        Considerar a nivel de
                      </FormLabel>
                      <RadioGroup
                        className={classes.group}
                        value={this.state.considerarAnRadio}
                        onChange={this.handlePorClienteRadio}
                        row={true}
                      >
                        <FormControlLabel
                          value="poliza"
                          control={<Radio color="primary" />}
                          label="Poliza"
                        />
                        <FormControlLabel
                          value="item"
                          control={<Radio color="primary" />}
                          label="Item"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div className="col">
                    <span className="label-class">Ramos</span>
                    <div className="ramosClass">
                      <Select
                        maxMenuHeight={200}
                        isSearchable={true}
                        value={{
                          label: this.state.ramoText,
                          value: this.state.ramoNumber
                        }}
                        onChange={this.selectHandleChange}
                        options={listOfRamos.map(option => ({
                          value: option.value,
                          label: option.label
                        }))}
                      />
                    </div>
                  </div>
                </div>
                <Divider />
                <div className="row porClientRowClass">
                  <div className="col">
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <FormLabel component="legend">Criterio</FormLabel>
                      <RadioGroup
                        className={classes.group}
                        value={this.state.criterioRadio}
                        onChange={this.handleChangeCriterioRadio}
                        row={true}
                      >
                        <FormControlLabel
                          value="fechaDeVigencia"
                          control={<Radio color="primary" />}
                          label="Fecha De Vigencia"
                        />
                        <FormControlLabel
                          value="cierreProduccionHistorica"
                          control={<Radio color="primary" />}
                          label="Cierre Produccion Historica"
                        />
                        <FormControlLabel
                          value="cierreProduccionalDia"
                          control={<Radio color="primary" />}
                          label="Cierre Produccion al Dia"
                        />
                        <FormControlLabel
                          value="carteraVigente"
                          control={<Radio color="primary" />}
                          label="Cartera Vigente"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
                <Divider />
                <div className="row porClientRowClass">
                  <div className="col">
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <FormLabel component="legend">
                        Considerar Estrato de
                      </FormLabel>
                      <RadioGroup
                        className={classes.group}
                        value={this.state.considerarRadio}
                        onChange={this.handleConsiderarEstraRadio}
                        row={true}
                      >
                        <FormControlLabel
                          value="personas"
                          control={<Radio color="primary" />}
                          label="Personas"
                        />
                        <FormControlLabel
                          value="empresas"
                          control={<Radio color="primary" />}
                          label="Empresas"
                        />
                        <FormControlLabel
                          selected
                          value="todos"
                          control={<Radio color="primary" />}
                          label="Todos"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div className="col">
                    <Button
                      variant="contained"
                      size="medium"
                      color="primary"
                      className="search-policy resetButtonClass"
                      onClick={this.resetSearch}
                    >
                      {"Limpiar"}
                    </Button>
                    <Link
                      to={{
                        pathname: `/consulta-global/por-ramos/line-of-business`,
                        state: stateObj
                      }}
                      className="BuskarLink"
                    >
                      <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        className="search-policy"
                        onClick={this.porRamosSearch}
                        disabled={this.state.enableBuskar}
                      >
                        {"Buscar"}
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </fieldset>
          </Card>
        </div>
      </main>
    );
  }
}

function mapStateToProps(state) {
  return {
    porRamosObj: state.porRamosObj
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addPorRamosFormData: porReduxReqObj =>
      dispatch({ type: "ADD_POR_RAMOS_FORM_DATA", porReduxReqObj }),
    deletePorRamosFormData: () =>
      dispatch({ type: "DELETE_POR_RAMOS_FORM_DATA" })
  };
}

let porRamosObj = connect(
  mapStateToProps,
  mapDispatchToProps
)(PorRamos);

export default withStyles(styles)(porRamosObj);
