import React from "react";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import { withStyles } from "@material-ui/core/styles";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";

import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { translate, Trans } from "react-i18next";
import DateFnsUtils from "@date-io/date-fns";
import Loader from "../../../BaseComponents/Loader/Loader";
import Input from '@material-ui/core/Input';
import Select from "react-select";
import moment from "moment";
import { gestionKeys } from "../../../Constants/analista_recuperoskeys";
import DyanmicTableWithSorting from "../../../BaseComponents/DyanmicTableWithSorting/DyanmicTableWithSorting";
import {
  MuiPickersUtilsProvider,
  DatePicker
} from "material-ui-pickers";
import { ToastContainer, toast } from "react-toastify";
import './usecase25.scss';
import { getDropdownGestionCir, getGestionCirSearchResult } from "../../api/analista_recuperos";
import { scrollToBottom } from "../../../utils/scrollDown";
import esLocale from "date-fns/locale/es";
import enLocale from "date-fns/locale/en-US";
const localeMap = {
  es: esLocale,
  en: enLocale
};
import ExcelDownload from '../../../utils/ExcelDownloadComponent'
import SearchIcon from "@material-ui/icons/CloudDownload";

const styles = theme => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing.unit * 3,
  },
  group: {
    margin: `${theme.spacing.unit}px 0`,
  },
});

class GestionCir extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companiaTerceroValue: "",
      conceptoValue: "",
      estadoValue: "",
      encargadoRecuperoValue: "",
      casaRemateValue: "",
      companiaTerceroLabel: "",
      conceptoLabel: "",
      estadoLabel: "",
      encargadoRecuperoLabel: "",
      casaRemateLabel: "",
      selectedRadionValue: "1",
      companiaTerceroList: [],
      conceptoList: [],
      estadoList: [],
      encargadoRecuperoList: [],
      casaRemateList: [],
      radioSelect: 1,
      tableData: undefined,
      startDate: null,
      endDate: null,
      showLoader: false,
      disableAllInput: false,
      enableSearch: true,
      excelsheetName: "Comprobantes_" + moment(new Date()).format("YYYYMMDD"),
      defaultSelect: {
        value: "",
        label: "-Seleccion-"
      }
    };
  }
  componentDidMount() {
    let sucursalObj = {
      value: "",
      label: "-Seleccion-"
    };
    this.showLoaderAction();
    getDropdownGestionCir().then((response) => {
      this.showLoaderAction();
      if (response && response.data && Object.keys(response.data).length) {
        response.data.leeCompaniaTercero && response.data.leeCompaniaTercero.unshift(sucursalObj);
        response.data.conceptos && response.data.conceptos.unshift(sucursalObj);
        response.data.indicador && response.data.indicador.unshift(sucursalObj);
        response.data.leeResponsablesCIR && response.data.leeResponsablesCIR.unshift(sucursalObj);
        response.data.leeCasasRemate && response.data.leeCasasRemate.unshift(sucursalObj);
        this.setState({
          companiaTerceroList: response.data.leeCompaniaTercero,
          conceptoList: response.data.conceptos,
          estadoList: response.data.indicador,
          encargadoRecuperoList: response.data.leeResponsablesCIR,
          casaRemateList: response.data.leeCasasRemate
        })
      } else {
        toast.error(response.msg, { position: toast.POSITION.TOP_RIGHT });
      }
    }).catch((error) => {
      console.log(error)
      this.showLoaderAction();
    })
  }
  showLoaderAction = () => {
    let showLoader = this.state.showLoader;
    this.setState({ showLoader: !showLoader });
  }
  resetSearch = () => {
    this.setState({
      companiaTerceroValue: this.state.defaultSelect,
      conceptoValue: this.state.defaultSelect,
      estadoValue: this.state.defaultSelect,
      encargadoRecuperoValue: this.state.defaultSelect,
      casaRemateValue: this.state.defaultSelect,
      startDate: null,
      endDate: null,
      radioSelect: 1,
      selectedRadionValue: "1",
      tableData: undefined,
      enableSearch: true,
      conceptoLabel: "",
      estadoLabel: "",
      encargadoRecuperoLabel: "",
      companiaTerceroLabel: "",
      casaRemateLabel: ""
    })
  }

  searchData = () => {
    this.showLoaderAction();
    let body = {
      flag: this.state.radioSelect,
      terreco: (this.state.companiaTerceroValue && this.state.companiaTerceroValue.value == "") ? "" : this.state.companiaTerceroValue,
      concepto: (this.state.conceptoValue && this.state.conceptoValue.value == "") ? "" : this.state.conceptoValue,
      estado: (this.state.estadoValue && this.state.estadoValue.value == "") ? "" : this.state.estadoValue,
      encardo: (this.state.encargadoRecuperoValue && this.state.encargadoRecuperoValue.value == "") ? "" : this.state.encargadoRecuperoValue,
      remate: (this.state.casaRemateValue && this.state.casaRemateValue.value == "") ? "" : this.state.casaRemateValue,
      fecha_start: (this.state.startDate && moment(this.state.startDate).format("YYYYMMDD")) || "",
      fecha_end: (this.state.endDate && moment(this.state.endDate).format("YYYYMMDD")) || "",
    }
    console.log("body", body)
    getGestionCirSearchResult(body).then((response) => {
      this.showLoaderAction();
      scrollToBottom();
      if (response && response.data && response.data.resultadosCir) {
        this.setState({
          tableData: response.data.resultadosCir
        })
      } else {
        this.setState({
          tableData: undefined
        })
        toast.error(response.msg, { position: toast.POSITION.TOP_RIGHT });
      }
    }).catch((ex) => {
      toast.error("Something went wrong", { position: toast.POSITION.TOP_RIGHT });
      this.showLoaderAction();
    })
  }

  handleChangeStartDate = (changeDate, event) => {
    this.setState({
      startDate: changeDate,
    });
    if (changeDate < this.state.endDate) {
      this.setState({
        enableSearch: (this.state.endDate && changeDate) ? false : true
      });
    }
  };

  handleChangeEndDate = (changeDate, event) => {
    this.setState({
      endDate: changeDate,
      enableSearch: (this.state.startDate && changeDate) ? false : true
    });
  };

  selectHandleChangeCompaniaTercero = actionMeta => {
    if (actionMeta) {
      this.setState({
        companiaTerceroValue: actionMeta.value,
        companiaTerceroLabel: actionMeta.label,
      });
    }
  };

  selectHandleChangeConcepto = actionMeta => {
    if (actionMeta) {
      this.setState({
        conceptoValue: actionMeta.value,
        conceptoLabel: actionMeta.label,
      });
    }
  };

  selectHandleChangeEstado = actionMeta => {
    if (actionMeta) {
      this.setState({
        estadoValue: actionMeta.value,
        estadoLabel: actionMeta.label,
      });
    }
  };

  handleRadioChange = event => {
    let value = event.target.value;
    if (value == 3 && value == 2) {
      this.setState({
        companiaTerceroValue: this.state.defaultSelect,
        conceptoValue: this.state.defaultSelect,
        estadoValue: this.state.defaultSelect,
        encargadoRecuperoValue: this.state.defaultSelect,
        casaRemateValue: this.state.defaultSelect,
        startDate: null,
        endDate: null,
      })
    }
    this.setState({
      selectedRadionValue: value,
      radioSelect: value,
      disableAllInput: (value == 1) ? false : true,
      enableSearch: (value == 1) ? true : false
    });
  };

  selectHandleChangeEncargadoRecupero = actionMeta => {
    if (actionMeta) {
      this.setState({
        encargadoRecuperoValue: actionMeta.value,
        encargadoRecuperoLabel: actionMeta.label,
      });
    }
  };
  focousOutStartDate = value => {
    if (!value) {
      this.setState({
        enableSearch: (this.state.selectedRadionValue == 1) ? true : false,
        startDate: null
      })
    }
  }
  focousOutEndDate = value => {
    if (!value) {
      this.setState({
        enableSearch: (this.state.selectedRadionValue == 1) ? true : false,
        endDate: null
      })
    }
  }
  selectHandleCasaRemate = actionMeta => {
    if (actionMeta) {
      this.setState({
        casaRemateValue: actionMeta.value,
        casaRemateLabel: actionMeta.label
      });
    }
  };

  donwloadExcel = () => {
    this.setState({
      excelDownlaod: true
    })
    setTimeout(()=>{
      this.setState({
        excelDownlaod: false
      })
    },1000)
  }

  render() {


    const { i18n } = this.props;
    let translateObj = (i18n.store.data[i18n.language] && i18n.store.data[i18n.language].translations) || i18n.store.data["es"].translations;
    const { classes, theme } = this.props;

    let newFormatData;
    if (!_.isEmpty(this.state.tableData) && !_.isEmpty(this.state.tableData)) {
      newFormatData = this.state.tableData && this.state.tableData.map((singleObj) => {
        return Object.values(singleObj);
      })
    }
    let finalData = [{
      columns: ["COMPROBANTE RECUPERO Y SALVATAJE"],
      data: [
        [],
        ["Fecha Desde", moment(this.state.startDate).format("DD/MM/YYYY")],
        ["Fecha Hasta", moment(this.state.endDate).format("DD/MM/YYYY")],
        ["Concepto", this.state.conceptoLabel],
        ["Estado", this.state.estadoLabel],
        ["Encargado Recupero", this.state.encargadoRecuperoLabel],
        ["Compania Tercero", this.state.companiaTerceroLabel],
        ["Casa Remate", this.state.casaRemateLabel]
      ]
    },
    {
      xSteps: 0, // Will start putting cell with 0 empty cell on left most
      ySteps: 1, //will put space of 2 rows,
      columns: [
        "Nro Siniestro",
        "Nro Ticket",
        "Concepto",
        "Clasificacion",
        "Nombre/Razon Social",
        "Monto CIR",
        "Fecha Creacion",
        "Factura/NC",
        "Estado",
        "Fecha Recaudacion"
      ],
      data: newFormatData
    }
    ];
    let sheetName = "sheet1";

    return (
      <main className="main-container">
        <Card className="Card-area box-show-none overflow-visible">
          <fieldset className="custom-border">
            <legend className="legend-box">Busqueda por Rango</legend>
            <div className="container">
              <div className="row porClientRowClass">
                <div className="col-sm-4">
                  <span className="label-class">Concepto</span>
                  <Select value={this.state.conceptoValue.value}
                    maxMenuHeight={180}
                    onChange={this.selectHandleChangeConcepto}
                    options={this.state.conceptoList}
                    isDisabled={this.state.disableAllInput}
                  />
                </div>
                <div className="col-sm-4">
                  <span className="label-class">Estado</span>
                  <Select value={this.state.estadoValue.value}
                    maxMenuHeight={180}
                    onChange={this.selectHandleChangeEstado}
                    options={this.state.estadoList}
                    isDisabled={this.state.disableAllInput}
                  />
                </div>
                <div className="col-sm-4">
                  <span className="label-class">Encargado Recupero</span>
                  <Select value={this.state.encargadoRecuperoValue.value}
                    maxMenuHeight={180}
                    onChange={this.selectHandleChangeEncargadoRecupero}
                    isDisabled={this.state.disableAllInput}
                    options={this.state.encargadoRecuperoList}
                  />
                </div>
              </div>

              <div className="row porClientRowClass">
                <div className="col-sm-6">
                  <span className="label-class">Compania Tercero</span>
                  <Select value={this.state.companiaTerceroValue.value}
                    maxMenuHeight={180}
                    onChange={this.selectHandleChangeCompaniaTercero}
                    options={this.state.companiaTerceroList}
                    isDisabled={this.state.disableAllInput}
                  />
                </div>
                <div className="col-sm-6">
                  <span className="label-class">Casa Remate</span>
                  <Select value={this.state.casaRemateValue.value}
                    maxMenuHeight={180}
                    onChange={this.selectHandleCasaRemate}
                    options={this.state.casaRemateList}
                    isDisabled={this.state.disableAllInput}
                  />
                </div>


              </div>
            </div>

            <div className="row porClientRowClass">
              <div className="col">
                {/* <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      

                    <RadioGroup
                      row={true}
                      value={this.state.selectedRadionValue}
                      onChange={this.handleRadioChange}
                    > */}

                <div className="porClientRowClass padding-left-15px">
                  <div className="float-align-col">
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap["es"]}>
                      <Grid
                        container
                        justify="space-around"
                      >
                        <DatePicker
                          InputLabelProps={{
                            shrink: true
                          }}
                          margin="normal"
                          label="Fecha Desde"
                          placeholder="dd/mm/yyyy"
                          value={this.state.startDate}
                          onChange={this.handleChangeStartDate}
                          invalidDateMessage={"Rango fecha erroneo"}
                          format="dd/MM/yyyy"
                          minDateMessage={"Rango fecha erroneo"}
                          disableOpenOnEnter
                          disabled={this.state.disableAllInput}
                          onBlur={event => this.focousOutStartDate(event.target.value)}
                          keyboard
                          style={{ width: 165 }}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </div>
                  <div className="float-align-col">
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap["es"]}>
                      <Grid
                        container
                        justify="space-around"
                      >
                        <DatePicker
                          InputLabelProps={{
                            shrink: true
                          }}
                          margin="normal"
                          label="Fecha Hasta"
                          placeholder="dd/mm/yyyy"
                          value={this.state.endDate}
                          onChange={this.handleChangeEndDate}
                          minDate={this.state.startDate}
                          minDateMessage={translateObj.policy_page.date_error_msg}
                          invalidDateMessage={"Rango fecha erroneo"}
                          onBlur={event => this.focousOutEndDate(event.target.value)}
                          format="dd/MM/yyyy"
                          disableOpenOnEnter
                          keyboard
                          style={{ width: 165 }}
                          disabled={this.state.disableAllInput}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
                {/*  */}
                {/* <FormControlLabel
                        value="2"
                        control={<Radio color="primary" />}
                        label="CIR con Factura Anticipada"
                      />
                      <FormControlLabel
                        value="3"
                        control={<Radio color="primary" />}
                        label="CIR con Factura Pendiente"
                      /> */}

                {/* </RadioGroup>
                  </FormControl> */}
              </div>
            </div>



            <div className="row porClientRowClass">

              <div className="col-sm-8"></div>
              <div className="col-sm-2">
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  className="search-policy"
                  disabled={false}
                  onClick={this.searchData}
                  disabled={this.state.enableSearch}
                >
                  {"Buscar"}
                </Button>
              </div>
              <div className="col-sm-2">
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  className="search-policy "
                  onClick={this.resetSearch}
                >
                  {"Limpiar"}
                </Button>
              </div>

            </div>
          </fieldset>
          {this.state.tableData && this.state.tableData.length ? <>
            <div className="">
              <Button
                variant="contained"
                size="medium"
                color="primary"
                className="search-policy download-button-style"
                onClick={this.donwloadExcel}
              >
                <SearchIcon className="download-icon" />
                {"Excel"}
              </Button>
            </div>
            <DyanmicTableWithSorting
              data={this.state.tableData}
              keyArray={gestionKeys}
              orderBy={gestionKeys[0]}
              defaultRowPerPage={20}
              isPaginationReq={true}
              isEnhancedTableHeadReq={true}
              virticalScroll={true}
              tableMaxHeight={"450"}
            />
          </> : null}
        </Card>
        {this.state.showLoader ? <Loader /> : null}
        {
          this.state.excelDownlaod ?
            <ExcelDownload excelData={finalData} sheetName={sheetName} excelName={this.state.excelsheetName} /> : null
        }
        <ToastContainer />
      </main >
    );
  }
}


GestionCir.propTypes = {
  classes: PropTypes.object.isRequired
};

export default translate("translations")(withStyles(styles)(GestionCir));


