import React from "react";
import Button from "@material-ui/core/Button";

const VolverButton = props => {
  return (
    <Button
      variant="contained"
      size="medium"
      color="primary"
      className="search-icon search-policy"
      style={{
        float: "right",
        marginBottom: "5px",
        marginRight: "10px"
      }}
      onClick={() => {
        window.history.back();
      }}
    >
      {"Volver"}
    </Button>
  );
};

export default VolverButton;
