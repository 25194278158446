import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import "./ItemsComponent.scss";
import TextField from "@material-ui/core/TextField";
import AntecedentesGenerales from "./SubComponent/antecedentesGenerales";
import AntecedentesAsegurado from "./SubComponent/antecedentesAsegurado";
import Primas from "./SubComponent/primas";
import ProductDetils from "./SubComponent/productDetils";
import DescriptionDelRiesgo from "./SubComponent/descriptionDelRiesgo";
import { translate, Trans } from "react-i18next";
import Button from "@material-ui/core/Button";
import { getItemDetails } from "./../../api/policy";
import Loader from "../../../BaseComponents/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import _ from "lodash";

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: "calc(100% - 240px)",
    backgroundColor: theme.palette.background.paper
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  }
});

class ItemsComponents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      claimObj: undefined,
      policyNo: "",
      enableSearch: true,
      itemNo: "",
      showLoader: false,
      showVolver: false
    };
    this.resetSearch = this.resetSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleItemChange = this.handleItemChange.bind(this);
    this.showItemDetail = this.showItemDetail.bind(this);
  }

  handleChange(event) {
    const value = event.target.value;
    let bln = value.length > 0 ? false : true;
    this.setState({ policyNo: value, enableSearch: bln });
  }

  handleItemChange(event) {
    const value = event.target.value;
    this.setState({ itemNo: value });
  }
  componentWillMount() {
    const {
      match: { params }
    } = this.props;
    let itemNo = params.itemNo;
    let policyNo = params.policyNo;
    let showVolver = policyNo ? true : false;
    this.getItemDetailsCall(policyNo, itemNo);
    this.setState({
      policyNo: policyNo,
      itemNo: itemNo,
      enableSearch: policyNo ? false : true,
      showVolver: showVolver
    });
  }
  showItemDetail() {
    if (this.state.policyNo && this.state.itemNo) {
      this.getItemDetailsCall(this.state.policyNo, this.state.itemNo);
      this.props.history.push(
        "/items-list/" + this.state.policyNo + "/" + this.state.itemNo
      );
    } else {
      this.props.history.push("/items-list/" + this.state.policyNo);
    }
  }
  getItemDetailsCall(policyNo, itemNo) {
    this.setState({
      claimObj: undefined,
      showLoader: true
    });
    getItemDetails(policyNo, itemNo)
      .then(response => {
        if (response && _.isEmpty(response.data)) {
          toast.error(
            response.msg || "No Items found for given Policy no and item no ",
            {
              position: toast.POSITION.TOP_RIGHT
            }
          );
        }
        this.setState({
          claimObj: response && response.data,
          showLoader: false
        });
      })
      .catch(err => {
        this.setState({
          claimObj: undefined,
          showLoader: false
        });
        console.log("errr", err);
      });
  }
  componentDidMount() {}
  resetSearch() {
    this.setState({
      policyNo: "",
      itemNo: "",
      claimObj: undefined
    });
  }

  backToPreviousPage = () => {
    window.history.back();
    window.history.back();
  };
  render() {
    const { i18n } = this.props;
    let translateObj =
      (i18n.store.data[i18n.language] &&
        i18n.store.data[i18n.language].translations) ||
      i18n.store.data["es"].translations;
    const { classes } = this.props;
    const { value } = this.state;
    const showLoader = this.state.showLoader;
    const itemData = {
      policyNo: this.state.policyNo,
      itemNo: this.state.itemNo
    };
    return (
      <div className="main-container">
        {showLoader ? <Loader /> : null}
        <div className="search-wrapper">
          <TextField
            id="standard-search"
            label={translateObj.policy_page.search_placeholder}
            type="search"
            className="text-field"
            margin="normal"
            value={this.state.policyNo}
            onChange={this.handleChange}
          />
          <TextField
            id="standard-item-search"
            label={translateObj.policy_page.search_item_placeholder}
            type="search"
            className="text-field"
            margin="normal"
            value={this.state.itemNo}
            onChange={this.handleItemChange}
          />
          <Button
            variant="contained"
            size="medium"
            color="primary"
            disabled={this.state.enableSearch}
            onClick={this.showItemDetail.bind()}
            className="search-policy"
          >
            {translateObj.policy_page.search_btn}
          </Button>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            className="search-policy"
            onClick={this.resetSearch.bind()}
          >
            {translateObj.policy_page.reset_btn}
          </Button>
          {this.state.showVolver ? (
            <Button
              variant="contained"
              size="medium"
              color="primary"
              className="search-policy"
              onClick={this.backToPreviousPage}
            >
              {"Volver"}
            </Button>
          ) : null}
        </div>
        {this.state.claimObj && !_.isEmpty(this.state.claimObj) ? (
          <>
            <AntecedentesGenerales
              data={this.state.claimObj}
              itemData={itemData}
            />
            <AntecedentesAsegurado
              data={
                this.state.claimObj && this.state.claimObj.antecedentesAsegurado
              }
            />
            <Primas data={this.state.claimObj && this.state.claimObj.primas} />
            <ProductDetils
              data={this.state.claimObj && this.state.claimObj.productDetils}
            />
            <DescriptionDelRiesgo
              data={
                this.state.claimObj && this.state.claimObj.descriptionDelRiesgo
              }
              usoDestino={this.state.claimObj && this.state.claimObj.descriptionDelRiesgo && this.state.claimObj.descriptionDelRiesgo.usoDestino}
              itemData={itemData}
            />
          </>
        ) : null}
        <ToastContainer />
      </div>
    );
  }
}

ItemsComponents.propTypes = {
  classes: PropTypes.object.isRequired
};
let translateObj = translate("translations")(ItemsComponents);

export default withStyles(styles)(translateObj);
