import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import "./DyanmicTable.scss";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import EnhancedTableHead from "./EnhancedTableHead";
import TablePagination from "@material-ui/core/TablePagination";
import _ from "lodash";
import Button from "@material-ui/core/Button";

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

function getPath(data) {
  let path = "";
  let key = "";
  let multiParamKeys = "";
  let reqObj = {};
  if (data && data.route) {
    path += data.route;
  }

  if (data && data.search) {
    const keys = _.keys(data.search);
    const searchtext = parseInt(data.search[keys[0]]);
    path = path + searchtext + "/";
  }

  if (data && data.key) {
    key = data.key;
  }
  if (data && data.keys) {
    multiParamKeys = data.keys;
  }
  if (data && data.reqObj) {
    reqObj = data.reqObj;
  }

  return { path, key, multiParamKeys, reqObj };
}

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  maxWidth: "5px",
  table: {
    minWidth: "100%"
  },
  tableWrapper: {
    overflowX: "auto"
  },
  overrides: {
    TableCell: {
      root: {
        backgroundColor: "lightblue"
      },
      paddingDefault: {
        padding: "1px 1px 1px 1px"
      }
    }
  }
});

class DyanmicTableWithSorting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: "asc",
      orderBy: this.props.orderBy || "nItem",
      page: 0,
      rowsPerPage: this.props.defaultRowPerPage || 5
    };
  }

  handleChangePage = (event, page) => {
    // this.setState({ page });
    this.props.fetchNextData(page+1);
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  getColumnData(d, row, index, path, key, multiParamKeys, reqObj, passClick) {
    let tableCellClassName =    this.props.coloumnLeftAlign === row    ? "centered-align text-align-left"  : "centered-align";
    const columnArray = ["nMandato","nombreContrato","poliza","accion" , "nroComprobante" ,"flag"];
    if (passClick) {
      if ((row == "pac"||row=="verDetalle") && !_.isEmpty(d[row])) {
        return (
          <TableCell align="left" key={index} className={tableCellClassName}>
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={() => this.props.passClick(d)}
            >
              {d[row]}
            </Button>
          </TableCell>
        );
      } else if ((columnArray.includes(row) || (row == "tipoDistribución" && d.parent == true)) && d[row]) {
        return (
          <TableCell
            align="left"
            key={index}
            className={tableCellClassName + " click-link-style"}
            onClick={() => this.props.passClick(d)}
          >
            {d[row]}
          </TableCell>
        );
      } else if (row == "card" && d[row].startsWith("069")) {
        return (
          <TableCell
            align="left"
            key={index}
            className={tableCellClassName + " click-link-style"}
            onClick={() => this.props.passClick()}
          >
            {d[row]}
          </TableCell>
        );
      } else {
        return (
          <TableCell align="left" key={index} className={tableCellClassName}>
            {d[row]}
          </TableCell>
        );
      }
    } else if (path && (key || multiParamKeys)) {
      if (row == key || _.includes(multiParamKeys, row)) {
        let customPath = "";
        if (this.props.linkCreateArray) {
          this.props.linkCreateArray.forEach(element => {
            if (d[element]) {
              customPath = customPath + "/" + d[element];
            }
          });
        } else {
          if (key) {
            customPath = d[key];
          } else {
            let keyLength = multiParamKeys.length;
            let i = 0;
            multiParamKeys.forEach(element => {
              if (d[element] && !(++i === keyLength)) {
                customPath = customPath + d[element] + "/";
              } else {
                customPath = customPath + d[element];
              }
            });
          }
        }
        return (
          <TableCell align="left" key={index} className={tableCellClassName}>
            {d.total ? (
              d[row]
            ) : (
              <Link
                to={{
                  pathname: `${path + customPath}`,
                  state: reqObj,
                  rowData: d
                }}
                className="tableCellClassName click-link-style"
              >
                {d[row]}
              </Link>
            )}
          </TableCell>
        );
      } /* else if (multiParamKeys) {
        let customPath = "";
        multiParamKeys.forEach(element => {
          // modifiedData.push(element);
          if (d[element]) {
            customPath = customPath + "/" + d[element];
          }
        });
        return (
          <TableCell align="left" key={index} className={tableCellClassName}>
            {d.total ? (
              d[row]
            ) : (
              <Link
                to={{
                  pathname: `${path + customPath}`,
                  state: reqObj
                }}
                className="tableCellClassName click-link-style"
              >
                {d[row]}
              </Link>
            )}
          </TableCell>
        );
      }*/ else {
        return (
          <TableCell align="left" key={index} className={tableCellClassName}>
            {d[row]}
          </TableCell>
        );
      }
    } else {
      return (
        <TableCell align="left" key={index} className={tableCellClassName}>
          {d[row]}
        </TableCell>
      );
    }
  }
  setRutNombre = obj => {
    if (this.props.setRutNombre) {
      this.props.setRutNombre(obj.rutCliente, obj.razonSocial);
    }
  };
  fetchNextData=(event, page)=>{
    console.log("page", event,page)
  }
  render() {
    const { order, orderBy } = this.state;
    const { classes } = this.props;
    const data = this.props.data;
    const id = this.props.id;
    const rows = this.props.keyArray;
    const pathData = this.props.pathData;
    const { path, key, multiParamKeys, reqObj } = getPath(pathData);
    const isPaginationReq = this.props.isPaginationReq;
    const isEnhancedTableHeadReq = this.props.isEnhancedTableHeadReq;
    const passClick = this.props.passClick;
    const tableMaxHeight = this.props.tableMaxHeight;

    return (
      <div>
        {data ? (
          <Paper className={classes.root}>
            <div
              className={classes.tableWrapper + " max-height-" + tableMaxHeight}
            >
              <Table className={classes.table}>
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={this.handleRequestSort}
                  rowCount={data.length}
                  keyArray={rows}
                  isEnhancedTableHeadReq={isEnhancedTableHeadReq}
                  verticalScroll={this.props.virticalScroll}
                  coloumnLeftAlign={this.props.coloumnLeftAlign}
                />
                <TableBody>
                  {isPaginationReq
                    ? stableSort(data, getSorting(order, orderBy))
                        .slice(
                          this.state.page * this.state.rowsPerPage,
                          this.state.page * this.state.rowsPerPage +
                            this.state.rowsPerPage
                        )
                        .map((d, index) => (
                          <TableRow
                            key={index}
                            onClick={
                              this.props.setRutNombre
                                ? this.setRutNombre.bind(this, d)
                                : ""
                            }
                            className={
                              this.props.setRutNombre ? "cursor-pointer" : ""
                            }
                          >
                            {Object.keys(rows).map((row, index) =>
                              this.getColumnData(
                                d,
                                row,
                                index,
                                path,
                                key,
                                multiParamKeys,
                                reqObj,
                                passClick
                              )
                            )}
                          </TableRow>
                        ))
                    : stableSort(data, getSorting(order, orderBy)).map(
                        (d, index) => {
                          return (
                            <TableRow
                              key={index}
                              className={
                                d.total ||
                                (d.comp && d.comp.indexOf("Total") > -1)
                                  ? "last-row"
                                  : ""
                              }
                            >
                              {Object.keys(rows).map((row, index) =>
                                this.getColumnData(
                                  d,
                                  row,
                                  index,
                                  path,
                                  key,
                                  multiParamKeys,
                                  reqObj,
                                  passClick
                                )
                              )}
                            </TableRow>
                          );
                        }
                      )}
                </TableBody>
              </Table>
            </div>
            {isPaginationReq ? (
              <TablePagination
                component="div"
                labelRowsPerPage=""
                rowsPerPageOptions={[]}
                count={this.props.totalCount}
                rowsPerPage={this.state.rowsPerPage}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`} 
                page={(this.props.pageNo===undefined?1:this.props.pageNo-1)}
                onChangePage={this.handleChangePage}
                backIconButtonProps={{
                  "aria-label": "Previous Page"
                }}
                nextIconButtonProps={{
                  "aria-label": "Next Page"
                }}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            ) : null}
          </Paper>
        ) : null}
      </div>
    );
  }
}

DyanmicTableWithSorting.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DyanmicTableWithSorting);
