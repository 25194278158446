import React from "react";
import TableComponet from "../../../BaseComponents/TableComponent/TableComponet";
import Card from "@material-ui/core/Card";

export default class ProductsPlanComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <main className="main-container">
        <Card>
          <div>
            <h3>Consulta Global (WIP)</h3>
          </div>
        </Card>
      </main>
    );
  }
}
