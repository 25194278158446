import React from "react";
import Button from "@material-ui/core/Button";
import "./Page403.scss";
import logo from "../../../assets/images/phoenixLogo.png";
import AppBar from "@material-ui/core/AppBar";
import PropTypes from "prop-types";
import classNames from "classnames";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { authContext } from "../../../utils/adalConfig";

const drawerWidth = 0;
const styles = theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  toolbar: {
    paddingRight: 24 // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 12
  },
  menuButtonHidden: {
    display: "none"
  },
  title: {
    flexGrow: 1
  }
});

class Page403 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  logOutAzureAD = () => {
    authContext.logOut();
    localStorage.clear();
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <main className="main-container">
          <AppBar position="absolute" className={classes.appBar}>
            <Toolbar>
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                className={classes.title}
              >
                <img className="logo" src={logo} alt="" />
              </Typography>
            </Toolbar>
          </AppBar>
          <div className="page403 custom-css-for-float">
            <h2 className="color-message">
              You are not authorized to see this.
            </h2>
            <div className="align-item-center">
              <span className="color-message">
                The Page you are trying to access has ristected access.
              </span>
            </div>
            <div className="align-item-center">
              <span className="color-message">
                If you feel this is a mistake, contact your admin.
              </span>
            </div>
            <div className="align-item-center align-margin">
              <Link to="/" className="remove-text-decpration">
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  className="search-policy"
                  onClick={this.logOutAzureAD}
                >
                  BACK TO LOGIN
                </Button>
              </Link>
            </div>
          </div>
        </main>
      </>
    );
  }
}

Page403.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(styles)(Page403);
