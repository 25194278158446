import { config, base_url, axiousInstance } from './index';
let axios=axiousInstance;

export function getNombreContratoDropdown() {
    return axios
        .post(base_url+ "contratosReaseguro/screen1", {}, config)
        .then(res => {
            return res && res.data;
        })
        .catch(error => {
            console.log("error " + error);
            return error;
        });
}

export function getClasesdeContratoDetails(contratoNumber) {
    let data = {
        "contratonumber": contratoNumber,//998
    };
    return axios
        .post(base_url+ "contratosReaseguro/screen2", data, config)
        .then(res => {
            return res.data;
        })
        .catch(error => {
            console.log("error " + error);
            return error;
        });
}

export function getLimitesDetails(contratoNumber) {
    let data = {
        "contratoNo": contratoNumber,//998
    };
    return axios
        .post(base_url+ "contratosReaseguro/screen3", data, config)
        .then(res => {

            return res.data;
        })
        .catch(error => {
            console.log("error " + error);
            return error;
        });
}

export function getParticipacionesDetails(contratoNumber) {
    let data = {
        "contratonumber": contratoNumber,//998
    };
    console.log(data);
    return axios
        .post(base_url+"contratosReaseguro/screen4", data, config)
        .then(res => {

            return res.data;
        })
        .catch(error => {
            console.log("error " + error);
            return error;
        });
}


export function getLimitesPorZonaDetails(contratoNumber) {
    let data = {
        "contratonumber": contratoNumber,//998
    };
    return axios
        .post(base_url + "contratosReaseguro/screen5", data, config)
        .then(res => {
            console.log(res);
            return res.data;
        })
        .catch(error => {
            console.log("error " + error);
            return error;
        });
}
export function getDescuentosDetails(contratoNumber) { 
    let data = {
        "contratonumber": contratoNumber,//998
    };
    return axios
        .post(base_url+"contratosReaseguro/screen6", data, config)
        .then(res => {
            console.log(res);
            return res.data;
        })
        .catch(error => {
            console.log("error " + error);
            return error;
        });
}

export function getConsultaDropDownDetails() {
    return axios
        .post(base_url + "consultaPorDistribution/screen1", {}, config)
        .then(res => {
            return res && res.data;
        })
        .catch(error => {
            console.log("error " + error);
            return error;
        });
}

export function getParticipacion(contratoNumber) {
    let data = {
        "contratoNumber": contratoNumber,//998
    };
    return axios
        .post(base_url+ "contratosReaseguro/screen4", data, config)
        .then(res => {
            return res.data;
        })
        .catch(error => {
            console.log("error " + error);
            return error;
        });
}

export function getLimitesPorZona(contratoNumber) {
    let data = {
        "cobertaNumber": contratoNumber,//998
    };
    return axios
        .post(base_url+ "contratosReaseguro/screen5", data, config)
        .then(res => {
            return res.data;
        })
        .catch(error => {
            console.log("error " + error);
            return error;
        });
}

export function getRamosVCoberturas(contratoNumber) {
    let data = {
        "contratonumber": contratoNumber,//998
    };
    return axios
        .post(base_url+ "contratosReaseguro/screen7", data, config)
        .then(res => {
            return res.data;
        })
        .catch(error => {
            console.log("error " + error);
            return error;
        });
}
export function getCondicionesData(contratoNumber) {
    let data = {
        "contratonumber": contratoNumber,//998
    };
    return axios
        .post(base_url+ "contratosReaseguro/screen8", data, config)
        .then(res => {
            console.log(res.data);
            return res.data;
        })
        .catch(error => {
            console.log("error " + error);
            return error;
        });
}

export function submitConsultaPorDetails(reqObj) {
    let data = reqObj;//poliza: 13022190

    console.log(data);
    return axios
        .post(base_url + "consultaPorDistribution/screen2", data, config)
        .then(res => {
            console.log(res.data);
            return res.data;
        })
        .catch(error => {
            console.log("error " + error);
            return error;
        });
}

export function getConsultaTableDetails(id) {
    console.log("id"+id)
    let data = {
        "id": id,//454343
    };
    return axios
        .post(base_url + "consultaPorDistribution/screen3", data, config)
        .then(res => {  console.log(res)
            return res.data;
        })
        .catch(error => {
            console.log("error " + error);
            return error;
        });
}
