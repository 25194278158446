import React from "react";
import DyanmicTableWithSorting from "../../../BaseComponents/DyanmicTableWithExcelDownload/DyanmicTableWithSorting";
import { translate, Trans } from "react-i18next";
import {
  getlineofbusinessdetail,
  getLeeNivelDosNew,
  getLeeNivelDosNewPaginatedData,
  getleeInfGenUnidad
} from "./../../api/policy";
import { getDosHydNewDetails } from "./../../api/consulta";
import Loader from "../../../BaseComponents/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import _ from "lodash";
import LeeNivelDosNewKeys from "./LeeNivelDosNewKeys.json";
import LeeInfGenUnidadKeys from "./LeeInfGenUnidadKeys.json";
import ProduccionHistoricaDetailKeys from "./ProduccionHistoricaDetailKeys.json";
import { ramosObject } from "../../../Constants/consultaConstant";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/CloudDownload";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import EmptyState from "../../../BaseComponents/EmptyState/EmptyState";
import ExcelDownloadCheckBox from "./PolicyDetailComponent/ExcelDownloadCheckBox";
import {
  getLeeNivelDosNewExcel,
  getleeInfGenUnidadExcel,
  getDosHydNewDetailsExcel
} from "./../../api/ExcelDownload";

var keys = {};
let selectRowData = [];
class LineOfBusinessDetail extends React.Component {
  constructor(props) {
    super(props);
    if (!props.location.state) {
      {
        let urlArr = _.split(window.location.pathname, "/");
        let navUrlStrig = "";
        if (urlArr && urlArr.length > 2) {
          navUrlStrig = "/" + urlArr[1] + "/" + urlArr[2];
        }
        this.props.history.push(navUrlStrig);
      }
    }
    this.state = {
      blnShowLineOfBusinessDetails: false,
      lobDtailsData: undefined,
      isPaginationReq:
        props.location.state.flag === "porReferenciaCorredor" ? true : false,
      defaultRowPerPage: 500,
      isEnhancedTableHeadReq: false,
      showLoader: false,
      ramo: "",
      reqObj: props.location.state,
      keys: undefined,
      altRamo: "",
      openDailog: false,
      excelReqObj: undefined,
      emptyState: false
    };
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props;
    const ramo = params.ramo;
    let reqObject = this.state.reqObj;
    if (ramo && ramo !== "ramo") {
      this.setState({
        ramo: ramo,
        reqObj: {
          ...this.state.reqObj,
          p_R_VLO_COD_RAMOS: ramosObject[ramo]
        }
      });

      const ramoValue = ramosObject[ramo];
      reqObject.p_R_VLO_COD_RAMOS = ramoValue;
    } else {
      this.setState({
        ramo: 0,
        reqObj: {
          ...this.state.reqObj,
          p_R_VLO_COD_RAMOS: 0
        }
      });
      reqObject.p_R_VLO_COD_RAMOS = 0;
    }

    if (
      reqObject.p_Criterio === 1007 ||
      (reqObject.unidadCase && !reqObject.historica)
    ) {
      this.setState({ keys: LeeInfGenUnidadKeys });
      reqObject.p_CRITERIO = reqObject.p_Criterio;
      reqObject.p_COD_SUS = 0;
      this.getleeInfGenUnidad(reqObject);
    } else if (reqObject.historica) {
      let p_Criterio =
        reqObject.prodHistorica === "cierreProduccionalDia" ? 1019 : 1017;
      reqObject.p_Criterio = p_Criterio;
      this.setState({ keys: ProduccionHistoricaDetailKeys });
      this.getDosHydNewDetails(reqObject);
    } else {
      reqObject.p_CRITERIO = reqObject.p_Criterio;
      this.setState({ keys: LeeNivelDosNewKeys, porRefCorReqObj: reqObject });
      this.getLeeNivelDosNew(reqObject);
    }
  }

  componentWillUnmount() {
    selectRowData = [];
  }

  getLineOfBusinessDetail(reqObj) {
    this.showLoader();

    getlineofbusinessdetail(reqObj)
      .then(res => {
        if (res.data && res.data.lobDetails) {
          let dataToDisplay = res.data.lobDetails;
          let modifiedData = dataToDisplay;
          modifiedData.push({});
          let total = res.data.lobTotal;
          total.forEach(element => {
            element.total = true;
            modifiedData.push(element);
          });
          this.setState({
            blnShowLineOfBusinessDetails: true,
            lobDtailsData: modifiedData
          });
          // toast.success(res.msg , { position: toast.POSITION.TOP_RIGHT})
        } else {
          toast.error(res.msg || "No Line of business details found", {
            position: toast.POSITION.TOP_RIGHT
          });
        }
        this.showLoader();
      })
      .catch(ex => {
        console.log(ex);
      });
  }

  getLeeNivelDosNew(reqObj) {
    this.showLoader();
    getLeeNivelDosNew(reqObj)
      .then(res => {
        if (res && res.length && reqObj.flag === "porReferenciaCorredor") {
          console.log("combine response", res);
          let dataToDisplay = res[0].LeeNivelDosNew;
          let modifiedData = dataToDisplay;
          let totalCount = {};
          totalCount = res[1].total;

          _.forEach(dataToDisplay, function(indexObj) {
            indexObj.excel = "Excel";
          });
          let ramoCode = res[0].LeeNivelDosNew[0].codRamo;
          let ramoString = "";
          _.forOwn(ramosObject, function(value, key) {
            if (value == ramoCode) {
              ramoString = key;
            }
          });
          ramoString = this.state.ramo ? this.state.ramo : ramoString;
          modifiedData.push({});
          let total = res[0].LeeNivelDosNewTotal;
          total.forEach(element => {
            element.total = true;
            modifiedData.push(element);
          });
          this.setState(
            Object.assign(
              this.state.reqObj,
              {
                p_R_VLO_COD_RAMOS: ramosObject[ramoString]
              },
              {
                blnShowLineOfBusinessDetails: true,
                lobDtailsData: modifiedData,
                ramo: ramoString,
                emptyState: false,
                totalCount: totalCount
              }
            )
          );
          // toast.success(res.msg , { position: toast.POSITION.TOP_RIGHT})
        } else if (res && res.success && !_.isEmpty(res.data)) {
          console.log("combine response", res);
          let dataToDisplay = res.data.LeeNivelDosNew;
          let modifiedData = dataToDisplay;

          _.forEach(dataToDisplay, function(indexObj) {
            indexObj.excel = "Excel";
          });
          let ramoCode = res.data.LeeNivelDosNew.codRamo;
          let ramoString = "";
          _.forOwn(ramosObject, function(value, key) {
            if (value == ramoCode) {
              ramoString = key;
            }
          });
          ramoString = this.state.ramo ? this.state.ramo : ramoString;
          modifiedData.push({});
          let total = res.data.LeeNivelDosNewTotal;
          total.forEach(element => {
            element.total = true;
            modifiedData.push(element);
          });
          this.setState(
            Object.assign(
              this.state.reqObj,
              {
                p_R_VLO_COD_RAMOS: ramosObject[ramoString]
              },
              {
                blnShowLineOfBusinessDetails: true,
                lobDtailsData: modifiedData,
                ramo: ramoString,
                emptyState: false
              }
            )
          );
        } else {
          this.setState({
            emptyState: true
          });
          toast.error(res.msg || "No Line of business details found", {
            position: toast.POSITION.TOP_RIGHT
          });
        }
        this.showLoader();
      })
      .catch(ex => {
        toast.error("Tiempo de espera agotado. Inténtalo de nuevo", {
          position: toast.POSITION.TOP_RIGHT
        });
        this.showLoader();
        console.log(ex);
      });
  }

  getleeInfGenUnidad(reqObj) {
    this.showLoader();

    getleeInfGenUnidad(reqObj)
      .then(res => {
        if (res.data && res.data.LeeInfGenUnidad) {
          let dataToDisplay = res.data.LeeInfGenUnidad;
          let modifiedData = dataToDisplay;
          _.forEach(dataToDisplay, function(indexObj) {
            indexObj.excel = "Excel";
          });
          modifiedData.push({});
          let total = res.data.LeeInfGenUnidadTotal;
          total.forEach(element => {
            element.total = true;
            modifiedData.push(element);
          });
          this.setState({
            blnShowLineOfBusinessDetails: true,
            lobDtailsData: modifiedData,
            emptyState: false
          });
          // toast.success(res.msg , { position: toast.POSITION.TOP_RIGHT})
        } else {
          this.setState({
            emptyState: true
          });
          toast.error(res.msg || "No Line of business details found", {
            position: toast.POSITION.TOP_RIGHT
          });
        }
        this.showLoader();
      })
      .catch(ex => {
        console.log(ex);
      });
  }

  getDosHydNewDetails(reqObj) {
    this.showLoader();

    getDosHydNewDetails(reqObj)
      .then(res => {
        if (res.data && res.data.dosHydNewDetails) {
          let dataToDisplay = res.data.dosHydNewDetails;
          let modifiedData = dataToDisplay;
          _.forEach(dataToDisplay, function(indexObj) {
            indexObj.excel = "Excel";
          });
          modifiedData.push({});
          let total = res.data.dosHydTotal;
          total.forEach(element => {
            element.total = true;
            modifiedData.push(element);
          });
          this.setState({
            blnShowLineOfBusinessDetails: true,
            lobDtailsData: modifiedData,
            emptyState: false
          });
          // toast.success(res.msg , { position: toast.POSITION.TOP_RIGHT})
        } else {
          this.setState({
            emptyState: true
          });
          toast.error(res.msg || "No Line of business details found", {
            position: toast.POSITION.TOP_RIGHT
          });
        }
        this.showLoader();
      })
      .catch(ex => {
        this.setState({
          emptyState: true
        });
        console.log(ex);
      });
  }

  showLoader() {
    let showLoader = this.state.showLoader;
    this.setState({ showLoader: !showLoader });
  }

  downloadEcel = () => {
    this.setState({
      openDailog: true
    });
  };

  clickRow = (data, event) => {
    console.log("data->", data);
    console.log("Evente .target.value", event.target.checked);
    if (event.target.checked) {
      let checkInArr = _.includes(selectRowData, data);
      if (!checkInArr) {
        selectRowData.push(data);
      }
    } else {
      let checkInArr = _.includes(selectRowData, data);
      if (checkInArr) {
        _.remove(selectRowData, data);
      }
    }
    let adExcelButtonFlag = selectRowData.length > 0 ? false : true;
    this.setState({
      adExcelButton: adExcelButtonFlag
    });

    console.log("selectRowData--->", selectRowData);
  };

  handleClose = () => {
    this.setState({ openDailog: false });
  };

  backToPreviousPage = () => {
    console.log("<-----Back to previous page---->");
    window.history.back();
  };
  getLeeNivelDosNewPaginatedData(reqObj) {
    this.showLoader();
    getLeeNivelDosNewPaginatedData(reqObj)
      .then(res => {
        if (res.data && res.data.LeeNivelDosNew) {
          let dataToDisplay = res.data.LeeNivelDosNew;
          let modifiedData = dataToDisplay;
          _.forEach(dataToDisplay, function(indexObj) {
            indexObj.excel = "Excel";
          });
          let ramoCode = res.data.LeeNivelDosNew[0].codRamo;
          let ramoString = "";
          _.forOwn(ramosObject, function(value, key) {
            if (value == ramoCode) {
              ramoString = key;
            }
          });
          ramoString = this.state.ramo ? this.state.ramo : ramoString;
          modifiedData.push({});
          let total = res.data.LeeNivelDosNewTotal;
          total.forEach(element => {
            element.total = true;
            modifiedData.push(element);
          });
          this.setState(
            Object.assign(
              this.state.reqObj,
              {
                p_R_VLO_COD_RAMOS: ramosObject[ramoString]
              },
              {
                blnShowLineOfBusinessDetails: true,
                lobDtailsData: modifiedData,
                ramo: ramoString,
                emptyState: false,
                pageNo: reqObj.pageNo
              }
            )
          );
          // toast.success(res.msg , { position: toast.POSITION.TOP_RIGHT})
        } else {
          this.setState({
            emptyState: true
          });
          toast.error(res.msg || "No Line of business details found", {
            position: toast.POSITION.TOP_RIGHT
          });
        }
        this.showLoader();
      })
      .catch(ex => {
        console.log(ex);
      });
  }
  fetchNextData = page => {
    let reqObj = this.state.porRefCorReqObj;
    reqObj.pageNo = page;
    this.setState({ lobDtailsData: undefined });
    this.getLeeNivelDosNewPaginatedData(reqObj);
  };

  render() {
    let slugUrl = "";
    if (this.state.reqObj && this.state.reqObj.slug) {
      slugUrl = "/" + this.state.reqObj.slug + "/" + "Line-of-business/poliza/";
    } else {
      slugUrl = "/consulta-global/por-cliente/line-of-business/poliza/";
    }
    console.log("totalCount", this.state.totalCount);
    const data = {
      route: slugUrl,
      reqObj: this.state.reqObj
    };

    if (
      (this.state.reqObj && this.state.reqObj.unidadCase) ||
      this.state.reqObj.historica ||
      this.state.reqObj.flag === "porSiniestro" ||
      this.state.reqObj.flag === "porReferenciaCorredor"
    ) {
      data.keys = ["poliza", "item"];
    } else {
      data.key = "poliza";
    }

    return (
      <main className="main-container">
        {this.state.showLoader ? <Loader /> : null}

        {this.state.blnShowLineOfBusinessDetails && this.state.lobDtailsData ? (
          <>
            <h3>Ramo: {this.state.ramo}</h3>
            <DyanmicTableWithSorting
              data={this.state.lobDtailsData}
              keyArray={this.state.keys}
              orderBy={this.state.keys[0]}
              pathData={data}
              passClick={this.clickRow}
              isPaginationReq={this.state.isPaginationReq}
              isEnhancedTableHeadReq={this.state.isEnhancedTableHeadReq}
              defaultRowPerPage={this.state.defaultRowPerPage}
              virticalScroll={true}
              tableMaxHeight={"400"}
              totalCount={this.state.totalCount}
              fetchNextData={this.fetchNextData}
              pageNo={this.state.pageNo}
            />
            <Button
              variant="contained"
              size="medium"
              color="primary"
              disabled={this.state.adExcelButton}
              className="search-icon search-policy"
              style={{ float: "right", marginBottom: "5px" }}
              onClick={this.downloadEcel}
            >
              <SearchIcon className="download-icon" />
              {"Excel"}
            </Button>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              className="search-icon search-policy"
              style={{
                float: "right",
                marginBottom: "5px",
                marginRight: "10px"
              }}
              onClick={this.backToPreviousPage}
            >
              {"Volver"}
            </Button>
          </>
        ) : null}

        {this.state.emptyState ? (
          <div className={"content"}>
            <EmptyState
              emoticon={"🔍"}
              message={"Informacion general no encontrada"}
              showButton={true}
            />
          </div>
        ) : null}
        <ToastContainer />
        {this.state.openDailog ? (
          <>
            {/* {showLoader ? <Loader /> : null} */}
            <Dialog
              open={this.state.openDailog}
              onClose={this.handleClose}
              aria-labelledby="responsive-dialog-title"
            >
              <div style={{ width: 600 }}>
                <DialogTitle
                  id="responsive-dialog-title"
                  className="dailog-title-style"
                >
                  Informacion a enviar a excel
                </DialogTitle>
                <ExcelDownloadCheckBox
                  excelKeys={
                    this.props.location.state.historica
                      ? ProduccionHistoricaDetailKeys
                      : this.state.keys
                  }
                  selectRowData={selectRowData}
                  reqObj={this.state.reqObj}
                  downloadEcelMethod={this.downloadEcel}
                  dialogueHandler={this.handleClose}
                  siniestroFlag={this.state.siniestrosCheckBoxReq}
                  ramosScreen={true}
                />
              </div>
            </Dialog>
          </>
        ) : null}
      </main>
    );
  }
}

export default translate("translations")(LineOfBusinessDetail);
