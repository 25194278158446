import React from 'react'
import AntecedentesGenerales from './AntecedentesGeneralesComponent/AntecedentesGenerales'
import AntecedentesProponente from "./AntecedentesProponenteComponent/AntecedentesProponente";
import AntecedentesCorredor from "./AntecedentesCorredorComponent/AntecedentesCorredor";
import SituactionCobranzaPrimas from "./SituactionCobranzaPrimasComponent/SituactionCobranzaPrimas";
import CSSGrid from "./SituactionCobranzaPrimasComponent/CSSGrid";
// import getPolicyDetails  from '../../api/policy';
import  './PolicyDetails.scss'

export default class Policy_details extends React.Component  {
  constructor(props) {
    super(props);
    this.state =  { 
      data : props.data
    }
  }

  componentWillReceiveProps(){
    this.setState({ data : this.props.data });      
  }

  render() {
    return (
      <div className="Policy-detail-container">
        <div className="Space-around"> <AntecedentesGenerales data = {this.state.data}/></div>
        <div className="Space-around"> <AntecedentesProponente data = {this.state.data && this.state.data.antecedentesProponente} /></div>
        <div className="Space-around"> <AntecedentesCorredor data = {this.state.data && this.state.data.antecedentesCorredor} /> </div>
        <div className="Space-around"> <CSSGrid data = {this.state.data && this.state.data.situactionCobranzaYPrimas} /></div>  
      </div>
    )
  }
}

