export const sinistralidadDetailsKeys = {
    "id":"ID",
    "Item":"Ítem",
    "PrimaNeta":"Prima Neta",
    "PrimaGanada":"Prima Ganada", 
    "MtoPagado":"Monto Pagado", 
    "MtoRecupero":"Monto Recupero",
    "MtoProvision":"Monto Provisión",
    "Siniestralidad":"Siniestralidad"
  };
  export const sinistroKeys= {
    "id":"ID",
    "TipoDano":"Tipo de Daño",
    "Siniestro":"Siniestro",
    "FechaSiniestro":"Fecha Siniestro", 
    "Item":"Ítem", 
    "Estado":"Estado",
    "MtoPerPro":"Monto Pago Probable",
    "MtoPagado":"Monto Pagado",
    "MtoRecupero":"Monto Recupero",
    "FechaLiquidacion":"Fecha Liquidación"
  };