import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { translate, Trans } from "react-i18next";
import Loader from "../../../BaseComponents/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import './usecase25.scss'
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import DateFnsUtils from "@date-io/date-fns";
import Select from 'react-select';
import {
  MuiPickersUtilsProvider,
  DatePicker
} from "material-ui-pickers";
import { getSiniestrosDetailsForAreatrabajo, getAreatrabajoDropdown } from "../../api/analista_recuperos";
import { siniestrotableForAreatrabajoKeys } from "../../../Constants/analista_recuperoskeys";
import { NoOptionsMessage, inputComponent, Control, Option, Placeholder, SingleValue, ValueContainer, MultiValue, Menu } from '../../../Constants/dailog'
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import DyanmicTableWithSorting from "../../../BaseComponents/DyanmicTableWithSorting/DyanmicTableWithSorting";
import { scrollToBottom } from "../../../utils/scrollDown";
import esLocale from "date-fns/locale/es";
import enLocale from "date-fns/locale/en-US";
import ExcelDownload from '../../../utils/ExcelDownloadComponent'
import SearchIcon from "@material-ui/icons/CloudDownload";

const localeMap = {
  es: esLocale,
  en: enLocale
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 150,
  },
  input: {
    display: 'flex',
    padding: 0,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
  grid: {
    width: "58%"
  }
});

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

class AreaTrabajo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      siniestroNo: "",
      patente: "",
      estadoLiquidacion: "",
      startDate: null,
      endDate: null,
      enableSearch: true,
      tableData: undefined,
      showLoader: false,
      origenArchivoDataList: [],
      defaultRowPerPage: 20,
      excelsheetName: "AreaAbogadosExternos"
    };
  }

  componentDidMount() {
    this.showLoaderAction();
    getAreatrabajoDropdown().then((response) => {
      this.showLoaderAction()
      if (response && response.data && response.data.dropdownDetails) {
        this.setState({
          origenArchivoDataList: response.data.dropdownDetails
        })
      } else {
        toast.error(response.msg, { position: toast.POSITION.TOP_RIGHT });
      }
    }).catch((err) => {
      this.showLoaderAction();
      toast.error("Something went wrong", { position: toast.POSITION.TOP_RIGHT });
    })
  }
  handleChange = (event) => {
    const value = event.target.value;
    let bln = value.length > 0 ? true : false;
    this.setState({ siniestroNo: value });
    this.enableSearch(value, this.state.patente, this.state.startDate, this.state.endDate)

  }
  handleChangePatente = (event) => {
    const value = event.target.value;
    let bln = value.length > 0 ? true : false;
    this.setState({ patente: value });
    this.enableSearch(this.state.siniestroNo, value, this.state.startDate, this.state.endDate)

  }
  resetSearch = () => {
    this.setState({
      siniestroNo: "",
      patente: "",
      estadoLiquidacion: "",
      startDate: null,
      endDate: null,
      tableData: undefined,
      enableSearch: true
    })

  }

  showLoaderAction() {
    let showLoader = this.state.showLoader;
    this.setState({
      showLoader: !showLoader
    });
  }

  getSiniestroDetails = () => {
    let tableData = undefined;
    this.showLoaderAction();
    getSiniestrosDetailsForAreatrabajo(this.state.siniestroNo, this.state.startDate, this.state.endDate, this.state.patente, this.state.estadoLiquidacion)
      .then((response) => {
        this.showLoaderAction();
        scrollToBottom();
        if (response.data && response.data.list1 && response.data.list1.length) {
          tableData = response.data.list1;
        }
        else {
          tableData = undefined;
        }
        this.setState({ tableData: tableData });
        !tableData && toast.error(response.msg || "No sinestros data found", {
          position: toast.POSITION.TOP_RIGHT
        });
      }).catch((ex) => {
        this.showLoaderAction();
        toast.error("Internal Server Error", {
          position: toast.POSITION.TOP_RIGHT
        });
      })
  }
  estadoLiquidChange = (label) => {
    this.setState({
      estadoLiquidacion: label,
    });
  }
  handleChangeEndDate = (changeDate, event) => {
    this.setState({
      endDate: changeDate
    });
    let bln = !_.isNull(changeDate) ? true : false;
    this.enableSearch(this.state.siniestroNo, this.state.patente, this.state.startDate, changeDate)

  };
  handleChangeStartDate = (changeDate, event) => {
    this.setState({
      startDate: changeDate
    });
    let bln = !_.isNull(changeDate) ? true : false;
    this.enableSearch(this.state.siniestroNo, this.state.patente, changeDate, this.state.endDate)
  };

  enableSearch = (sinistro, patente, startDate, endDate) => {
    if (sinistro || patente || (startDate && endDate)) {
      this.setState({ enableSearch: false });
    } else {
      this.setState({ enableSearch: true });
    }
    console.log(startDate+" "+endDate)
    if ((startDate && endDate && (startDate > endDate))) {
      this.setState({ enableSearch: true });
    }
  }

  focousOutEndDate = value => {
    if (!value) {
      this.setState({
        endDate: null
      })
      this.enableSearch(this.state.siniestroNo, this.state.patente, this.state.startDate, value)

    }
  }

  focousOutStartDate = value => {
    if (!value) {
      this.setState({
        startDate: null
      })
      this.enableSearch(this.state.siniestroNo, this.state.patente, value, this.state.endDate)
    }

  }
  donwloadExcel = () => {
    this.setState({
      excelDownlaod: true
    })
  }

  render() {
    const { classes, theme } = this.props;
    const { i18n } = this.props;
    let translateObj =
      (i18n.store.data[i18n.language] &&
        i18n.store.data[i18n.language].translations) ||
      i18n.store.data["es"].translations;
    let newFormatData;
    if (!_.isEmpty(this.state.tableData) && !_.isEmpty(this.state.tableData)) {
      newFormatData = this.state.tableData && this.state.tableData.map((singleObj) => {
        return Object.values(singleObj);
      })
    }

    let finalData = [{
      columns: ["Area Trabajo Abogados Externos"],
      data: []
    },
    {
      xSteps: 0, // Will start putting cell with 0 empty cell on left most
      ySteps: 1, //will put space of 2 rows,
      columns: [
        "Siniestro",
        "Fecha siniestro",
        "Tipo sinestro",
        "Estado liquidacion",
        "Costo",
        "Provision defensa",
        "Juzgado",
        "Gestion",
        "Responsable"
      ],
      data: newFormatData
    }
    ];
    let sheetName = "sheet1";

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
          width: '150px',
        },
      }),
    };
    return (
      <main className="main-container">
        {this.state.showLoader ? <Loader /> : null}
        <div className="search-wrapper">
          <div className="col-data-100">
            <label className="margin-top-right-form">Nro. siniestro:</label>
            <TextField
              id="custom-css-standard-input"
              type="search"
              className="text-field margin-0"
              placeholder="Nro Siniestro"
              margin="normal"
              value={this.state.siniestroNo}
              onChange={this.handleChange}
              style={{ width: 350 }}

            />
          </div>
          <div className="col-data-100" >
            <label className="margin-top-right-form">Patente (ej.RS0000):</label>
            <TextField
              id="custom-css-standard-input"
              type="search"
              className="text-field margin-0"
              margin="normal"
              placeholder="Patente (ej.RS0000)"
              value={this.state.patente}
              onChange={this.handleChangePatente}
              style={{ width: 350 }}
            />
          </div>
          <div className="col-data-100" >
            <label className="margin-top-right-form ">Estado de Liquidacion:</label>
            <Select
              classes={classes}
              className="dsp-inline-block drop-down-menu"
              styles={selectStyles}
              options={this.state.origenArchivoDataList}
              width="220"
              components={components}
              value={this.state.estadoLiquidacion}
              onChange={this.estadoLiquidChange}
              placeholder="--Seleccione--"
              isClearable
            />
          </div>
          <div className="col-data-100" >
            <label className="margin-top-right-form date-align-text">Fecha siniestro de:</label>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap["es"]}>
              <DatePicker
                className="margin-right-20px"
                margin="normal"
                placeholder="dd/mm/yyyy"
                label={"Fecha desde"}
                value={this.state.startDate}
                onChange={this.handleChangeStartDate}
                disableOpenOnEnter
                keyboard
                format="dd/MM/yyyy"
                invalidDateMessage={"Rango fecha erroneo"}
                minDateMessage={"Rango fecha erroneo"}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: 165 }}
                onBlur={event => this.focousOutStartDate(event.target.value)}

              />
              <DatePicker
                margin="normal"
                placeholder="dd/mm/yyyy"
                label={"Fecha hasta"}
                value={this.state.endDate}
                onChange={this.handleChangeEndDate}
                disableOpenOnEnter
                keyboard
                format="dd/MM/yyyy"
                minDate={this.state.startDate}
                minDateMessage={translateObj.policy_page.date_error_msg}
                invalidDateMessage={"Rango fecha erroneo"}
                style={{ width: 165 }}
                InputLabelProps={{
                  shrink: true,
                }}
                onBlur={event => this.focousOutEndDate(event.target.value)}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div className="btn-group">
            <Button
              variant="contained"
              size="medium"
              color="primary"
              className="search-policy margin-left-100px"
              disabled={this.state.enableSearch}
              onClick={this.getSiniestroDetails}
            >
              {translateObj.policy_page.search_btn}
            </Button>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              className="search-policy"
              onClick={this.resetSearch}
            >
              {translateObj.policy_page.reset_btn}
            </Button>
          </div>
        </div>
        {this.state.tableData && this.state.tableData.length ? <>
          <div className="">
            <Button
              variant="contained"
              size="medium"
              color="primary"
              className="search-policy download-button-style"
              onClick={this.donwloadExcel}
            >
              <SearchIcon className="download-icon" />
              {"Excel"}
            </Button>
          </div>
          <DyanmicTableWithSorting
            data={this.state.tableData}
            keyArray={siniestrotableForAreatrabajoKeys}
            orderBy={siniestrotableForAreatrabajoKeys[0]}
            defaultRowPerPage={this.state.defaultRowPerPage}
            isPaginationReq={true}
            isEnhancedTableHeadReq={true}
            virticalScroll={true}
            tableMaxHeight={"450"}
          />
        </> : null}
        {
          this.state.excelDownlaod ?
            <ExcelDownload excelData={finalData} sheetName={sheetName} excelName={this.state.excelsheetName} /> : null
        }
        <ToastContainer />
      </main>
    );
  }
}


AreaTrabajo.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default translate("translations")(withStyles(styles, { withTheme: true })(AreaTrabajo));


