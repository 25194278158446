import React from 'react';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";
import { getMinuta } from "../../../api/policy";
import  {  dpi, a4, getFormattedDate } from '../../../../Constants/item';
import PDFGenerator from './PDFGenerator'
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../../BaseComponents/Loader/Loader";
import minuta from "../../../../assets/minuta.pdf"



  

export default class AntecedentesGenerales extends React.Component  {
  constructor(props) {
    super(props);
    this.state =  {
        data:undefined,
        pdf : undefined,
        showLoader : false
    }

    this.downLoadPDF = this.downLoadPDF.bind(this)
  }
  componentDidMount() {
    this.setState({ data : this.props.data ,  itemData :  this.props.itemData}); 
  }

  componentWillReceiveProps() {
    this.setState({ data : this.props.data , itemData :  this.props.itemData}); 
  }

  showLoaderAction() {
    let showLoader = this.state.showLoader;
    this.setState({
      showLoader: !showLoader
    });
  }

addPage(canvas, pdf, pageCount, totalPages) {
    const image = canvas.toDataURL('image/jpeg', 1);
    pdf.addImage(image, 'JPEG', 0, 0);
    if (pageCount < totalPages - 1) {
        pdf.addPage();
    }
  }

  downLoadPDF(){
    this.showLoaderAction();
    const { poliza , item, endoso }  = this.props.data;

    getMinuta(poliza,item,endoso)
    .then((res)=>{
        if(res && res.data && !_.isEmpty(res.data)) {
          window.open(
            minuta,
            "_blank"
          )  
        }else {
            toast.error(res.msg , { position: toast.POSITION.TOP_RIGHT });
        }
        this.showLoaderAction();        
    }).catch((ex)=>{
        toast.error("Something went wrong.." , { position: toast.POSITION.TOP_RIGHT });
    })
  }

  render() {
    let dataObj=this.state.data;
    return (
      <div className="">       
      {
          dataObj ? 
          <div className="search-wrapper">
            <Button variant="contained" size="medium" color="primary" className="search-policy button-position-style" onClick={this.downLoadPDF.bind()}>
                {"Minuta"}
            </Button>
            <Link  to={{ pathname: `/items-list/coverage/${this.state.itemData.policyNo}/${this.state.itemData.itemNo}` }} style={{ textDecoration: "none" }}>
            <Button variant="contained" size="medium" color="primary" className="search-policy button-position-style">
                {"Ver Cobertura"}
            </Button>
         </Link>
         </div> : null         
      }
        {dataObj?
            <Card className="Card-area box-show-none">
            <fieldset className="custom-border">
              <legend className="legend-box">Antecedentes Generales</legend>
                <div>
                    <span className="Space-around">
                    <TextField InputLabelProps={{ shrink: true }}
                        id="standard-bare"
                        label="Poliza"
                        className="textField"
                        value={dataObj.poliza }
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                        readOnly: true,
                        }}
                        style = {{width: 200}}
                    />
                    </span>
                    <span className="Space-around">
                
                    <TextField InputLabelProps={{ shrink: true }}
                        id="standard-bare"
                        label="Item"
                        className="textField"
                        value={dataObj.item }
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                        readOnly: true,
                        }}
                        style = {{width: 180}}
                    />
                    </span>
                    <span className="Space-around">
                
                    <TextField InputLabelProps={{ shrink: true }}
                        id="standard-bare"
                        label="Endoso"
                        className="textField"
                        value={dataObj.endoso }
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                        readOnly: true,
                        }}
                        style = {{width: 250}}
                    />
                    </span>
                    <span className="Space-around">
                    <TextField InputLabelProps={{ shrink: true }}
                        id="standard-bare"
                        label="Item Anterior"
                        className="textField"
                        value={dataObj.itemAnte }
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                        readOnly: true,
                        }}
                        style = {{width: 250}}
                    />
                    </span>
                </div>
                <div>
                    <span className="Space-around">
                    <TextField InputLabelProps={{  shrink: true }}
                        id="standard-bare"
                        label="Fecha Ingreso"
                        className="textField"
                        value={dataObj.fechaIng }
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                        readOnly: true,
                        }}
                        style = {{width: 300}}
                    />
                    </span>
                    <span className="Space-around">
                
                    <TextField InputLabelProps={{ shrink: true }}
                        id="standard-bare"
                        label="Vigencia Desde"
                        className="textField"
                        value={dataObj.vigDesde }
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                        readOnly: true,
                        }}
                        style = {{width: 300}}
                    />
                    </span>
                    <span className="Space-around">
                
                    <TextField InputLabelProps={{ shrink: true }}
                        id="standard-bare"
                        label="Vigencia Hasta"
                        className="textField"
                        value={dataObj.vigHasta }
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                        readOnly: true,
                        }}
                        style = {{width: 300}}
                    />
                    </span>
                </div>
                <div>
                    <span className="Space-around">
                    <TextField InputLabelProps={{ shrink: true }}
                        id="standard-bare"
                        label="Rel. Aseg"
                        className="textField"
                        value={dataObj.relAseg }
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                        readOnly: true,
                        }}
                        style = {{width: 300}}
                    />
                    </span>
                    <span className="Space-around">
                
                    <TextField InputLabelProps={{ shrink: true }}
                        id="standard-bare"
                        label="Fact. Calc"
                        className="textField"
                        value={dataObj.factCalc }
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                        readOnly: true,
                        }}
                        style = {{width: 300}}
                    />
                    </span>
                    <span className="Space-around">
                
                    <TextField InputLabelProps={{ shrink: true }}
                        id="standard-bare"
                        label="N&#176; Dias"
                        className="textField"
                        value={dataObj.nDias }
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                        readOnly: true,
                        }}
                        style = {{width: 300}}
                    />
                    </span>
                </div>
                <div>
                    <span className="Space-around">
                    <TextField InputLabelProps={{ shrink: true }}
                        id="standard-bare"
                        label="Propuesta"
                        className="textField"
                        value={dataObj.propuesta }
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                        readOnly: true,
                        }}
                        style = {{width: 300}}
                    />
                    </span>
                    <span className="Space-around">
                
                    <TextField InputLabelProps={{ shrink: true }} 
                        id="standard-bare"
                        label="Origen"
                        className="textField"
                        value={dataObj.origen }
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                        readOnly: true,
                        }}
                        style = {{width: 300}}
                    />
                    </span>
                    <span className="Space-around">
                
                    <TextField InputLabelProps={{ shrink: true }}
                        id="standard-bare"
                        label="Referencia Corrida"
                        className="textField"
                        value={dataObj.refCor }
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                        readOnly: true,
                        }}
                        style = {{width: 300}}
                    />
                    </span>
                </div>
                <div>
                    <span className="Space-around">
                        <TextField InputLabelProps={{ shrink: true }}
                            id="standard-bare"
                            label="N &#176; Inspección"
                            className="textField"
                            value={dataObj.nInspeccion }
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 620}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{ shrink: true }}
                            id="standard-bare"
                            label="IRI"
                            className="textField"
                            value={dataObj.iri }
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                            readOnly: true,
                            }}
                            style = {{width: 300}}
                        />
                    </span>
                </div>
                </fieldset>
            </Card>
        :null}
        {this.state.showLoader ? <Loader /> : null}

        { this.state.pdf ?  
            <PDFGenerator dpi={dpi} pageSize={a4} data={this.state.pdf}> 
            </PDFGenerator>
            : null
        }
        <ToastContainer />

      </div>
      
    )
  }
}