import XLSX from "xlsx";
import _ from "lodash";
import { config, base_url, axiousInstance } from "./index";
// let axios = axiousInstance;
import axios from "axios";
import { ramosObject } from "../../Constants/consultaConstant";
import LineOfBussinesKeys from "../../Constants/ConsultaGlobal/LineOfBussines.json";
import siniestroKeys from "../../Constants/ConsultaGlobal/siniestro.json";
import DetPagRegKeys from "../../Constants/ConsultaGlobal/DetPagReg.json";
import HistoricaKeys from "../../Constants/ConsultaGlobal/Historica.json";
import RamoExcelKeys from "../../Constants/ConsultaGlobal/RamoExcelKeys.json";
import MateriaAsegKeys from "../../Constants/ConsultaGlobal/MateriaAsegKeys.json";

function getHeader(historica, listOfCond, ramoValue) {
  /**Calculation of Excel Keys  */
  let LineOfBussinesModifiedKeys = [];
  // array1.concat(array2, array3, ..., arrayX)
  if (listOfCond.Detalle_De_La_Busqueda && historica) {
    LineOfBussinesModifiedKeys = HistoricaKeys.historicaKeyArr;
  } else {
    LineOfBussinesModifiedKeys = LineOfBussinesKeys.LineOfBussinesKeysArr;
  }

  if (listOfCond.Cobranza) {
    // LineOfBussinesModifiedKeys = _.assign(
    //   LineOfBussinesModifiedKeys,
    //   LineOfBussinesKeys.Cobranza
    // );
    LineOfBussinesModifiedKeys = LineOfBussinesModifiedKeys.concat(
      LineOfBussinesKeys.CobranzaArr
    );
  }

  if (listOfCond.Siniestro) {
    // LineOfBussinesModifiedKeys = _.assign(
    //   LineOfBussinesModifiedKeys,
    //   LineOfBussinesKeys.Siniestro
    // );
    LineOfBussinesModifiedKeys = LineOfBussinesModifiedKeys.concat(
      LineOfBussinesKeys.SiniestroArr
    );
  }

  if (listOfCond.Cobranza || listOfCond.Siniestro) {
    // LineOfBussinesModifiedKeys = _.assign(
    //   LineOfBussinesModifiedKeys,
    //   LineOfBussinesKeys.CobranzaSiniestroCommonKeys
    // );
    LineOfBussinesModifiedKeys = LineOfBussinesModifiedKeys.concat(
      LineOfBussinesKeys.CobranzaSiniestroCommonKeysArr
    );
  }

  if (listOfCond.Materia_Asegurad) {
    // LineOfBussinesModifiedKeys = _.assign(
    //   LineOfBussinesModifiedKeys,
    //   MateriaAsegKeys[ramoValue]
    // );
    let materiaObj = _.values(MateriaAsegKeys[ramoValue]);
    LineOfBussinesModifiedKeys = LineOfBussinesModifiedKeys.concat(materiaObj);
  }

  if (
    (listOfCond.Cobranza ||
      listOfCond.Siniestro ||
      listOfCond.Materia_Asegurad) &&
    !historica
  ) {
    // LineOfBussinesModifiedKeys = _.assign(LineOfBussinesModifiedKeys, {
    //   codigoRamo: "Cod Ramo"
    // });
    LineOfBussinesModifiedKeys = LineOfBussinesModifiedKeys.concat([
      "Cod Ramo"
    ]);
  }

  if (historica) {
    // console.log(
    //   "HistoricaKeys.historicaCommonKey",
    //   HistoricaKeys.historicaCommonKey
    // );
    // LineOfBussinesModifiedKeys = _.assign(
    //   LineOfBussinesModifiedKeys,
    //   HistoricaKeys.historicaCommonKey
    // );
    LineOfBussinesModifiedKeys = LineOfBussinesModifiedKeys.concat(
      HistoricaKeys.historicaCommonKeyArr
    );
  }
  return LineOfBussinesModifiedKeys;
}

export function getlineofbusinessExcel(
  head,
  data,
  listOfCond,
  selectedRow,
  amountOfDataLargeFlag
) {
  let emailID = "";
  if (localStorage.getItem("emailID")) {
    emailID = localStorage.getItem("emailID");
  } else {
    emailID = emailID;
  }
  if (amountOfDataLargeFlag) {
    if (data.historica) {
      let historicaReqObj = {
        p_Criterio: data.p_Criterio,
        p_PeriodoAnoMes: data.p_PeriodoAnoMes,
        p_Oficina: 0,
        p_Indicador_matriz: "S",
        p_R_VLO_CODIGO_UNO: 0,
        p_R_VST_CODIGO_UNO: 0,
        p_R_VLO_COD_RAMOS: data.p_R_VLO_COD_RAMOS,
        p_CodigoOficina: 0,
        p_RutUsuario: data.p_RutUsuario,
        flag: data.flag,
        p_COD_TXT_SUS: 0,
        p_R_VLO_FEC_INICIO: data.p_PeriodoAnoMes,
        p_CHK_Produccion: listOfCond.Produccion ? 1 : 0,
        p_CHK_Reaseguro: 0,
        p_CHK_Siniestro: 0,
        p_CHK_Perfil_Cliente: 0,
        p_CHK_Materia: listOfCond.Materia_Asegurad ? 1 : 0,
        p_GI_CRITERIO: 1014,
        p_R_VLO_COD_RAMOS1: ramosObject[selectedRow.ramo],
        p_R_VLO_CODIGO_UNO1: 0,
        p_R_VST_CODIGO_UNO1: "T",
        p_CodigoOficina1: 0,
        p_RutUsuario1: 0,
        ramo: selectedRow.ramo,
        emailId: emailID
      };

      return axios
        .post(base_url + "excelDownload/excelFlow2", historicaReqObj, config)
        .then(res => {
          return res.data;
        })
        .catch(error => {
          console.log("error " + error);
          return error;
        });
    } else {
      let reqObj = {
        p_Criterio: data.p_Criterio,
        p_R_VLO_CODIGO_UNO: data.p_R_VLO_CODIGO_UNO,
        p_R_VLO_COD_SUS: data.p_R_VLO_COD_SUS,
        p_R_VLO_FEC_INICIO: data.p_R_VLO_FEC_INICIO,
        p_R_VLO_FEC_TERMINO: data.p_R_VLO_FEC_TERMINO,
        p_R_VST_CODIGO_UNO: data.p_R_VST_CODIGO_UNO,
        p_R_VST_CODIGO_DOS: data.p_R_VST_CODIGO_DOS,
        p_R_VLO_FEC_POL_INI: data.p_R_VLO_FEC_POL_INI,
        p_R_VLO_FEC_POL_TER: data.p_R_VLO_FEC_POL_TER,
        p_R_VLO_COD_RAMOS: data.p_R_VLO_COD_RAMOS,
        p_CodigoOficina: data.p_CodigoOficina,
        p_RutUsuario: data.p_RutUsuario,
        flag: data.flag,
        p_COD_TXT_SUS: 0,
        p_CHK_Produccion: listOfCond.Produccion ? 1 : 0,
        p_CHK_Reaseguro: 0,
        p_CHK_Cobranza: listOfCond.Cobranza ? 1 : 0,
        p_CHK_Siniestro: listOfCond.Siniestro ? 1 : 0,
        p_CHK_Perfil_Cliente: 0,
        p_CHK_Materia: listOfCond.Materia_Asegurad ? 1 : 0,
        p_GI_CRITERIO: 9,
        p_R_VLO_FEC_INICIO1: 0,
        p_R_VLO_FEC_TERMINO1: 0,
        p_R_VLO_FEC_POL_INI1: data.p_R_VLO_FEC_POL_INI,
        p_R_VLO_FEC_POL_TER1: data.p_R_VLO_FEC_POL_TER,
        p_R_VLO_COD_RAMOS1: ramosObject[selectedRow.ramo],
        p_R_VLO_COD_SUBRAMOS: 0,
        p_R_VLO_CODIGO_UNO1: data.p_R_VLO_CODIGO_UNO,
        p_R_VLO_CODIGO_DOS: 0,
        p_VST_HISTORICO: "N",
        p_VST_DE_DONDE: "",
        p_VST_OPCION_SINIESTRALIDAD: "",
        p_VST_OPCION_FECHA: "",
        p_R_VST_PATENTE: "",
        p_R_VST_CODIGO_UNO1: data.p_R_VST_CODIGO_UNO,
        p_R_VST_CODIGO_DOS1: data.p_R_VST_CODIGO_DOS,
        p_CodigoOficina1: 10,
        p_RutUsuario1: 7735769,
        ramo: selectedRow.ramo,
        emailId: emailID
      };

      if (listOfCond.Detalle_De_La_Busqueda && listOfCond.Detalle_Siniestro) {
        let changesRamoObj = {
          p_COD_TXT_SUS: 0,
          p_CHK_Produccion: listOfCond.Produccion ? 1 : 0,
          p_CHK_Reaseguro: 0,
          p_CHK_Cobranza: listOfCond.Cobranza ? 1 : 0,
          p_CHK_Siniestro: listOfCond.Siniestro ? 1 : 0,
          p_CHK_Perfil_Cliente: 0,
          p_CHK_Materia: listOfCond.Materia_Asegurad ? 1 : 0,
          p_GI_CRITERIO: data.p_Criterio,
          p_R_VLO_FEC_INICIO: 0,
          p_R_VLO_FEC_TERMINO: 0,
          p_R_VLO_FEC_POL_INI: data.p_R_VLO_FEC_POL_INI,
          p_R_VLO_FEC_POL_TER: data.p_R_VLO_FEC_POL_TER,
          p_R_VLO_COD_RAMOS: ramosObject[selectedRow.ramo],
          p_R_VLO_COD_SUBRAMOS: 0,
          p_R_VLO_CODIGO_UNO: data.p_R_VLO_CODIGO_UNO,
          p_R_VLO_CODIGO_DOS: 0,
          p_VST_HISTORICO: "N",
          p_VST_DE_DONDE: "",
          p_VST_OPCION_SINIESTRALIDAD: "",
          p_VST_OPCION_FECHA: "",
          p_R_VST_PATENTE: "",
          p_R_VST_CODIGO_UNO: "T",
          p_R_VST_CODIGO_DOS: "",
          p_CodigoOficina: 10,
          p_RutUsuario: 7735769,
          ramo: selectedRow.ramo,
          emailId: emailID
        };
        axios.post(
          base_url + "excelDownload/excelFlow3",
          changesRamoObj,
          config
        );
      }

      return axios
        .post(base_url + "excelDownload/excelFlow1", reqObj, config)
        .then(res => {
          return res.data;
        })
        .catch(error => {
          console.log("error " + error);
          return error;
        });
    }
  } else {
    let listOfRamosObj = [];
    let requestArr = [];
    if (data.historica) {
      requestArr.push(
        axios.post(base_url + "por-ramos/getUnoHydNewDetails", data, config)
      );
    } else if (listOfCond.Resultado_Busqueda) {
      requestArr.push(axios.post(base_url + "getlineofbusiness", data, config));
    }

    if (listOfCond.Detalle_De_La_Busqueda) {
      let newData = {};
      if (data.historica) {
        newData = {
          p_COD_TXT_SUS: 0,
          p_CHK_Produccion: listOfCond.Produccion ? 1 : 0,
          p_CHK_Reaseguro: 0,
          p_CHK_Siniestro: 0,
          p_CHK_Perfil_Cliente: 0,
          p_CHK_Materia: listOfCond.Materia_Asegurad ? 1 : 0,
          p_GI_CRITERIO: 1014,
          p_R_VLO_FEC_INICIO: data.p_PeriodoAnoMes,
          p_R_VLO_COD_RAMOS: 9,
          p_R_VLO_CODIGO_UNO: 0,
          p_R_VST_CODIGO_UNO: "T",
          p_CodigoOficina: 0,
          p_RutUsuario: 0
        };
      } else {
        newData = {
          p_COD_TXT_SUS: 0,
          p_CHK_Produccion: listOfCond.Produccion ? 1 : 0,
          p_CHK_Reaseguro: 0,
          p_CHK_Cobranza: listOfCond.Cobranza ? 1 : 0,
          p_CHK_Siniestro: listOfCond.Siniestro ? 1 : 0,
          p_CHK_Perfil_Cliente: 0,
          p_CHK_Materia: listOfCond.Materia_Asegurad ? 1 : 0,
          p_GI_CRITERIO: data.p_Criterio,
          p_R_VLO_FEC_INICIO: 0,
          p_R_VLO_FEC_TERMINO: 0,
          p_R_VLO_FEC_POL_INI: data.p_R_VLO_FEC_POL_INI,
          p_R_VLO_FEC_POL_TER: data.p_R_VLO_FEC_POL_TER,
          p_R_VLO_COD_RAMOS: 9,
          p_R_VLO_COD_SUBRAMOS: 0,
          p_R_VLO_CODIGO_UNO: data.p_R_VLO_CODIGO_UNO,
          p_R_VLO_CODIGO_DOS: 0,
          p_VST_HISTORICO: "N",
          p_VST_DE_DONDE: "",
          p_VST_OPCION_SINIESTRALIDAD: "",
          p_VST_OPCION_FECHA: "",
          p_R_VST_PATENTE: "",
          p_R_VST_CODIGO_UNO: "T",
          p_R_VST_CODIGO_DOS: "",
          p_CodigoOficina: 10,
          p_RutUsuario: 7735769
        };
      }

      _.forEach(selectedRow, function(row) {
        if (data.historica) {
          console.log("data------->", data);
          let ramoValue = ramosObject[row.ramo];
          let changesRamoObj = Object.assign({}, newData);
          changesRamoObj.p_R_VLO_COD_RAMOS = ramoValue;
          listOfRamosObj.push(changesRamoObj);
          requestArr.push(
            axios.post(base_url + "excelDownload/flow2", changesRamoObj, config)
          );
        } else if (!listOfCond.ramosScreen) {
          let ramoValue = ramosObject[row.ramo];
          let changesRamoObj = Object.assign({}, newData);
          changesRamoObj.p_R_VLO_COD_RAMOS = ramoValue;
          listOfRamosObj.push(changesRamoObj);
          requestArr.push(
            axios.post(base_url + "excelDownload/flow1", changesRamoObj, config)
          );
        } else {
          let changesRamoObj = Object.assign({}, newData);
          changesRamoObj.p_R_VLO_COD_RAMOS = data.p_R_VLO_COD_RAMOS;
          listOfRamosObj.push(changesRamoObj);
          requestArr.push(
            axios.post(base_url + "excelDownload/flow1", changesRamoObj, config)
          );
        }
      });
      console.log("listOfRamosObj----->", listOfRamosObj);
    }

    let listOfSiniestroObj = [];
    if (listOfCond.Detalle_De_La_Busqueda && listOfCond.Detalle_Siniestro) {
      let siniestroObj = {
        p_COD_TXT_SUS: 0,
        p_CHK_Produccion: listOfCond.Produccion ? 1 : 0,
        p_CHK_Reaseguro: 0,
        p_CHK_Cobranza: listOfCond.Cobranza ? 1 : 0,
        p_CHK_Siniestro: listOfCond.Siniestro ? 1 : 0,
        p_CHK_Perfil_Cliente: 0,
        p_CHK_Materia: listOfCond.Materia_Asegurad ? 1 : 0,
        p_GI_CRITERIO: data.p_Criterio,
        p_R_VLO_FEC_INICIO: 0,
        p_R_VLO_FEC_TERMINO: 0,
        p_R_VLO_FEC_POL_INI: data.p_R_VLO_FEC_POL_INI,
        p_R_VLO_FEC_POL_TER: data.p_R_VLO_FEC_POL_TER,
        p_R_VLO_COD_RAMOS: 13,
        p_R_VLO_COD_SUBRAMOS: 0,
        p_R_VLO_CODIGO_UNO: data.p_R_VLO_CODIGO_UNO,
        p_R_VLO_CODIGO_DOS: 0,
        p_VST_HISTORICO: "N",
        p_VST_DE_DONDE: "",
        p_VST_OPCION_SINIESTRALIDAD: "",
        p_VST_OPCION_FECHA: "",
        p_R_VST_PATENTE: "",
        p_R_VST_CODIGO_UNO: "T",
        p_R_VST_CODIGO_DOS: "",
        p_CodigoOficina: 10,
        p_RutUsuario: 7735769
      };
      _.forEach(selectedRow, function(row) {
        let ramoValue = ramosObject[row.ramo];
        let changesRamoObj = Object.assign({}, siniestroObj);
        changesRamoObj.p_R_VLO_COD_RAMOS = ramoValue;
        listOfSiniestroObj.push(changesRamoObj);
        requestArr.push(
          axios.post(base_url + "excelDownload/flow3", changesRamoObj, config)
        );
      });
      console.log("listOfSiniestroObj----->", listOfSiniestroObj);
    }

    return axios
      .all(requestArr)
      .then(
        axios.spread((...args) => {
          if (
            listOfCond.Resultado_Busqueda ||
            listOfCond.Detalle_De_La_Busqueda
          ) {
            var createXLSLFormatObj = [];
            /* XLS Head Columns */
            var xlsHeader = _.values(head);
            xlsHeader.shift();

            /* File Name */
            var filename = "Informacion_General_XLS.xlsx";
            var wb = XLSX.utils.book_new();

            if (listOfCond.Resultado_Busqueda) {
              if (data.historica) {
                if (
                  args[0].data &&
                  args[0].data.data &&
                  args[0].data.data.unoHydNewDetails
                ) {
                  let dataToDisplay = args[0].data.data.unoHydNewDetails;
                  let modifiedData = dataToDisplay;

                  var xlsRows = modifiedData;
                  // let xlsHeaderWithExcelCol = delete xlsHeader.Excel;
                  createXLSLFormatObj.push(xlsHeader);

                  xlsRows.forEach(function(xlsRowsObj) {
                    let innerRowData = [];
                    _.forEach(xlsRowsObj, function(value, key) {
                      if (key !== "excel") {
                        innerRowData.push(xlsRowsObj[key]);
                      }
                    });
                    createXLSLFormatObj.push(innerRowData);
                  });
                  /* Sheet Name */
                  var ws_name = "Informacion_General";
                  if (typeof console !== "undefined") console.log(new Date());

                  let ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);
                  /* Add worksheet to workbook */
                  XLSX.utils.book_append_sheet(wb, ws, ws_name);
                }
              } else if (
                args[0].data &&
                args[0].data.data &&
                args[0].data.data.lob
              ) {
                let dataToDisplay = args[0].data.data.lob;
                let modifiedData = dataToDisplay;

                var xlsRows = modifiedData;

                createXLSLFormatObj.push(xlsHeader);

                xlsRows.forEach(function(xlsRowsObj) {
                  let innerRowData = [];
                  _.forEach(xlsRowsObj, function(value, key) {
                    innerRowData.push(xlsRowsObj[key]);
                  });
                  createXLSLFormatObj.push(innerRowData);
                });
                /* Sheet Name */
                var ws_name = "Informacion_General";
                if (typeof console !== "undefined") console.log(new Date());

                let ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);
                /* Add worksheet to workbook */
                XLSX.utils.book_append_sheet(wb, ws, ws_name);
              }
            }

            /* Excel for Ramos */
            /* XLS Head Columns */
            if (listOfCond.Detalle_De_La_Busqueda) {
              if (data.historica) {
                for (let i = 0; i < selectedRow.length; i++) {
                  let createXLSLForRamosFormatObj = [];
                  // let xlsHeaderRamos = _.values(HistoricaKeys);

                  let historicaModifiedKeys = getHeader(
                    data.historica,
                    listOfCond,
                    ramosObject[selectedRow[i].ramo]
                  );
                  let xlsHeaderRamos = historicaModifiedKeys;

                  let ramosXlsRows = {};
                  if (listOfCond.Resultado_Busqueda) {
                    ramosXlsRows = args[i + 1].data.data.excelDetails;
                  } else {
                    ramosXlsRows = args[i].data.data.excelDetails;
                  }
                  createXLSLForRamosFormatObj.push(xlsHeaderRamos);

                  if (ramosXlsRows && ramosXlsRows.length > 0) {
                    ramosXlsRows.forEach(function(xlsRowsObj) {
                      let innerRowData = [];
                      _.forEach(xlsRowsObj, function(value, key) {
                        innerRowData.push(xlsRowsObj[key]);
                      });
                      createXLSLForRamosFormatObj.push(innerRowData);
                    });
                  } else {
                    createXLSLForRamosFormatObj.push([{}]);
                  }

                  let ws_name_ramo = selectedRow[i].ramo;
                  if (ws_name_ramo.length > 30) {
                    ws_name_ramo = ws_name_ramo.substring(0, 29);
                  }
                  let ws_ramo = XLSX.utils.aoa_to_sheet(
                    createXLSLForRamosFormatObj
                  );
                  XLSX.utils.book_append_sheet(wb, ws_ramo, ws_name_ramo);
                }
              } else {
                for (let i = 0; i < selectedRow.length; i++) {
                  let createXLSLForRamosFormatObj = [];
                  createXLSLForRamosFormatObj.push([selectedRow[i].ramo]);
                  let LineOfBussinesModifiedKeys = getHeader(
                    data.historica,
                    listOfCond,
                    ramosObject[selectedRow[i].ramo]
                  );
                  let xlsHeaderRamos = LineOfBussinesModifiedKeys;
                  let ramosXlsRows = {};
                  if (listOfCond.Resultado_Busqueda) {
                    ramosXlsRows = args[i + 1].data.data.ob1;
                  } else {
                    ramosXlsRows = args[i].data.data.ob1;
                  }
                  createXLSLForRamosFormatObj.push(xlsHeaderRamos);

                  if (ramosXlsRows && ramosXlsRows.length > 0) {
                    ramosXlsRows.forEach(function(xlsRowsObj) {
                      let innerRowData = [];
                      _.forEach(xlsRowsObj, function(value, key) {
                        innerRowData.push(xlsRowsObj[key]);
                      });
                      createXLSLForRamosFormatObj.push(innerRowData);
                    });
                  } else {
                    createXLSLForRamosFormatObj.push([{}]);
                  }

                  let ws_name_ramo = selectedRow[i].ramo;
                  if (ws_name_ramo.length > 25) {
                    ws_name_ramo = ws_name_ramo.substring(0, 25);
                  }
                  let ws_ramo = XLSX.utils.aoa_to_sheet(
                    createXLSLForRamosFormatObj
                  );
                  XLSX.utils.book_append_sheet(wb, ws_ramo, ws_name_ramo);
                }
              }
            }

            /* Siniestro Details */
            /* File Name */
            if (
              listOfCond.Detalle_De_La_Busqueda &&
              listOfCond.Detalle_Siniestro
            ) {
              if (typeof console !== "undefined") console.log(new Date());
              let wb = XLSX.utils.book_new();

              var siniestrosFilename = "Siniestros.xlsx";
              for (let i = 0; i < listOfSiniestroObj.length; i++) {
                let createXLSLForSiniestrosFormatObj = [];
                let xlsHeaderSiniestro = _.values(siniestroKeys);
                let siniestrosXlsRows = [];
                console.log(
                  "listOfCond.Resultado_Busqueda",
                  listOfCond.Resultado_Busqueda
                );
                if (listOfCond.Resultado_Busqueda) {
                  siniestrosXlsRows =
                    args[selectedRow.length + 1 + i].data.data.object1;
                } else {
                  siniestrosXlsRows =
                    args[selectedRow.length + i].data.data.object1;
                }

                createXLSLForSiniestrosFormatObj.push(xlsHeaderSiniestro);

                console.log("siniestrosXlsRows--->", siniestrosXlsRows);

                if (siniestrosXlsRows && siniestrosXlsRows.length > 0) {
                  siniestrosXlsRows.forEach(function(xlsRowsObj) {
                    let innerRowData = [];
                    _.forEach(xlsRowsObj, function(value, key) {
                      innerRowData.push(xlsRowsObj[key]);
                    });
                    createXLSLForSiniestrosFormatObj.push(innerRowData);
                  });
                } else {
                  createXLSLForSiniestrosFormatObj.push([{}]);
                }

                let createXLSLForSiniestrosRamosFormatObj = [];
                let xlsHeaderSiniestroRamos = _.values(DetPagRegKeys);
                let siniestrosRamosXlsRows = [];
                if (listOfCond.Resultado_Busqueda) {
                  siniestrosRamosXlsRows =
                    args[selectedRow.length + i + 1].data.data.object2;
                } else {
                  siniestrosRamosXlsRows =
                    args[selectedRow.length + i].data.data.object2;
                }

                createXLSLForSiniestrosRamosFormatObj.push(
                  xlsHeaderSiniestroRamos
                );

                if (
                  siniestrosRamosXlsRows &&
                  siniestrosRamosXlsRows.length > 0
                ) {
                  siniestrosRamosXlsRows.forEach(function(xlsRowsObj) {
                    let innerRowData = [];
                    _.forEach(xlsRowsObj, function(value, key) {
                      innerRowData.push(xlsRowsObj[key]);
                    });
                    createXLSLForSiniestrosRamosFormatObj.push(innerRowData);
                  });
                } else {
                  createXLSLForSiniestrosRamosFormatObj.push([{}]);
                }

                let ws_name_siniestro = "sin_" + selectedRow[i].ramo;
                if (ws_name_siniestro.length > 25) {
                  ws_name_siniestro = ws_name_siniestro.substring(0, 25);
                }

                let ws_name_siniestro_ramo = "det_p" + selectedRow[i].ramo;
                if (ws_name_siniestro_ramo.length > 25) {
                  ws_name_siniestro_ramo = ws_name_siniestro_ramo.substring(
                    0,
                    25
                  );
                }

                let ws_name_siniestro_data = XLSX.utils.aoa_to_sheet(
                  createXLSLForSiniestrosFormatObj
                );
                let ws_name_siniestro_ramo_data = XLSX.utils.aoa_to_sheet(
                  createXLSLForSiniestrosRamosFormatObj
                );
                XLSX.utils.book_append_sheet(
                  wb,
                  ws_name_siniestro_data,
                  ws_name_siniestro
                );
                XLSX.utils.book_append_sheet(
                  wb,
                  ws_name_siniestro_ramo_data,
                  ws_name_siniestro_ramo
                );
              }

              XLSX.writeFile(wb, siniestrosFilename);
            }

            /* Write workbook and Download */
            if (typeof console !== "undefined") console.log(new Date());
            XLSX.writeFile(wb, filename);
            if (typeof console !== "undefined") console.log(new Date());
            return {
              success: true,
              msg: "Excel download successful",
              data: {}
            };
          }
        })
      )
      .catch(error => {
        console.log(error);
        return {
          success: false,
          msg: "Tiempo de espera agotado. Inténtalo de nuevo",
          data: {}
        };
      });
  }
}

export function geRamosExcel(head, data, listOfCond, selectedRow) {
  let emailID = "";
  if (localStorage.getItem("emailID")) {
    emailID = localStorage.getItem("emailID");
  } else {
    emailID = emailID;
  }
  /**Calculation of Excel Keys  */
  let LineOfBussinesModifiedKeys = {};
  if (listOfCond.Detalle_De_La_Busqueda) {
    LineOfBussinesModifiedKeys = LineOfBussinesKeys.LineOfBussinesKeys;
  }

  if (listOfCond.Cobranza) {
    LineOfBussinesModifiedKeys = _.assign(
      LineOfBussinesModifiedKeys,
      LineOfBussinesKeys.Cobranza
    );
  }

  if (listOfCond.Siniestro) {
    LineOfBussinesModifiedKeys = _.assign(
      LineOfBussinesModifiedKeys,
      LineOfBussinesKeys.Siniestro
    );
  }

  if (listOfCond.Cobranza || listOfCond.Siniestro) {
    LineOfBussinesModifiedKeys = _.assign(
      LineOfBussinesModifiedKeys,
      LineOfBussinesKeys.CobranzaSiniestroCommonKeys
    );
  }

  if (listOfCond.Materia_Asegurad) {
    LineOfBussinesModifiedKeys = _.assign(
      LineOfBussinesModifiedKeys,
      LineOfBussinesKeys.Materia_Asegurad
    );
  }

  if (
    listOfCond.Cobranza ||
    listOfCond.Siniestro ||
    listOfCond.Materia_Asegurad
  ) {
    LineOfBussinesModifiedKeys = _.assign(LineOfBussinesModifiedKeys, {
      codigoRamo: "Cod Ramo "
    });
  }

  if (data.flag === "porReferenciaCorredor") {
    let historicaReqObj = {
      p_Criterio: data.p_Criterio,
      p_R_VLO_CODIGO_UNO: data.p_R_VLO_CODIGO_UNO,
      p_R_VLO_COD_SUS: 0,
      p_R_VLO_FEC_INICIO: 0,
      p_R_VLO_FEC_TERMINO: "20190703",
      p_R_VST_CODIGO_UNO: "T",
      p_R_VST_CODIGO_DOS: "",
      p_R_VLO_FEC_POL_INI: data.p_R_VLO_FEC_POL_INI,
      p_R_VLO_FEC_POL_TER: data.p_R_VLO_FEC_POL_INI,
      p_R_VLO_COD_RAMOS: data.p_R_VLO_COD_RAMOS,
      p_R_VST_PATENTE: data.p_R_VST_PATENTE,
      p_CodigoOficina: 10,
      p_RutUsuario: 7735769,
      flag: "porReferenciaCorredor",
      p_CRITERIO: data.p_Criterio,
      emailId: emailID
    };

    return axios
      .post(base_url + "dosNewExcel", historicaReqObj, config)
      .then(res => {
        return res.data;
      })
      .catch(error => {
        console.log("error " + error);
        return error;
      });
  } else if (data.flag === "porSiniestro") {
    let ramoString = "";
    let ramoCode =
      data.lobDtailsData && data.lobDtailsData.length
        ? data.lobDtailsData[0].codRamo
        : 0;
    ramoCode = parseInt(ramoCode);
    _.forOwn(ramosObject, function(value, key) {
      if (value == ramoCode) {
        ramoString = key;
      }
    });

    let reqObj = {
      p_Criterio: data.p_Criterio,
      p_R_VLO_CODIGO_UNO: data.p_R_VLO_CODIGO_UNO,
      p_R_VLO_COD_SUS: data.p_R_VLO_COD_SUS,
      p_R_VLO_FEC_INICIO: 0,
      p_R_VLO_FEC_TERMINO: 0,
      p_R_VST_CODIGO_UNO: "T",
      p_R_VST_CODIGO_DOS: data.p_R_VST_CODIGO_DOS,
      p_R_VLO_FEC_POL_INI: data.p_R_VLO_FEC_POL_INI,
      p_R_VLO_FEC_POL_TER: data.p_R_VLO_FEC_POL_TER,
      p_R_VLO_COD_RAMOS: ramoCode,
      p_CodigoOficina: 10,
      p_RutUsuario: 7735769,
      flag: data.flag,
      p_COD_TXT_SUS: 0,
      p_CHK_Produccion: listOfCond.Produccion ? 1 : 0,
      p_CHK_Reaseguro: 0,
      p_CHK_Cobranza: listOfCond.Cobranza ? 1 : 0,
      p_CHK_Siniestro: listOfCond.Siniestro ? 1 : 0,
      p_CHK_Perfil_Cliente: 0,
      p_CHK_Materia: listOfCond.Materia_Asegurad ? 1 : 0,
      p_GI_CRITERIO: data.p_Criterio,
      p_R_VLO_FEC_INICIO1: 0,
      p_R_VLO_FEC_TERMINO1: 0,
      p_R_VLO_FEC_POL_INI1: data.p_R_VLO_FEC_POL_INI,
      p_R_VLO_FEC_POL_TER1: data.p_R_VLO_FEC_POL_TER,
      p_R_VLO_COD_RAMOS1: ramoCode,
      p_R_VLO_COD_SUBRAMOS: 0,
      p_R_VLO_CODIGO_UNO1: data.p_R_VLO_CODIGO_UNO,
      p_R_VLO_CODIGO_DOS: 0,
      p_VST_HISTORICO: "N",
      p_VST_DE_DONDE: "",
      p_VST_OPCION_SINIESTRALIDAD: "",
      p_VST_OPCION_FECHA: "",
      p_R_VST_PATENTE: "",
      p_R_VST_CODIGO_UNO1: "T",
      p_R_VST_CODIGO_DOS1: data.p_R_VST_CODIGO_DOS,
      p_CodigoOficina1: 10,
      p_RutUsuario1: 7735769,
      ramo: ramoString,
      emailId: emailID
    };

    return axios
      .post(base_url + "excelDownload/excelFlow1", reqObj, config)
      .then(res => {
        return res.data;
      })
      .catch(error => {
        console.log("error " + error);
        return error;
      });
  } else {
    let requestArr = [];
    let modifiedReqObj = data;
    modifiedReqObj.p_R_VST_PATENTE = "";
    // modifiedReqObj.p_Criterio = 1017;
    modifiedReqObj.p_Criterio = data.p_Criterio;
    if (data.historica) {
      modifiedReqObj.p_Criterio = 1017;
      requestArr.push(
        axios.post(
          base_url + "por-ramos/getDosHydNewDetails",
          modifiedReqObj,
          config
        )
      );
    } else if (listOfCond.Resultado_Busqueda && data.unidadCase) {
      requestArr.push(
        axios.post(base_url + "getleeInfGenUnidad", modifiedReqObj, config)
      );
    } else if (listOfCond.Resultado_Busqueda) {
      modifiedReqObj.p_R_VLO_COD_RAMOS =
        modifiedReqObj.lobDtailsData[0].codRamo;
      // delete modifiedReqObj.lobDtailsData;
      requestArr.push(
        axios.post(base_url + "getLeeNivelDosNew", modifiedReqObj, config)
      );
    }

    if (listOfCond.Detalle_De_La_Busqueda) {
      let newData = {};
      if (data.historica) {
        newData = {
          p_COD_TXT_SUS: 0,
          p_CHK_Produccion: listOfCond.Produccion ? 1 : 0,
          p_CHK_Reaseguro: 0,
          p_CHK_Siniestro: 0,
          p_CHK_Perfil_Cliente: 0,
          p_CHK_Materia: listOfCond.Materia_Asegurad ? 1 : 0,
          p_GI_CRITERIO: 1014,
          p_R_VLO_FEC_INICIO: data.p_PeriodoAnoMes,
          p_R_VLO_COD_RAMOS: 9,
          p_R_VLO_CODIGO_UNO: 0,
          p_R_VST_CODIGO_UNO: "T",
          p_CodigoOficina: 0,
          p_RutUsuario: 0
        };
      } else {
        newData = {
          p_COD_TXT_SUS: 0,
          p_CHK_Produccion: listOfCond.Produccion ? 1 : 0,
          p_CHK_Reaseguro: 0,
          p_CHK_Cobranza: listOfCond.Cobranza ? 1 : 0,
          p_CHK_Siniestro: listOfCond.Siniestro ? 1 : 0,
          p_CHK_Perfil_Cliente: 0,
          p_CHK_Materia: listOfCond.Materia_Asegurad ? 1 : 0,
          p_GI_CRITERIO: data.p_Criterio,
          p_R_VLO_FEC_INICIO: 0,
          p_R_VLO_FEC_TERMINO: 0,
          p_R_VLO_FEC_POL_INI: data.p_R_VLO_FEC_POL_INI,
          p_R_VLO_FEC_POL_TER: data.p_R_VLO_FEC_POL_TER,
          p_R_VLO_COD_RAMOS: 9,
          p_R_VLO_COD_SUBRAMOS: 0,
          p_R_VLO_CODIGO_UNO: data.p_R_VLO_CODIGO_UNO,
          p_R_VLO_CODIGO_DOS: 0,
          p_VST_HISTORICO: "N",
          p_VST_DE_DONDE: "",
          p_VST_OPCION_SINIESTRALIDAD: "",
          p_VST_OPCION_FECHA: "",
          p_R_VST_PATENTE: "",
          p_R_VST_CODIGO_UNO: "T",
          p_R_VST_CODIGO_DOS: "",
          p_CodigoOficina: 10,
          p_RutUsuario: 7735769
        };
      }

      if (data.historica) {
        console.log("data------->", data);
        let ramoValue = ramosObject[data.ramo];
        let changesRamoObj = Object.assign({}, newData);
        changesRamoObj.p_R_VLO_COD_RAMOS = data.p_R_VLO_COD_RAMOS;
        requestArr.push(
          axios.post(base_url + "excelDownload/flow2", changesRamoObj, config)
        );
      } else if (!listOfCond.ramosScreen) {
        let ramoValue = ramosObject[data.ramo];
        let changesRamoObj = Object.assign({}, newData);
        changesRamoObj.p_R_VLO_COD_RAMOS = ramoValue;
        requestArr.push(
          axios.post(base_url + "excelDownload/flow1", changesRamoObj, config)
        );
      } else {
        let changesRamoObj = Object.assign({}, newData);
        changesRamoObj.p_R_VLO_COD_RAMOS = parseInt(data.p_R_VLO_COD_RAMOS);
        requestArr.push(
          axios.post(base_url + "excelDownload/flow1", changesRamoObj, config)
        );
      }
    }

    return axios
      .all(requestArr)
      .then(
        axios.spread((...args) => {
          if (
            listOfCond.Resultado_Busqueda ||
            listOfCond.Detalle_De_La_Busqueda
          ) {
            var createXLSLFormatObj = [];
            /* XLS Head Columns */
            var xlsHeader = _.values(head);
            if (xlsHeader[0] === "Excel") {
              xlsHeader.shift();
            }

            /* File Name */
            var filename = "Informacion_General_XLS.xlsx";
            var wb = XLSX.utils.book_new();

            if (listOfCond.Resultado_Busqueda) {
              if (data.historica) {
                if (
                  args[0].data &&
                  args[0].data.data &&
                  args[0].data.data.dosHydNewDetails
                ) {
                  let dataToDisplay = args[0].data.data.dosHydNewDetails;
                  let modifiedData = dataToDisplay;

                  var xlsRows = modifiedData;
                  // let xlsHeaderWithExcelCol = delete xlsHeader.Excel;
                  createXLSLFormatObj.push(xlsHeader);

                  xlsRows.forEach(function(xlsRowsObj) {
                    let innerRowData = [];
                    _.forEach(xlsRowsObj, function(value, key) {
                      innerRowData.push(xlsRowsObj[key]);
                    });
                    createXLSLFormatObj.push(innerRowData);
                  });
                  /* Sheet Name */
                  var ws_name = "Informacion_General";
                  if (typeof console !== "undefined") console.log(new Date());

                  let ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);
                  /* Add worksheet to workbook */
                  XLSX.utils.book_append_sheet(wb, ws, ws_name);
                }
              } else if (
                args[0].data &&
                args[0].data.data &&
                args[0].data.data.LeeNivelDosNew
              ) {
                let dataToDisplay = args[0].data.data.LeeNivelDosNew;
                let modifiedData = dataToDisplay;

                var xlsRows = modifiedData;

                createXLSLFormatObj.push(xlsHeader);

                xlsRows.forEach(function(xlsRowsObj) {
                  let innerRowData = [];
                  _.forEach(xlsRowsObj, function(value, key) {
                    innerRowData.push(xlsRowsObj[key]);
                  });
                  createXLSLFormatObj.push(innerRowData);
                });
                /* Sheet Name */
                var ws_name = "Informacion_General";
                if (typeof console !== "undefined") console.log(new Date());

                let ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);
                /* Add worksheet to workbook */
                XLSX.utils.book_append_sheet(wb, ws, ws_name);
              }
            }

            /* Excel for Ramos */
            /* XLS Head Columns */
            if (listOfCond.Detalle_De_La_Busqueda) {
              if (data.historica) {
                let createXLSLForRamosFormatObj = [];
                // let xlsHeaderRamos = _.values(HistoricaKeys);
                let historicaModifiedKeys = getHeader(
                  data.historica,
                  listOfCond,
                  data.p_R_VLO_COD_RAMOS
                );
                let xlsHeaderRamos = historicaModifiedKeys;

                let ramosXlsRows = {};
                if (listOfCond.Resultado_Busqueda) {
                  ramosXlsRows = args[1].data.data.excelDetails;
                } else {
                  ramosXlsRows = args[0].data.data.excelDetails;
                }
                createXLSLForRamosFormatObj.push(xlsHeaderRamos);

                if (ramosXlsRows && ramosXlsRows.length > 0) {
                  ramosXlsRows.forEach(function(xlsRowsObj) {
                    let innerRowData = [];
                    _.forEach(xlsRowsObj, function(value, key) {
                      innerRowData.push(xlsRowsObj[key]);
                    });
                    createXLSLForRamosFormatObj.push(innerRowData);
                  });
                } else {
                  createXLSLForRamosFormatObj.push([{}]);
                }

                let ws_name_ramo = data.p_R_VLO_COD_RAMOS.toString();
                let ws_ramo = XLSX.utils.aoa_to_sheet(
                  createXLSLForRamosFormatObj
                );
                XLSX.utils.book_append_sheet(wb, ws_ramo, ws_name_ramo);
              } else {
                let createXLSLForRamosFormatObj = [];
                // let xlsHeaderRamos = _.values(LineOfBussinesModifiedKeys);
                let LineOfBussinesModifiedKeys = getHeader(
                  data.historica,
                  listOfCond,
                  data.p_R_VLO_COD_RAMOS
                );
                let xlsHeaderRamos = LineOfBussinesModifiedKeys;
                let ramosXlsRows = {};
                if (listOfCond.Resultado_Busqueda) {
                  ramosXlsRows = args[1].data.data.ob1;
                } else {
                  ramosXlsRows = args[0].data.data.ob1;
                }
                createXLSLForRamosFormatObj.push(xlsHeaderRamos);

                if (ramosXlsRows && ramosXlsRows.length > 0) {
                  ramosXlsRows.forEach(function(xlsRowsObj) {
                    let innerRowData = [];
                    _.forEach(xlsRowsObj, function(value, key) {
                      innerRowData.push(xlsRowsObj[key]);
                    });
                    createXLSLForRamosFormatObj.push(innerRowData);
                  });
                } else {
                  createXLSLForRamosFormatObj.push([{}]);
                }

                let ws_name_ramo = data.p_R_VLO_COD_RAMOS.toString();
                let ws_ramo = XLSX.utils.aoa_to_sheet(
                  createXLSLForRamosFormatObj
                );
                XLSX.utils.book_append_sheet(wb, ws_ramo, ws_name_ramo);
              }
            }

            /* Write workbook and Download */
            if (typeof console !== "undefined") console.log(new Date());
            XLSX.writeFile(wb, filename);
            if (typeof console !== "undefined") console.log(new Date());
            return {
              success: true,
              msg: "Excel generated successfully",
              data: {}
            };
          }
        })
      )
      .catch(error => {
        console.log(error);
        return {
          success: false,
          msg: "Tiempo de espera agotado. Inténtalo de nuevo",
          data: {}
        };
      });
  }
}

export function getLeeNivelDosNewExcel(head, reqObj) {
  reqObj.p_R_VST_PATENTE = "";
  let data = reqObj;

  return axios
    .post(base_url + "getLeeNivelDosNew", data, config)
    .then(res => {
      var createXLSLFormatObj = [];

      /* XLS Head Columns */
      var xlsHeader = _.values(head);

      if (res.data && res.data.data && res.data.data.LeeNivelDosNew) {
        let dataToDisplay = res.data.data.LeeNivelDosNew;
        let modifiedData = dataToDisplay;
        modifiedData.push({});
        let total = res.data.data.LeeNivelDosNewTotal;
        total.forEach(element => {
          modifiedData.push(element);
        });

        var xlsRows = modifiedData;

        createXLSLFormatObj.push(xlsHeader);

        xlsRows.forEach(function(xlsRowsObj) {
          var innerRowData = [];
          _.forEach(xlsRowsObj, function(value, key) {
            innerRowData.push(xlsRowsObj[key]);
          });
          createXLSLFormatObj.push(innerRowData);
        });

        /* File Name */
        var filename = "Resumen_Ramo_XLS.xlsx";

        /* Sheet Name */
        var ws_name = "Resumen_Ramo";

        if (typeof console !== "undefined") console.log(new Date());
        var wb = XLSX.utils.book_new(),
          ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);

        /* Add worksheet to workbook */
        XLSX.utils.book_append_sheet(wb, ws, ws_name);

        /* Write workbook and Download */
        if (typeof console !== "undefined") console.log(new Date());
        XLSX.writeFile(wb, filename);
        if (typeof console !== "undefined") console.log(new Date());
      } else {
        alert("No data found to download");
      }
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
}

export function getleeInfGenUnidadExcel(head, reqObj) {
  reqObj.p_R_VST_PATENTE = "";
  let data = reqObj;

  return axios
    .post(base_url + "getleeInfGenUnidad", data, config)
    .then(res => {
      var createXLSLFormatObj = [];

      /* XLS Head Columns */
      var xlsHeader = _.values(head);

      if (res.data && res.data.data && res.data.data.LeeInfGenUnidad) {
        let dataToDisplay = res.data.data.LeeInfGenUnidad;
        let modifiedData = dataToDisplay;
        modifiedData.push({});
        let total = res.data.data.LeeInfGenUnidadTotal;
        total.forEach(element => {
          modifiedData.push(element);
        });

        var xlsRows = modifiedData;

        createXLSLFormatObj.push(xlsHeader);

        xlsRows.forEach(function(xlsRowsObj) {
          var innerRowData = [];
          _.forEach(xlsRowsObj, function(value, key) {
            innerRowData.push(xlsRowsObj[key]);
          });
          createXLSLFormatObj.push(innerRowData);
        });

        /* File Name */
        var filename = "Informacion_General_XLS.xlsx";

        /* Sheet Name */
        var ws_name = "Informacion_General";

        if (typeof console !== "undefined") console.log(new Date());
        var wb = XLSX.utils.book_new(),
          ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);

        /* Add worksheet to workbook */
        XLSX.utils.book_append_sheet(wb, ws, ws_name);

        /* Write workbook and Download */
        if (typeof console !== "undefined") console.log(new Date());
        XLSX.writeFile(wb, filename);
        if (typeof console !== "undefined") console.log(new Date());
      } else {
        alert("No data found to download");
      }
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
}

export function getProductionExcel(head, data) {
  var createXLSLFormatObj = [];

  /* XLS Head Columns */
  var xlsHeader = _.values(head);
  delete data[data.length - 1].total;
  var xlsRows = data;

  createXLSLFormatObj.push(
    ["PENTASECURITY SEGUROS GENERALES	"],
    ["Consulta Global "],
    ["Producción"],
    [],
    []
  );

  createXLSLFormatObj.push(xlsHeader);

  xlsRows.forEach(function(xlsRowsObj) {
    var innerRowData = [];
    _.forEach(xlsRowsObj, function(value, key) {
      innerRowData.push(xlsRowsObj[key]);
    });
    createXLSLFormatObj.push(innerRowData);
  });

  /* File Name */
  var filename = "Produccion_XLS.xlsx";

  /* Sheet Name */
  var ws_name = "Produccion";

  if (typeof console !== "undefined") console.log(new Date());
  var wb = XLSX.utils.book_new(),
    ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);

  /* Add worksheet to workbook */
  XLSX.utils.book_append_sheet(wb, ws, ws_name);

  /* Write workbook and Download */
  if (typeof console !== "undefined") console.log(new Date());
  XLSX.writeFile(wb, filename);
  if (typeof console !== "undefined") console.log(new Date());
}

export function geCobranzaExcel(head, data) {
  var createXLSLFormatObj = [];

  /* XLS Head Columns */
  var xlsHeader = _.values(head);

  delete data[data.length - 1].total;
  var xlsRows = data;

  createXLSLFormatObj.push(
    ["PENTASECURITY SEGUROS GENERALES	"],
    ["Consulta Global "],
    ["Cobranza"],
    [],
    []
  );

  createXLSLFormatObj.push(xlsHeader);

  xlsRows.forEach(function(xlsRowsObj) {
    var innerRowData = [];
    _.forEach(xlsRowsObj, function(value, key) {
      innerRowData.push(xlsRowsObj[key]);
    });
    createXLSLFormatObj.push(innerRowData);
  });

  /* File Name */
  var filename = "Cobranza_XLS.xlsx";

  /* Sheet Name */
  var ws_name = "Cobranza";

  if (typeof console !== "undefined") console.log(new Date());
  var wb = XLSX.utils.book_new(),
    ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);

  /* Add worksheet to workbook */
  XLSX.utils.book_append_sheet(wb, ws, ws_name);

  /* Write workbook and Download */
  if (typeof console !== "undefined") console.log(new Date());
  XLSX.writeFile(wb, filename);
  if (typeof console !== "undefined") console.log(new Date());
}

export function getSinistrosExcel(head, data) {
  var createXLSLFormatObj = [];

  /* XLS Head Columns */
  var xlsHeader = _.values(head);

  delete data[data.length - 1].total;
  var xlsRows = data;

  createXLSLFormatObj.push(
    ["PENTASECURITY SEGUROS GENERALES	"],
    ["Consulta Global "],
    ["Siniestros"],
    [],
    []
  );

  createXLSLFormatObj.push(xlsHeader);

  xlsRows.forEach(function(xlsRowsObj) {
    var innerRowData = [];
    _.forEach(xlsRowsObj, function(value, key) {
      innerRowData.push(xlsRowsObj[key]);
    });
    createXLSLFormatObj.push(innerRowData);
  });

  /* File Name */
  var filename = "Sinistros_XLS.xlsx";

  /* Sheet Name */
  var ws_name = "Sinistros";

  if (typeof console !== "undefined") console.log(new Date());
  var wb = XLSX.utils.book_new(),
    ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);

  /* Add worksheet to workbook */
  XLSX.utils.book_append_sheet(wb, ws, ws_name);

  /* Write workbook and Download */
  if (typeof console !== "undefined") console.log(new Date());
  XLSX.writeFile(wb, filename);
  if (typeof console !== "undefined") console.log(new Date());
}

export function getMateriaAsegExcel(head, data) {
  var createXLSLFormatObj = [];

  /* XLS Head Columns */
  var xlsHeader = _.values(head);

  var xlsRows = data;

  createXLSLFormatObj.push(
    ["PENTASECURITY SEGUROS GENERALES	"],
    ["Consulta Global "],
    ["Materia Aseg."],
    [],
    []
  );

  createXLSLFormatObj.push(xlsHeader);

  xlsRows.forEach(function(xlsRowsObj) {
    var innerRowData = [];
    _.forEach(xlsRowsObj, function(value, key) {
      innerRowData.push(xlsRowsObj[key]);
    });
    createXLSLFormatObj.push(innerRowData);
  });

  /* File Name */
  var filename = "MateriaAseg_XLS.xlsx";

  /* Sheet Name */
  var ws_name = "MateriaAseg";

  if (typeof console !== "undefined") console.log(new Date());
  var wb = XLSX.utils.book_new(),
    ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);

  /* Add worksheet to workbook */
  XLSX.utils.book_append_sheet(wb, ws, ws_name);

  /* Write workbook and Download */
  if (typeof console !== "undefined") console.log(new Date());
  XLSX.writeFile(wb, filename);
  if (typeof console !== "undefined") console.log(new Date());
}

export function getReaseguroExcel(head, data) {
  var createXLSLFormatObj = [];

  /* XLS Head Columns */
  var xlsHeader = _.values(head);

  delete data[data.length - 1].total;
  var xlsRows = data;

  createXLSLFormatObj.push(
    ["PENTASECURITY SEGUROS GENERALES	"],
    ["Consulta Global "],
    ["Reaseguro"],
    [],
    []
  );

  createXLSLFormatObj.push(xlsHeader);

  xlsRows.forEach(function(xlsRowsObj) {
    var innerRowData = [];
    _.forEach(xlsRowsObj, function(value, key) {
      innerRowData.push(xlsRowsObj[key]);
    });
    createXLSLFormatObj.push(innerRowData);
  });

  /* File Name */
  var filename = "Reaseguro_XLS.xlsx";

  /* Sheet Name */
  var ws_name = "Reaseguro";

  if (typeof console !== "undefined") console.log(new Date());
  var wb = XLSX.utils.book_new(),
    ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);

  /* Add worksheet to workbook */
  XLSX.utils.book_append_sheet(wb, ws, ws_name);

  /* Write workbook and Download */
  if (typeof console !== "undefined") console.log(new Date());
  XLSX.writeFile(wb, filename);
  if (typeof console !== "undefined") console.log(new Date());
}

const getcobranza = reqParam => {
  let data = {};

  data.p_R_VLO_COD_RAMOS = reqParam.ramos;
  data.p_R_VLO_NRO_POLIZA = reqParam.policyNo;
  data.p_R_VLO_NRO_UNIDAD = reqParam.itemNo;
  data.p_R_VLO_RUT = reqParam.rut;
  return axios
    .post(base_url + "getcobranza", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
};

const getProduction = reqParam => {
  let data = {};
  data.p_R_VLO_COD_RAMOS = reqParam.ramos;
  data.p_R_VLO_NRO_POLIZA = reqParam.policyNo;
  data.p_R_VLO_NRO_UNIDAD = reqParam.itemNo;
  data.p_R_VLO_RUT = reqParam.rut;

  return axios
    .post(base_url + "getProduction", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
};

const getSiniestro = reqParam => {
  let data = {};

  data.p_Poliza = reqParam.policyNo;
  data.p_Item = reqParam.itemNo;

  return axios
    .post(base_url + "getSiniestro", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
};

const getreaseguro = reqParam => {
  let data = {};
  data.p_R_VLO_COD_RAMOS = reqParam.ramos;
  data.p_R_VLO_NRO_POLIZA = reqParam.policyNo;
  data.p_R_VLO_NRO_UNIDAD = reqParam.itemNo;
  data.p_R_VLO_RUT = reqParam.rut;
  return axios
    .post(base_url + "getreaseguro", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
};

const getMateriaAsseg = reqParam => {
  let data = {};
  data.p_R_VLO_COD_RAMOS = reqParam.ramos;
  data.p_R_VLO_NRO_POLIZA = reqParam.policyNo;
  data.p_R_VLO_NRO_UNIDAD = reqParam.itemNo;

  return axios
    .post(base_url + "getMateriaAsseg", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
};

const getCoaseguro = reqParam => {
  let data = {};

  data.policyNo = reqParam.policyNo;

  return axios
    .post(base_url + "getCoaseguro", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
};

export function DetaileDePolizaFun(reqParam) {
  console.log("Request Object inside DetaileDePolizaFun", reqParam);
  return new Promise(async (resolve, reject) => {
    Promise.all([
      await getProduction(reqParam),
      await getcobranza(reqParam),
      await getSiniestro(reqParam),
      await getreaseguro(reqParam),
      await getMateriaAsseg(reqParam),
      await getCoaseguro(reqParam)
    ])
      .then(response => {
        // TODO: Need to add validation condition

        var responseObjToReturn = {};

        // Production
        if (response && response.length > 0 && response[0].data) {
          let production = response[0].data.production;
          let productionTotal = response[0].data.productionTotal[0];
          productionTotal.total = true;
          production.push({});
          production.push(productionTotal);
          responseObjToReturn.production = production;
        } else {
          let production = [];
          responseObjToReturn.production = production;
        }

        // Cobranza
        if (response && response.length > 1 && response[1].data) {
          let cobranza = response[1].data.cobranza;
          let cobranzaTotal = response[1].data.cobranzaTotal[0];
          cobranzaTotal.total = true;
          cobranza.push({});
          cobranza.push(cobranzaTotal);
          responseObjToReturn.cobranza = cobranza;
        } else {
          let cobranza = [];
          responseObjToReturn.cobranza = cobranza;
        }

        // Siniestro
        if (response && response.length > 2 && response[2].data) {
          let siniestro =
            response[2].data.siniestro && response[2].data.siniestro.length > 0
              ? response[2].data.siniestro
              : [];
          let siniestroTotal =
            response[2].data.siniestroTotal &&
            response[2].data.siniestroTotal.length > 0
              ? response[2].data.siniestroTotal[0]
              : {};
          siniestroTotal.total = true;
          siniestro.push({});
          siniestro.push(siniestroTotal);
          responseObjToReturn.siniestro = siniestro;
        } else {
          let siniestro = [];
          responseObjToReturn.siniestro = siniestro;
        }

        // Reaseguro
        if (response && response.length > 3 && response[3].data) {
          let reaseguro = response[3].data.reaseguro;
          let reaseguroTotal = response[3].data.reaseguroTotal[0];
          reaseguroTotal.total = true;
          reaseguro.push({});
          reaseguro.push(reaseguroTotal);
          responseObjToReturn.reaseguro = reaseguro;
        } else {
          let reaseguro = [];
          responseObjToReturn.reaseguro = reaseguro;
        }

        // MateriaAsseg
        if (response && response.length > 4 && response[4].data) {
          let materiaAseg = response[4].data.materiaAseg;
          responseObjToReturn.materiaAseg = materiaAseg;
        } else {
          let materiaAseg = [];
          responseObjToReturn.materiaAseg = materiaAseg;
        }

        // Coaseguro
        if (response && response.length > 5 && response[5].data) {
          let coaseguro = response[5].data;
          responseObjToReturn.coaseguro = coaseguro;
        } else {
          let coaseguro = {};
          responseObjToReturn.coaseguro = coaseguro;
        }
        resolve(responseObjToReturn);
      })
      .catch(error => {
        console.log(error.message);
        reject(error);
      });
  });
}

export function getUnoHydNewDetailsExcel(head, data) {
  return axios
    .post(base_url + "por-ramos/getUnoHydNewDetails", data, config)
    .then(res => {
      var createXLSLFormatObj = [];

      /* XLS Head Columns */
      var xlsHeader = _.values(head);

      if (res.data && res.data.data && res.data.data.unoHydNewDetails) {
        let dataToDisplay = res.data.data.unoHydNewDetails;
        let modifiedData = dataToDisplay;
        modifiedData.push({});
        modifiedData.push(res.data.data.unoHydTotal[0]);

        var xlsRows = modifiedData;

        createXLSLFormatObj.push(xlsHeader);

        xlsRows.forEach(function(xlsRowsObj) {
          var innerRowData = [];
          _.forEach(xlsRowsObj, function(value, key) {
            innerRowData.push(xlsRowsObj[key]);
          });
          createXLSLFormatObj.push(innerRowData);
        });

        /* File Name */
        var filename = "Informacion_General_XLS.xlsx";

        /* Sheet Name */
        var ws_name = "Informacion_General";

        if (typeof console !== "undefined") console.log(new Date());
        var wb = XLSX.utils.book_new(),
          ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);

        /* Add worksheet to workbook */
        XLSX.utils.book_append_sheet(wb, ws, ws_name);

        /* Write workbook and Download */
        if (typeof console !== "undefined") console.log(new Date());
        XLSX.writeFile(wb, filename);
        if (typeof console !== "undefined") console.log(new Date());
      } else {
        alert("No data found to download");
      }
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
}

export function getDosHydNewDetailsExcel(head, data) {
  return axios
    .post(base_url + "por-ramos/getDosHydNewDetails", data, config)
    .then(res => {
      var createXLSLFormatObj = [];

      /* XLS Head Columns */
      var xlsHeader = _.values(head);

      if (res.data && res.data.data && res.data.data.dosHydNewDetails) {
        let dataToDisplay = res.data.data.dosHydNewDetails;
        let modifiedData = dataToDisplay;
        modifiedData.push({});
        modifiedData.push(res.data.data.dosHydTotal[0]);

        var xlsRows = modifiedData;

        createXLSLFormatObj.push(xlsHeader);

        xlsRows.forEach(function(xlsRowsObj) {
          var innerRowData = [];
          _.forEach(xlsRowsObj, function(value, key) {
            innerRowData.push(xlsRowsObj[key]);
          });
          createXLSLFormatObj.push(innerRowData);
        });

        /* File Name */
        var filename = "Informacion_General_XLS.xlsx";

        /* Sheet Name */
        var ws_name = "Informacion_General";

        if (typeof console !== "undefined") console.log(new Date());
        var wb = XLSX.utils.book_new(),
          ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);

        /* Add worksheet to workbook */
        XLSX.utils.book_append_sheet(wb, ws, ws_name);

        /* Write workbook and Download */
        if (typeof console !== "undefined") console.log(new Date());
        XLSX.writeFile(wb, filename);
        if (typeof console !== "undefined") console.log(new Date());
      } else {
        alert("No data found to download");
      }
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
}
