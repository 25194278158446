import React from 'react';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import DyanmicTableWithSorting from "../../../BaseComponents/DyanmicTableWithSorting/DyanmicTableWithSorting";
import {  api } from '../../api/reasuguro'
import _ from "lodash";
import { ToastContainer, toast } from "react-toastify";
import Loader  from '../../../BaseComponents/Loader/Loader'
import  { scrollToBottom } from  '../../../utils/scrollDown';
import { ColumnNMandato , columnPorCoburtura}  from './constants';
import { translate, Trans } from "react-i18next";
import Button from "@material-ui/core/Button";

class DetaileReaseguroItem extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            showLoader : false

        }
    }

    componentDidMount(){
        const { match: { params }  } = this.props;
        const {policyNo , itemNo , fechaConsulta , vigenciaDesde , vigenciaHasta } = params;
        const paramsValue = {
            policyNo  : parseInt(policyNo), 
            itemNo : parseInt(itemNo) , 
            fechaConsulta :  fechaConsulta, 
            vigenciaDesde : vigenciaDesde, 
            vigenciaHasta  : vigenciaHasta
        }
        this.setState({paramsValue :  paramsValue})

        let data =   {
            policyNo  : parseInt(policyNo), 
            itemNo : parseInt(itemNo) , 
            fechaConsulta :  parseInt(fechaConsulta) , 
            vigenciaDesde : parseInt(vigenciaDesde), 
            vigenciaHasta  : parseInt(vigenciaHasta)
        }

        this.getDetailreaseguroItem(data)
    }

    getDetailreaseguroItem = (data)=>{
        this.showLoaderAction();
        try {
            api.getScreen2(data)
            .then((res)=>{
                if(res && res.data && !_.isEmpty(res.data)) {
                    const  { details , obj1 , obj2 }  = res.data
                    this.setState({
                        details :  details ,
                        obj1 :  obj1,
                        obj2 : obj2,
                        showTable:  true
                    })

                }else {
                    toast.error(res.msg, {position: toast.POSITION.TOP_RIGHT});
                }
                this.showLoaderAction();
            })
            .catch((ex)=>{
                toast.error("Something went wrong.", {position: toast.POSITION.TOP_RIGHT});
                this.showLoaderAction()       
            })
        }catch(ex){
            toast.error("Something went wrong.", {position: toast.POSITION.TOP_RIGHT});
            this.showLoaderAction()
        }

    }

    showLoaderAction(){
        let showLoader = this.state.showLoader;
        this.setState({ showLoader: !showLoader });
    }   
    
    clickRow = (data)=>{
        this.getDataAPI(data)              
    }

    getDataAPI(data){
        let {paramsValue} = this.state;
        if(paramsValue) {
            paramsValue.nombreContrato = data.nombreContrato;
            this.state.nombreContrato = data.nombreContrato;
            this.getScreen3(paramsValue)
        }  
    }

    getScreen3(data){
        this.showLoaderAction() 
        try{
            api.getScreen3(data)
            .then((res)=>{
                if(res && res.data && !_.isEmpty(res.data)){
                    let {de} =  res.data ;
                    _.forEach(de,(d)=>{
                        d.parent = true
                        d.parentName="screen3"
                    })
                    this.setState({
                        showTable : false,
                        showScreen3 :  true,
                        screen3 :  de
                    })
                }else {
                    toast.error(res.msg, {position: toast.POSITION.TOP_RIGHT});
                }
                scrollToBottom();
                this.showLoaderAction(); 
            })
            .catch((ex)=>{
                toast.error("Something went wrong.", {position: toast.POSITION.TOP_RIGHT});
                this.showLoaderAction() 
            })

        }catch(ex){
            toast.error("Something went wrong.", {position: toast.POSITION.TOP_RIGHT});
            this.showLoaderAction() 
        }
    }

    sreen4Func = (clickData)=>{
        let {tipoDistribución,parentName , codigoReasegurador}  = clickData;
        let screenData =  this.state.screen3;
        let childCount = _.filter(screenData,{parentName : tipoDistribución}).length;

        if(childCount){
            if(parentName) {
                let length =  screenData.length;
                let hasChild = false;
                let parentNameDelete = new Set([]);
                for(let i=length-1;i >=0;i--){
                    if(screenData[i].parentName == tipoDistribución ){
                        hasChild = screenData[i].hasChild ? true: false;
                        if(screenData[i].tipoDistribución){
                            parentNameDelete.add(screenData[i].tipoDistribución)
                        } 
                        screenData.splice(i,1)
                    }
                }
                length = screenData.length;
                if(hasChild && parentNameDelete){
                    for(let i=length-1;i >=0;i--){
                        if(parentNameDelete.has(screenData[i].parentName)){
                            screenData.splice(i,1)
                        }
                    }
                }
            } 
            this.setState({
                screen3 : screenData
            })
        } else {
            if(tipoDistribución == "Facultativo"){
                this.getFacultativo(tipoDistribución);
            }else if(parentName == "Facultativo" && tipoDistribución ){
                this.getFacultativoChild(tipoDistribución , codigoReasegurador);
            }else if(tipoDistribución == "Coaseguro"){
                this.getCoaseguro(clickData)
            }else if(tipoDistribución == "Contrato"){
                this.getContrato(tipoDistribución)
            }else if(parentName == "Contrato" && tipoDistribución){
                this.getContratoChild(tipoDistribución , parentName)
            }
        }
    }

    getFacultativo(tipoDistribución){
        let paramsValue  =  this.state.paramsValue;
        paramsValue.tipoDistribución = tipoDistribución
        this.showLoaderAction(); 
        try {
            api.getScreen4(paramsValue)
            .then((res)=>{
                if(res && res.data && !_.isEmpty(res.data)){
                    let  {de} = res.data;
                    let screen3  = this.state.screen3
                    _.forEach( screen3,(data,index)=>{
                        if(data.tipoDistribución == "Facultativo" ){
                            _.forEach(de,(d)=>{
                                d.parent = true;
                                d.hasChild = true;
                                d.parentName = "Facultativo";
                                screen3.splice(index+1,0,d)
                            })
                        }
                    });
                    this.setState({screen3 :  screen3})
                    scrollToBottom();
                }else {
                    toast.error(res.msg, {position: toast.POSITION.TOP_RIGHT}); 
                }
                this.showLoaderAction(); 

            }).catch((ex)=>{
                toast.error("Something went wrong.", {position: toast.POSITION.TOP_RIGHT});
                this.showLoaderAction(); 
            })

        }catch(ex){
            toast.error("Something went wrong.", {position: toast.POSITION.TOP_RIGHT});
            this.showLoaderAction(); 
        }
    }

    getFacultativoChild(tipoDistribución , codigoReasegurador){
        let {paramsValue} =  this.state;
        paramsValue.codigoReasegurador = codigoReasegurador;
        this.showLoaderAction(); 
        try {
            api.getScreen4a(paramsValue)
            .then((res)=>{
                if(res && res.data && !_.isEmpty(res.data)){
                    let  {de} = res.data;
                    _.forEach(this.state.screen3,(data,index)=>{
                        if(data.tipoDistribución == tipoDistribución ){
                            _.forEach(de,(d)=>{
                                d.parent = false;
                                d.hasChild = false;
                                d.parentName  = tipoDistribución
                                this.state.screen3.splice(index+1,0,d)
                            })
                        }
                    });
                    this.setState({screen3  : this.state.screen3})
                    scrollToBottom();
                }else {
                    toast.error(res.msg, {position: toast.POSITION.TOP_RIGHT}); 
                }
                this.showLoaderAction(); 
            }).catch((ex)=>{
                toast.error("Something went wrong.", {position: toast.POSITION.TOP_RIGHT});
                this.showLoaderAction(); 
            })
        }catch(ex){
            toast.error("Something went wrong.", {position: toast.POSITION.TOP_RIGHT});
            this.showLoaderAction(); 
        }
    }
    getCoaseguro(clickData){
        let  {porcentaje,montoAsegurado,prima,descuento} = clickData;
        montoAsegurado = montoAsegurado.replace(/,/g, "");
        let {policyNo} = this.state.paramsValue;
        let data = {
            policyNo,porcentaje,montoAsegurado,prima,descuento
        }
        this.showLoaderAction();
        try {
            api.getScreen5(data)
            .then((res)=>{
                if(res && res.data && !_.isEmpty(res.data)){
                    let  { de} = res.data;
                    let screen3 =  this.state.screen3
                    _.forEach(screen3,(data,index)=>{
                        if(data.tipoDistribución == "Coaseguro" ){
                            _.forEach(de,(d)=>{
                                d.parent = false;
                                d.hasChild = false;
                                d.parentName = "Coaseguro";
                                screen3.splice(index+1,0,d)
                            })
                        }
                    });
                    this.setState({
                        screen3  : screen3,
                        coaseguro : de
                    })
                    this.showLoaderAction();
                    scrollToBottom();
                }else {
                    toast.error(res.msg, {position: toast.POSITION.TOP_RIGHT}); 
                    this.showLoaderAction();
                }
            }).catch((ex)=>{
                toast.error("Something went wrong.", {position: toast.POSITION.TOP_RIGHT});
                this.showLoaderAction();
            })

        }catch(ex){
            toast.error("Something went wrong.", {position: toast.POSITION.TOP_RIGHT});
            this.showLoaderAction();
        }
    }

    getContrato(tipoDistribución){
        let paramsValue  =  this.state.paramsValue;
        paramsValue.tipoDistribución = tipoDistribución
        this.showLoaderAction(); 
        try {
            api.getScreen4b(paramsValue)
            .then((res)=>{
                if(res && res.data && !_.isEmpty(res.data)){
                    let  {de} = res.data;
                    let screen3  = this.state.screen3
                    _.forEach( screen3,(data,index)=>{
                        if(data.tipoDistribución == "Contrato"  ){
                            _.forEach(de,(d)=>{
                                if(!d.claseChild){
                                    d.parent = true;
                                    d.hasChild = true;
                                    d.parentName = "Contrato";
                                    screen3.splice(index+1,0,d)
                                }
                            })
                        }
                    });
                    this.setState({screen3 :  screen3})
                    scrollToBottom();
                }else {
                    toast.error(res.msg, {position: toast.POSITION.TOP_RIGHT}); 
                }
                this.showLoaderAction(); 

            }).catch((ex)=>{
                toast.error("Something went wrong.", {position: toast.POSITION.TOP_RIGHT});
                this.showLoaderAction(); 
            })

        }catch(ex){
            toast.error("Something went wrong.", {position: toast.POSITION.TOP_RIGHT});
            this.showLoaderAction(); 
        }
    }

    getContratoChild(tipoDistribución , parentName){
        let paramsValue  =  this.state.paramsValue;
        paramsValue.tipoDistribución = parentName
        this.showLoaderAction(); 
        try {
            api.getScreen4b(paramsValue)
            .then((res)=>{
                if(res && res.data && !_.isEmpty(res.data)){
                    let  {de} = res.data;
                    _.forEach(this.state.screen3,(data,index)=>{
                        if(data.tipoDistribución == tipoDistribución ){
                            _.forEach(de,(d)=>{
                                if(d.claseChild ==  tipoDistribución){
                                    d.parent = false;
                                    d.hasChild = false;
                                    d.parentName  = tipoDistribución
                                    this.state.screen3.splice(index+1,0,d)
                                }                                                               
                            })
                        }
                    });
                    this.setState({screen3  : this.state.screen3})
                    scrollToBottom();
                }else {
                    toast.error(res.msg, {position: toast.POSITION.TOP_RIGHT}); 
                }
                this.showLoaderAction(); 

            }).catch((ex)=>{
                toast.error("Something went wrong.", {position: toast.POSITION.TOP_RIGHT});
                this.showLoaderAction(); 
            })

        }catch(ex){
            toast.error("Something went wrong.", {position: toast.POSITION.TOP_RIGHT});
            this.showLoaderAction(); 
        }
    }

    goMainPage=()=>{
        const {policyNo , itemNo , fechaConsulta  } = this.state.paramsValue;
        this.props.history.push(`/reaseguradores/consulta-reaseguro/${policyNo}/${itemNo}/${fechaConsulta}`)
    }

    goDeatailePage=()=>{
        this.setState({
            showScreen3 :  undefined,
            showTable :  true
        })
    }

    render(){

        const { i18n } = this.props;
        // let translateObj =     (i18n.store.data[i18n.language] && i18n.store.data[i18n.language].translations) ||  i18n.store.data["es"].translations;
        let {obj1 , obj2} = this.state
        
        return(
            <main className="main-container">
                {this.state.showLoader ? <Loader /> : null }              
                {this.state && this.state.obj1 ?                
                <div>
                <Card className="Card-area box-show-none">
                    <fieldset className="custom-border">
                    <legend className="legend-box">Datos Generales Ítem</legend>
                    <div className="vertical-align-middle">
                        <span className="Space-around">
                            <TextField InputLabelProps={{ shrink: true}}
                                id="standard-bare"
                                label="N° Póliza"
                                className="textField"
                                value={obj1.póliza}
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                                style = {{width: 250,height: 40}}
                            />
                        </span>
                        <span className="Space-around">
                            <TextField InputLabelProps={{   shrink: true }}
                                id="standard-bare"
                                label="N° Ítem: "
                                className="textField"
                                value={obj1.item}
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                                style = {{width: 150,height: 40}}
                            />
                        </span>
                        <span className="Space-around">
                            <TextField InputLabelProps={{ shrink: true}}
                                id="standard-bare"
                                label="Vigencia Desde"
                                className="textField"
                                value={obj1.vigenciaDesde}
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                                style = {{width: 250,height: 40}}
                            />
                        </span>
                        <span className="Space-around">
                            <TextField InputLabelProps={{   shrink: true }}
                                id="standard-bare"
                                label="Vigencia Hasta"
                                className="textField"
                                value={obj1.vigenciaHasta}
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                                style = {{width: 250,height: 40}}
                            />
                        </span>
                    </div>
                    <div className="vertical-align-middle">
                        <span className="Space-around">
                            <TextField InputLabelProps={{ shrink: true}}
                                id="standard-bare"
                                label="Ramo"
                                className="textField"
                                value={obj1.ramo}
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                                style = {{width: 470,height: 40}}
                            />
                        </span>
                        <span className="Space-around">
                            <TextField InputLabelProps={{   shrink: true }}
                                id="standard-bare"
                                label="Moneda"
                                className="textField"
                                value={obj1.moneda}
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                                style = {{width: 470,height: 40}}
                            />
                        </span>
                    </div>
                    <div className="vertical-align-middle">
                        <span className="Space-around">
                            <TextField InputLabelProps={{ shrink: true}}
                                id="standard-bare"
                                label="Nombre Contratante"
                                className="textField"
                                value={obj1.nombreContratante}
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                                style = {{width: 960,height: 40}}
                            />
                        </span>
                    </div>
                    <div className="vertical-align-middle">
                        <span className="Space-around">
                            <TextField InputLabelProps={{ shrink: true}}
                                id="standard-bare"
                                label="Nombre Asegurado"
                                className="textField"
                                value={obj1.nombreAsegurado}
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                                style = {{width: 960,height: 40}}
                            />
                        </span>
                    </div>
                    <div className="vertical-align-middle">
                        <span className="Space-around">
                            <TextField InputLabelProps={{ shrink: true}}
                                id="standard-bare"
                                label="Uso Destino "
                                className="textField"
                                value={obj1.usoDestinos}
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                                style = {{width: 470,height: 40}}
                            />
                        </span>
                        <span className="Space-around">
                            <TextField InputLabelProps={{   shrink: true }}
                                id="standard-bare"
                                label=""
                                className="textField"
                                value={obj1.p_usoDestinos}
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                                style = {{width: 470,height: 40}}
                            />
                        </span>
                    </div>
                    <div className="vertical-align-middle">
                        <span className="Space-around">
                            <TextField InputLabelProps={{ shrink: true}}
                                id="standard-bare"
                                label="Tipo de Construcción"
                                className="textField"
                                value={obj1.tipoConstrucción}
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                                style = {{width: 470,height: 40}}
                            />
                        </span>
                        <span className="Space-around">
                            <TextField InputLabelProps={{   shrink: true }}
                                id="standard-bare"
                                label=""
                                className="textField"
                                value={obj1.p_tipoConstrucción}
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                                style = {{width: 470,height: 40}}
                            />
                        </span>
                    </div>
                    { this.state.nombreContrato ?
                    <div className="vertical-align-middle">
                        <span className="Space-around">
                            <TextField InputLabelProps={{ shrink: true}}
                                id="standard-bare"
                                label="Nombre contrato"
                                className="textField"
                                value={this.state.nombreContrato}
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                                style = {{width: 960,height: 50}}
                            />
                        </span>
                    </div>
                    : null }
                    <div className="vertical-align-middle">
                        <span className="Space-around">
                            <TextField InputLabelProps={{ shrink: true}}
                                id="standard-bare"
                                label="Forzado"
                                className="textField"
                                value={obj1.forzados}
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                                style = {{width: 960,height: 50}}
                            />
                        </span>
                    </div>
                </fieldset>
                </Card>
                 { this.state.showTable ?
                    <Card className="Card-area box-show-none margin-top-40px">
                        <fieldset className="custom-border">
                           {
                                obj2 ? <legend className="legend-box">Detalle Reaseguro Ítem  vigente entre {obj2.vigenteDesde} y  {obj2.vigenteHasta}  </legend> : null 
                            }
                        
                        <DyanmicTableWithSorting 
                            data={this.state.details}
                            keyArray={ColumnNMandato}
                            orderBy={ColumnNMandato[0]}
                            pathData={{}}
                            virticalScroll={true}
                            tableMaxHeight={"400"}
                            isEnhancedTableHeadReq={false}
                            virticalScroll={true}
                            tableMaxHeight={"400"}  
                            passClick={this.clickRow}                
                        />
                        </fieldset>
                    </Card>
                    : null 
                }
            </div> 
             :null 
             }
            {
                this.state.showScreen3 ?
                <div>
                    <Card className="Card-area box-show-none margin-top-40px">
                    <fieldset className="custom-border">
                        <legend className="legend-box">Detalle Reaseguro Ítem  vigente entre {obj2.vigenteDesde} y  {obj2.vigenteHasta}  </legend>
                        <DyanmicTableWithSorting 
                            data={this.state.screen3}
                            keyArray={columnPorCoburtura}
                            orderBy={columnPorCoburtura[0]}
                            pathData={{}}
                            virticalScroll={true}
                            tableMaxHeight={"400"}
                            isEnhancedTableHeadReq={false}
                            virticalScroll={true}
                            tableMaxHeight={"400"}  
                            passClick={this.sreen4Func}                     
                        />
                        </fieldset>
                    </Card>
                </div>
                : null
            }
             <>
                {!this.state.showScreen3 ? 
                <div className="right-align">
                <Button  
                        variant="contained"
                        size="medium"
                        color="primary"
                        className="search-policy button-margin"
                        onClick={this.goMainPage}
                    >
                        Volver
                    </Button>
                </div>  
                 : 
                 <div className="right-align">
                 <Button  
                         variant="contained"
                         size="medium"
                         color="primary"
                         className="search-policy button-margin"
                         onClick={this.goDeatailePage}
                     >
                         Volver
                     </Button>
                 </div> 
                }
               </>
            <ToastContainer />
            </main>
        )
    }
}

export default DetaileReaseguroItem;

