export const  ColumnNMandato = {
    "nombreContrato" : "Nombre Contrato" ,
    "montoAsegurado" :"Monto Asegurado" ,
    "prima" : "Prima" 
}

export const  columnPorCoburtura = {
    "tipoDistribución" :"Tipo Distribución" ,
    "porcentaje" : "Porcentaje(%)" ,
    "montoAsegurado"  : "Monto Asegurado" ,
    "prima" : "Prima" ,
    "descuento":  "Descuento(%)"  
}