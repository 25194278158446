export const columnName  = {
    "nticket":"Nº Ticket",
    "mo":"MO", 
    "valorTipoCambio":"Valor tipo Cambio", 
    "cantidaddemovimientos":"Cantidad de movimientos", 
    "totalMontoPagarMonedaOrigen":"Total Pagado MO", 
    "totalDescuentoMonedaOrigen":"Total DPP MO", 
    "totalMontoPagarMOMP":"Total en MO-MP" ,
    "TotalInterésmoraMP":"Total Interés mora MP", 
    "totalGastoCobranzaMonedaPago":"Total Gastos de cobranza MP", 
    "totalInteresCreditosMP":"Total interés créditos MP", 
    "TotalapagarMP":"Total a pagar MP", 
    "totalDiferenciaCambioMonedaPago":"Total diferencia de cambio MO"  
  };

export const columnNameTwo = {
    "formadepago":"Forma de pago", 
    "banco":"Banco", 
    "nctacteNtarjeta":"Nº cta cte / Nº tarjeta", 
    "nchequeNcartolaNloteNvalevista":"Nº cheque / Nº cartola / Nº lote / Nº vale vista", 
    "fecha":"Fecha" ,
    "nDepósitoCódigodeautorización":"Nº Depósito / Código de autorización" ,
    "rutNoperación":"RUT / Nº operación" ,
    "nombretitularformadepago":"Nombre titular forma de pago" ,
    "teléfonocontacto ":"Teléfono contacto ",
    "totalpagado":"Total pagado" 
  };
    

export const consultaDeTicket = {
    "nroTicket" : "Nº Ticket", 
    "tipo" :"Tipo" ,
    "rutPagador" :"RUT Pagador", 
    "nombrePagador" : "Nombre Pagador", 
    "mp":"MP", 
    "montoMp" :"Monto MP" ,
    "usuario":"Usuario" ,
    "fechaTicket" :"Fecha Ticket" ,
    "estado":"Estado" ,
    "nroComprobante" :"Nº Comprobante" 
}