import { config, base_url, axiousInstance } from './index';
let axios=axiousInstance;


  
export function getCoverageByPolicyItemNo(data){
 /* https://lrigoocw6l.execute-api.us-east-1.amazonaws.com/dev/getCoverageDetail  */

  //url : "https://lrigoocw6l.execute-api.us-east-1.amazonaws.com/dev/getCoverageDetail"
  
  return axios.post(base_url+"getCoverageDetail",data,config)
    .then(res => {
      return res.data;
    })
    .catch((error) => {
        console.log('error ' + error);
        return error;
     });

}


export function servicioAsistenciaDetail(policyNo , itemNo){
 
   //url : "https://lrigoocw6l.execute-api.us-east-1.amazonaws.com/dev/servicioAsistenciaDetail"
   const  data  =  {
    policyNo :policyNo , 
    itemNo :   itemNo
   }
   
   return axios.post(base_url+"servicioAsistenciaDetail",data,config)
     .then(res => {
       return res.data;
     })
     .catch((error) => {
         console.log('error ' + error);
         return error;
      });
 
 }
