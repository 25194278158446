import React from "react";
import DyanmicTableWithSorting from "../../../BaseComponents/DyanmicTableWithSortingServerSidePagination/DyanmicTableWithSorting";
import EmptyState from "../../../BaseComponents/EmptyState/EmptyState";
import { translate, Trans } from "react-i18next";
import { getCheckList } from "../../api/CajaPagadora";
import Loader from "../../../BaseComponents/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import _ from "lodash";
import CheckListKeys from "./CheckListKeys.json";

class CheckList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      CheckListData: undefined,
      emptyState: false,
      isPaginationReq: true,
      isEnhancedTableHeadReq: true
    };
  }

  componentDidMount() {
    this.setState({
      CheckListData:this.props.CheckListData,
      emptyState:this.props.emptyState,
      totalElement:this.props.totalElement,
      pageNo:this.props.pageNo
    })
  }
  componentWillReceiveProps=(nextProps)=>{
    this.setState({
      CheckListData: nextProps.CheckListData,
      emptyState: nextProps.emptyState,
      totalElement:nextProps.totalElement,
      pageNo:nextProps.pageNo
    })
  }
  fetchNextData=(pageNo)=>{
    this.props.fetchNextData(pageNo);
  }
  render() {
    const data = {
      route: "/caja-pagadora/consulta-de-cheques/check-details/",
      key: "numero"
    };
    let showLoader = this.state.showLoader;
    return (
      <div >
        {this.state.CheckListData && !this.state.emptyState ? (
          <div className="margin-top-30px">
            <DyanmicTableWithSorting
              data={this.state.CheckListData}
              keyArray={CheckListKeys}
              totalCount={this.state.totalElement}
              orderBy={CheckListKeys[0]}
              pathData={data}
              reqObj={this.state.reqObj}
              isPaginationReq={this.state.isPaginationReq}
              isEnhancedTableHeadReq={this.state.isEnhancedTableHeadReq}
              defaultRowPerPage={this.state.defaultRowPerPage||100}
              virticalScroll={true}
              tableMaxHeight={"400"}
              fetchNextData={this.fetchNextData}
              pageNo={this.state.pageNo}
            />
          </div>
        ) : (
          <div className={"content"}>
            <EmptyState
              emoticon={"🔍"}
              message={"Registros Encontrados no encontrada"}
            />
          </div>
        )}
      </div>
    );
  }
}

export default translate("translations")(CheckList);
