import React from 'react';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';

export default class Primas extends React.Component  {
  constructor(props) {
    super(props);
    this.state =  {
    }
  }
  componentDidMount() {
    this.setState({ data : this.props.data }); 
  }

  componentWillReceiveProps() {
    this.setState({ data : this.props.data }); 
  }

  render() {
    let dataObj=this.state.data;
    return (
      <div className="margin-top-30">
        {dataObj?
             <Card className="Card-area box-show-none">
             <fieldset className="custom-border">
               <legend className="legend-box">Primas</legend>
                {/* <h3 className="Heading-name">Primas</h3> */}
                <div>
                    <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="Afecto Neto"
                        className="textField"
                        value={dataObj.afectoNeto}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                        readOnly: true,
                        }}
                    />
                    </span>
                    <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="Exento Neto"
                        className="textField"
                        value={dataObj.exentoNeto}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                        readOnly: true,
                        }}
                    />
                    </span>
                    <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="Iva"
                        className="textField"
                        margin="normal"
                        value={dataObj.iva}
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                          }}
                    />
                    </span>
                    <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="Bruto"
                        className="textField"
                        margin="normal"
                        value={dataObj.bruto}
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                          }}
                    />
                    </span>
                </div>
                </fieldset>
            </Card>
        :null}
      </div>
    )
  }
}