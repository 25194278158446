import React from "react";
import DyanmicTableWithSorting from "../../../BaseComponents/DyanmicTableWithSorting/DyanmicTableWithSorting";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import TextField from "@material-ui/core/TextField";
import { translate, Trans } from "react-i18next";
import Button from "@material-ui/core/Button";
import { getCarPolicies } from "./../../api/policy";
import Loader from "../../../BaseComponents/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import { css } from "glamor";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import './PatenteMotorComponent.scss'

const styles = theme => ({
    root: {
      display: "flex"
    },
    formControl: {
      margin: theme.spacing.unit * 3
    },
    group: {
      margin: `$({theme.spacing.unit})px 0`
    },
    textField: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit
    }
  });

class PatenteMotorComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        Patente : false,
        Motor :  false,
        inputValue  : "",
        enableSearch : true,
        carPolicies : undefined,
        showLoader: false,
        isPaginationReq: true,
        isEnhancedTableHeadReq: true,
        defaultRowPerPage: 5
    };
   

    this.showCarPolicies  = this.showCarPolicies.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.resetSearch = this.resetSearch.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.enableSearchButton = this.enableSearchButton.bind(this);

  }
  
  componentDidMount() {
    
  }

  showLoaderAction() {
    let showLoader = this.state.showLoader;
    this.setState({ showLoader: !showLoader });
  }

  
  handleChangeRadioButton = event =>{
    let value = event.target.value;

    if(!_.isEmpty(value)) {
        let Patente = value == "Patente" ? true : false
        let Motor  = value == "Motor" ? true : false
        this.setState({'Motor' : Motor , 'Patente' :  Patente })
    }    
  }

  showCarPolicies(){
      this.showLoaderAction();
      let comp = this.state.Patente == true ? 1 : 2 ;
      let inputValue =  this.state.inputValue;
      this.setState({carPolicies : undefined})
      getCarPolicies(inputValue,comp ).then((res)=>{
          if(res.data && !_.isEmpty(res.data)) {
              this.setState({carPolicies : res.data.carPolicies})
          } else {
            toast.error(res.msg || "No car policies found.", { position: toast.POSITION.TOP_RIGHT  });
              this.setState({ carPolicies: undefined});
          }
          this.showLoaderAction();
      }).catch((ex)=>{
        toast.error("Something went wrong.", { position: toast.POSITION.TOP_RIGHT  });
    })
  }

  handleChange(event) {
    const value = event.target.value;
    let blnValue = value.length > 0;
    this.setState({ inputValue: value ,enableSearch: blnValue });
    this.enableSearchButton();
  }

  handleKeyPress(event) {
    if (event.key === "Enter") {
      this.showCarPolicies();
    }
  }

  resetSearch() {
    this.setState({
      carPolicies: undefined,
      inputValue: "",
      Patente: false,
      Motor: false,
      enableSearch : false
    });
  }

  enableSearchButton() {
    let enableSearch = _.isEmpty(this.state.inputValue) ? false : true ;
    let radioSelected = this.state.Patente || this.state.Motor;
    return !(enableSearch && radioSelected);
  }
  
 
  render() {
    
    const { i18n } = this.props;
    const { classes } = this.props;
    let translateObj =     (i18n.store.data[i18n.language] && i18n.store.data[i18n.language].translations) ||  i18n.store.data["es"].translations;
    let keys = {"Poliza": "Poliza", "Endoso": "Endoso", "Item": "Item", "Rut": "Rut","Nombre": "Nombre","patente":"Patente", "Motor": "Motor", "V.Desde": "V.Desde", "V.Hasta": "V.Hasta"}
    const data = {
        route: "/items-list/",
        keys: ["Poliza", "Item"]
    };

    return (
      <main className="main-container">
        {this.state.showLoader ? <Loader /> : null}

      <div>
      <FormControl component="fieldset" className={classes.formControl}>
            <RadioGroup
              aria-label="gender"
              name="Patento-motor"
              className={classes.group}
              onChange={this.handleChangeRadioButton}
              row={true}
            >
             <fieldset className="custom-border remove-border">
                <legend className="legend-box mb-0">Buscar Por</legend>
              <FormControlLabel
                value="Patente"
                control={<Radio color="primary"  checked={this.state.Patente}/>}
                label="Patente"
              />
              <FormControlLabel
                value="Motor"
                control={<Radio color="primary" checked={this.state.Motor} />}
                label="Motor"
              />
              </fieldset>
              <fieldset className="custom-border remove-border">
                <legend className="legend-box mb-0">Buscar Como</legend>
              <FormControlLabel
                value="Igualdad"
                control={<Radio color="primary" checked="true" />}
                label="Igualdad"
              />
              </fieldset>
            </RadioGroup>
          </FormControl>
          <TextField
              id="standard-search"
              label="Patente o Motor"
              margin="normal"
              className="text-field text-margin-potento"
              value={this.state.inputValue}
              onChange={this.handleChange}
              onKeyPress={this.handleKeyPress}

            />
            <Button
                variant="contained"
                size="medium"
                color="primary"
                onClick={this.showCarPolicies}
                disabled={this.enableSearchButton()}
                className="search-policy button-motor"
            >
                {translateObj.policy_page.search_btn}
            </Button>
            <Button
                variant="contained"
                size="medium"
                color="primary"
                onClick={this.resetSearch}
                className="search-policy button-motor"
            >
                {translateObj.policy_page.reset_btn}
            </Button>
      </div>

      { this.state.carPolicies ? 
        <DyanmicTableWithSorting
        data={this.state.carPolicies}
        keyArray={keys}
        orderBy={keys[0]}
        pathData={data}
        isPaginationReq={this.state.isPaginationReq}
        isEnhancedTableHeadReq={this.state.isEnhancedTableHeadReq}
        defaultRowPerPage={this.state.defaultRowPerPage}
        /> : null}
        
        <ToastContainer />
      </main>
    );
  }
}


export default translate("translations")(withStyles(styles)(PatenteMotorComponent));


