import React from 'react'
import { translate, Trans } from "react-i18next";
import { ToastContainer, toast } from 'react-toastify';
import _ from 'lodash';
import DyanmicTableWithSorting from "../../../BaseComponents/DyanmicTableWithSorting/DyanmicTableWithSorting";
import Loader from "../../../BaseComponents/Loader/Loader";
import { getRamosVariosPerdidaProbable } from "./../../api/ramoVarios";
import {PerdidakeyJson} from "./../../../Constants/ramosVarios";
import Button from "@material-ui/core/Button";

class PerdidaProbable extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      showLoader : false,
      siniestroText:"",
      policy:"",
      item:"",
      resultData:undefined,
    };
  }

  componentDidMount(){
    const { match: { params } , location : {search} } = this.props;
    let siniestro=params.siniestro;
    let policy=params.policy;
    let item=params.item;
    this.setState({
        siniestro,
        policy,
        item,
        search
    })
    this.showLoaderAction();
    getRamosVariosPerdidaProbable(siniestro, policy, item).then(response=>{
        this.showLoaderAction();
        if(response && response.data && Object.keys(response.data) && response.data.perProSinie && response.data.perProSinie.length){
            this.setState({
              resultData:response.data.perProSinie,
                showData:true
            })
        }else{
            toast.error(response.msg, {position: toast.POSITION.TOP_RIGHT});
            this.setState({
              resultData:undefined,
                showData:false
            })
        }
      })
  }
  showLoaderAction(){
      let showLoader = this.state.showLoader;
      this.setState({showLoader: !showLoader})
  }
  VolverClick=()=>{
    let  {siniestro,  policy, item , search} = this.state;
    this.props.history.push(`/ramos/consultasiniestros/${siniestro}/${policy}/${item}${search}`)
  }

  render() {
      let showLoader=this.state.showLoader;
      return (
      <main className="main-container"> 
      <div>
        <div className="volver-style">
          <p className="left-block">Historial Pérdida Probable </p>        
          <Button variant="contained" size="medium" onClick={this.VolverClick} color="primary" className="search-policy float-right-button">Volver</Button>
        </div>
        <div>
        {showLoader ? <Loader /> : null}   
        {
           this.state.resultData?
            <DyanmicTableWithSorting
                data={this.state.resultData}
                keyArray={PerdidakeyJson}
                virticalScroll={true}
                tableMaxHeight={"400"}
            />:null
       }
       </div>
      </div>
        <ToastContainer />    
      </main>
   
    );
  }

}

export default  translate("translations")(PerdidaProbable);