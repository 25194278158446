import React from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { translate, Trans } from "react-i18next";
import Button from "@material-ui/core/Button";
import Loader from "../../../BaseComponents/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import DyanmicTableWithSorting from "../../../BaseComponents/DyanmicTableWithSorting/DyanmicTableWithSorting";
import _ from 'lodash';
import { NoOptionsMessage, inputComponent, Control, Option, Placeholder, SingleValue, ValueContainer, MultiValue, Menu } from '../../../Constants/dailog'
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import Select from 'react-select';
import ExcelDownload from '../../../utils/ExcelDownloadComponent'
import moment from "moment";
import 'moment/min/locales';
import SearchIcon from "@material-ui/icons/CloudDownload";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  TimePicker,
  DatePicker
} from "material-ui-pickers";
import Grid from '@material-ui/core/Grid';
import './CajaPenta.scss'
import { api } from '../../api/caja-penta'
import { filterosHistorial } from '../../../Constants/cajaPanta';
import { scrollToBottom } from '../../../utils/scrollDown'
import esLocale from "date-fns/locale/es";
import enLocale from "date-fns/locale/en-US";

const localeMap = {
  es: esLocale,
  en: enLocale
};


const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 350,
  },
  input: {
    display: 'flex',
    padding: 0,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
  grid: {
    width: "70%"
  }
});



const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};


class FilterosHistorial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTable: false,
      showLoader: false,
      isPaginationReq: true,
      isEnhancedTableHeadReq: true,
      defaultRowPerPage: 5,
      expandSelectWidth: false,
      dropDownData: {},
      origenArchivo: undefined,
      tipoArchivo: undefined,
      estado: undefined,
      usuarios: undefined,
      nRegistros: undefined,
      startDate: null,
      endDate: null
    };


    this.resetSearch = this.resetSearch.bind(this);
    this.disableSearchBtn = this.disableSearchBtn.bind(this);
    this.showTableData = this.showTableData.bind(this);

  }

  componentDidMount() {
    this.showLoaderAction()
    try {
      api.getDropDownDetails()
        .then((res) => {
          if (res && res.data && !_.isEmpty(res.data)) {
            let { origenArchivo, tipoArchivo, estado, usuarios, archivos } = res.data;
            this.setState({
              origenArchivoData: origenArchivo || [],
              tipoArchivoData: tipoArchivo|| [],
              estadoData: estado|| [],
              usuariosData: usuarios|| [],
              showForm: true,
            })

            const { match: { params } , location : {search}     } = this.props;
            let query = ""
            let keyValue = {}
            if(search){
                query =  search.slice(1,search.length).split("&")
                _.forEach(query,(q)=>{
                    let splitString =  q.split("=")
                    if(splitString){
                      let key = splitString[0];
                      let value = splitString[1];
                      keyValue[key] = value 
                    }                       
                })
            }    
            let keys = _.keys(keyValue)
            _.forEach(keys, (key)=>{
              if(key == "origenArchivo") {
                this.setState({origenArchivo : {value : keyValue.origenArchivo , label : keyValue.origenArchivo  }})
              }else if(key == "tipoArchivo") {
                this.setState({tipoArchivo : {value : keyValue.tipoArchivo , label : keyValue.tipoArchivo  }})
              }else if(key == "estado") {
                let label = this.getLabel(estado , keyValue.estado , "id", "descripcion")
                this.setState({estado : {value : keyValue.estado , label : label  }})
              }else if(key == "usuarios") {
                let label =  this.getLabel(usuarios , keyValue.usuarios , "rutUsuario" , "nombreUsuario") ;
                this.setState({usuarios : {value : keyValue.usuarios , label :  label}})
              }else if(key == "startDate") {
                let startDate =  moment(keyValue.startDate).format("YYYY/MM/DD")
                this.setState({startDate : new Date(startDate)})
              }else if(key == "endDate") {
                let endDate =  moment(keyValue.endDate).format("YYYY/MM/DD")
                this.setState({endDate : endDate})
              }
            })

          } else {
            toast.error(res.msg, { position: toast.POSITION.TOP_RIGHT });
          }
          this.showLoaderAction();
        })
        .catch((ex) => {
          toast.error("Something went wrong.", { position: toast.POSITION.TOP_RIGHT });
          this.showLoaderAction();
        })

    } catch (ex) {
      toast.error("Something went wrong.", { position: toast.POSITION.TOP_RIGHT });
      this.showLoaderAction();
    }
  }

  getLabel(data,matchvalue, checkFieldValue , returnFieldValue){
    let label;
    _.forEach(data, (d)=>{
      if(d[checkFieldValue] == matchvalue) {
        label =  d[returnFieldValue];
      }
    })
    return label;
  }

  handleChangeEndDate = (chageDate, event) => {
    this.setState({
      endDate: chageDate
    });
  };

  handleChangeStartDate = (chageDate, event) => {
    this.setState({
      startDate: chageDate
    });
  };

  showLoaderAction() {
    let showLoader = this.state.showLoader;
    this.setState({ showLoader: !showLoader });
  }

  handleSuggestionsChange = name => value => {
    let expandSelectWidth = (value && value.label.length > 18) ? true : false;
    this.setState({ [name]: value, expandSelectWidth: expandSelectWidth });
  };


  customFilterOrigenArchivo = (text) => {
    let filterSuggestion = this.state.origenArchivoData.filter((element) => {
      return (element.descripcion.toString().indexOf(text) > -1 || element.descripcion.toLocaleLowerCase().indexOf(text.toLocaleLowerCase()) > -1)
    }).slice(0, 200);
    this.setState({ origenArchivoList: filterSuggestion });
  }

  customFilterusuarios = (text) => {
    let filterSuggestion = this.state.usuariosData.filter((element) => {
      return (element.nombreUsuario.toString().indexOf(text) > -1 || element.nombreUsuario.toLocaleLowerCase().indexOf(text.toLocaleLowerCase()) > -1)
    }).slice(0, 200);
    this.setState({ usuariosList: filterSuggestion });
  }

  customFilterTipoArchivo = (text) => {
    let filterSuggestion = this.state.tipoArchivoData.filter((element) => {
      return (element.descripcion.toString().indexOf(text) > -1 || element.descripcion.toLocaleLowerCase().indexOf(text.toLocaleLowerCase()) > -1)
    }).slice(0, 200);
    this.setState({ tipoArchivoList: filterSuggestion });
  }

  customFilterEstado = (text) => {
    let filterSuggestion = this.state.estadoData.filter((element) => {
      return (element.descripcion.toString().indexOf(text) > -1 || element.descripcion.toLocaleLowerCase().indexOf(text.toLocaleLowerCase()) > -1)
    }).slice(0, 200);
    this.setState({ estadoList: filterSuggestion });
  }

  customFilterSuggestions = (text) => {
    let filterSuggestion = this.state.origenArchivoData.filter((element) => {
      return (element.descripcion.toString().indexOf(text) > -1 || element.descripcion.toLocaleLowerCase().indexOf(text.toLocaleLowerCase()) > -1)
    }).slice(0, 200);
    this.setState({ origenArchivoList: filterSuggestion });
  }
  handleDateChange = name => value => {
    let expandSelectWidth = (value && value.label.length > 18) ? true : false;
    this.setState({ [name]: value, expandSelectWidth: expandSelectWidth });
  };

  handleOrigenArchivo = name => value => {
    let expandSelectWidth = (value && value.label.length > 18) ? true : false;
    this.setState({ [name]: value, expandSelectWidth: expandSelectWidth, usuarios: {} });
  };

  customFilterDate = (text) => {
    let filterSuggestion = this.state.dateRange.filter((element) => {
      return (element.fechaProceso.toString().indexOf(text) > -1 || element.fechaProceso.toLocaleLowerCase().indexOf(text.toLocaleLowerCase()) > -1)
    }).slice(0, 200);
    this.setState({ dateRangeList: filterSuggestion });
  }

  customnRegistros = (text) => {
    let filterSuggestion = this.state.nRegistrosData.filter((element) => {
      return (element.nRegistros.toString().indexOf(text) > -1 || element.nRegistros.toLocaleLowerCase().indexOf(text.toLocaleLowerCase()) > -1)
    }).slice(0, 200);
    this.setState({ nRegistrosDataList: filterSuggestion });
  }

  showTableData() {
    let origenArchivo = this.state.origenArchivo ? this.state.origenArchivo.value : "";
    let tipoArchivo = this.state.tipoArchivo ? this.state.tipoArchivo.value : "";
    let estado = this.state.estado ? this.state.estado.value : "";
    let usuarios = this.state.usuarios ? this.state.usuarios.value : "";
    let startDate = this.state.startDate ? moment(this.state.startDate).format("YYYYMMDD") : "";
    let endDate = this.state.endDate ? moment(this.state.endDate).format("YYYYMMDD") : "";


    let data = {
      origenArchivo: origenArchivo,
      tipoArchivo: tipoArchivo,
      estado: estado,
      usuarios: usuarios,
      startDate: startDate,
      endDate: endDate
    }


    this.showLoaderAction()
    try {
      api.getDropDownData(data)
        .then((res) => {
          if (res && res.data && !_.isEmpty(res.data)) {
            let { archivos } = res.data;
            this.setState({ archivos: archivos })
            scrollToBottom();
          } else {
            toast.error(res.msg, { position: toast.POSITION.TOP_RIGHT });
          }
          this.showLoaderAction();
        })
        .catch((ex) => {
          toast.error("Something went wrong.", { position: toast.POSITION.TOP_RIGHT });
          this.showLoaderAction();
        })

    } catch (ex) {
      toast.error("Something went wrong.", { position: toast.POSITION.TOP_RIGHT });
      this.showLoaderAction();
    }

  }

  disableSearchBtn() {
    let bln = true
    let origenArchivo = _.isEmpty(this.state.origenArchivo) ? true : false;
    let tipoArchivo = _.isEmpty(this.state.tipoArchivo) ? true : false;
    let estado = _.isEmpty(this.state.estado) ? true : false;
    let usuarios = _.isEmpty(this.state.usuarios) ? true : false;
    let startDate = _.isEmpty(this.state.startDate) ? true : false;
    let endDate = _.isEmpty(this.state.endDate) ? true : false;

    if (origenArchivo === false || tipoArchivo === false || estado === false || usuarios === false || (this.state.startDate != null && this.state.endDate != null)) {
      bln = false;
      if (this.state.startDate != null && this.state.endDate != null && (this.state.startDate > this.state.endDate)) {
        bln = true;
      }
    }
    return bln;
  }

  resetSearch() {
    this.setState({
      origenArchivo: null,
      tipoArchivo: null,
      estado: null,
      usuarios: null,
      startDate: null,
      endDate: null,
      archivos  : undefined
    })
  }


  clickOnLink=(data)=>{
    let {nroCarga, moneda, oficina, períodoContable, fechaHora} = data;
    let {origenArchivo,tipoArchivo,estado,usuarios,startDate, endDate } = this.state;
    let query = ""
    if(origenArchivo){
      query +=  `origenArchivo=${origenArchivo.value}&`
    }
    if(tipoArchivo){
      query +=  `tipoArchivo=${tipoArchivo.value}&`
    }
    if(estado && estado.value){
      query +=  `estado=${estado.value}&`
    }
    if(usuarios && usuarios.value){
      query +=  `usuarios=${usuarios.value}&`
    }
    if(startDate){
      query +=  `startDate=${ moment(this.state.startDate).format("YYYYMMDD")}&`;
    }
    if(endDate){
      query +=  `endDate=${ moment(this.state.endDate).format("YYYYMMDD")}`;
    }

    this.props.history.push(`/caja-penta/filterosh-historial/detalle-de-tickets/${nroCarga}/${moneda}/${oficina}/${períodoContable}/${fechaHora}?${query}`)
  }



  render() {
    let excelFirstColoulnValue = this.state.excelTitle;
    if (this.state.date && this.state.date.value) {
      let dateInSpanish = moment(this.state.date.value).locale('es').format('YYYYMMDD');
      excelFirstColoulnValue = excelFirstColoulnValue + " " + dateInSpanish;
    }
    const { i18n } = this.props;
    let translateObj = (i18n.store.data[i18n.language] && i18n.store.data[i18n.language].translations) || i18n.store.data["es"].translations;
    const { classes, theme } = this.props;
    const width450px = this.state.expandSelectWidth ? "width450px" : "";

    const data = this.state.data;
    const showTable = this.state.showTable;

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
        },
      }),
    };

    let newFormatData = data && data.map((singleObj) => {
      return Object.values(singleObj);
    })

    let columnNames = filterosHistorial;


    return (
      <main className="main-container">
        <div>
          {this.state.showLoader ? <Loader /> : null}
        </div>
        {this.state.showForm ?
          <div>
            <fieldset className="custom-border">
              <legend className="legend-box">Consultar Mandatos Cliente</legend>
              <div className="drop-down-menu">
                <div className="width-for-lable">
                  <label>Fecha Desde/Hasta</label>
                </div>
                <div className="z-index-95">
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap["es"]}>
                    <Grid
                      container
                      className={classes.grid}
                      justify="space-around"
                    >
                      <DatePicker
                        margin="normal"
                        value={this.state.startDate}
                        onChange={this.handleChangeStartDate}
                        placeholder="dd/mm/yyyy"
                        format="dd/MM/yyyy"
                        width="200"
                        disableOpenOnEnter
                        minDateMessage={"Rango fecha erroneo"}
                        invalidDateMessage={"Rango fecha erroneo"}
                        keyboard
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </div>
                <div className="z-index-95">
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap["es"]}>
                    <Grid
                      container
                      className={classes.grid}
                      justify="space-around"
                    >
                      <DatePicker
                        margin="normal"
                        value={this.state.endDate}
                        invalidDateMessage={"Rango fecha erroneo"}
                        minDate={this.state.startDate}
                        minDateMessage={translateObj.policy_page.date_error_msg}
                        onChange={this.handleChangeEndDate}
                        placeholder="dd/mm/yyyy"
                        format="dd/MM/yyyy"
                        disableOpenOnEnter
                        keyboard
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div>
                <div className="drop-down-menu-1">
                  <div className="width-for-lable">
                    <label>Origen Archivo</label>
                  </div>
                  <div className="select-style">
                    <Select
                      className={"font-14px " + width450px}
                      classes={classes}
                      styles={selectStyles}
                      options={this.state.origenArchivoData.map(d => ({
                        value: d.descripcion,
                        label: d.descripcion,
                      }))}
                      components={components}
                      value={this.state.origenArchivo}
                      onChange={this.handleOrigenArchivo('origenArchivo')}
                      placeholder="Seleccione "
                      isClearable
                      onInputChange={this.customFilterOrigenArchivo}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="drop-down-menu-2">
                  <div className="width-for-lable">
                    <label>Tipo Archivo</label>
                  </div>
                  <div className="select-style">
                    <Select
                      className={"font-14px " + width450px}
                      classes={classes}
                      styles={selectStyles}
                      options={this.state.tipoArchivoData.map(d => ({
                        value: d.descripcion,
                        label: d.descripcion,
                      }))}
                      components={components}
                      value={this.state.tipoArchivo}
                      onChange={this.handleDateChange('tipoArchivo')}
                      placeholder="Seleccione"
                      isClearable
                      onInputChange={this.customFilterTipoArchivo}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="drop-down-menu-3">
                  <div className="width-for-lable">
                    <label>Estado</label>
                  </div>
                  <div className="select-style">
                    <Select
                      className={"font-14px " + width450px}
                      classes={classes}
                      styles={selectStyles}
                      options={this.state.estadoData.map(d => ({
                        value: d.id,
                        label: d.descripcion,
                      }))}
                      components={components}
                      value={this.state.estado}
                      onChange={this.handleDateChange('estado')}
                      placeholder="Seleccione "
                      isClearable
                      onInputChange={this.customFilterEstado}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="drop-down-menu-4">
                  <div className="width-for-lable">
                    <label>Usuario</label>
                  </div>
                  <div className="select-style">
                    <Select
                      className={"font-14px " + width450px}
                      classes={classes}
                      styles={selectStyles}
                      options={this.state.usuariosData.map(d => ({
                        value: d.rutUsuario,
                        label: d.nombreUsuario,
                      }))}
                      components={components}
                      value={this.state.usuarios}
                      onChange={this.handleDateChange('usuarios')}
                      placeholder="Seleccione "
                      isClearable
                      onInputChange={this.customFilterusuarios}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="search-wrapper select-style submit-button-center">
                  <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    className="search-policy"
                    disabled={this.disableSearchBtn()}
                    onClick={this.showTableData.bind()}
                  >
                    {translateObj.policy_page.accept_btn}
                  </Button>
                  <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    className="search-policy"
                    onClick={this.resetSearch.bind()}
                  >
                    {translateObj.policy_page.reset_btn}
                  </Button>
                </div>
              </div>
            </fieldset>
            {this.state.archivos ?
              <div>
                <h4 className="caja-h3">Historial de Archivos</h4>
                <DyanmicTableWithSorting
                  data={this.state.archivos}
                  keyArray={columnNames}
                  orderBy={columnNames[0]}
                  isPaginationReq={true}
                  isEnhancedTableHeadReq={true}
                  verticalScroll={true}
                  defaultRowPerPage={5}
                  passClick = {this.clickOnLink}
                />
              </div>
              : null
            }
          </div>

          : null
        }


        <ToastContainer />
      </main>
    );
  }
}

FilterosHistorial.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default translate("translations")(withStyles(styles, { withTheme: true })(FilterosHistorial));
