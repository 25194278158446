export const clasesDeContratoKeys = {
    "retención": "% Retención",
    "cesión": "% Cesion",
  };

export const contratosTabFourKeys={
  "clase":"Clase de Contrato",
  "tipo":"Tipo",
  "nombreSecurity":"Participante",
  "partSecurity":"Participacion (%)",
  "tasaResidencial":"TM Residencial",
  "tasaNoResidencial":"TM No Residencial",
}

export const limitesTabFiveKeys={
  "zone":"Zona",
  "cparte":"Cuota Parte",
  "exdente":"Excedente 1",
  "totalCol":"Total"
}

export const ramosTabTableOneKeys={
  "código":"Código",
  "nombreRamo":"Nombre Ramo",
  "verDetalle":"Ver Detalle"
}

export const ramosTabTableTwoKeys={
  "codigoRamo":"Codigo Ramo",
  "nombreRamo":"Nombre Ramo",
  "codigoCobertura":"Código Cobertura",
  "tipo":"Tipo",
  "nombreCobertura":"Nombre Cobertura",
}
export const consultaPorDistributionKeys = {
  "idPredistribucion": "ID ",
  "nombreRamo": "Ramo",
  "anoContable": "Ano",
  "option":"Opciones"
};

export const polizaTableOneKeys = {
  "poliza":"N Poliza",
  "nombreContrante": "Contratante/Grupo",
  "monto":"Monto",
  "pNeta": "P.Neta",
};

export const polizaTableTwoKeys = {
  "poliza":"N Poliza",
  "nombreContrante": "Contratante/Grupo",
  "montoBff":"Monto BBFF",
  "montoXp":"Monto PxP",
  "pNeta": "P.Neta"
};

export const detalleDistribucionKeys = {
  "tipoParticipacion": "Tipo Reaseguro",
  "reasegurodar": "Reasegurodor/Broker",
  "percentage": "%",
  "montoAsegurado": "MontoAsegurado",
  "prima": "Prima",
  "dcto": "Dcto (%)",
  
};


