import React from "react";
import {H2} from "../Headings/Headings"
import "./Loader.scss"

const Loader = props => {
	return (
		<div className="page-loader" >
			<div className="loader">
			</div>
			<H2 className="loader-text">
				{ props.msg }
			</H2>
		</div>
	);
};

export default Loader;