export const dpi = 72;

export const a4 = {
    width: 8.2677,
    height: 11.6929
}


export function getFormattedDate() {
  let date = new Date();
  let year = date.getFullYear();
  let month = (1 + date.getMonth()).toString().padStart(2, '0');
  let day = date.getDate().toString().padStart(2, '0');

  return (day + '-' + month + '-' + year).toString();
}