import {local} from "./local";
let config=local;
let env=process.env.REACT_APP_STAGE;
if (env) {
    try{
        let configData=require('./'+env.trim());
        config=(configData && configData[env])?configData[env]:config;
    }catch(exception){
        console.log("error", exception);
    }
}
if(env!="local"){console.log = function(){};}
export default config;