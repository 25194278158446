import React from 'react';
import PropTypes from 'prop-types';
// import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import _ from "lodash";
import Divider from "@material-ui/core/Divider";
import { translate, Trans } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import FormLabel from "@material-ui/core/FormLabel";
import { Link } from "react-router-dom";

import TextField from '@material-ui/core/TextField';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import EmptyState from "../../../../BaseComponents/EmptyState/EmptyState";


import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Select from "react-select";
import {
    MuiPickersUtilsProvider,
    DatePicker
} from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";

class DataGenerales extends React.Component {

    constructor(props) {
        super(props);
        let response = {
            avisoPasoSiniestro: 0,
            fechaVigDesde: null,
            fechaVigHasta: null,
            indiceRiesgo: 0,
            iri: 0,
            limitacionCapacidad: "",
            nombreMoneda: "",
            periodoLiquidacion: 0,
            retencionExclusiva: 0,
            codigoContrato: 0
        }
        this.state = {
            DataGeneralesData: undefined,
            showDataGenerales: true,
            responseObj: this.props.firstTabData||response
        };
    }
    componentWillReceiveProps(nextProps){
        this.setState({
            responseObj:nextProps.firstTabData
        })
    }

    resetSearch = () => {
        let response = {
            avisoPasoSiniestro: 0,
            fechaVigDesde: null,
            fechaVigHasta: null,
            indiceRiesgo: 0,
            iri: 0,
            limitacionCapacidad: "",
            nombreMoneda: "",
            periodoLiquidacion: 0,
            retencionExclusiva: 0,
            nombreContrato: "",
            codigoContrato: "",
        };
        this.setState({
            showDataGenerales: true
        })
        this.props.enableAllTabs("", response, "clearData");
    }


    componentDidMount() {
        let showDataGenerales = this.props.data && this.props.data.length > 0 ? true : false;
        this.setState({
            DataGeneralesData: this.props.data,
            showDataGenerales: showDataGenerales
        });

    }

    handleToggle = name => event => {

        let key = name;
        let val = event.target.checked;
        if (name === "Contrato") {
            this.setState({
                contrato: (val) ? false : true
            });
        }
    }

    selectHandleChangeNombreAgrupacion = actionMeta => {
        if (actionMeta && actionMeta.value) {
            this.setState({
                nombreAgrupacion: actionMeta.value,
            });
        }
    };

    selectHandleChangeNombreContrato = actionMeta => {
        if (actionMeta && actionMeta.value) {
            this.getDataGenraleDet(actionMeta);
        }
    };

    getDataGenraleDet(actionMeta) {
        let nombreSelectedRecord = _.find(this.state.DataGeneralesData, [
            "codigoContrato",
            parseInt(actionMeta.value)
        ]);
        nombreSelectedRecord["nombreContrato"]=actionMeta.label
        nombreSelectedRecord["codigoContrato"]=actionMeta.value
        if (nombreSelectedRecord) {
            this.enableTabs(actionMeta.value, nombreSelectedRecord);
        }
    }


    handleChangeStartDate = (changeDate, event) => {
        this.setState({
            startDate: changeDate
        });
    };

    handleChangeEndDate = (changeDate, event) => {
        this.setState({
            endDate: changeDate
        });
    };


    selectHandleChangeMoneda = actionMeta => {
        if (actionMeta && actionMeta.value) {
            this.setState({
                moneda: actionMeta.value,
            });
        }
    };

    handleChangeLimitacionDeCapacided = event => {
        const value = event.target.value;
        this.setState({
            limitacionDeCapacided: value
        });
    };

    selectHandleChangePeriodoDeLiquidation = actionMeta => {
        if (actionMeta && actionMeta.value) {
            this.setState({
                periodoDeLiquidation: actionMeta.value,
            });
        }
    };

    submitData = () => {
        this.props.searchFuntion(this.state.responseObj.codigoContrato)
    }

    enableTabs=(value, responseObj)=>{
        this.props.enableAllTabs(value, responseObj)
    }

    render() {
        const showDataGenerales = this.state.showDataGenerales;
        return (
            <div className="tab-class">
                    <Card style={{ marginTop: "25px" }} className="Card-area box-show-none">
                        <div>
                            <div className="container">
                                <div className="search-wrapper">
                                    <div className="row porClientRowClass">

                                        <div className="col-sm-4">
                                            <span className="label-class">Nombre contrato(*)</span>
                                            <Select
                                                maxMenuHeight={180}
                                                value={{
                                                    label: this.state.responseObj.nombreContrato,
                                                    value: this.state.responseObj.codigoContrato
                                                }}
                                                isSearchable={true}
                                                onChange={this.selectHandleChangeNombreContrato}
                                                options={this.state.DataGeneralesData ? this.state.DataGeneralesData.map(option => ({
                                                    value: option.codigoContrato,
                                                    label: option.nombreContrato
                                                })) : []}

                                            />
                                        </div>
                                        <div className="col-sm-4" >
                                            <span className="label-class">Moneda (*)</span>

                                            <Select
                                                value={{
                                                    label: this.state.responseObj.nombreMoneda,
                                                    value: this.state.responseObj.nombreMoneda
                                                }}

                                                maxMenuHeight={180}
                                                isDisabled={true}
                                            />
                                        </div>
                                        <div className="col-sm-4">
                                            <TextField
                                                id="standard-number"
                                                label="Aviso y pago de siniestros (*)"
                                                value={this.state.responseObj.avisoPasoSiniestro}
                                                fullWidth
                                                onChange={this.handleChangeAvisoSinestros}
                                                type="number"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                margin="normal"
                                                disabled={true}
                                            />
                                        </div>


                                    </div>

                                    <div className="row porClientRowClass">
                                        <div className="col-data-100" >
                                            <label className=" date-align-text">Vigencias del contrato(*)</label>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>

                                                <DatePicker
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    margin="normal"
                                                    label="Inicio"
                                                    placeholder="dd/mm/yyyy"
                                                    value={this.state.responseObj.fechaVigDesde}
                                                    onChange={this.handleChangeStartDate}
                                                    format="dd/MM/yyyy"
                                                    disableOpenOnEnter
                                                    keyboard
                                                    style={{ width: 165, margin: 10 }}
                                                    disabled={true}
                                                />

                                                <DatePicker
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    margin="normal"
                                                    label="Fin"
                                                    placeholder="dd/mm/yyyy"
                                                    value={this.state.responseObj.fechaVigHasta}
                                                    onChange={this.handleChangeStartDate}
                                                    format="dd/MM/yyyy"
                                                    disableOpenOnEnter
                                                    keyboard
                                                    style={{ width: 165, margin: 10 }}
                                                    disabled={true}
                                                />

                                            </MuiPickersUtilsProvider>

                                        </div>



                                    </div>
                                </div>
                                <Divider></Divider>
                                <div className="row porClientRowClass">

                                    <div className="col-sm-4">
                                        <label >Periodo de liquidación(*)</label>
                                        <Select value={{
                                            label: this.state.responseObj.periodoLiquidacion,
                                            value: this.state.responseObj.periodoLiquidacion
                                        }}
                                            maxMenuHeight={180}
                                            onChange={this.selectHandleChangePeriodoDeLiquidation}
                                            isDisabled={true}
                                        />
                                    </div>
                                    <div className="col-sm-4 check-box-align " >
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<Checkbox color="primary" checked={this.state.responseObj.retencionExclusiva} />}
                                                label="Contrato 100% rentencion exclusiva"
                                                value={this.state.responseObj.retencionExclusiva}
                                                disabled={true}

                                            />
                                        </FormGroup>
                                    </div>
                                    <div className="col-sm-4 ">

                                        <FormLabel component="legend">Atributos del ramo</FormLabel>
                                        <FormControlLabel
                                            control={<Checkbox color="primary" checked={this.state.responseObj.indiceRiesgo} />}
                                            label="Indices de riesgo"
                                            value={this.state.responseObj.indiceRiesgo}
                                            disabled={true}

                                        />
                                        <FormControlLabel
                                            control={<Checkbox color="primary" checked={this.state.responseObj.iri} />}
                                            label="IRI"
                                            value={this.state.responseObj.iri}
                                            disabled={true}
                                        />
                                    </div>


                                </div>

                                <Divider></Divider>
                                <div className="row porClientRowClass">
                                    <div className="col-sm-12">

                                        <TextField
                                            id="outlined-multiline-static"
                                            label="Limitacion de capacided"
                                            multiline
                                            rows="3"
                                            value={this.state.responseObj.limitacionCapacidad}
                                            onChange={this.handleChangelimitacionDeCapacided}
                                            fullWidth
                                            margin="normal"
                                            variant="outlined"
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="reso-btn-group">
                            <div className="btn">
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    onClick={this.resetSearch}
                                    className="search-policy pull-right resetButtonClass "> {"Limpiar"}</Button>
                            </div>
                            <div className="btn" >
                                <Button variant="contained" disabled={!this.state.responseObj.codigoContrato} onClick={this.submitData} size="medium" color="primary" className="search-policy  pull-right resetButtonClass">
                                    Confirmar cambios y continuar
                                </Button>
                            </div>
                        </div>
                    </Card>
            </div>
        )
    }
}

export default translate("translations")(DataGenerales);