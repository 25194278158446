export const listOfRamos = [
  { value: 0, label: "Todos" },
  { value: 1, label: "Incendio" },
  { value: 3, label: "Terremoto" },
  { value: 8, label: "Robo" },
  { value: 9, label: "Vehiculos Motorizados" },
  { value: 10, label: "Vehiculos Motorizados G2" },
  { value: 11, label: "Casco Marítimo" },
  { value: 12, label: "Casco Aéreo" },
  { value: 13, label: "Transporte Terrestre" },
  { value: 14, label: "Transporte Marítimo" },
  { value: 15, label: "Transporte Aéreo" },
  { value: 16, label: "Equipo Móvil" },
  { value: 17, label: "Todo Riesgo Construcción y Montaje" },
  { value: 18, label: "Avería de Maquinaria" },
  { value: 19, label: "Equipos Electrónicos" },
  { value: 20, label: "Responsabilidad Civil Vehíc.Motorizados" },
  { value: 21, label: "Responsabilidad Civil" },
  { value: 22, label: "Multiriesgos" },
  { value: 23, label: "Accidentes Personales" },
  { value: 24, label: "S.O.A.P." },
  { value: 25, label: "Garantía" },
  { value: 26, label: "Fidelidad" },
  { value: 30, label: "Varios" },
  { value: 31, label: "Responsabilidad de Garage" },
  { value: 32, label: "Agrícola" },
  { value: 33, label: "Locomoción Colectiva" },
  { value: 34, label: "Remesa de Valores" },
  { value: 35, label: "Vidrios, Cristales y Espejos" },
  { value: 36, label: "Integral del Hogar" },
  { value: 37, label: "Animales Finos" },
  { value: 38, label: "Piscicultura" },
  { value: 39, label: "Todo Riesgo Objetos Valiosos" },
  { value: 40, label: "Protección de Oficina" },
  { value: 41, label: "Protección de Comercio" },
  { value: 42, label: "Protección de Hogar" },
  { value: 43, label: "Miscelaneos" },
  { value: 44, label: "Hogar" },
  { value: 45, label: "Banco Santiago Hogar" },
  { value: 46, label: "Multiriesgo Hogar" },
  { value: 47, label: "Asistencias" },
  { value: 48, label: "MultiTransporte" }
];
