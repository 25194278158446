import React from "react";
import DyanmicTableWithSorting from "../../../../BaseComponents/DyanmicTableWithSorting/DyanmicTableWithSorting";
import { translate, Trans } from "react-i18next";
import _ from "lodash";
import MateriaAsegKeys from "./MateriaAsegKeys.json";
import CloudDownload from "@material-ui/icons/CloudDownload";
import Button from "@material-ui/core/Button";
import { getMateriaAsegExcel } from "./../../../api/ExcelDownload";
import EmptyState from "../../../../BaseComponents/EmptyState/EmptyState";
import VolverButton from "../../../../BaseComponents/VolverButtons/VolverButton";
import "./tabs.scss";

class MateriaAseg extends React.Component {
  constructor(props) {
    let ramo = props.ramo;
    super(props);
    this.state = {
      MateriaAsegData: undefined,
      isPaginationReq: false,
      defaultRowPerPage: 10,
      isEnhancedTableHeadReq: false,
      keys: MateriaAsegKeys[ramo],
      showMateriaAseg: true
    };
  }

  componentDidMount() {
    let showMatData =
      this.props.data && this.props.data.length > 0 ? true : false;
    this.setState({
      MateriaAsegData: this.props.data,
      showMateriaAseg: showMatData
    });
  }

  downloadEcel = () => {
    getMateriaAsegExcel(this.state.keys, this.state.MateriaAsegData);
  };

  render() {
    let keysCheck = this.state.keys ? true : false;

    return (
      <div className="tab-class">
        {this.state.showMateriaAseg && keysCheck ? (
          <>
            <DyanmicTableWithSorting
              data={this.state.MateriaAsegData}
              keyArray={this.state.keys}
              orderBy={this.state.keys[0]}
              pathData={{}}
              isPaginationReq={this.state.isPaginationReq}
              isEnhancedTableHeadReq={this.state.isEnhancedTableHeadReq}
              defaultRowPerPage={this.state.defaultRowPerPage}
              virticalScroll={true}
              tableMaxHeight={"400"}
            />
            <Button
              variant="contained"
              size="medium"
              color="primary"
              disabled={false}
              className="search-icon search-policy"
              style={{ float: "right", marginBottom: "5px" }}
              onClick={this.downloadEcel}
            >
              <CloudDownload className="download-icon" />
              {"Excel"}
            </Button>
            <VolverButton />
          </>
        ) : (
          <div className={"content"}>
            <EmptyState
              emoticon={"🔍"}
              message={"No hay datos de Materia Asegurada"}
            />
          </div>
        )}
      </div>
    );
  }
}

export default translate("translations")(MateriaAseg);
