import React from "react";
import PropTypes from "prop-types";
import DyanmicTableWithSorting from "../../../../BaseComponents/DyanmicTableWithSorting/DyanmicTableWithSorting";
import { translate, Trans } from "react-i18next";
import _ from "lodash";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import VolverButton from "../../../../BaseComponents/VolverButtons/VolverButton";
import "./tabs.scss";

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "#3f51b5",
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  table: {
    minWidth: 700
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  }
});

let id = 0;
function createData(name, calories) {
  id += 1;
  return { id, name, calories };
}

function Coaseguro(props) {
  let pDeParticipaciion1 = props.data.pDeParticipaciion1
    ? props.data.pDeParticipaciion1
    : "";
  let pDeParticipaciion2 = props.data.pDeParticipaciion2
    ? props.data.pDeParticipaciion2
    : "";
  let pDeParticipaciion3 = props.data.pDeParticipaciion3
    ? props.data.pDeParticipaciion3
    : "";
  let pDeParticipaciion4 = props.data.pDeParticipaciion4
    ? props.data.pDeParticipaciion4
    : "";
  let pDeParticipaciion5 = props.data.pDeParticipaciion5
    ? props.data.pDeParticipaciion5
    : "";
  let pDeParticipaciion6 = props.data.pDeParticipaciion6
    ? props.data.pDeParticipaciion6
    : "";
  let pDeParticipaciion7 = props.data.pDeParticipaciion7
    ? props.data.pDeParticipaciion7
    : "";
  let pDeParticipaciion8 = props.data.pDeParticipaciion8
    ? props.data.pDeParticipaciion8
    : "";
  let pDeParticipaciion9 = props.data.pDeParticipaciion9
    ? props.data.pDeParticipaciion9
    : "";
  let pDeParticipaciion10 = props.data.pDeParticipaciion10
    ? props.data.pDeParticipaciion10
    : "";
  const rows = [
    createData(props.data.nombreDeCompania1, pDeParticipaciion1),
    createData(props.data.nombreDeCompania2, pDeParticipaciion2),
    createData(props.data.nombreDeCompania3, pDeParticipaciion3),
    createData(props.data.nombreDeCompania4, pDeParticipaciion4),
    createData(props.data.nombreDeCompania5, pDeParticipaciion5),
    createData(props.data.nombreDeCompania6, pDeParticipaciion6),
    createData(props.data.nombreDeCompania7, pDeParticipaciion7),
    createData(props.data.nombreDeCompania8, pDeParticipaciion8),
    createData(props.data.nombreDeCompania9, pDeParticipaciion9),
    createData(props.data.nombreDeCompania10, pDeParticipaciion10)
  ];
  const { classes } = props;
  return (
    <div className="coaseguro">
      <div style={{ margin: "25px" }}>
        <Card className="Card-area box-show-none">
          <fieldset className="custom-border">
            <div className="compania-lider">
              <span>
                <TextField
                  id="standard-bare"
                  label="Compañía Lider"
                  className="textField"
                  value={props.data.companiaLider}
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    readOnly: true
                  }}
                  style={{ width: 300 }}
                />
              </span>
            </div>
            <div className="poliza-lider">
              <span>
                <TextField
                  id="standard-bare"
                  label="N* De Referencia"
                  className="textField"
                  value={props.data.nPolizaLider}
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    readOnly: true
                  }}
                  style={{ width: 300 }}
                />
              </span>
            </div>
          </fieldset>
        </Card>
      </div>
      <div style={{ margin: "25px" }}>
        <Card className="Card-area box-show-none">
          <fieldset className="custom-border">
            <legend className="legend-box">Compañías Coaseguro</legend>
            <Paper className={classes.root}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <CustomTableCell>Nombre DE Compañía</CustomTableCell>
                    <CustomTableCell align="right">
                      % DE Participación
                    </CustomTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map(row => (
                    <TableRow className={classes.row} key={row.id}>
                      <CustomTableCell component="th" scope="row">
                        {row.name}
                      </CustomTableCell>
                      <CustomTableCell align="right">
                        {row.calories}
                      </CustomTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </fieldset>
          <VolverButton />
        </Card>
      </div>
    </div>
  );
}

Coaseguro.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Coaseguro);
