import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { display } from '@material-ui/system';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import  './SituactionCobranzaPrimas.scss'

const styles = theme => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridGap: `${theme.spacing.unit * 3}px`,
    flexWrap: 'nowrap'
  },
  paper: {
    padding: theme.spacing.unit,
    textAlign: 'center',
    color: theme.palette.text.primary,
    fontSize: `15px`,
    whiteSpace: 'nowrap',
    fontFamily:'Roboto',
    marginBottom: theme.spacing.unit
  },
  divider: {
    margin: `${theme.spacing.unit * 2}px 0`,
  },
  card: {
    padding: `20px`
  }
});

function CSSGrid(props) {
  const { classes } = props;
  const situactionCobranzaYPrimas = props.data;
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <div>
      {situactionCobranzaYPrimas ?<>
      <Divider className={classes.divider} />
      <Card className={classes.card}>
        <h3 className="Heading-name">Situación Cobranza y Primas</h3>

        <div className={classes.container}>
            <div style={{ gridColumnEnd: 'span 3' }}>
            </div>
            <div style={{ gridColumnEnd: 'span 3' }}>
              <Paper className={classes.paper +" remove-shadow"} >Afecto Neto</Paper>
            </div>
            <div style={{ gridColumnEnd: 'span 3' }}>
              <Paper className={classes.paper +" remove-shadow"} >Exento Neto</Paper>
            </div>
            <div style={{ gridColumnEnd: 'span 3' }}>
              <Paper className={classes.paper +" remove-shadow"} >Bruto</Paper>
            </div>

            <div style={{ gridColumnEnd: 'span 3' }}>
              <Paper className={classes.paper +" remove-shadow"} >Propuestas</Paper>
            </div>
            <div style={{ gridColumnEnd: 'span 3' }}>
              <Paper className={classes.paper +" remove-shadow"} >{situactionCobranzaYPrimas.afectoNeto.propuestas}</Paper>
            </div>
            <div style={{ gridColumnEnd: 'span 3' }}>
              <Paper className={classes.paper +" remove-shadow"} >{situactionCobranzaYPrimas.exentoNeto.propuestas}</Paper>
            </div>
            <div style={{ gridColumnEnd: 'span 3' }}>
              <Paper className={classes.paper +" remove-shadow"} >{situactionCobranzaYPrimas.bruto.propuestas}</Paper>
            </div>

            <div style={{ gridColumnEnd: 'span 3' }}>
              <Paper className={classes.paper +" remove-shadow"} >Endosos</Paper>
            </div>
            <div style={{ gridColumnEnd: 'span 3' }}>
              <Paper className={classes.paper +" remove-shadow"} >{situactionCobranzaYPrimas.afectoNeto.endosos}</Paper>
            </div>
            <div style={{ gridColumnEnd: 'span 3' }}>
              <Paper className={classes.paper +" remove-shadow"} >{situactionCobranzaYPrimas.exentoNeto.endosos}</Paper>
            </div>
            <div style={{ gridColumnEnd: 'span 3' }}>
              <Paper className={classes.paper +" remove-shadow"} >{situactionCobranzaYPrimas.bruto.endosos}</Paper>
            </div>

            <div style={{ gridColumnEnd: 'span 3' }}>
              <Paper className={classes.paper +" remove-shadow"} >Total</Paper>
            </div>
            <div style={{ gridColumnEnd: 'span 3' }}>
              <Paper className={classes.paper +" remove-shadow"} >{situactionCobranzaYPrimas.afectoNeto.total}</Paper>
            </div>
            <div style={{ gridColumnEnd: 'span 3' }}>
              <Paper className={classes.paper +" remove-shadow"} >{situactionCobranzaYPrimas.exentoNeto.total}</Paper>
            </div>
            <div style={{ gridColumnEnd: 'span 3' }}>
              <Paper className={classes.paper +" remove-shadow"} >{situactionCobranzaYPrimas.bruto.total}</Paper>
            </div>
          </div>
        <Divider className={classes.divider} />
        <div className={classes.container}>

          <div style={{ gridColumnEnd: 'span 4' }}>
          </div>
          <div style={{ gridColumnEnd: 'span 8' }}>
            <Paper className={classes.paper +" remove-shadow"} >Pagos Documentos</Paper>
          </div>

          <div style={{ gridColumnEnd: 'span 4' }}>
            <Paper className={classes.paper +" remove-shadow"} >Interés</Paper>
          </div>
          <div style={{ gridColumnEnd: 'span 8' }}>
            <Paper className={classes.paper +" remove-shadow"} >{situactionCobranzaYPrimas.pageDocumentos.interes}</Paper>
          </div>
          <div style={{ gridColumnEnd: 'span 4' }}>
            <Paper className={classes.paper +" remove-shadow"} >Prima</Paper>
          </div>
          <div style={{ gridColumnEnd: 'span 8' }}>
            <Paper className={classes.paper +" remove-shadow"} >{situactionCobranzaYPrimas.pageDocumentos.prima}</Paper>
          </div>

        </div>
        <Divider className={classes.divider} />
        <div className="remove-padding vertical-align-middle"> 
          <span className="Space-around">
              <TextField InputLabelProps={{
    shrink: true,
}}
                  id="standard-bare"
                  label="Prima Bruta"
                  className="textField"
                  margin="normal"
                  value={situactionCobranzaYPrimas.primabruta}
                  variant="outlined"
                  InputProps={{
                      readOnly: true,
                    }}
                  style = {{width: 150}}
              />
              <span className="minus-sign">-</span>
              <TextField InputLabelProps={{
    shrink: true,
}}
                  id="standard-bare"
                  label="Documentado"
                  className="textField"
                  margin="normal"
                  value={situactionCobranzaYPrimas.documentado}
                  variant="outlined"
                  InputProps={{
                      readOnly: true,
                    }}
                  style = {{width: 150}}
              />
              <span className="minus-sign">-</span>
              <TextField InputLabelProps={{
    shrink: true,
}}
                  id="standard-bare"
                  label="Pagos Contado"
                  className="textField"
                  margin="normal"
                  value={situactionCobranzaYPrimas.pagosContado}
                  variant="outlined"
                  InputProps={{
                      readOnly: true,
                    }}
                  style = {{width: 150}}
              />
              <span className="minus-sign">+</span>
              <TextField InputLabelProps={{
    shrink: true,
}}
                  id="standard-bare"
                  label="Devoluciones"
                  className="textField"
                  margin="normal"
                  value={situactionCobranzaYPrimas.devoluciones}
                  variant="outlined"
                  InputProps={{
                      readOnly: true,
                    }}
                  style = {{width: 150}}
              />
              <span className="minus-sign">=</span>
              <TextField InputLabelProps={{
    shrink: true,
}}
                  id="standard-bare"
                  label="Prima Pendiente"
                  className="textField"
                  margin="normal"
                  value={situactionCobranzaYPrimas.primaPendiente}
                  variant="outlined"
                  InputProps={{
                      readOnly: true,
                    }}
                  style = {{width: 180}}
              />
          </span>
        </div>

      </Card>
    </>:null}
    </div>
  );
}

CSSGrid.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CSSGrid);