export const local =  {
    apiEndPoint:"https://m9ncaw8mvf.execute-api.us-east-1.amazonaws.com/dev/",
    env:"local",
    adalConfig: {
        tenant: 'LibertyMutual.onmicrosoft.com',
        tenantId: '08a83339-90e7-49bf-9075-957ccd561bf1',
        clientId: '77cf73d2-959a-4821-97c7-becb98f30758',// application id fro local env
        endpoints: {
            api: 'https://libertymutual.onmicrosoft.com//9ad7bc51-e0d6-422c-b8a8-41d22170cdac',
        },
        cacheLocation: 'localStorage',
    }
  };
