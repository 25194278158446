import { config, base_url, axiousInstance } from './index';
let axios=axiousInstance;

export function getCollectionByPolicyNo(policyNo) {
  /* url  : "https://lrigoocw6l.execute-api.us-east-1.amazonaws.com/dev/getCollectionByPolicy"

   body : {
	"policyNo" : 18002362
    }

*/
  const data = {
    policyNo: policyNo
  };
  return axios
    .post(base_url + "getCollectionByPolicy", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
}
export function getCollectionByDocumentNo(documentNo) {
  const data = {
    docNo: documentNo
  };
  return axios
    .post(base_url + "getDocumentosByDocNo", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
}

export function getCollectionByVigente(documentoNo) {
  /* url  : "https://lrigoocw6l.execute-api.us-east-1.amazonaws.com/dev/getCollectionByVigente"

   body : {
	"documentoNo" : 18002362
    }

  */
  const data = {
    documentoNo: documentoNo
  };
  return axios
    .post(base_url + "getCollectionByVigente", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
}

export function getCollectionResumen(documentoNo) {
  const data = {
    policyNo: documentoNo
  };
  return axios
    .post(base_url + "getCollectionResumen", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
}
