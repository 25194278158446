import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import PolicyDetails from "../PolicyDetailsComponent/PolicyDetails";
import policydata from "../PolicyDetailsComponent/policydata.json";
import { translate, Trans } from "react-i18next";
import { getPolicyDetails } from "./../../api/policy";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./HomePage.scss";
import { css } from "glamor";
import CircularProgressComponent from "../../../BaseComponents/CircularProgressComponent/CircularProgressComponent";
import Loader from "../../../BaseComponents/Loader/Loader";
import { Link } from "react-router-dom";
import CreatableSelect from "react-select/lib/Creatable";

const styles = theme => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: "100vh",
    overflow: "auto"
  },
  progress: {
    margin: theme.spacing.unit * 2
  }
});

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPolicy: false,
      searchText: "",
      policyData: undefined,
      enableSearch: true,
      showLoader: false
    };
    this.showPolicyDetails = this.showPolicyDetails.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.resetSearch = this.resetSearch.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    const {  match: { params } } = this.props;
    const {policyNo ,policy, rut} = params;

    if (policyNo || policy) {
      this.setState({ searchText: policyNo || policy, enableSearch: false  , rut  : rut});
      let policyNumber = policyNo || policy;
      this.showPolicyDetails(policyNumber , rut);
    }
  }

  /* function to display policy details information */
  showPolicyDetails(policyNumber , rut) {
    this.showLoaderAction();
    const policyNo = this.state.searchText || policyNumber;
    console.log(this.state)
    if(this.props.match.path == "/policy/:policy/:rut") { 
      // this.props.history.push(`policy/${policyNo}/${rut}`);
    }else if (this.props.match.path == "/" || this.props.match.path == "/policy") {
        this.props.history.push("policy/" + policyNo);
    }
    else {
      this.props.history.push(
        this.props.match.path.replace(":policyNo", policyNo)
      );
    }
    
    this.setState({ policyData: undefined });

    getPolicyDetails(policyNo).then(response => {
      if (!_.isEmpty(response.data)) {
        this.setState({
          showPolicy: true,
          policyData: response.data
        });
        localStorage.setItem("policyNo", policyNo);
        this.showLoaderAction();
      } else {
        toast.error(response.msg || "Please enter policy number", {
          position: toast.POSITION.TOP_RIGHT
        });
        this.setState({
          showPolicy: false,
          policyData: undefined
        });
        this.showLoaderAction();
      }
    });
  }

  showLoaderAction() {
    let showLoader = this.state.showLoader;
    this.setState({
      showLoader: !showLoader
    });
  }

  handleChange(event) {
    const value = event.target.value;
    let bln = value.length > 0 ? false : true;
    this.setState({ searchText: value, enableSearch: bln });
  }

  handleKeyPress(event) {
    if (event.key === "Enter") {
      this.showPolicyDetails();
    }
  }

  resetSearch() {
    this.setState({
      showPolicy: false,
      blnAdvanceSearch: false,
      searchText: "",
      policyData: undefined,
      blnShowPorClientSearch: false
    });
  }

  reDirectToRut=()=>{
    const {searchText : policy , rut} = this.state;
    this.props.history.push(`/buscar/${policy}/${rut}`)
  }

  render() {
    const { i18n } = this.props;
    const { classes } = this.props;
    let translateObj =
      (i18n.store.data[i18n.language] &&
        i18n.store.data[i18n.language].translations) ||
      i18n.store.data["es"].translations;
    let showLoader = this.state.showLoader;
    return (
      <main className={classes.content + " home-page"}>
        {showLoader ? <Loader /> : null}
        <Trans i18nKey="title" />
        <div className={classes.appBarSpacer} />
        <div className="search-wrapper">
          <TextField
            id="custom-css-standard-input"
            label={translateObj.policy_page.search_placeholder}
            type="search"
            className="text-field"
            margin="normal"
            value={this.state.searchText}
            onChange={this.handleChange}
            onKeyPress={this.handleKeyPress}
          />
          <Button
            variant="contained"
            size="medium"
            color="primary"
            className="search-policy"
            disabled={this.state.enableSearch}
            onClick={this.showPolicyDetails.bind()}
          >
            {translateObj.policy_page.search_btn}
          </Button>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            className="search-policy"
            onClick={this.resetSearch.bind()}
          >
            {translateObj.policy_page.reset_btn}
          </Button>
          { this.state.rut ? 
          <Button
            variant="contained"
            size="medium"
            color="primary"
            className="search-policy"
            onClick={this.reDirectToRut}
          >
            {"Volver"}
          </Button> : null
          }
        </div>
        {this.state.policyData ? (
          <div className="search-wrapper  button-group">
            <Link
              to={{ pathname: `/claims/${this.state.searchText}` }}
              style={{ textDecoration: "none" }}
            >
              <Button
                variant="contained"
                size="medium"
                color="primary"
                className="search-policy"
              >
                {translateObj.view_claim}
              </Button>
            </Link>
            <Link
              to={{ pathname: `/items-list/${this.state.searchText}` }}
              style={{ textDecoration: "none" }}
            >
              <Button
                variant="contained"
                size="medium"
                color="primary"
                className="search-policy"
              >
                {translateObj.view_item}
              </Button>
            </Link>

            <Link
              to={{ pathname: `/endorsement/${this.state.searchText}` }}
              style={{ textDecoration: "none" }}
            >
              <Button
                variant="contained"
                size="medium"
                color="primary"
                className="search-policy"
              >
                {translateObj.view_endorsement}
              </Button>
            </Link>
            <Link
              to={{ pathname: `/collections/${this.state.searchText}` }}
              style={{ textDecoration: "none" }}
            >
              <Button
                variant="contained"
                size="medium"
                color="primary"
                className="search-policy"
              >
                {translateObj.view_collection}
              </Button>
            </Link>
            <Link
              to={{ pathname: `/coinsurance/${this.state.searchText}` }}
              style={{ textDecoration: "none" }}
            >
              <Button
                variant="contained"
                size="medium"
                color="primary"
                className="search-policy"
              >
                {translateObj.view_coinsurance}
              </Button>
            </Link>
            <Link
              to={{ pathname: `/documento/poliza/${this.state.searchText}` }}
              style={{ textDecoration: "none" }}
            >
              <Button
                variant="contained"
                size="medium"
                color="primary"
                className="search-policy"
              >
                {translateObj.liquidadores}
              </Button>
            </Link>
          </div>
        ) : null}

        <Typography variant="h4" gutterBottom component="h2">
          {this.state.showPolicy ? (
            <PolicyDetails data={this.state.policyData} />
          ) : null}
        </Typography>
        <ToastContainer />
      </main>
    );
  }
}

HomePage.propTypes = {
  classes: PropTypes.object.isRequired
};

let data = withStyles(styles)(HomePage);
export default translate("translations")(data);
