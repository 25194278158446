import React from 'react'
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import DyanmicTable from '../../../../BaseComponents/DyanmicTable/DyanmicTable';
import './SinistralidadComponent.scss'
import {sinistralidadDetailsKeys } from "./../../../../Constants/claim"

export default function SinistralidadComponent(props) {
  const sinistralidad = props.sinistralidad;
  
  return (
    <div>
        {sinistralidad ?
        <div>
            <div>
                <h4 className="Heading-name font-20">Resumen Siniestralidad Póliza</h4>
                <div className="margin-for-table">
                    <span className="Space-around">
                        <TextField InputLabelProps={{
    shrink: true,
}}
                            id="standard-bare"
                            label="Prima Ganada"
                            className="textField"
                            value={sinistralidad.primaGanada}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            style = {{width:150}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
    shrink: true,
}}
                            id="standard-bare"
                            label="Provisión"
                            className="textField"
                            value={sinistralidad.provision}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            style = {{width:150}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
    shrink: true,
}}
                            id="standard-bare"
                            label="Recupero"
                            className="textField"
                            value={sinistralidad.recupero}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            style = {{width:150}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
    shrink: true,
}}
                            id="standard-bare"
                            label="Pagos"
                            className="textField"
                            value={sinistralidad.pagos}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            style = {{width:150}}
                        />
                    </span>
                    <span className="Space-around">
                        <TextField InputLabelProps={{
    shrink: true,
}}
                            id="standard-bare"
                            label="Siniestralidad"
                            className="textField"
                            value={sinistralidad.sinistralidad+"%"}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            style = {{width:150}}
                        />
                    </span>
                </div>
            </div>
            <div className="margin-for-table">
                <h4 className="Heading-name font-20">Detalle Siniestralidad Póliza</h4>
                <DyanmicTable data={sinistralidad.sinistralidadPolizaDetails}  id="item" keyArray={sinistralidadDetailsKeys} 
              virticalScroll={true}
              tableMaxHeight={"400"} />
            </div>
        </div>
        : null }
   </div>
  )
}
