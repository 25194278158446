import { config, base_url, axiousInstance } from './index';
let axios=axiousInstance;

const getcobranza = reqParam => {
  let data = {};

  data.p_R_VLO_COD_RAMOS = reqParam.ramos;
  if (reqParam.porPoliza || reqParam.nonRut) {
    data.p_R_VLO_NRO_POLIZA = reqParam.policyNo;
    // data.p_R_VLO_NRO_UNIDAD = reqParam.itemNo;
    data.p_R_VLO_NRO_UNIDAD = reqParam.porPolizaItem;
    data.p_R_VLO_RUT = 0;
  } else if (reqParam.porSinistro || reqParam.porReferenciaCorredor) {
    data.p_R_VLO_NRO_POLIZA = reqParam.policyNo;
    data.p_R_VLO_NRO_UNIDAD = reqParam.itemNo;
    // data.p_R_VLO_NRO_UNIDAD = reqParam.porPolizaItem;
    data.p_R_VLO_RUT = 0;
  } else {
    data.p_R_VLO_NRO_POLIZA = reqParam.policyNo;
    // data.p_R_VLO_NRO_UNIDAD = reqParam.itemNo;
    data.p_R_VLO_NRO_UNIDAD = 0;
    data.p_R_VLO_RUT = reqParam.rut;
  }
  return axios
    .post(base_url + "getcobranza", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
};

const getProduction = reqParam => {
  let data = {};
  data.p_R_VLO_COD_RAMOS = reqParam.ramos;
  if (reqParam.porPoliza || reqParam.nonRut) {
    data.p_R_VLO_NRO_POLIZA = parseInt(reqParam.policyNo);
    data.p_R_VLO_NRO_UNIDAD = reqParam.porPolizaItem;
    // data.p_R_VLO_NRO_UNIDAD = 0;
    data.p_R_VLO_RUT = 0;
  } else if (reqParam.porSinistro || reqParam.porReferenciaCorredor) {
    data.p_R_VLO_NRO_POLIZA = parseInt(reqParam.policyNo);
    data.p_R_VLO_NRO_UNIDAD = reqParam.itemNo;
    // data.p_R_VLO_NRO_UNIDAD = 0;
    data.p_R_VLO_RUT = 0;
  } else {
    data.p_R_VLO_NRO_POLIZA = parseInt(reqParam.policyNo);
    data.p_R_VLO_NRO_UNIDAD = reqParam.itemNo;
    // data.p_R_VLO_NRO_UNIDAD = 0;
    data.p_R_VLO_RUT = reqParam.rut;
  }

  return axios
    .post(base_url + "getProduction", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
};

const getSiniestro = reqParam => {
  let data = {};

  if (reqParam.porPoliza || reqParam.nonRut) {
    data.p_Poliza = reqParam.policyNo;
    data.p_Item = reqParam.porPolizaItem;
  } else if (reqParam.porSinistro || reqParam.porReferenciaCorredor) {
    data.p_Poliza = reqParam.policyNo;
    data.p_Item = reqParam.itemNo;
  } else {
    data.p_Poliza = reqParam.policyNo;
    data.p_Item = reqParam.itemNo;
  }
  // data.p_Item = 0;

  return axios
    .post(base_url + "getSiniestro", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
};

const getreaseguro = reqParam => {
  let data = {};
  data.p_R_VLO_COD_RAMOS = reqParam.ramos;
  if (reqParam.porPoliza || reqParam.nonRut) {
    data.p_R_VLO_NRO_POLIZA = reqParam.policyNo;
    data.p_R_VLO_NRO_UNIDAD = reqParam.porPolizaItem;
    data.p_R_VLO_RUT = 0;
  } else if (reqParam.porSinistro || reqParam.porReferenciaCorredor) {
    data.p_R_VLO_NRO_POLIZA = reqParam.policyNo;
    data.p_R_VLO_NRO_UNIDAD = reqParam.itemNo;
    data.p_R_VLO_RUT = 0;
  } else {
    data.p_R_VLO_NRO_POLIZA = reqParam.policyNo;
    data.p_R_VLO_NRO_UNIDAD = reqParam.itemNo;
    data.p_R_VLO_RUT = reqParam.rut;
  }

  return axios
    .post(base_url + "getreaseguro", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
};

const getMateriaAsseg = reqParam => {
  let data = {};
  data.p_R_VLO_COD_RAMOS = reqParam.ramos;
  if (reqParam.porPoliza || reqParam.nonRut) {
    data.p_R_VLO_NRO_POLIZA = parseInt(reqParam.policyNo);
    data.p_R_VLO_NRO_UNIDAD = reqParam.porPolizaItem;
  } else if (reqParam.porSinistro || reqParam.porReferenciaCorredor) {
    data.p_R_VLO_NRO_POLIZA = parseInt(reqParam.policyNo);
    data.p_R_VLO_NRO_UNIDAD = reqParam.itemNo;
  } else {
    data.p_R_VLO_NRO_POLIZA = parseInt(reqParam.policyNo);
    data.p_R_VLO_NRO_UNIDAD = parseInt(reqParam.itemNo);
  }
  // data.p_R_VLO_NRO_UNIDAD = 0;

  return axios
    .post(base_url + "getMateriaAsseg", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
};

const getCoaseguro = reqParam => {
  let data = {};

  data.policyNo = reqParam.policyNo;

  return axios
    .post(base_url + "getCoaseguro", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
};

export function DetaileDePolizaFun(reqParam) {
  return new Promise(async (resolve, reject) => {
    Promise.all([
      await getProduction(reqParam),
      await getcobranza(reqParam),
      await getSiniestro(reqParam),
      await getreaseguro(reqParam),
      await getMateriaAsseg(reqParam),
      await getCoaseguro(reqParam)
    ])
      .then(response => {
        // TODO: Need to add validation condition

        var responseObjToReturn = {};

        // Production
        if (response && response.length > 0 && response[0].data) {
          let production = response[0].data.production;
          if (
            response[0].data.productionTotal &&
            response[0].data.productionTotal.length > 0
          ) {
            let productionTotal = response[0].data.productionTotal[0];
            productionTotal.total = true;
            production.push({});
            production.push(productionTotal);
          }
          responseObjToReturn.production = production;
        } else {
          let production = [];
          responseObjToReturn.production = production;
        }

        // Cobranza
        if (response && response.length > 1 && response[1].data) {
          let cobranza = response[1].data.cobranza;
          if (
            response[1].data.cobranzaTotal &&
            response[1].data.cobranzaTotal.length > 0
          ) {
            let cobranzaTotal = response[1].data.cobranzaTotal[0];
            cobranzaTotal.total = true;
            cobranza.push({});
            cobranza.push(cobranzaTotal);
          }
          responseObjToReturn.cobranza = cobranza;
        } else {
          let cobranza = [];
          responseObjToReturn.cobranza = cobranza;
        }

        // Siniestro
        // Siniestro
        if (response && response.length > 2 && response[2].data) {
          let siniestro = response[2].data.siniestro
            ? response[2].data.siniestro
            : [];
          if (
            response[2].data.siniestroTotal &&
            response[2].data.siniestroTotal.length > 0
          ) {
            let siniestroTotal = response[2].data.siniestroTotal[0];
            siniestroTotal.total = true;
            siniestro.push({});
            siniestro.push(siniestroTotal);
          }

          responseObjToReturn.siniestro = siniestro;
        } else {
          let siniestro = [];
          responseObjToReturn.siniestro = siniestro;
        }
        // Reaseguro
        if (response && response.length > 3 && response[3].data) {
          let reaseguro = response[3].data.reaseguro;
          if (
            response[3].data.reaseguroTotal &&
            response[3].data.reaseguroTotal.length > 0
          ) {
            let reaseguroTotal = response[3].data.reaseguroTotal[0];
            reaseguroTotal.total = true;
            reaseguro.push({});
            reaseguro.push(reaseguroTotal);
          }

          responseObjToReturn.reaseguro = reaseguro;
        } else {
          let reaseguro = [];
          responseObjToReturn.reaseguro = reaseguro;
        }

        // MateriaAsseg
        if (response && response.length > 4 && response[4].data) {
          let materiaAseg = response[4].data.materiaAseg;
          responseObjToReturn.materiaAseg = materiaAseg;
        } else {
          let materiaAseg = [];
          responseObjToReturn.materiaAseg = materiaAseg;
        }

        // Coaseguro
        if (response && response.length > 5 && response[5].data) {
          let coaseguro = response[5].data;
          responseObjToReturn.coaseguro = coaseguro;
        } else {
          let coaseguro = {};
          responseObjToReturn.coaseguro = coaseguro;
        }
        resolve(responseObjToReturn);
      })
      .catch(error => {
        console.log(error.message);
        reject(error);
      });
  });
}

export function getnegocio() {
  let data = {};

  return axios
    .post(base_url + "getnegocio", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
}

export function getreferencia() {
  let data = {};

  return axios
    .post(base_url + "getreferencia", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
}

export function getPeriodo() {
  let data = {};

  return axios
    .post(base_url + "por-ramos/getPeriodo", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
}

export function getUnoHydNewDetails(reqObj) {
  let data = reqObj;

  return axios
    .post(base_url + "por-ramos/getUnoHydNewDetails", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
}

export function getDosHydNewDetails(reqObj) {
  let data = reqObj;

  return axios
    .post(base_url + "por-ramos/getDosHydNewDetails", data, config)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log("error " + error);
      return error;
    });
}
