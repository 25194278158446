import React from 'react';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';

export default class ProductDetils extends React.Component  {
  constructor(props) {
    super(props);
    this.state =  {
    }
  }
  componentDidMount() {
    this.setState({ data : this.props.data }); 
  }

  componentWillReceiveProps() {
    this.setState({ data : this.props.data }); 
  }

  render() {
    let dataObj=this.state.data;
    return (
      <div className="margin-top-30">
        {dataObj?
            <Card className="Card-area box-show-none">
               <fieldset className="custom-border">
                {/* <legend className="legend-box">Product Details</legend> */}
                {/* <h3 className="Heading-name">Product Detils</h3> */}
                <div>
                    <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="Producto"
                        className="textField"
                        value={dataObj.productID}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                        readOnly: true,
                        }}
                        style = {{width: 300}}
                    />
                    </span>
                    <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label=""
                        className="textField"
                        value={dataObj.productName}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                        readOnly: true,
                        }}
                        style = {{width: 300}}
                    />
                    </span>
                    <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="Deducible"
                        className="textField"
                        margin="normal"
                        value={dataObj.deductible}
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                          }}
                        style = {{width: 300}}
                    />
                    </span>
                </div>
                <div>
                <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label="Plan"
                        className="textField"
                        margin="normal"
                        value={dataObj.planID}
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                          }}
                        style = {{width: 300}}
                    />
                    </span>
                    <span className="Space-around">
                    <TextField InputLabelProps={{
    shrink: true,
}}
                        id="standard-bare"
                        label=""
                        className="textField"
                        margin="normal"
                        value={dataObj.planName}
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                          }}
                        style = {{width: 650}}
                    />
                    </span>
                </div>
                </fieldset>
            </Card>
        :null}
      </div>
    )
  }
}